import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { LockService } from 'src/app/services/lock.service';
import { StoreService } from 'src/app/services/store.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { LOCKDUMMY } from 'src/app/helpers/dummylock';
import { FilterMenuType, MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { addDays, getHours, getMinutes, isAfter, isBefore, set, subDays } from 'date-fns';

@Component({
  selector: 'app-lock-usage',
  templateUrl: './lock-usage.component.html',
  styleUrls: ['./lock-usage.component.sass','../../../styles.sass']
})
export class LockUsageComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  title = "Door events";
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;
  
  minEnd:any = new Date();
  startDate:any = new Date(new Date().setHours(16,0,0,0));
  endDate:any = new Date(new Date().setHours(22,0,0,0) + 24*3600*1000);
  isFormValid:boolean = false;
  selectAllDoors:boolean = false;
  selectAllEvents:boolean = false;
  doorListLoading:boolean = false;
  eventListLoading:boolean = false;
  currentToken:string = "";
  currentDoorCode:string = "";
  doorsList:any[] = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  hotelOnly:boolean = true;
  allFloors:number[] = [];
  missingEntranceFloors:number[] = [];

  isSearching:boolean = false;
  isExporting:boolean = false;
  showResult:boolean = false;
  searchResult:any[] = [];
  prevRequest:any;
  totalCount:number = 0;
  currentCount:number = 0;

  doorMenuOpened:boolean = false;
  showMenuTooltip:boolean = false;
  doorMenuValue:string = "No doors";

  eventFilters:FilterMenuType[] = [];

  constructor(
    private router: Router,
    public utils: UTILS,
    public lockService: LockService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private toast: CustomToastService,
  ) { 

  }

  ngOnInit(): void {
    this.getData();
  }

  menuTooltipTimeOut:any;
  triggerDoorMenu(state:boolean) {
    this.doorMenuOpened = state;
    clearTimeout(this.menuTooltipTimeOut);
    if(state) {
      if(this.getAllFilter().length) {
        this.showMenuTooltip = true;
      }
    } else {
      this.showMenuTooltip = false;
      this.loadEventType();
    }
  }

  getData(): void {
    const processData = (data:any) => {
      if(data && data.length) {
        this.hotels = data;
        let tmpHotelList:MenuType[] = [];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
            value: hotel.hotelId.toString(),
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;
        setTimeout(() => {
          this.checkParam();
        }, 100);
      }
    }
    if(this.hotelConfig.MUIfeature.dummyLock) {
      processData(LOCKDUMMY.DUMMY_LOCK_HOTEL);
    } else {
      this.lockService.getHotels().subscribe(data => {
        if(data && data.length) {
          processData(data)
        }
      })
    }
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    this.loadDoorList(Number(item.value));
  }
  selectHotelByValue(value:string) {
    const findHotel = this.hotelList.find(item => item.value === value);
    if(findHotel) {
      this.selectHotel(findHotel)
    } else {
      this.selectHotel(this.hotelList[0])
    }
  }

  loadDoorList(hotelId:any) {
    this.doorListLoading = true;
    this.doorsList = [];
    this.selectAllEvents = false;
    this.eventFilters = [];
    const findRequestedHotel = this.getHotelObj(hotelId);
    const processDoorData = (data:any) => {
      if(data && data.length) {
        let tmpDoorList:any[] = [];
        const tmpOtherDoors = data.filter((door:any) => !["floor", "room", "elevator"].includes(door.type));
        const otherSortOrder = ["gate", "main", "other"];
        tmpOtherDoors.sort((a:any,b:any) => {
          return otherSortOrder.indexOf(a.type) - otherSortOrder.indexOf(b.type);
        })
        tmpOtherDoors.forEach((door:any) => {
          tmpDoorList.push({
            label: door.accessProfileName,
            value: door.token,
            floor: door.floor,
            type: door.type,
            checked: false
          })
        })
        // Group and sort floor doors
        let tmpFloorDoors:any[] = [];
        let allFloors:number[] = [];
        data.forEach((door:any) => {
          if(!allFloors.includes(door.floor) && door.floor !== -1) {
            allFloors.push(door.floor);
          }
        })
        this.allFloors = allFloors;
        let missingEntranceFloors:number[] = [];
        const allEntrance = data.filter((door:any) => door.type === "floor");
        allFloors.forEach(floor => {
          const floorSortOrder = ["elevator", "floor", "room"];
          const filteredFloorDoors:any[] = data.filter((door:any) => door.floor === floor);
          filteredFloorDoors.sort((a,b) => {
            return floorSortOrder.indexOf(a.type) - floorSortOrder.indexOf(b.type);
          });
          tmpFloorDoors = tmpFloorDoors.concat(filteredFloorDoors);
          const findEntrance = allEntrance.filter((entrance:any) => entrance.floor === floor);
          if(!findEntrance.length) {
            missingEntranceFloors.push(floor);
          }
        })
        this.missingEntranceFloors = missingEntranceFloors;
        tmpFloorDoors.forEach(door => {
          tmpDoorList.push({
            label: door.accessProfileName,
            value: door.token,
            floor: door.floor,
            type: door.type,
            checked: false
          })
        })
        this.doorsList = tmpDoorList;
        this.doorListLoading = false;
      }
    }
    if(this.hotelConfig.MUIfeature.dummyLock) {
      processDoorData(LOCKDUMMY.DUMMY_LOCK_DOOR_LIST);
    } else {
      this.lockService.getAccessDoor(findRequestedHotel.label).subscribe((data:any[]) => {
        processDoorData(data)
      })
    }
  }

  loadEventType(userToken?:any) {
    if(this.selectedHotel) {
      const findRequestedHotel = this.getHotelObj(this.selectedHotel.value);
      const processDummy = (data:string[]) => {
        let tmpEventFilters:FilterMenuType[] = [];
        data.forEach((eventType:string) => tmpEventFilters.push({
          label: eventType,
          value: eventType,
          checked: true
        }));
        this.eventFilters = tmpEventFilters;
        
        this.eventListLoading = false;
      }
      if(userToken) {
        this.eventListLoading = true;
        this.eventFilters = [];
        if(this.hotelConfig.MUIfeature.dummyLock) {
          setTimeout(() => {
            processDummy(LOCKDUMMY.DUMMY_LOCK_EVENT_TYPE);
          }, 2000);
        } else {
          this.lockService.getUserDoorEventType(findRequestedHotel.label, userToken).subscribe((data:any[]) => {
            let tmpEventFilters:FilterMenuType[] = [];
            data.forEach((eventType:string) => tmpEventFilters.push({
              label: eventType,
              value: eventType,
              checked: true
            }));
            this.eventFilters = tmpEventFilters;
            this.eventListLoading = false;
            this.showEvent("new");
          }, error => {
            this.eventListLoading = false;
            this.toast.error(
              error.message,
              'Error!'
            );
          })
        }
      } else {
        const selectedDoors = this.getAllFilter();
        if(selectedDoors.length) {
          this.eventListLoading = true;
          this.eventFilters = [];
          let request:any[] = [];
          selectedDoors.forEach(door => request.push(door.value));
          if(this.hotelConfig.MUIfeature.dummyLock) {
            setTimeout(() => {
              processDummy(LOCKDUMMY.DUMMY_LOCK_EVENT_TYPE);
            }, 2000);
          } else {
            this.lockService.getDoorEventType(findRequestedHotel.label, request).subscribe((data:any[]) => {
              let tmpEventFilters:FilterMenuType[] = [];
              data.forEach((eventType:string) => tmpEventFilters.push({
                label: eventType,
                value: eventType,
                checked: true
              }));
              this.eventFilters = tmpEventFilters;
              this.eventListLoading = false;
            }, error => {
              this.eventListLoading = false;
              this.toast.error(
                error.message,
                'Error!'
              );
            })
          }
        }
      }
    }
  }

  checkParam() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      const hotelId = params.get("hotelId");
      const userToken = params.get("userToken");
      const userDoorCode = params.get("doorCode");
      let checkUserParams = ["hotelId", "userToken", "doorCode", "startDate", "startTime", "endDate", "endTime"].every((key:any) => paramKeys.includes(key));
      let checkHotelParams = ["hotelId"].every((key:any) => paramKeys.includes(key));

      if(checkHotelParams && hotelId) {
        this.selectHotelByValue(hotelId)
      }
      if(!checkUserParams && !checkHotelParams) {
        this.selectHotelByValue(this.hotels[0].hotelId)
      }
      if(!checkUserParams && this.selectedHotel) {
        this.loadDoorList(Number(this.selectedHotel.value));
      }

      if(checkUserParams && userToken && userDoorCode) {
        this.hotelOnly = false;
        this.startDate = new Date(`${params.get("startDate")} ${params.get("startTime")}`);
        this.endDate = new Date(`${params.get("endDate")} ${params.get("endTime")}`);
        this.currentToken = userToken;
        this.currentDoorCode = userDoorCode;
        this.loadEventType(userToken)
      } else {
        this.hotelOnly = true;
      }
    })
  }

  disableSearch() {
    this.isFormValid = false;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  dateChange(value:Date | undefined, type:string) {
    switch(type) {
      case 'startDate':
        {
          let tmpStartDate = value;
          let tmpEndDate = _.cloneDeep(this.endDate);
          if(tmpStartDate && tmpEndDate && isAfter(tmpStartDate, tmpEndDate)) {
            const prevHour = getHours(tmpEndDate);
            const prevMinute = getMinutes(tmpEndDate);
            tmpEndDate = set(addDays(new Date(tmpStartDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
        }
        break;
      case 'endDate':
        {
          let tmpStartDate = _.cloneDeep(this.startDate);
          let tmpEndDate = value;
          if(tmpStartDate && tmpEndDate && isBefore(tmpEndDate, tmpStartDate)) {
            const prevHour = getHours(tmpStartDate);
            const prevMinute = getMinutes(tmpStartDate);
            tmpStartDate = set(subDays(new Date(tmpEndDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
        }
        break;
    }
  }
  setDateFromDropdown(value:Date, type:string) {
    switch (type) {
      case "startDate":
        this.startDate = value;
        break;
      case "endDate":
        this.endDate = value;
        break;
      default:
        break;
    }
  }

  // Door list
  setNumberOfDoors() {
    const checkedDoors = this.getAllFilter().length;
    if(checkedDoors) {
      this.doorMenuValue = `${checkedDoors} ${checkedDoors === 1 ? "door" : "doors"}`;
    } else {
      this.doorMenuValue = "No doors";
    }
  }
  changeDoor(index:number, event:any) {
    this.doorsList[index].checked = event.target.checked;
    this.checkAllDoors();
    this.setNumberOfDoors();
    if(this.doorMenuOpened) {
      if(this.getAllFilter().length) {
        this.showMenuTooltip = true;
      } else {
        this.showMenuTooltip = false;
      }
    }
  }
  setAllDoors(event:any) {
    this.selectAllDoors = event.target.checked;
    if(event.target.checked) {
      this.doorsList.map(door => door.checked = true);
      if(this.doorMenuOpened) {
        this.showMenuTooltip = true;
      }
    } else {
      this.doorsList.map(door => door.checked = false);
      if(this.doorMenuOpened) {
        this.showMenuTooltip = false;
      }
    }
    this.setNumberOfDoors();
  }
  checkAllDoors() {
    const checkUncheckedDoors = this.doorsList.filter(door => !door.checked);
    if(checkUncheckedDoors.length) {
      this.selectAllDoors = false;
    } else {
      this.selectAllDoors = true;
    }
  }
  checkSelectedDoor() {
    const checkCheckedDoors = this.doorsList.filter(door => door.checked);
    if(checkCheckedDoors.length) {
      return true;
    } else {
      return false;
    }
  }
  getAllFilter(){
    return this.doorsList.filter((f: any) => f.checked);
  }

  // Event list
  handleUpdatedEventFilters(newFilters:FilterMenuType[]) {
    this.eventFilters = newFilters
  }
  checkSelectedEvent() {
    const checkCheckedEvents = this.eventFilters.filter(ev => ev.checked);
    if(checkCheckedEvents.length) {
      return true;
    } else {
      return false;
    }
  }
  getAllEvent(){
    return this.eventFilters.filter((f: any) => f.checked);
  }

  getSelectedDoorTokenList() {
    let tokenList:any[] = [];
    const selectedDoors = this.getAllFilter();
    selectedDoors.forEach(door => {
      tokenList.push(door.value)
    })
    return tokenList;
  }

  getSelectedEvent() {
    let evList:any[] = [];
    const selectedEv = this.getAllEvent();
    selectedEv.forEach(ev => {
      evList.push(ev.value)
    })
    return evList;
  }

  getHotelObj(hotelId?:string): any {
    let hotelObj:any = {};
    if(hotelId) {
      hotelObj = this.hotels.find((obj:any) => {
        return obj.hotelId == hotelId;
      });
    } else if (this.selectedHotel) {
      let hotelId = Number(this.selectedHotel.value);
      hotelObj = this.hotels.find((obj:any) => {
        return obj.hotelId == hotelId;
      });
    }
    return hotelObj;
  }

  getHotelObjByLabel(label:string): any {
    let hotelObj = this.hotels.find((obj:any) => {
      return obj.label == label;
    });
    return hotelObj;
  }

  showEvent(type:string) {
    let searchRequest:any;
    this.searchResult = [];
    const hotelLabel = this.getHotelObj().label;
    this.isSearching = true;
    this.showResult = false;
    switch (type) {
      case "next":
        this.prevRequest.offset += 20;
        searchRequest = this.prevRequest;
        break;
      case "prev":
        this.prevRequest.offset -= 20;
        searchRequest = this.prevRequest;
        break;
      default:
        searchRequest = {
          'fromDate': formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
          'fromTime': formatDate(this.startDate, "HH:mm", "en-US"),
          'toDate': formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
          'toTime': formatDate(this.endDate, "HH:mm", "en-US"),
          'filterBy': this.getSelectedEvent(),
          'offset': 0,
          'limit': 20
        }
        break;
    }
    if(!this.currentToken) {
      searchRequest.hotelLabel = hotelLabel;
      searchRequest.doorAccessProfileTokenList = this.getSelectedDoorTokenList();
    }
    this.prevRequest = searchRequest;
    if(this.hotelConfig.MUIfeature.dummyLock) {
      this.processEventResult(LOCKDUMMY.DUMMY_LOCK_USAGE)
    } else {
      if(this.currentToken) {
        this.lockService.getUserUsage(hotelLabel, this.currentToken, searchRequest).subscribe(data => {
          this.processEventResult(data);
        }, error => {
          this.isSearching = false;
          this.toast.error(
            error.message,
            'Error!'
          );
        })
      } else {
        this.lockService.getUsage(hotelLabel, searchRequest).subscribe(data => {
          this.processEventResult(data);
        }, error => {
          this.isSearching = false;
          this.toast.error(
            error.message,
            'Error!'
          );
        })
      }
    }
  }

  processEventResult(data:any) {
    const getResultHotel = this.getHotelObj();
    data.items.forEach((usage:any) => {
      const tmpItem:any = {
        door: usage.accessProfileName,
        doorCode: usage.doorCode,
        time: moment(usage.instant).tz(getResultHotel.zone).format('DD.MM.YYYY HH:mm'),
        doorEvent: usage.eventType,
        doorName: usage.description,
        doorReservation: usage.reservationCode
      };
      this.searchResult.push(tmpItem);
    });
    this.totalCount = data.total;
    this.currentCount = this.prevRequest.offset + 20 > data.total ? data.total : this.prevRequest.offset + 20;
    this.isSearching = false;
    this.showResult = true;
  }

  exportCsv() {
    this.isExporting = true;
    const hotelLabel = this.getHotelObj().label;
    let exportRequest:any = {
      'fromDate': formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
      'fromTime': formatDate(this.startDate, "HH:mm", "en-US"),
      'toDate': formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
      'toTime': formatDate(this.endDate, "HH:mm", "en-US"),
      'filterBy': this.getSelectedEvent(),
    }
    if(this.currentToken) {
      exportRequest.doorCode = this.currentDoorCode;
      this.lockService.exportUserUsage(hotelLabel, this.currentToken, exportRequest).subscribe(data => {
        this.processDownloadCsv(data);
      }, error => {
        this.isExporting = false
      })
    } else {
      exportRequest.hotelLabel = hotelLabel;
      exportRequest.doorAccessProfileTokenList = this.getSelectedDoorTokenList();
      this.lockService.exportUsage(hotelLabel, exportRequest).subscribe(data => {
        this.processDownloadCsv(data);
      }, error => {
        this.isExporting = false
      })
    }
  }

  processDownloadCsv(data:any) {
    const doorCode = this.currentDoorCode ? "code-usage-report-" + this.currentDoorCode : "door-events-report";
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.setAttribute("download", `${doorCode}-${moment(this.startDate).format('YYYY-MM-DD')}-${moment(this.endDate).format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.isExporting = false
  }

  backToTopPage(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  backToLock() {
    this.router.navigate(['/locks'], {queryParams: {back: true}});
  }

}
