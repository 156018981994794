import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { Auth2Service } from 'src/app/services/auth2.service';
import { TenantService } from 'src/app/services/tenant.service';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';
import { ResultAlertType, LoadingAlertType } from '../common/dropdown-menu/dropdown-menu.component';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { LoginResultType } from 'src/app/@types/auth';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  content: any = {
    usernameLabel: 'Email address',
    passwordLabel: 'Password',
    newPasswordLabel: 'New Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    signInBtn: 'Sign in'
  }
  form: any = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  updateForm:any = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  isLoggedIn: boolean = false;
  newPasswordSession: string | null = null;
  uiTabs: any = [];
  lastUrl: string = "";
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  constructor(
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    private auth2Service: Auth2Service,
    public utils: UTILS,
    private toast: CustomToastService,
    private tenantService: TenantService,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private location: Location
  ) { }

  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: '',
    hideFooter: false
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string, hideFooter:boolean = false) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc,
      hideFooter: hideFooter
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  ngOnInit(): void {
    this.sessionCheck();
  }

  checkValidateForm(type:string): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.form.controls.email.status === "INVALID") {
      if (this.form.controls.email.errors.required) {
        this.toast.error("Email is required.", 'Error!');
        return isErrorForm;
      }
      if (this.form.controls.email.errors.email) {
        this.toast.error("Please, enter valid email address.", 'Error!');
        return isErrorForm;
      }
    }
    if (type === "updatePassword") {
      if (this.updateForm.controls.newPassword.status === "INVALID") {
        if (this.updateForm.controls.newPassword.errors.required) {
          this.toast.error("Please enter a new password.", 'Error!');
          return isErrorForm;
        }
        if (this.updateForm.controls.newPassword.errors) {
          this.toast.error("Please enter a valid new password.", 'Error!');
          return isErrorForm;
        }
      }
      if (this.updateForm.controls.newPassword.value != this.updateForm.controls.confirmNewPassword.value) {
        this.toast.error("New password and confirm password do not match.", 'Error!');
        return false;
      }
    }
    else {
      // Check password
      if (this.form.controls.password.status === "INVALID") {
        if (this.form.controls.password.errors.required) {
          this.toast.error("Password is required.", 'Error!');
          return isErrorForm;
        }
        if (this.form.controls.password.errors) {
          this.toast.error("Please enter a valid password.", 'Error!');
          return isErrorForm;
        }
      }
    }
    return !isErrorForm;
  }
  sessionCheck() {
    if(this.hotelConfig.hotel.hotelChainName === "adminPortal") {
      this.route.queryParamMap.subscribe({
        next: (params) => {
          let paramKeys = params.keys;
          let checkBasicParams = ['forceSignOut'].every((key:any) => paramKeys.includes(key));
          if(checkBasicParams) {
            this.auth2Service.logoutAccount();
            setTimeout(() => {
              this.router.navigateByUrl("/login");
            }, 100);
          } else {
            const getToken = window.localStorage.getItem("jwt");
            const getRefreshToken = window.localStorage.getItem("refresh-jwt");
            const getUpdateSession = window.localStorage.getItem("updateSession");
            if(getToken && getUpdateSession === "not-complete") {
              this.auth2Service.logoutAccount();
            }
            if(getToken && getRefreshToken && GlobalUserSession) {
              this.handleRedirectAfterLogin();
            }
          }
        },
      })
    } else {
      this.router.navigateByUrl("/reservations");
    }
  }
  handleRedirectAfterLogin() {
    this.router.navigateByUrl("/dashboard");
  }
  handleSaveSession(data:LoginResultType) {
    this.auth2Service.saveLoginSession(data.token, data.refreshtoken).subscribe({
      next: (result) => {
        this.closeLoadingAlert();
        switch (result) {
          case "success":
            this.toast.success("Log in successfully", 'Success!');
            this.handleRedirectAfterLogin();
            break;
          default:
            this.openResultAlert("error", "Sign in failed", `An error occurred when signing in, please try again later! Error code: ${result}`)
            break;
        }
      }, error: (err) => {
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred when signing in, please try again later");
        this.auth2Service.logoutAccount();
      }
    });
  }
  loginCheck(email:string, password:string, updateSuccess:boolean = false) {
    this.openLoadingAlert(updateSuccess ? "Password updated, signing in..." : "Signing in...");
    this.auth2Service.loginAccount(email, password).subscribe({
      next: (data:LoginResultType) => {
        if (data.challenge && data.session && !updateSuccess) {
          this.closeLoadingAlert();
          localStorage.setItem("updateSession", "not-complete");
          this.newPasswordSession = data.session;
          this.openCustomModal("updatePasswordModal");
        } else if (data.token) {
          this.handleSaveSession(data)
        }
      },
      error: (err) => {
        this.closeLoadingAlert();
        this.toast.error('Sign in failed. Please check credentials', 'Invalid credentials!');
      }
    });
  }
  onSubmit(): void {
    if (!this.checkValidateForm("signin")) {
      return;
    }
    this.loginCheck(this.form.value.email, this.form.value.password);
  }
  updatePassword() {
    if (!this.checkValidateForm("updatePassword")) {
      return;
    }
    if(this.newPasswordSession) {
      this.closeCustomModal("updatePasswordModal");
      this.openLoadingAlert("Updating password...");
      this.auth2Service.confirmUser(this.form.value.email, this.updateForm.controls.newPassword.value, this.newPasswordSession).subscribe({
        next: (data) => {
          if (data.token) {
            this.closeLoadingAlert();
            this.loginCheck(this.form.value.email, this.updateForm.controls.newPassword.value, true);
            window.localStorage.removeItem("updateSession")
          }
        },
        error: (err) => {
          console.log(err)
          this.openCustomModal("updatePasswordModal");
          this.closeLoadingAlert();
          this.openResultAlert("error", "Error", "An error occurred when updating your account password, please try again later")
        }
      });
    }
  }
}
