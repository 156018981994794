<app-header activePage="voucher"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Vouchers</div>
        </div>
      </div>

      <form [formGroup]="voucherSearch">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>Search for</label>
            <input class="form-control custom-input searchQuery" formControlName="searchQuery">

            <label>Optional filters</label>
            <dropdown-menu
              menuType="filter"
              [filterList]="filterList"
              [hasChildValue]="true"
              [fullWidth]="true"
              [minWidth]="300"
              (updatedList)="handleUpdatedFilters($event)"
            ></dropdown-menu>
          </div>
          @if (hotels.length > 1) {
            <div class="col-12 col-md-2">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <div class="col-6 col-md-2">
            <label>Start date</label>
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="startDate"
              [secondDate]="endDate"
              alignment="adaptive"
              [allowEmptyDate]="true"
              (selectDate)="dateChange($event, 'startDate')"
            ></dropdown-menu>

          </div>
          <div class="col-6 col-md-2">
            <label>End date</label>
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="endDate"
              [secondDate]="startDate"
              alignment="adaptive"
              [allowEmptyDate]="true"
              (selectDate)="dateChange($event, 'endDate')"
            ></dropdown-menu>

            <label>Date selection</label>
            <div class="box-selector">
              <div class="custom-radio">
                <input type="radio" name="date-selection" (click)="dateMode = 'OVERLAP'" [checked]="dateMode == 'OVERLAP'">
                <div class="radio-box">Overlap</div>
              </div>
              <div class="custom-radio">
                <input type="radio" name="date-selection" (click)="dateMode = 'EXACT'" [checked]="dateMode == 'EXACT'">
                <div class="radio-box">Exact</div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="bottom-filter">
        <div class="bottom-filter-left"></div>
        <div class="bottom-filter-right">
          <button class="btn btn-none-outline-green large-btn" (click)="clearSearch()"><fa-icon type="solid" icon="trash-can"></fa-icon>Clear search</button>
          <button class="btn btn-green large-btn" (click)="onSearch()"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
        </div>
      </div>

    </div>
  </div>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <app-discount-table [discountResult]="discountResult" [discountCount]="discountCount" (showMore)="showMore('discount')"></app-discount-table>

        <app-monetary-table [monetaryResult]="monetaryResult" [monetaryCount]="monetaryCount" (showMore)="showMore('monetary')"></app-monetary-table>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>