import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { CONSTANT } from 'src/app/helpers/constants';
import { CustomersService } from 'src/app/services/customers.service';
import {UTILS} from 'src/app/helpers/utils';
import { AnimationOptions } from 'ngx-lottie';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { FilterMenuType } from '../common/dropdown-menu/dropdown-menu.component';
import _ from 'lodash';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.sass','../../../styles.sass']
})
export class CustomersComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  optionFilters:FilterMenuType[] = [
    {label: "Full name", value: "FULL_NAME", checked: false},
    {label: "First name", value: "FIRST_NAME", checked: false },
    {label: "Last name", value: "LAST_NAME", checked: false},
    {label: "Email", value: "EMAIL", checked: true},
    {label: "Mobile number", value: "MOBILE_NUMBER", checked: false},
    {label: "SSN", value: "SSN", checked: false},
    {label: "Blocked", value: "BLOCKED", checked: false},
  ];
  selectedCustomer: string = "Email";
  optionFilterAll = ['FIRST_NAME', 'LAST_NAME', 'EMAIL', 'MOBILE', 'SSN'];
  customerStateAll = ['BLOCKED', 'UNBLOCKED'];
  contentAlerts: any = {
    loadResponseNoCustomer: 'No customers found!',
    loadError: 'Failed to load customers!',
    needInputTextSearch: 'You need input into text search!'
  };
  form: any = {
    query: "",
    limit: 30,
    offset: 0,
    filter:''
  };
  loading: boolean = false;
  hasShowMore: boolean = false;
  hasChooseOptionFilters: boolean = true;
  isBackToCustomersPage: boolean = false;
  isShowDashboardBtn: boolean = true;
  customers: any[] = [];
  step: number = 0;
  totalCountCustomer: number = 0;
  limit: number = CONSTANT.LIMITOFSEARCH;
  currentFilterStatus: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    public utils: UTILS,
    private customersService: CustomersService,
    private toast: CustomToastService
  ) { }

  ngOnInit(): void {
  }
  onSubmit(): void {
    this.getCustomerData(this.form, 0);
  }
  checkValidateFormRequest(request: any): Boolean {
    if (request.query === "") {
      // Allow search customer with BLOCKED optional filters
      let hasNotOnlyBlocked = true;
      request.optionalFilters.forEach((optional: any) => { 
        if (optional === 'BLOCKED' && request.optionalFilters.length === 1) {
          hasNotOnlyBlocked = false;
        }
      });
      return hasNotOnlyBlocked;
    }
    return false;
  }
  textChange(): void {
    this.hasChooseOptionFilters = true;
  }
  getCustomerData(request: any, nextpage: number): void {
    if (this.checkValidateFormRequest(request)) {
      this.toast.error(this.contentAlerts.needInputTextSearch, "Error");
      this.hasChooseOptionFilters = false;
      this.customers = [];
      return;
    }
    if (nextpage === 0) {
      this.customers = [];
    }
    this.loading = true;
    this.hasChooseOptionFilters = true;
    let tmpSelectedFilters:string[] = [];
    this.optionFilters.forEach(filter => {
      if(filter.checked) {
        tmpSelectedFilters.push(filter.value)
      }
    })
    request.optionalFilters = tmpSelectedFilters;
    this.customersService.searchCustomers(request).subscribe(
      (data: any) => {
        if (data.results.length === 0) {
          this.toast.error(this.contentAlerts.loadResponseNoCustomer, "Error");
          this.loading = false;
          return;
        }
        this.customers = this.customers.concat(data.results);
        this.hasShowMore = (this.customers.length === data.total) ? false : true;
        this.totalCountCustomer = data.total;
        this.isBackToCustomersPage = true;
        this.loading = false;
      });
  }
  loadMore(): void {
    this.step++;
    const stepForm = cloneDeep(this.form);
    stepForm.offset = stepForm.limit * this.step;
    this.getCustomerData(stepForm, this.step);
  }
  backToTopPage(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  addNewCustomer(): void {
    this.router.navigateByUrl("/customer");
  }
  clearSearchCustomers() {
    let tmpFilters:FilterMenuType[] = _.cloneDeep(this.optionFilters);
    tmpFilters.forEach(filter => {
      if(filter.value === "EMAIL") {
        filter.checked = true;
      } else {
        filter.checked = false;
      }
    })
    this.optionFilters = tmpFilters;
    this.loading = false;
    this.hasChooseOptionFilters = true;
    this.customers = [];
    this.form = {
      query: "",
      limit: 30,
      offset: 0
    };
  }
  handleUpdatedFilters(newFilters:FilterMenuType[]) {
    this.optionFilters = newFilters;
  }
  dashboardOnCustomers(): void {
    this.router.navigateByUrl("/customers/customer-dashboard");
  }
  selectCustomer(email: string,mobile: string) {
    this.router.navigate(["customer-card/", email, mobile.substring(1,mobile.length)]);
  }
  selectStatus(status:string):void{
    if(this.currentFilterStatus===''){
      this.currentFilterStatus = status;
      return
    }if(this.currentFilterStatus === status){
      this.currentFilterStatus = '';

      return
    }
    this.currentFilterStatus = status;

  }
}