<app-header activePage="locks"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Axis Lock UI</h1>
        </div>
      </div>
      <form [formGroup]="lockDetail">
        <div class="row">
          <div class="col-6 col-md-2" style="margin-bottom: 10px">
            @if (hotels.length > 1) {
              <div>
                <label>Hotel</label>
                <dropdown-menu
                  [menuList]="hotelList"
                  [selectedItem]="selectedHotel"
                  [fullWidth]="true"
                  (selectItem)="selectHotel($event)"
                ></dropdown-menu>
              </div>
            }
          </div>
          <div class="col-6 col-md-3" style="margin-bottom: 10px">
            @if (!isCreateNewCode) {
              <label>Reservation</label>
              <input class="form-control custom-input searchQuery" formControlName="reservation">
            }
          </div>
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12 col-md-6" style="margin-bottom: 10px">
                <label>Select doors</label>
                <div class="door-checkbox-list">
                  <div class="menu-tooltip-list" [ngClass]="{'show': showMenuTooltip}">
                    <div class="menu-title">Selected doors</div>
                    @for (selectedDoor of getAllFilter(); track selectedDoor) {
                      <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                        {{selectedDoor.label}}
                      </div>
                    }
                  </div>
                  <dropdown-menu
                    [fullWidth]="true"
                    menuType="custom"
                    [highlightError]="doorMenuValue === 'No doors'"
                    [menuButtonValue]="doorMenuValue"
                    (eventMenuOpened)="triggerDoorMenu($event)"
                  >
                    <div class="door-item-list">
                      <div class="normal-checkbox" (click)="setAllDoors($event)">
                        <input type="checkbox" [checked]="selectAllDoors">
                        <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        <div class="checkbox-label">All</div>
                      </div>
                      @for (door of doorsList; track door; let i = $index) {
                        @if (!['floor', 'room', 'elevator'].includes(door.type)) {
                          <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                            <input type="checkbox" [checked]="door.checked">
                            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                            <div class="checkbox-label">{{door.label}}</div>
                          </div>
                        }
                      }
                      @for (floor of allFloors; track floor) {
                        @for (door of doorsList; track door; let i = $index) {
                          <!-- Elevator -->
                          @if (door.type === 'elevator' && door.floor === floor) {
                            <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                              <input type="checkbox" [checked]="door.checked">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{door.label}}</div>
                            </div>
                          }
                          <!-- Rooms with entrances -->
                          @if (door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)) {
                            <div class="accordion">
                              <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                              <div class="normal-checkbox main-checkbox" style="height: 32.84px">
                                <div class="checkbox-content" (click)="changeDoor(i,$event)">
                                  <input type="checkbox" [checked]="door.checked">
                                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                  <div class="checkbox-label">{{door.label}}</div>
                                </div>
                                <label class="accordion-arrow" for="floor{{door.floor}}">
                                  <fa-icon type="solid" icon="chevron-right"></fa-icon>
                                </label>
                              </div>
                              <div class="accordion-content">
                                @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                  @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                    <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                      <input type="checkbox" [checked]="roomDoor.checked">
                                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                      <div class="checkbox-label">{{roomDoor.label}}</div>
                                    </div>
                                  }
                                }
                              </div>
                            </div>
                          }
                        }
                        <!-- Rooms without entrances -->
                        @if (missingEntranceFloors.includes(floor)) {
                          <div class="accordion">
                            <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                            <div class="normal-checkbox main-checkbox">
                              <div class="checkbox-content">
                                <input type="checkbox" disabled>
                                <div class="checkbox-box disabled"><fa-icon type="solid" icon="check"></fa-icon></div>
                                <div class="checkbox-label">
                                  Floor {{floor}}
                                  <div class="checkbox-desc">(No entrance)</div>
                                </div>
                              </div>
                              <label class="accordion-arrow" for="floor{{floor}}">
                                <fa-icon type="solid" icon="chevron-right"></fa-icon>
                              </label>
                            </div>
                            <div class="accordion-content">
                              @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                  <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                    <input type="checkbox" [checked]="roomDoor.checked">
                                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                    <div class="checkbox-label">{{roomDoor.label}}</div>
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </dropdown-menu>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-bottom: 10px">
                <label>Name</label>
                <input class="form-control custom-input" [ngClass]="{error: doorCodeName.invalid}" formControlName="name">
              </div>
            </div>
          </div>
          <div class="col-6 col-md-2" style="margin-bottom: 10px">
            <label>Door code</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Input code" required [ngClass]="{error: doorCode.invalid}" formControlName="doorCode">
              <button type="button" class="datepicker-btn" (click)="reRollDoorCode()"  [ngClass]="{disabled: !isCreateNewCode}">
                <fa-icon type="solid" icon="arrows-rotate"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-6 col-md-3" style="margin-bottom: 10px">
            @if (!isCreateNewCode) {
              <label class="empty-label-for-btn"></label>
              <button class="btn btn-green input-height full-width" (click)="openEventLog()">Event log</button>
            }
          </div>
          <div class="col-12 col-md-7">
            <div class="row" [ngClass]="{disabled:deactivate}">
              <div class="col-sm-6" style="margin-bottom: 10px">
                <label>Start date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="startDate"
                      [secondDate]="endDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="startDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" style="margin-bottom: 10px">
                <label>End date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="endDate"
                      [secondDate]="startDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="endDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            @if (!isCreateNewCode) {
              <div class="normal-checkbox" style="margin-top: 10px" (click)="setDeactivate($event)">
                <input type="checkbox" [checked]="deactivate">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">De-Activate</div>
              </div>
            }
          </div>
          <div class="col-12">
            <div class="right-btns">
              <button class="btn btn-green input-height" (click)="saveDoorCode()" [disabled]="lockDetail.invalid || !isFormValid || !checkSelectedDoor()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Save</button>
              <button class="btn btn-green input-height" (click)="backToLock()"><fa-icon type="solid" icon="ban"></fa-icon>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>