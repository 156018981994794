import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityLogType, ReservationHotelType } from '../activity-log-2.component';
import { CountryDataType, UTILS } from 'src/app/helpers/utils';
import { CONSTANT } from 'src/app/helpers/constants';
import { format, isAfter } from 'date-fns';
import {default as countryData} from 'src/assets/resources/country.json';
import _ from 'lodash';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { ProductByIdType } from '../../reservation-detail/reservation-detail.component';
import { TZDate } from '@date-fns/tz';
import { GlobalSegmentList } from 'src/app/helpers/global';

// Reservation data type
type Guest = {
  id: number;
  age: number | null;
  ssn: string | null;
  city: string;
  email: string | null;
  index: number;
  mobile: string | null;
  signed: boolean;
  address: string;
  blocked: boolean;
  country: string;
  lastName: string;
  firstName: string;
  roomAlias: number;
  idUploaded: string | null;
  postalCode: string;
  dateOfBirth: string | null;
  nationality: string | null;
  passportNumber: string | null;
  purposeOfVisit: string | null;
  completedCheckIn: string | null;
  completedCheckInOnline: boolean;
};

type Price = {
  amount: string;
  vatPercentage: number;
};

type LineItem = {
  price: Price;
  cancelled: string | null;
  productId: number;
  amountPaid: number;
  refundable: boolean;
  invoiceDate: string;
  serviceDate: string;
  voucherCode: string | null;
  otaRatePlanId: string | null;
  isAdjustmentPrice: boolean;
};

type RoomRequest = {
  id: number;
  end: string;
  start: string;
  hotelId: number;
  productId: number;
  forDisabled: boolean;
};

type Order = {
  id: number;
  labels: string[];
  roomId: number | null;
  cancelled: string | null;
  lineItems: LineItem[];
  roomAlias: number;
  roomLabel: string | null;
  isOverrided: boolean;
  roomRequest: RoomRequest;
  totalAdults: number;
  totalChildren: number;
  totalExternalGuest: number;
};

type Company = {
  name: string;
  ytunnus: string;
  reference: string;
};

type Customer = {
  ssn: string;
  city: string;
  mobile: string;
  signed: boolean;
  address: string;
  blocked: boolean;
  country: string;
  lastName: string;
  emailReal: string;
  firstName: string;
  postalCode: string | null;
  dateOfBirth: string;
  nationality: string;
  emailVirtual: string | null;
  passportNumber: string;
  purposeOfVisit: string;
};

type Reservation = {
  state: string;
  guests: Guest[];
  orders: Order[];
  checkIn: string;
  company: Company;
  checkOut: string;
  customer: Customer;
  lineItems: LineItem[];
  breakfastsForAll: boolean;
};

// Paragraph type
type ParagraphType = {
  [key:string]: string | string[]
}

// Compare type
type CompareType = {
  attribute: string,
  previous: string,
  current: string
}

export type ActivityItemType = {
  avatar: string,
  avatarColor: string,
  action: string,
  authorName: string,
  time: string,
  content: any
}

// Reservation type
type CompareStringParamType = {
  previous?: string,
  current: string
}
type CompareCompanyParamType = {
  previous?: Company,
  current: Company
}
type ContentParagraphType = {
  key: string,
  value: string,
  type: string
}

type ReservationActivityOrderLineItemType = {
  productId: number,
  productName: string,
  amount: number,
  startDate: string,
  endDate: string,
  price: string,
  vatPercentage: string
}
type ReservationActivityOrderLineItemDisplayType = {
  title: string,
  values: ContentParagraphType[]
}
export type CompareLineItemsParamType = {
  added: ReservationActivityOrderLineItemDisplayType[],
  removed: ReservationActivityOrderLineItemDisplayType[]
}

type ReservationActivityOrderType = {
  orderCancelledTime: string | null,
  roomLabel: string | null,
  isOverrided: boolean,
  totalAdults: number,
  totalChildren: number,
  totalExternalGuest: number,
  lineItems?: CompareLineItemsParamType
}
type ReservationActivityOrderDisplayType = {
  title: string,
  values: ContentParagraphType[],
  lineItems?: CompareLineItemsParamType
}
type OrderChangeType = {
  valueChanged: CompareType[],
  roomLabel: string | null,
  lineItems?: CompareLineItemsParamType
}
type CompareOrderParamType = {
  changed: OrderChangeType[],
  removed: ReservationActivityOrderDisplayType[],
  added: ReservationActivityOrderDisplayType[]
}
type ReservationCompareType = {
  title: string,
  values: CompareType[]
}
export type ReservationActivityType = {
  compare: ReservationCompareType[],
  orders: CompareOrderParamType
}

@Component({
  selector: 'activity-log-item',
  templateUrl: './activity-log-item.component.html',
  styleUrl: './activity-log-item.component.sass'
})
export class ActivityLogItemComponent implements OnInit {
  @Input() activity:ActivityLogType | null = null;
  @Input() prevActivity:ActivityLogType | null = null;
  @Input() hotel?:ReservationHotelType;
  @Input() products?:ProductByIdType;
  @ViewChild('accordionContent', { static: false }) accordionContent!: ElementRef;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  accordionHeight:number = 0;
  accordionOpened:boolean = false;
  activityItem:ActivityItemType | null = null;
  activityType:string = "PARAGRAPH";

  constructor(
    private utils: UTILS,
    private storeService: StoreService,
  ) { }
  
  ngOnInit(): void {
    this.setupActivity();
  }

  findActivityGroup():string | null {
    let selectedGroup:string | null = null;
    if(this.activity) {
      CONSTANT.activityGroup.forEach(group => {
        const findAction = group.actionList.find(action => action === this.activity?.action);
        if(findAction) {
          selectedGroup = group.type;
          this.activityType = group.type;
        }
      })
    }
    return selectedGroup;
  }

  setupActivity() {
    if(this.activity && this.hotel) {
      let avatarColor = "nelsonUser"
      switch(this.activity.author.name) {
        case "System":
          avatarColor = "system";
          break;
        case "User":
          avatarColor = "user";
          break;
        default:
          avatarColor = "nelsonUser";
          break;
      }

      const contentType = this.findActivityGroup();
      let content:any = null;
      switch (contentType) {
        case "PARAGRAPH":
          content = this.setupActivityParagraph();
          break;
        case "COMPARE":
          content = this.setupActivityCompare();
          break;
        case "RESERVATION":
          content = this.setupActivityReservation();
          break;
        default:
          content = this.setupActivityParagraph();
          break;
      }

      let tmpActivityItem: ActivityItemType = {
        avatar: this.utils.createAvatarFromName(this.activity.author.name),
        avatarColor: avatarColor,
        action: CONSTANT.activityActions[this.activity.action] || this.activity.action,
        authorName: this.activity.author.name,
        time: format(new TZDate(new Date(this.activity.timestamp), this.hotel.zone), "HH:mm"),
        content: content
      }
      this.activityItem = tmpActivityItem;
      if((contentType && !["RESERVATION"].includes(contentType)) || !contentType) {
        setTimeout(() => {
          this.toggleAccordion("open");
        }, 500);
      }
    }
  }

  convertCamelTextToText(text:string) {
    const wordsToConvert = ["SSN", "ISO", "ID", "OTA"];
    const wordsRegex = new RegExp(`\\b(${wordsToConvert.join('|')})\\b`, 'gi');
    return text
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(wordsRegex, (match) => match.toUpperCase())
        .replace(/^./, char => char.toUpperCase());
  }
  convertUnderscoreTextToText(input: string): string {
    const formatted = input.toLowerCase().replace(/_/g, ' ');
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  // Setup paragraph
  handleKey(text: string): string {
    switch (text) {
      case "roomLabel":
        return "Room"
      case "labels":
        return "Updated requests"
      case "isSucceeded":
        return "Reset successfully"
      case "isOverrided":
        return "Is overridden"
      case "toBeRefunedAmount":
        return "Refund amount"
      case "destinations":
        return "Sent to"
      case "notified":
        return "Allow send notifications"
      case "orderCancelledTime":
        return "Room cancelled time"
      default:
        return this.convertCamelTextToText(text)
    }
  }
  uppercaseToNormalText(text: string): string {
    if(text) {
      return text
        .toLowerCase()
        .replace(/^./, char => char.toUpperCase());
    } else {
      return text
    }
  }
  getCountryNameByISO(iso:string) {
    const countries:CountryDataType[] = countryData;
    const findCountry = countries.find(country => country.iso3 === iso);
    if(findCountry) {
      return findCountry.name
    } else {
      return iso
    }
  }
  handleValue(key:string, value:any) {
    const countryFields = ["country", "nationality"];
    const fieldWithUppercase = ["purposeOfVisit", "option", "invoiceStatus"];
    const fennoaPaymentTypeField = ["fennoaPaymentType"];
    const fieldWithLocale = ["invoiceLanguage"];
    const fieldWithUnderscore = ["emailType", "linkType", "messageSource", "guestCommsType"];

    const dateISOCheck = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
    const dateISOCheckExtened = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z$/;
    const dateCheck = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const priceCheck = /^[A-Z]{3} (\d+(\.\d+)?([eE][+-]?\d+)?|\.\d+([eE][+-]?\d+)?)$/;

    let tmpValue = value;
    if(countryFields.includes(key)) {
      tmpValue = this.getCountryNameByISO(value)
    } else if (fieldWithUppercase.includes(key)) {
      tmpValue = this.uppercaseToNormalText(value)
    } else if (fennoaPaymentTypeField.includes(key)) {
      tmpValue = this.findFennoaPaymentType(value)
    } else if (fieldWithLocale.includes(key)) {
      tmpValue = this.findLanguage(value)
    } else if (fieldWithUnderscore.includes(key)) {
      tmpValue = this.convertUnderscoreTextToText(value)
    } else if (typeof value === "boolean") {
      tmpValue = value ? "Yes" : "No"
    } else if (priceCheck.test(value)) {
      const splitPrice = tmpValue.split(" ");
      tmpValue = `${Number(splitPrice[1]).toFixed(2)} ${this.utils.getCurrency(splitPrice[0])}`
    } else if (dateISOCheck.test(value) || dateISOCheckExtened.test(value)) {
      tmpValue = format(new TZDate(new Date(value), this.hotel ? this.hotel.zone : "Europe/Helsinki"), "dd.MM.yyyy HH:mm")
    } else if (dateCheck.test(value)) {
      tmpValue = format(new Date(value), "dd.MM.yyyy")
    }
    return tmpValue
  }
  handleObjectValue(object:any) {
    let finalData:any[] = [];
    const activityGroup = this.findActivityGroup();
    if(this.activity) {
      const dataKey = Object.keys(object);
      dataKey.forEach((key) => {
        if((key !== "action" && activityGroup !== "RESERVATION") || (activityGroup === "RESERVATION" && key !== "lineItems")) {
          let value:any = "";
          let type = "default";
          const tmpValue:any = object[key];
          if(!tmpValue) {
            if (key === "notified") {
              value = "No"
            } else if (key === "error") {
              value = "No error"
            } else if (tmpValue === 0) {
              value = "0"
            } else {
              value = "<Empty>"
              type = "valueEmpty"
            }
          } else if (tmpValue.constructor === Array) {
            const checkArrayObject = tmpValue.some(item => item !== null && item.constructor !== Array && typeof item === "object");
            type = checkArrayObject ? "valueObject" : "default";
            if(checkArrayObject) {
              let tmpFinalValue:any[] = [];
              tmpValue.forEach((objectValue:any, objectIndex:number) => {
                tmpFinalValue.push({
                  key: `${this.handleKey(key)} ${objectIndex + 1}`,
                  value: this.handleObjectValue(objectValue),
                  type: "valueObject"
                })
              })
              value = tmpFinalValue;
            } else {
              let tmpFinalValue:string[] = [];
              if(key === "labels") {
                tmpValue.forEach((label:string) => {
                  tmpFinalValue.push(this.renderLabels(label))
                })
                value = tmpFinalValue
              } else {
                tmpFinalValue = tmpValue
              }
              value = tmpFinalValue.map(item => (!item ? '<empty value>' : item)).join(', ');
            }
          } else if(tmpValue.constructor !== Array && typeof tmpValue === "object") {
            value = this.handleObjectValue(tmpValue);
            type = "valueObject";
          } else {
            value = this.handleValue(key, tmpValue)
          }
          if(value || (value.constructor === Array && value.length > 0)) {
            finalData.push({
              key: this.handleKey(key),
              value: value,
              type: type
            })
          }
        }
      })
    }
    return finalData;
  }
  setupActivityParagraph() {
    if(this.activity) {
      const data:ParagraphType | null = this.activity.data;
      if(data) {
        return this.handleObjectValue(data);
      } else {
        return null;
      }
    }
    return null;
  }

  // Setup compare
  getSegmentName(codeName:string) {
    if(GlobalSegmentList.length) {
      const findSegment = GlobalSegmentList.find(segment => segment.code === codeName);
      if(findSegment) {
        return findSegment.name;
      } else {
        return codeName;
      }
    }
    return codeName;
  }
  setupActivityCompare() {
    if(this.activity) {
      const data:any = this.activity.data;
      let finalData:CompareType[] = [];
      if(this.activity.action === "UPDATE_LANGUAGE") {
        finalData.push({
          attribute: "Reservation language",
          previous: this.findLanguage(data.oldValue),
          current: this.findLanguage(data.newValue)
        })
      } else if(this.activity.action === "CHANGE_ROOM") {
        finalData.push({
          attribute: "Room",
          previous: this.findLanguage(data.sourceRoomLabel),
          current: this.findLanguage(data.destinationRoomLabel)
        })
      } else {
        let dataKey:string[] = [];
        switch (this.activity.action) {
          case "UPDATE_CONTACT_INFO_NEW":
          case "UPDATE_GUEST_INFO_NEW":
            dataKey = Object.keys(data.current);
            dataKey.forEach((key:string) => {
              finalData.push({
                attribute: this.convertCamelTextToText(key),
                previous: this.handleValue(key, data.previous[key]),
                current: this.handleValue(key, data.current[key])
              })
            })
            break;
          case "UPDATE_RESERVATION_SEGMENT":
            finalData.push({
              attribute: "Segment",
              previous: this.getSegmentName(data.previous),
              current: this.getSegmentName(data.current)
            })
            break;
          default:
            break;
        }
      }
      return finalData;
    }
  }

  // Setup reservation
  convertDate(date:string) {
    return format(new TZDate(new Date(date), this.hotel ? this.hotel.zone : "Europe/Helsinki"), "dd.MM.yyyy HH:mm");
  }
  getPriceNumber(price:string) {
    return Number(price.split(" ")[1])
  }
  compareLineItems(data:LineItem[], prevData?:LineItem[]):CompareLineItemsParamType | undefined {
    let finalLineItems: CompareLineItemsParamType | undefined = {} as CompareLineItemsParamType;
    let tmpCurrent:ReservationActivityOrderLineItemType[] = [];
    let tmpRemoved:ReservationActivityOrderLineItemType[] = [];
    let tmpPrevCurrent:ReservationActivityOrderLineItemType[] = [];
    let tmpPrevRemoved:ReservationActivityOrderLineItemType[] = [];
    let uniqueProductId:number[] = [...new Set(data.map(item => item.productId))];
    uniqueProductId.forEach(productId => {
      if(this.products) {
        let tmpItemCurrent: ReservationActivityOrderLineItemType = {
          productId: productId,
          productName: this.products[productId] ? this.utils.getProductName(this.products[productId].name) : `Product ID ${productId}`,
          amount: 0,
          startDate: "",
          endDate: "",
          price: "",
          vatPercentage: ""
        }
        let tmpItemRemoved: ReservationActivityOrderLineItemType = {
          productId: productId,
          productName: this.products[productId] ? this.utils.getProductName(this.products[productId].name) : `Product ID ${productId}`,
          amount: 0,
          startDate: "",
          endDate: "",
          price: "",
          vatPercentage: ""
        }
        let currency = "EUR";
        let priceCurrent = 0;
        let priceRemoved = 0;
        data.forEach(lineItem => {
          if(lineItem.productId === productId) {
            if(lineItem.cancelled) {
              if(!tmpItemRemoved.startDate) {
                tmpItemRemoved.startDate = lineItem.serviceDate;
              }
              if(!tmpItemRemoved.endDate) {
                tmpItemRemoved.endDate = lineItem.serviceDate;
              } else if(isAfter(new Date(lineItem.serviceDate), tmpItemRemoved.endDate)) {
                tmpItemRemoved.endDate = lineItem.serviceDate;
              }
              if(!tmpItemRemoved.vatPercentage) {
                tmpItemRemoved.vatPercentage = `${lineItem.price.vatPercentage}%`;
              }
              tmpItemRemoved.amount++;
              const splitPrice = lineItem.price.amount.split(" ");
              currency = splitPrice[0];
              priceRemoved = priceRemoved + Number(splitPrice[1]);
            } else {
              if(!tmpItemCurrent.startDate) {
                tmpItemCurrent.startDate = lineItem.serviceDate;
              }
              if(!tmpItemCurrent.endDate) {
                tmpItemCurrent.endDate = lineItem.serviceDate;
              } else if(isAfter(new Date(lineItem.serviceDate), tmpItemCurrent.endDate)) {
                tmpItemCurrent.endDate = lineItem.serviceDate;
              }
              if(!tmpItemCurrent.vatPercentage) {
                tmpItemCurrent.vatPercentage = `${lineItem.price.vatPercentage}%`;
              }
              tmpItemCurrent.amount++;
              const splitPrice = lineItem.price.amount.split(" ");
              currency = splitPrice[0];
              priceCurrent = priceCurrent + Number(splitPrice[1]);
            }
          }
        })
        tmpItemCurrent.price = `${currency} ${priceCurrent.toFixed(2)}`;
        tmpItemRemoved.price = `${currency} ${priceRemoved.toFixed(2)}`;
        tmpCurrent.push(tmpItemCurrent);
        tmpRemoved.push(tmpItemRemoved);

        // Prev data
        if(prevData) {
          let tmpItemPrevCurrent: ReservationActivityOrderLineItemType = {
            productId: productId,
            productName: this.products[productId] ? this.utils.getProductName(this.products[productId].name) : `Product ID ${productId}`,
            amount: 0,
            startDate: "",
            endDate: "",
            price: "",
            vatPercentage: ""
          }
          let tmpItemPrevRemoved: ReservationActivityOrderLineItemType = {
            productId: productId,
            productName: this.products[productId] ? this.utils.getProductName(this.products[productId].name) : `Product ID ${productId}`,
            amount: 0,
            startDate: "",
            endDate: "",
            price: "",
            vatPercentage: ""
          }
          let currency = "EUR";
          let pricePrevCurrent = 0;
          let pricePrevRemoved = 0;
          prevData.forEach(lineItem => {
            if(lineItem.productId === productId) {
              if(lineItem.cancelled) {
                if(!tmpItemPrevRemoved.startDate) {
                  tmpItemPrevRemoved.startDate = lineItem.serviceDate;
                }
                if(!tmpItemPrevRemoved.endDate) {
                  tmpItemPrevRemoved.endDate = lineItem.serviceDate;
                } else if(isAfter(new Date(lineItem.serviceDate), tmpItemPrevRemoved.endDate)) {
                  tmpItemPrevRemoved.endDate = lineItem.serviceDate;
                }
                if(!tmpItemPrevRemoved.vatPercentage) {
                  tmpItemPrevRemoved.vatPercentage = `${lineItem.price.vatPercentage}%`;
                }
                tmpItemPrevRemoved.amount++;
                const splitPrice = lineItem.price.amount.split(" ");
                currency = splitPrice[0];
                pricePrevRemoved = pricePrevRemoved + Number(splitPrice[1]);
              } else {
                if(!tmpItemPrevCurrent.startDate) {
                  tmpItemPrevCurrent.startDate = lineItem.serviceDate;
                }
                if(!tmpItemPrevCurrent.endDate) {
                  tmpItemPrevCurrent.endDate = lineItem.serviceDate;
                } else if(isAfter(new Date(lineItem.serviceDate), tmpItemPrevCurrent.endDate)) {
                  tmpItemPrevCurrent.endDate = lineItem.serviceDate;
                }
                if(!tmpItemPrevCurrent.vatPercentage) {
                  tmpItemPrevCurrent.vatPercentage = `${lineItem.price.vatPercentage}%`;
                }
                tmpItemPrevCurrent.amount++;
                const splitPrice = lineItem.price.amount.split(" ");
                currency = splitPrice[0];
                pricePrevCurrent = pricePrevCurrent + Number(splitPrice[1]);
              }
            }
          })
          tmpItemPrevCurrent.price = `${currency} ${pricePrevCurrent.toFixed(2)}`;
          tmpItemPrevRemoved.price = `${currency} ${pricePrevRemoved.toFixed(2)}`;
          tmpPrevCurrent.push(tmpItemPrevCurrent);
          tmpPrevRemoved.push(tmpItemPrevRemoved);
        }
      }
    })
    // console.log(tmpCurrent)
    // console.log(tmpRemoved)
    // console.log(tmpPrevCurrent)
    // console.log(tmpPrevRemoved)
    // console.log("-------------------")
    let tmpFinalAdded:ReservationActivityOrderLineItemType[] = [];
    let tmpFinalRemoved:ReservationActivityOrderLineItemType[] = [];
    tmpCurrent.forEach(product => {
      const finalProduct = _.cloneDeep(product)
      const currency = product.price.split(" ")[0];

      const findPrevCurrent = tmpPrevCurrent.find(item => item.productId === product.productId);
      let amountAdded = product.amount;
      let priceAdded = this.getPriceNumber(product.price);
      if(findPrevCurrent) {
        amountAdded = product.amount - findPrevCurrent.amount;
        priceAdded = this.getPriceNumber(product.price) - this.getPriceNumber(findPrevCurrent.price)
      }
      if(amountAdded > 0 || priceAdded > 0) {
        finalProduct.amount = amountAdded;
        finalProduct.price = `${currency} ${priceAdded}`;
        tmpFinalAdded.push(finalProduct);
      }
    })
    tmpRemoved.forEach(product => {
      const finalProduct = _.cloneDeep(product)
      const currency = product.price.split(" ")[0];

      const findPrevRemoved = tmpPrevRemoved.find(item => item.productId === product.productId);
      let amountRemoved = product.amount;
      let priceRemoved = this.getPriceNumber(product.price);
      if(findPrevRemoved) {
        amountRemoved = product.amount - findPrevRemoved.amount;
        priceRemoved = this.getPriceNumber(product.price) - this.getPriceNumber(findPrevRemoved.price)
      }
      if(amountRemoved > 0 || priceRemoved > 0) {
        finalProduct.amount = amountRemoved;
        finalProduct.price = `${currency} ${priceRemoved}`;
        tmpFinalRemoved.push(finalProduct)
      }
    })

    if(tmpFinalAdded.length) {
      let finalAdded:ReservationActivityOrderLineItemDisplayType[] = [];
      tmpFinalAdded.forEach((item) => {
        finalAdded.push({
          title: item.productName,
          values: this.handleObjectValue(item)
        })
      })
      finalLineItems.added = finalAdded;
    }
    if(tmpFinalRemoved.length) {
      let finalRemoved:ReservationActivityOrderLineItemDisplayType[] = [];
      tmpFinalRemoved.forEach((item) => {
        finalRemoved.push({
          title: item.productName,
          values: this.handleObjectValue(item)
        })
      })
      finalLineItems.removed = finalRemoved;
    }
    if(!tmpFinalAdded.length && !tmpFinalRemoved.length) {
      finalLineItems = undefined
    }
    return finalLineItems;
  }
  compareOrder(data:Order[], prevData?:Order[]):CompareOrderParamType {
    let changed:OrderChangeType[] = [];
    let removed:ReservationActivityOrderType[] = [];
    let added:ReservationActivityOrderType[] = [];
    data.forEach((order, orderIndex) => {
      let currentOrder:ReservationActivityOrderType = {
        orderCancelledTime: order.cancelled ? this.convertDate(order.cancelled) : null,
        roomLabel: order.roomLabel,
        isOverrided: order.isOverrided,
        totalAdults: order.totalAdults,
        totalChildren: order.totalChildren,
        totalExternalGuest: order.totalChildren,
        lineItems: this.compareLineItems(order.lineItems)
      }
      if(prevData) {
        const prevOrder = prevData.find(prevOrder => prevOrder.id === order.id);
        if(prevOrder) {
          let orderData:OrderChangeType = {
            valueChanged: [],
            roomLabel: order.roomLabel ? `Room ${order.roomLabel}` : `Unassigned room ${orderIndex + 1}`,
            lineItems: this.compareLineItems(order.lineItems, prevOrder.lineItems)
          }
          if(order.cancelled && !prevOrder.cancelled) {
            removed.push(currentOrder);
          }
          if(order.roomLabel !== prevOrder.roomLabel) {
            orderData.valueChanged.push({
              attribute: "Room",
              previous: prevOrder.roomLabel || "",
              current: order.roomLabel || ""
            })
          }
          if(order.isOverrided !== prevOrder.isOverrided) {
            orderData.valueChanged.push({
              attribute: "Is overridden",
              previous: this.handleValue("order", prevOrder.isOverrided),
              current: this.handleValue("order", order.isOverrided)
            })
          }
          if(order.totalAdults !== prevOrder.totalAdults) {
            orderData.valueChanged.push({
              attribute: "Total adults",
              previous: prevOrder.totalAdults.toString(),
              current: order.totalAdults.toString()
            })
          }
          if(order.totalChildren !== prevOrder.totalChildren) {
            orderData.valueChanged.push({
              attribute: "Total children",
              previous: prevOrder.totalChildren.toString(),
              current: order.totalChildren.toString()
            })
          }
          if(order.totalExternalGuest !== prevOrder.totalExternalGuest) {
            orderData.valueChanged.push({
              attribute: "Total external guests",
              previous: prevOrder.totalExternalGuest.toString(),
              current: order.totalExternalGuest.toString()
            })
          }
          changed.push(orderData)
        } else {
          added.push(currentOrder); 
        }
      } else {
        added.push(currentOrder);        
      }
    })

    let finalAdded:ReservationActivityOrderDisplayType[] = [];
    added.forEach((item, index) => {
      finalAdded.push({
        title: item.roomLabel ? `Room ${item.roomLabel}` : `Unassigned room ${index + 1}`,
        values: this.handleObjectValue(item),
        lineItems: item.lineItems
      })
    })

    let finalRemoved:ReservationActivityOrderDisplayType[] = [];
    removed.forEach((item, index) => {
      finalRemoved.push({
        title: item.roomLabel ? `Room ${item.roomLabel}` : `Unassigned room ${index + 1}`,
        values: this.handleObjectValue(item),
        lineItems: item.lineItems
      })
    })

    return {
      changed: changed,
      removed: finalRemoved,
      added: finalAdded
    }
  }
  setupActivityReservation() {
    let finalData:ReservationActivityType = {} as ReservationActivityType;
    if(this.activity) {
      const data:Reservation = this.activity.data;
      let state:CompareStringParamType = {
        previous: undefined,
        current: data.state
      }
      let startDate:CompareStringParamType = {
        previous: undefined,
        current: this.convertDate(data.checkIn)
      }
      let endDate:CompareStringParamType = {
        previous: undefined,
        current: this.convertDate(data.checkOut)
      }
      let company:CompareCompanyParamType = {
        previous: undefined,
        current: data.company
      };
      let orders:CompareOrderParamType = {} as CompareOrderParamType
      if(this.prevActivity) {
        const prevData:Reservation = this.prevActivity.data;
        state.previous = prevData.state;
        startDate.previous = this.convertDate(prevData.checkIn);
        endDate.previous = this.convertDate(prevData.checkOut);
        company.previous = prevData.company;
        orders = this.compareOrder(data.orders, prevData.orders)
      } else {
        orders = this.compareOrder(data.orders)
      }

      let stateCompare:CompareType[] = [];
      if(state.current !== state.previous) {
        stateCompare.push({
          attribute: "State",
          previous: state.previous ? this.utils.showReservationStatus(state.previous) : "",
          current: this.utils.showReservationStatus(state.current)
        })
      }

      let companyCompare:CompareType[] = [];
      const companyKey = Object.keys(company.current);
      companyKey.forEach(key => {
        let current = company.current[key as keyof Company];
        let previous = "";
        if(company.previous) {
          previous = company.previous[key as keyof Company];
        }
        if(current !== previous) {
          companyCompare.push({
            attribute: this.handleKey(key),
            previous: previous,
            current: current
          })
        }
      });

      let dateCompare:CompareType[] = [];
      if(startDate.current !== startDate.previous) {
        dateCompare.push({
          attribute: "Start date",
          previous: startDate.previous ? startDate.previous : "",
          current: startDate.current
        })
      }
      if(endDate.current !== endDate.previous) {
        dateCompare.push({
          attribute: "End date",
          previous: endDate.previous ? endDate.previous : "",
          current: endDate.current
        })
      }

      let finalCompare: ReservationCompareType[] = [
        {
          title: "Reservation state",
          values: stateCompare
        },
        {
          title: "Reservation date",
          values: dateCompare
        },
        {
          title: "Organization info",
          values: companyCompare
        }
      ];

      finalData = {
        compare: finalCompare,
        orders: orders
      }
    }
    return finalData;
  }

  toggleAccordion(forceAction?:string) {
    if(this.activityItem && this.activityItem.content) {
      if((!forceAction && !this.accordionOpened) || (forceAction && forceAction === "open")) {
        const content = this.accordionContent.nativeElement.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(this.accordionContent.nativeElement);
        const marginTop = parseFloat(computedStyle.marginTop);
        this.accordionHeight = content.height + marginTop;
        this.accordionOpened = true;
      } else if((!forceAction && this.accordionOpened)|| (forceAction && forceAction === "close")) {
        this.accordionHeight = 0;
        this.accordionOpened = false;
      }
    }
  }
  renderLabels(label:string) {
    const findLabel = this.hotelConfig.MUIfeature.reservationLabelList.find(dLabel => dLabel.id === label);
    if(findLabel) {
      return findLabel.name;
    } else {
      return label;
    }
  }
  findFennoaPaymentType(value:string) {
    const findType = CONSTANT.FENNOA_INVOICE_RESERVATION_TYPE.find(type => type.value === value);
    if(findType) {
      return findType.name
    } else {
      return value;
    }
  }
  findLanguage(value:string) {
    const findType = CONSTANT.SUPPORTED_LANGUAGES.find(type => type.value === value);
    if(findType) {
      return findType.label
    } else {
      return value;
    }
  }

}
