<app-header activePage="customers"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Customers Dashboard</div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-sm-3">
          <!-- Start datepicker -->
          <label for="start-date">From*</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="fromDate"
            [secondDate]="toDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="dateChange($event, 'startDate')"
          ></dropdown-menu>
        </div>
        <!-- End date picker -->
        <div class="col-6 col-sm-3">
          <label for="end-date">To*</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="toDate"
            [secondDate]="fromDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="dateChange($event, 'endDate')"
          ></dropdown-menu>
        </div>
        <div class="col-12 col-sm-3">
          <label for="optional-filter">Compare to</label>
          <dropdown-menu
            [menuList]="compareList"
            [selectedItem]="selectedCompare"
            [fullWidth]="true"
            (selectItem)="selectCompare($event)"
          ></dropdown-menu>
        </div>
        <div class="col-12 col-md-3">
          <div class="button-list no-button-top-margin label-offset">
            <button type="button" class="btn btn-green" (click)="applyFilter()">Apply</button>
            <button type="button" class="btn btn-outline-green" (click)="redirectToCustomerDataExport()">Export data</button>
          </div>
        </div>
      </div>
      <div>
        <small class="">* Dates refer to creation date (time booked, time joined member program, time blocked)</small>
      </div>

      <div class="row">
        <!-- Show message after change customer/ reason for customer -->
        <div class="col-md-12 message-alert-dashboard">
          @for (alert of alerts; track alert) {
            <div class="alert alert-{{alert.type}}" role="alert">
              {{ alert.message }}
            </div>
          }
        </div>
        <div class="row">
          @if (isDashboardData && !loading) {
            <div class="col-md-9 box">
              <!-- Data for customer -->
              <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.customers }}"placement="bottom">
                <div>
                  {{ numberWithCommas(customerDashboard.customer.total)}}
                  @if (numberNotIsNaN(customerDashboard.customer.growthRate) && numberBiggerZero(customerDashboard.customer.growthRate)) {
                    <span class="upward-arrow"
                      >
                      <fa-icon type="solid" icon="arrow-up"></fa-icon><small>+ {{ customerDashboard.customer.growthRate }}%</small>
                    </span>
                  }
                  @if (numberNotIsNaN(customerDashboard.customer.growthRate) && !numberBiggerZero(customerDashboard.customer.growthRate)) {
                    <span class="downward-arrow"
                      >
                      <fa-icon type="solid" icon="arrow-down"></fa-icon><small>- {{
                      absForGrowthRate(customerDashboard.customer.growthRate)
                    }}%</small>
                  </span>
                }
                @if (!numberNotIsNaN(customerDashboard.customer.growthRate) && (customerDashboard.customer.total > customerDashboard.customer.previousTotal)) {
                  <span class="upward-arrow"
                    >
                    <fa-icon type="solid" icon="arrow-up"></fa-icon>
                  </span>
                }
                @if (!numberNotIsNaN(customerDashboard.customer.growthRate) && (customerDashboard.customer.total < customerDashboard.customer.previousTotal)) {
                  <span class="downward-arrow"
                    >
                    <fa-icon type="solid" icon="arrow-down"></fa-icon>
                    <small>na%</small>
                  </span>
                }
              </div>
              <h5 class="item-title">Customer emails</h5>
            </div>
            <!-- Data for member -->
            <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.members }}"
              placement="bottom">
              <div>
                {{numberWithCommas(customerDashboard.member.total)}}
                @if (numberNotIsNaN(customerDashboard.member.growthRate) && numberBiggerZero(customerDashboard.member.growthRate)) {
                  <span class="upward-arrow"
                    >
                    <fa-icon type="solid" icon="arrow-up"></fa-icon><small>+ {{ customerDashboard.member.growthRate }}%</small>
                  </span>
                }
                @if (numberNotIsNaN(customerDashboard.member.growthRate) && !numberBiggerZero(customerDashboard.member.growthRate)) {
                  <span class="downward-arrow"
                    >
                    <fa-icon type="solid" icon="arrow-down"></fa-icon><small>- {{
                    absForGrowthRate(customerDashboard.member.growthRate)
                  }}%</small>
                </span>
              }
              @if (!numberNotIsNaN(customerDashboard.member.growthRate) && (customerDashboard.member.total > customerDashboard.member.previousTotal)) {
                <span class="upward-arrow"
                  ><fa-icon type="solid" icon="arrow-up"></fa-icon><small>na%</small></span>
                }
                @if (!numberNotIsNaN(customerDashboard.member.growthRate) && (customerDashboard.member.total < customerDashboard.member.previousTotal)) {
                  <span class="downward-arrow"
                    ><fa-icon type="solid" icon="arrow-down"></fa-icon><small>na%</small></span>
                  }
                </div>
                <h5 class="item-title">Members</h5>
              </div>
              <!-- Data for subscriber -->
              <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.subscribers }}"
                placement="bottom">
                <div>
                  {{numberWithCommas(customerDashboard.subscriber.total)}}
                  @if (numberNotIsNaN(customerDashboard.subscriber.growthRate) && numberBiggerZero(customerDashboard.subscriber.growthRate)) {
                    <span class="upward-arrow"
                      >
                      <fa-icon type="solid" icon="arrow-up"></fa-icon><small>+ {{ customerDashboard.subscriber.growthRate }}%</small>
                    </span>
                  }
                  @if (numberNotIsNaN(customerDashboard.subscriber.growthRate) && !numberBiggerZero(customerDashboard.subscriber.growthRate)) {
                    <span class="downward-arrow"
                      >
                      <fa-icon type="solid" icon="arrow-down"></fa-icon><small>- {{
                      absForGrowthRate(customerDashboard.subscriber.growthRate)
                    }}%</small>
                  </span>
                }
                @if (!numberNotIsNaN(customerDashboard.subscriber.growthRate) && (customerDashboard.subscriber.total > customerDashboard.subscriber.previousTotal)) {
                  <span class="upward-arrow"
                    ><span
                    class="upward-arrow-icon"></span><small>na%</small></span>
                  }
                  @if (!numberNotIsNaN(customerDashboard.subscriber.growthRate) && (customerDashboard.subscriber.total < customerDashboard.subscriber.previousTotal)) {
                    <span class="downward-arrow"
                      ><span
                      class="downward-arrow-icon"></span><small>na%</small></span>
                    }
                  </div>
                  <h5 class="item-title">Subscribers</h5>
                </div>
              </div>
            }
          </div>

          <!-- Loading icon -->
          @if (loading) {
            <div class="center-item">
              <ng-lottie
                [options]="options"
                width="200px"
                height="200px"
              ></ng-lottie>
            </div>
          }
        </div>

      </div>
    </div>

  </div>