<app-header activePage="reservations"></app-header>

<div class="center-item" *ngIf="!reservation">
  <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
</div>

<div class="page-header-container" *ngIf="reservation">
  <div class="page-header-inner">
    <div class="page-title-with-btn-container">
      <div class="page-twb-title">
        <div class="view-desc">Reservation</div>
        <div class="view-title">
          {{reservation.reservationCode}}
          <span class="special-icon" tooltip="Reservation includes rooms for disabled" placement="bottom"
            *ngIf="hasDisabled">
            <fa-icon type="solid" icon="wheelchair"></fa-icon>
          </span>
        </div>
        <div class="hotel-label-info">
          <div class="label-title" *ngIf="hotels.length > 1">{{showHotelText()}}{{utils.hotelDisplayByHotel(hotelConfig,
            reservation.hotel)}}</div>
          <div class="label-status" [class]="getReservationStateType(reservation.state)"
            [tooltip]="getReservationStateDesc(reservation.state)" placement="bottom">
            <div class="status-icon" *ngIf="reservation.state === 'CONFIRMED'">
              <fa-icon type="solid" icon="circle-check"></fa-icon>
            </div>
            <div class="status-icon" *ngIf="reservation.state === 'PENDING_CONFIRMATION'">
              <fa-icon type="regular" icon="hourglass-start"></fa-icon>
            </div>
            <div class="status-icon" *ngIf="reservation.state === 'CANCELLED'">
              <fa-icon type="solid" icon="circle-xmark"></fa-icon>
            </div>
            <div class="status-icon" *ngIf="reservation.state === 'BLOCKED'">
              <fa-icon type="solid" icon="ban"></fa-icon>
            </div>
            <div class="status-icon" *ngIf="reservation.state === 'INITIAL'">
              <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
            </div>
            <div class="status-state">
              {{utils.showReservationStatus(reservation.state)}}
            </div>
          </div>
        </div>
        <div class="normal-checkbox" style="margin-top: 10px;">
          <input type="checkbox" [checked]="reservation.notifyCustomer" (click)="changeNotifyCustomer($event)">
          <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
          <div class="checkbox-label">Send notifications</div>
        </div>
      </div>
      <div class="page-twb-btn-bar">
        <div class="btn-bar-group" *ngIf="editAllowed">
          <div class="group-item">
            <button type="button" (click)="viewGuests()" class="btn btn-green">
              <fa-icon type="solid" icon="users"></fa-icon>View guests
            </button>
          </div>
          <!-- <div class="group-item">
            <button (click)="this.openActivityLogModal()" type="button" class="btn btn-green">
              <fa-icon type="solid" icon="memo"></fa-icon>Activity log (Old)
            </button>
          </div> -->
          <div class="group-item">
            <button (click)="this.openActivityLog2Modal()" type="button" class="btn btn-green">
              <fa-icon type="solid" icon="memo"></fa-icon>Activity log
            </button>
          </div>
          <div class="group-item">
            <dropdown-menu buttonTitle="Send message" buttonIconType="solid" buttonIcon="paper-plane"
              actionTitle="Select message type" menuType="action"
              [menuActionList]="sendMessageActionMenu" [disableMenu]="isCancelled() || isPendingConfirmation()"
              (selectActionItem)="openNotificationModal($event)"></dropdown-menu>
          </div>
        </div>
        <div class="btn-bar-group">
          <ng-container
            *ngIf="!editAllowed && paymentAllowed && ( (isExternalReservation() && hotelConfig.MUIfeature.allowExternalReservationRefund) || !isExternalReservation() )">
            <div class="group-item"><button type="button" (click)="openRefundModal()" class="btn btn-green"><fa-icon
                  type="solid" icon="turn-left"></fa-icon>Refund</button></div>
          </ng-container>
          <ng-container *ngIf="editAllowed">
            <ng-container
              *ngIf="!isEditDisabled() && inputEdited || isAllowExternalReservationAddExtra() && inputEdited">
              <div class="group-item">
                <button type="button" (click)="openCustomModal('saveChangesAlert')" [disabled]="disabledBtnWhileCallingAPI"
                  class="btn btn-green">
                  <fa-icon type="solid" icon="floppy-disk"></fa-icon>Save changes
                </button>
              </div>
              <div class="group-item">
                <button type="button" (click)="openCustomModal('revertChangesAlert')" class="btn btn-green ">
                  <fa-icon type="solid" icon="rotate-left"></fa-icon>Revert changes
                </button>
              </div>
            </ng-container>
            <div class="group-item" *ngIf="isPendingConfirmation()">
              <button type="button" [disabled]="isLoadingConfirm" (click)="confirmReservation()" class="btn btn-green">
                <fa-icon type="solid" icon="check"></fa-icon>Confirm
              </button>
            </div>
            <div class="group-item" *ngIf="isBlocked()">
              <button type="button" (click)="openUnblockModal()" class="btn btn-green">
                <fa-icon type="solid" icon="user-unlock"></fa-icon>Unblock
              </button>
            </div>
            <div class="group-item" *ngIf="isPendingConfirmation()">
              <button type="button" (click)="openCustomModal('cancelChangesAlert')" class="btn btn-green">
                <fa-icon type="solid" icon="xmark"></fa-icon>Cancel changes
              </button>
            </div>
            <div class="group-item">
              <button type="button" (click)="openRefundModal()" class="btn btn-green">
                <fa-icon type="solid" icon="turn-left"></fa-icon>Refund
              </button>
            </div>
            <div class="group-item" *ngIf="!isPendingConfirmation()">
              <button type="button" (click)="openCancelReservationAlert()" class="btn btn-outline-red"
                [disabled]="isCancelled()">
                <fa-icon type="solid" icon="ban"></fa-icon>Cancel reservation
              </button>
            </div>
            <div class="group-item" *ngIf="!isExternalReservation() && !isPendingConfirmation()">
              <button type="button" (click)="changeReservation()" class="btn btn-green">
                <fa-icon type="solid" icon="pen-to-square"></fa-icon>Change reservation
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid main-container" *ngIf="reservation">
  <div class="element-container">
    <div class="split-equal-height-container">
      <div class="split-container">
        <div class="title-inline-btn">
          <div class="tib-title">
            <div class="container-header-title">Customer information</div>
          </div>
          <div class="tib-button">
            <button *ngIf="editAllowed" (click)="openCustomerEditModal()" class="btn btn-green icon-only"><fa-icon
                type="solid" icon="user-pen"></fa-icon></button>
          </div>
        </div>
        <div class="customer-info">
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="user"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Full name
              </div>
              <div class="content-value">
                {{reservation.customer.firstName + ' ' + reservation.customer.lastName}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="envelope"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Real email
              </div>
              <div class="content-value">
                <a
                  [routerLink]="['/customer-card/',reservation.customer.emailReal,reservation.customer.mobile.substring(1,reservation.customer.mobile)]">{{reservation.customer.emailReal
                  ? reservation.customer.emailReal : 'n/a'}}</a>
              </div>
            </div>
          </div>
          <div class="info-item" [hidden]="!isBookingChannel">
            <div class="item-icon">
              <fa-icon type="solid" icon="at"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Virtual email
              </div>
              <div class="content-value">
                {{reservation.customer.emailVirtual ? reservation.customer.emailVirtual : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="mobile-signal-out"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Mobile
              </div>
              <div class="content-value">
                {{reservation.customer.mobile ? reservation.customer.mobile : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="globe"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Nationality
              </div>
              <div class="content-value">
                {{getCountryLabelBasedOnCode(reservation.customer.nationality)}}
              </div>
            </div>
          </div>
          <ng-container *ngIf="hasNationality">
            <div class="info-item" *ngIf="isSSN">
              <div class="item-icon">
                <fa-icon type="solid" icon="id-card"></fa-icon>
              </div>
              <div class="item-content">
                <div class="content-title">
                  SSN
                </div>
                <div class="content-value">
                  {{reservation.customer.ssn ? reservation.customer.ssn : 'n/a'}}
                </div>
              </div>
            </div>
            <div class="info-item" *ngIf="!isSSN">
              <div class="item-icon">
                <fa-icon type="solid" icon="passport"></fa-icon>
              </div>
              <div class="item-content">
                <div class="content-title">
                  Passport number
                </div>
                <div class="content-value">
                  {{reservation.customer.passportNumber ? reservation.customer.passportNumber : 'n/a'}}
                </div>
              </div>
            </div>
          </ng-container>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="house"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Address
              </div>
              <div class="content-value">
                {{reservation.customer.address ? reservation.customer.address : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="mailbox"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Postal code
              </div>
              <div class="content-value">
                {{reservation.customer.postalCode ? reservation.customer.postalCode : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="city"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                City
              </div>
              <div class="content-value">
                {{reservation.customer.city ? reservation.customer.city : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="building-memo"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Organization
              </div>
              <div class="content-value">
                {{reservation.company.name ? reservation.company.name : 'n/a'}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="ticket"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Campaign code
              </div>
              <div class="content-value">
                {{campaignCodes(reservation.orders)}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="globe"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Country
              </div>
              <div class="content-value">
                {{getCountryLabelBasedOnCode(reservation.customer.country)}}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="chart-pie"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Segment
              </div>
              <div class="content-value">
                @if(editSegment) {
                  <div class="action-menu">
                    <dropdown-menu
                      [menuId]="'edit-reservation-segment'"
                      [menuList]="segmentList"
                      [selectedItem]="selectedSegment"
                      [menuLoading]="changingSegment"
                      [minWidth]="300"
                      [inlineMenu]="true"
                      [buttonFontSize]="'14pt'"
                      emptyMenuText="Select segment"
                      (selectItem)="selectSegment($event)"
                    ></dropdown-menu>
                  </div>
                  <div class="action-button">
                    <button class="btn btn-green icon-only btn-compact" (click)="saveSegment()" [disabled]="changingSegment">
                      <fa-icon type="solid" icon="check"></fa-icon>
                    </button>
                    <button class="btn btn-outline-green icon-only btn-compact" (click)="toggleEditSegment()" [disabled]="changingSegment">
                      <fa-icon type="solid" icon="xmark"></fa-icon>
                    </button>
                  </div>
                } @else {
                  {{selectedSegment ? selectedSegment.label : (reservation.reservationSegment ? reservation.reservationSegment : "n/a")}}
                  <div class="action-button edit-button">
                    <button class="btn btn-none-outline-green btn-compact" (click)="toggleEditSegment()">
                      <fa-icon type="solid" icon="pen-to-square"></fa-icon>
                      <span>Edit</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <ng-container *ngIf="reservation.bookingChannel === 'NELSON'"><img
                  src="assets/images/logo/nelson.png"></ng-container>
              <ng-container *ngIf="reservation.bookingChannel === 'BOOKINGCOM'"><img
                  src="assets/images/logo/bookingcom.png"></ng-container>
              <ng-container *ngIf="reservation.bookingChannel === 'EXPEDIA'"><img
                  src="assets/images/logo/expedia.png"></ng-container>
              <ng-container *ngIf="reservation.bookingChannel === 'MOBILEAPP'"><fa-icon type="light"
                  icon="mobile-notch"></fa-icon></ng-container>
              <ng-container *ngIf="reservation.bookingChannel === 'LEGACY'"><fa-icon type="solid"
                  icon="browser"></fa-icon></ng-container>
              <ng-container *ngIf="getChannelLabel(reservation.bookingChannel) === 'Unknown'"><fa-icon type="solid"
                  icon="circle-question"></fa-icon></ng-container>
            </div>
            <div class="item-content">
              <div class="content-title">
                Booking channel
              </div>
              <div class="content-value"
                [ngClass]="{'error-color': getChannelLabel(reservation.bookingChannel) === 'Unknown'}">
                {{ getChannelLabel(reservation.bookingChannel) }}
              </div>
            </div>
          </div>
          <div class="info-item" *ngIf="reservation.bookingChannelReservationId">
            <div class="item-icon">
              <fa-icon type="solid" icon="id-badge"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                External channel ID
              </div>
              <div class="content-value">
                {{ reservation.bookingChannelReservationId }}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="item-icon">
              <fa-icon type="solid" icon="memo-pad"></fa-icon>
            </div>
            <div class="item-content">
              <div class="content-title">
                Reservation language
              </div>
              <div class="content-value">
                <dropdown-menu
                  [menuId]="'edit-reservation-lang'"
                  [menuList]="langList"
                  [selectedItem]="selectedLang"
                  [menuLoading]="changingLang"
                  [minWidth]="150"
                  [inlineMenu]="true"
                  [buttonFontSize]="'14pt'"
                  (selectItem)="selectReservationLang($event)"
                ></dropdown-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="split-container comment-container">
        <div class="container-header-title">Comments</div>
        <div class="comment-box">
          <ng-container *ngIf="comments">
            <div class="comment-item" *ngFor="let comment of comments">
              <div class="info-container">
                <div class="info-email">{{comment.author.name}}</div>
                <div class="info-time">{{utils.convertISOFullDate(comment.timestamp)}}</div>
              </div>
              <div class="info-content">{{comment.data.comment}}</div>
            </div>
          </ng-container>
        </div>
        <div class="comment-input-container" [formGroup]="commentForm">
          <textarea class="form-control" rows="2" placeholder="Write a comment..."
            formControlName="commentInput"></textarea>
          <button class="btn btn-green icon-only" [disabled]="disabledBtnWhileCallingAPI"
            (click)="saveComment()"><fa-icon type="solid" icon="plus"></fa-icon></button>
        </div>

      </div>
    </div>
  </div>

  <div class="element-container">
    <div class="summary-container" *ngIf="selectedSummaryView">
      <div class="summary-date">
        <div class="container-header-title">Summary</div>
        <div class="view-desc">
          {{reservation.startDate | date:'dd.MM.yyyy'}} - {{reservation.endDate | date:'dd.MM.yyyy'}}
        </div>
      </div>
      <div class="summary-menu">
        <dropdown-menu [menuId]="'summary-view'" [menuList]="summaryView" [selectedItem]="selectedSummaryView"
          [fullWidth]="true" (selectItem)="selectSummaryView($event)"></dropdown-menu>
      </div>
    </div>
    <!-- All products summary table -->
    <div class="custom-table-wrapper" *ngIf="selectedSummaryView.value === 'allProducts'">
      <table class="custom-table summary-table">
        <thead>
          <tr class="dark-bg">
            <th>Product</th>
            <th class="text-right">Units</th>
            <th class="text-right">Rooms</th>
            <th class="text-right">Unit price</th>
            <th class="text-right">Price</th>
            <th class="text-right">List price</th>
          </tr>
        </thead>
        <tbody *ngIf="orders">
          <ng-container *ngFor="let product of orders.productsInReservation">
            <tr
              *ngIf="getTotalSummary('price', product.productId) != 0 && getTotalSummary('count', product.productId) > 0">
              <td>
                <ng-container>{{utils.showRoomTitle(getProductName(product.productId,
                  -1))}}</ng-container>
              </td>
              <td class="text-right">
                {{getTotalSummary('count', product.productId)}}
              </td>
              <td class="text-right">
                {{getUnitRoomSummary(product.productId)}}
              </td>
              <td class="text-right">
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="unitSummaryChange(product.productId, $event)"
                  value="{{getUnitSummary(product.productId) | currencyFormat:'price'}}" *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()"> {{getUnitSummary(product.productId) | currencyFormat:"price"}}</span>
                <span> {{getTotalSummary('price', product.productId) | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <input type="text" appEditPrice (input)="markEdited()"
                  (blur)="summaryChange(product.productId, getTotalSummary('price', product.productId), $event)"
                  value="{{getTotalSummary('price', product.productId) | currencyFormat:'price'}}"
                  *ngIf="!isEditDisabled()">
                <span *ngIf="isEditDisabled()">{{getTotalSummary('price', product.productId) |
                  currencyFormat:"price"}}</span>
                <span> {{getTotalSummary('price', product.productId) | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">{{getTotalSummary('listPrice', product.productId) | currencyFormat:"fullPrice"}}
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <th colspan="4">Reservation total</th>
            <th class="text-right">{{getTotalSummary('totalPrice') | currencyFormat:"fullPrice"}}</th>
            <th class="text-right">{{getTotalSummary('totalListPrice') | currencyFormat:"fullPrice"}}</th>
          </tr>
          <tr class="total-row">
            <td class="text-left" colspan="4">Total non-refundable items</td>
            <td class="text-right">{{getTotalSummary('totalNonRefundablePrice') | currencyFormat:"fullPrice"}}</td>
            <td class="text-right">{{getTotalSummary('totalNonRefundableListPrice') | currencyFormat:"fullPrice"}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Extras summary table -->
    <div class="custom-table-wrapper"
      *ngIf="orders && orders.extrasSummary.length && selectedSummaryView.value === 'extras'">
      <table class="custom-table summary-table">
        <thead>
          <tr class="dark-bg">
            <th>Product</th>
            <th class="text-right" style="width: 200px;">Unit price</th>
            <th class="text-right" style="width: 200px;">Price</th>
            <th class="text-right" style="width: 200px;">List price</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let product of orders.extrasSummary">
            <tr class="black-border">
              <td>
                <b>{{utils.showRoomTitle(getProductName(product.productId, -1))}} x {{product.amount}}</b>
              </td>
              <td class="text-right">
                <b>
                  <input type="text" appEditPrice (input)="markEdited()"
                    (blur)="unitSummaryChange(product.productId, $event)"
                    value="{{product.unitPrice | currencyFormat:'price'}}" *ngIf="!isEditDisabled()">
                  <span *ngIf="isEditDisabled()"> {{product.unitPrice | currencyFormat:"price"}}</span>
                  <span> {{product.unitPrice | currencyFormat:"currency"}}</span>
                </b>
              </td>
              <td class="text-right">
                <b>
                  <input type="text" appEditPrice (input)="markEdited()"
                    (blur)="summaryChange(product.productId, product.price, $event)"
                    value="{{product.price | currencyFormat:'price'}}" *ngIf="!isEditDisabled()">
                  <span *ngIf="isEditDisabled()"> {{product.price | currencyFormat:"price"}}</span>
                  <span> {{product.price | currencyFormat:"currency"}}</span>
                </b>
              </td>
              <td class="text-right">
                <b>
                  <span> {{product.listPrice | currencyFormat:"price"}}</span>
                  <span> {{product.listPrice | currencyFormat:"currency"}}</span>
                </b>
              </td>
            </tr>
            <ng-container *ngFor="let date of product.dates">
              <tr>
                <td>
                  {{date.date | date: 'dd.MM.YYYY'}} x {{date.amount}}
                </td>
                <td class="text-right">
                  <input type="text" appEditPrice (input)="markEdited()"
                    (blur)="extraSummaryChange('unit', date.amount, product.productId, date.date, $event)"
                    value="{{date.unitPrice | currencyFormat:'price'}}" *ngIf="!isEditDisabled()">
                  <span *ngIf="isEditDisabled()"> {{date.unitPrice | currencyFormat:"price"}}</span>
                  <span> {{date.unitPrice | currencyFormat:"currency"}}</span>
                </td>
                <td class="text-right">
                  <input type="text" appEditPrice (input)="markEdited()"
                    (blur)="extraSummaryChange('total', date.amount, product.productId, date.date, $event)"
                    value="{{date.price | currencyFormat:'price'}}" *ngIf="!isEditDisabled()">
                  <span *ngIf="isEditDisabled()"> {{date.price | currencyFormat:"price"}}</span>
                  <span> {{date.price | currencyFormat:"currency"}}</span>
                </td>
                <td class="text-right">
                  <span> {{date.listPrice | currencyFormat:"price"}}</span>
                  <span> {{date.listPrice | currencyFormat:"currency"}}</span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <th colspan="2">Total</th>
            <th class="text-right">
              <span> {{getExtrasTotalSummary('price') | currencyFormat:"price"}}</span>
              <span> {{getExtrasTotalSummary('price') | currencyFormat:"currency"}}</span>
            </th>
            <th class="text-right">
              <span> {{getExtrasTotalSummary('list') | currencyFormat:"price"}}</span>
              <span> {{getExtrasTotalSummary('list') | currencyFormat:"currency"}}</span>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Stay range summary table -->
    <div class="custom-table-wrapper"
      *ngIf="orders && orders.dateRangeSummary.length && selectedSummaryView.value === 'stayRange'">
      <table class="custom-table summary-table">
        <thead>
          <tr class="dark-bg">
            <th>Check-in</th>
            <th>Check-out</th>
            <th class="text-right" style="width: 150px;">Room count</th>
            <th class="text-right" style="width: 200px;">Unit price</th>
            <th class="text-right" style="width: 200px;">Price</th>
            <th class="text-right" style="width: 200px;">List price</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let product of orders.dateRangeSummary">
            <tr class="black-border">
              <td>
                <b>{{product.startDate}}</b>
              </td>
              <td>
                <b>{{product.endDate}}</b>
              </td>
              <td class="text-right">
                {{product.roomCount}}
              </td>
              <td class="text-right"></td>
              <td class="text-right">
                <span> {{product.price | currencyFormat:"price"}}</span>
                <span> {{product.price | currencyFormat:"currency"}}</span>
              </td>
              <td class="text-right">
                <span> {{product.listPrice | currencyFormat:"price"}}</span>
                <span> {{product.listPrice | currencyFormat:"currency"}}</span>
              </td>
            </tr>
            <ng-container *ngFor="let item of product.products">
              <tr>
                <td colspan="3">
                  {{utils.showRoomTitle(getProductName(item.productId, -1))}} x {{item.amount}}
                </td>
                <td class="text-right">
                  <span> {{item.unitPrice | currencyFormat:"price"}}</span>
                  <span> {{item.unitPrice | currencyFormat:"currency"}}</span>
                </td>
                <td class="text-right">
                  <span> {{item.price | currencyFormat:"price"}}</span>
                  <span> {{item.price | currencyFormat:"currency"}}</span>
                </td>
                <td class="text-right">
                  <span> {{item.listPrice | currencyFormat:"price"}}</span>
                  <span> {{item.listPrice | currencyFormat:"currency"}}</span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <th colspan="4">Total</th>
            <th class="text-right">
              <span> {{getDateRangeTotalSummary('price') | currencyFormat:"price"}}</span>
              <span> {{getDateRangeTotalSummary('price') | currencyFormat:"currency"}}</span>
            </th>
            <th class="text-right">
              <span> {{getDateRangeTotalSummary('list') | currencyFormat:"price"}}</span>
              <span> {{getDateRangeTotalSummary('list') | currencyFormat:"currency"}}</span>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <!-- <div class="text-right">
    <button type="button" class="btn btn-green btn-big format-for-btn add-payment" (click)="openAdditionalServicesModal()" *ngIf="!isEditDisabled()"><fa-icon type="solid" icon="plus"></fa-icon>Add additional services</button>
  </div> -->

  <div class="element-container">
    <div class="payment-title-container">
      <div class="container-header-title">Payments</div>
      <div class="payment-ref">
        <div class="ref-title">Reference number:</div>
        <div class="ref-number">{{getReferenceNumber('code')}} - {{getReferenceNumber('number')}}</div>
      </div>
    </div>
    <div class="custom-table-wrapper">
      <table class="custom-table">
        <thead>
          <tr class="dark-bg">
            <th>Date</th>
            <th>Amount</th>
            <th>Payment provider</th>
            <th>Payment method / Payment reference</th>
            <th *ngIf="hasRefund">Refund note</th>
          </tr>
          <tr class="total-row">
            <th>Total paid</th>
            <th class="text-right">{{reservation.totalPaid | currencyFormat:'fullPrice'}}</th>
            <th></th>
            <th></th>
            <th *ngIf="hasRefund"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let payment of payments; ; let i = index">
            <tr (click)="checkInvoice(payment.fennoaInvoiceId, i)" [ngClass]="{'clickable': payment.fennoaInvoiceId}">
              <td class="">{{utils.convertStringDate(payment.paymentDate)}}</td>
              <td class="text-right">{{payment.amount | currencyFormat:'fullPrice'}}</td>
              <td>{{ payment.manualPaymentProvider ? payment.manualPaymentProvider : (payment.paymentProvider ===
                'MONETARY_VOUCHER' && isGiftCardPayment(payment.paymentMethod) ? "Gift card" : payment.paymentProvider |
                paymentProviderFilter ) }}</td>
              <td class="text-left">
                <ng-container *ngIf="payment.manualPaymentProvider">{{ payment.paymentReference }}</ng-container>
                <ng-container *ngIf="!payment.manualPaymentProvider && !isGiftCardPayment(payment.paymentMethod)">{{
                  payment.paymentMethod }}</ng-container>
              </td>
              <td *ngIf="hasRefund" style="max-width: 300px">
                <div *ngIf="payment.isRefund" class="refund-cell">
                  <div class="refund-reason"
                    tooltip="{{ !isGiftCardPayment(payment.paymentMethod) ? payment.reason : payment.paymentMethod }}"
                    placement="bottom">
                    <ng-container *ngIf="!isGiftCardPayment(payment.paymentMethod)">
                      <div class="payment-reason">{{ payment.paymentStatus === 'REFUND_ERROR' ? "Refund error" :
                        payment.reason }}</div>
                    </ng-container>
                    <ng-container *ngIf="isGiftCardPayment(payment.paymentMethod)">
                      <div class="payment-method-gift-card" (click)="openVoucher(payment.paymentMethod)">{{
                        payment.paymentMethod }}</div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="payment.paymentStatus == 'MANUAL_REFUND'">
                    <div class="refund-icon-hover" tooltip="Pending refund" placement="bottom"
                      (click)="openManualRefundModal(payment.paymentId)"><fa-icon type="solid"
                        icon="triangle-exclamation"></fa-icon></div>
                  </ng-container>
                  <ng-container *ngIf="payment.paymentStatus == 'COMPLETED'">
                    <div tooltip="Refund completed" placement="bottom"><fa-icon type="solid"
                        icon="check-to-slot"></fa-icon></div>
                  </ng-container>
                </div>
              </td>
              <!-- <td class="text-left" *ngIf="payment.isRefund && isGiftCardPayment(payment.paymentMethod)">{{ payment.reason }}</td> -->
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="dark-bg">
            <td class="text-left">{{getPriceNumber(totalBalance) < 0 ? 'Balance to be refunded' : 'Balance to be paid'
                }}</td>
            <td class="text-right">{{totalBalance | currencyFormat:'fullPrice'}}</td>
            <td></td>
            <td></td>
            <td *ngIf="hasRefund"></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="button-list" style="margin-top: 7.5px">
      <button type="button" class="btn btn-green" (click)="openCustomModal('proceedRefundAlert')"
        *ngIf="getPriceNumber(totalBalance) < 0 && paymentAllowed"><fa-icon type="solid"
          icon="turn-left"></fa-icon>Proceed to refund</button>
      <!-- <button type="button" class="btn btn-green" (click)="openAdditionalServicesModal()"><fa-icon type="solid" icon="plus"></fa-icon>Add additional services</button> -->
      <ng-container *ngIf="hasPdf">
        <dropdown-menu buttonTitle="Save invoice PDF" buttonIconType="solid" buttonIcon="file-arrow-down"
          actionTitle="Select options" menuType="action" alignment="adaptive"
          [menuActionList]="saveManualInvoicePDFActionMenu"
          (selectActionItem)="proceedForCreditInvoice($event)"></dropdown-menu>
      </ng-container>
      @if(paymentAllowed) {
        <dropdown-menu buttonTitle="Add payment" buttonIconType="solid" buttonIcon="plus"
          actionTitle="Select payment type" alignment="adaptive" [menuActionList]="addPaymentActionMenu" menuType="action"
          (selectActionItem)="openSelectedPaymentType($event)">
        </dropdown-menu>
      }
    </div>
  </div>

  <div class="element-container">
    <div class="container-header-title" style="margin-bottom: 10px">All rooms</div>
    <div class="normal-checkbox" style="display: inline-flex;">
      <input type="checkbox" [checked]="showCancelled" (click)="toggleShowCancelled($event)">
      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
      <div class="checkbox-label">Show cancelled items</div>
    </div>
    @for(order of reservation.orders; track order.id; let i = $index) {
      @if(!isOrderCancelled(i) || (isOrderCancelled(i) && showCancelled)) {
        <div class="room-name-container">
          <div class="room-title-info">
            <div class="container-header-title" [ngClass]="{'error-color': isOrderCancelled(i)}">Room {{order.roomNumber}}</div>
            <div class="room-disabled-icon" *ngIf="order.forDisabled">
              <span class="special-icon" tooltip="Rooms for disabled" placement="bottom">
                <fa-icon type="solid" icon="wheelchair"></fa-icon>
              </span>
            </div>
            <div class="room-status" *ngIf="isOrderCancelled(i)">
              <div class="status">
                <div class="status-icon">
                  <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
                </div>
                <div class="status-text">Cancelled</div>
              </div>
            </div>
            <div class="room-date">
              <div class="view-desc">{{order.startDate}} - {{order.endDate}}</div>
            </div>
          </div>
        </div>
        @if(order.uiLabels && order.uiLabels.labelList.length) {
          <div style="margin-top: 20px; margin-bottom: 30px;">
            <div class="container-item-header-title with-loading">
              <div class="title-text">Special requests</div>
              @if(order.uiLabels.loading) {
                <div class="title-loading">
                  <ng-lottie
                    [options]="options"
                    width="25px"
                    height="25px"
                  ></ng-lottie>
                </div>
              }
              @if(!order.uiLabels.loading && order.uiLabels.status !== "idle") {
                @if(order.uiLabels.status === "success") {
                  <div class="title-badge success">
                    <div class="badge-icon">
                        <fa-icon type="solid" icon="circle-check"></fa-icon>
                    </div>
                    <div class="badge-text">Updated</div>
                  </div>
                }
                @if(order.uiLabels.status === "error") {
                  <div class="title-badge error">
                    <div class="badge-icon">
                        <fa-icon type="solid" icon="circle-xmark"></fa-icon>
                    </div>
                    <div class="badge-text">Error</div>
                  </div>
                }
              }
            </div>
            <div class="special-label-container" style="margin-top: 10px">
              @for (label of order.uiLabels.labelList; track label.id) {
                <div class="label-item">
                  <div class="normal-checkbox" [ngClass]="{'disabled': order.uiLabels.loading || isPendingConfirmation()}">
                    <input type="checkbox" [(ngModel)]="label.checked" (ngModelChange)="toggleLabel(order.id)">
                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    <div class="checkbox-label">
                      <div class="label-icon">
                        <fa-icon [type]="label.iconType" [icon]="label.iconName"></fa-icon>
                      </div>
                      <div class="label-text">{{label.name}}</div>
                    </div>
                  </div>
                </div>
              }
            </div>
            @if(order.uiLabels.unknownLabels.length) {
              <div style="margin-top: 5px;">
                <div class="container-item-header-title" style="margin-bottom: 5px">
                  <div class="title-text">Other requests</div>
                </div>
                <div>{{order.uiLabels.unknownLabels.join(', ')}}</div>
              </div>
            }
          </div>
        }
        <div class="door-code-container" [attr.data-order]="order.id">
          <div class="door-code-info">
            <ng-container *ngIf="hotelConfig.feature.showDoorInfo">
              <div class="door-code-title">Door code:</div>
              <div class="door-code-code">
                <span class="door-na" *ngIf="!order.hasDoorCode">Not available</span>
                <span class="door-code" *ngIf="order.hasDoorCode">•••••</span>
              </div>
              <div class="door-code-button">
                <button class="btn btn-green" *ngIf="order.hasDoorCode && !doorCodeTimeout['show'+order.id]" (click)="showDoorCode(order.id)">
                  <fa-icon type="solid" icon="eye"></fa-icon><span>Show</span>
                </button>
                <button class="btn btn-green code-toggle" *ngIf="order.hasDoorCode && doorCodeTimeout['show'+order.id]" (click)="setDoorCode('hide', order.id)">
                  <fa-icon type="solid" icon="eye-slash"></fa-icon><span>Hide</span>
                  <div class="timeout-bar">
                    <div class="bar"></div>
                  </div>
                </button>
                <button class="btn btn-green" *ngIf="editAllowed" (click)="openResetAlert(order.roomNumber, order.id, reservation.reservationCode, reservation.uuid)"><fa-icon type="solid" icon="arrows-rotate"></fa-icon><span>Reset</span></button>
              </div>
            </ng-container>
          </div>
          <div class="door-code-usage">
            <button class="btn btn-outline-green" (click)="openUsage(order.roomRequest.id)"><fa-icon type="solid" icon="list-timeline"></fa-icon>See room usage</button>
          </div>
        </div>
  
        <div class="custom-table-wrapper" style="margin-bottom: 15px;">
          <table class="custom-table" *ngIf="reservation.guests">
            <thead>
              <tr class="dark-bg">
                <th>Guest name</th>
                <!-- <th>Age</th> -->
                <th>Email</th>
                <th>Phone number</th>
                <th>SSN / Passport number</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let guest of getGuestListBasedOnAlias(order.roomAlias);let guestIndex = index">
                <ng-template #checkinTooltipHover>
                  <div class="checkin-tooltip">
                    <div><b>Checked-in</b></div>
                    <div>{{convertDate(guest.completedCheckIn)}}</div>
                  </div>
                </ng-template>
                <ng-template #notCheckinTooltipHover>
                  <div class="checkin-tooltip">
                    <div><b>Not checked-in yet</b></div>
                  </div>
                </ng-template>
                <ng-template #childCheckinTooltipHover>
                  <div class="checkin-tooltip">
                    <div><b>Underaged guest</b></div>
                  </div>
                </ng-template>
                <tr *ngIf="isRoomEmpty(order.roomAlias) && guestIndex === 0">
                  <td (click)="editGuestsInBUI(guest.id)">
                    <div class="checkin-item">
                      <div class="checkin-item-check error-color" [tooltip]="notCheckinTooltipHover" placement="top" *ngIf="guest.firstName">
                        <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
                      </div>
                      <div class="checkin-guest">
                        {{reservation.customer.firstName}} {{reservation.customer.lastName}}
                      </div>
                    </div>
                  </td>
                  <td *ngIf="!reservation.customer.emailReal && !reservation.customer.emailVirtual">n/a</td>
                  <td *ngIf="reservation.customer.emailReal || reservation.customer.emailVirtual"><a [routerLink]="['/customer-card/',reservation.customer.emailReal,reservation.customer.mobile.substring(1,reservation.customer.mobile)]">{{reservation.customer.emailReal ? reservation.customer.emailReal : reservation.customer.emailVirtual}}</a></td>
                  <td>{{reservation.customer.mobile ? reservation.customer.mobile : 'n/a'}}</td>
                  <td>{{ reservation.customer.nationality === "FIN" ? (reservation.customer.ssn ? reservation.customer.ssn : 'n/a') : (reservation.customer.passportNumber ? reservation.customer.passportNumber : 'n/a')}}</td>
                </tr>
                <tr *ngIf="!isRoomEmpty(order.roomAlias) || (isRoomEmpty(order.roomAlias) && guestIndex !== 0)">
                  <td *ngIf="guest.completedCheckInOnline === true" (click)="editGuestsInBUI(guest.id)">
                    <div class="checkin-item">
                      <div class="checkin-item-check success-color" [tooltip]="checkinTooltipHover" placement="top">
                        <fa-icon type="solid" icon="circle-check"></fa-icon>
                      </div>
                      <div class="checkin-guest">
                        {{guest.firstName}} {{guest.lastName}}
                      </div>
                    </div>
                  </td>
                  <td *ngIf="guest.completedCheckInOnline !== true" (click)="editGuestsInBUI(guest.id)">
                    <div class="checkin-item">
                      <div class="checkin-item-check error-color" [tooltip]="notCheckinTooltipHover" placement="top" *ngIf="guest.firstName && !isGuestUnderaged(guest.age)">
                        <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
                      </div>
                      <div class="checkin-item-check" [tooltip]="childCheckinTooltipHover" placement="top" *ngIf="guest.firstName && isGuestUnderaged(guest.age)">
                        <fa-icon type="solid" icon="child"></fa-icon>
                      </div>
                      <div class="checkin-guest">
                        {{guest.firstName}} {{guest.lastName}}
                      </div>
                    </div>
                  </td>
                  <td *ngIf="!guest.email">n/a</td>
                  <td *ngIf="guest.email"><a [routerLink]="['/customer-card/',guest.email,guest.mobile ? guest.mobile.substring(1,guest.mobile) : '']">{{guest.email}}</a></td>
                  <td>{{guest.mobile ? guest.mobile : 'n/a'}}</td>
                  <td>{{ guest.nationality === "FIN" ? (guest.ssn ? guest.ssn : 'n/a') : (guest.passportNumber ? guest.passportNumber : 'n/a')}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="custom-table-wrapper">
          <table class="custom-table safari-table z-index-format" *ngIf="!isCancelled()">
            <colgroup>
              <col>
              <col style="width: 331px">
              <col>
              <col>
              <col style="width: 150px">
              <col style="width: 150px">
            </colgroup>
            <thead class="sticky">
              <tr class="dark-bg">
                <th><div class="padding">Product</div></th>
                <th><div class="padding">Date</div></th>
                <th><div class="padding">Price</div></th>
                <th><div class="padding">List price</div></th>
                <th><div class="padding">Non-refundable</div></th>
                <th><div class="padding">Cancel</div></th>
              </tr>
            </thead>
            <tbody class="z-index-format">
              <ng-container *ngFor="let totalItem of filterInvoiceTypeForOrders(orders.roomOrders[i]); let productIndex = index">
                <tr class="total-row sticky one-row" *ngIf="totalItem.count > 0 || showCancelled">
                  <th colspan="2" *ngIf="orders" (click)="toggleOrderProductAccordion('order' + i + 'product' + productIndex)">
                    <div class="padding">
                      <span *ngIf="!orderProductAccordion.includes('order' + i + 'product' + productIndex)">
                        <fa-icon type="solid" icon="chevron-down"></fa-icon>
                      </span>
                      <span *ngIf="orderProductAccordion.includes('order' + i + 'product' + productIndex)">
                        <fa-icon type="solid" icon="chevron-up"></fa-icon>
                      </span>
                      <span style="margin-left: 10px;">{{utils.showRoomTitle(getProductName(totalItem.productId, i))}} x {{totalItem.count}}</span>
                    </div>
                  </th>
                  <th class="text-right">
                    <div class="padding">
                      <span style="width: 150px">
                        <input type="text" appEditPrice (input)="markEdited()"
                        (blur)="productTotalChange(i, totalItem.productId, totalItem.price, $event)"
                        value="{{totalItem.price | currencyFormat:'price'}}" *ngIf="!isEditDisabled() && totalItem.price != 0">
                        <span *ngIf="isEditDisabled()">{{totalItem.price | currencyFormat:"price"}}</span>
                        <span> {{totalItem.price | currencyFormat:"currency"}}</span>
                      </span>
                    </div>
                  </th>
                  <th class="text-right">
                    <div class="padding"><span style="width: 100px">{{totalItem.listPrice | currencyFormat:'fullPrice'}}</span></div></th>
                  <th></th>
                  <th></th>
                </tr>
                <ng-container *ngIf="orderProductAccordion.includes('order' + i + 'product' + productIndex)">
                  <ng-container *ngFor="let lineItem of order.lineItems; let itemIndex = index">
                    <ng-container *ngIf="lineItem.productId == totalItem.productId">
                      <tr *ngIf="(!lineItem.cancelled || (lineItem.cancelled && showCancelled))">
                        <td class="text-right" colspan="2">
                          <div class="padding">
                            <span style="margin-right: 5px;">
                              <span style="width: 17px; text-align: center" class="nelson-color" *ngIf="getState(lineItem) === 'minus'">
                                <fa-icon type="solid" icon="xmark"></fa-icon>
                              </span>
                              <span style="width: 17px; text-align: center" class="nelson-color" *ngIf="getState(lineItem) === 'remove'">
                                <fa-icon type="solid" icon="minus"></fa-icon>
                              </span>
                            </span>
                            <div style="display: inline-block;width: 77px">{{lineItem.serviceDate | date:'dd.MM.yyyy'}}</div>
                          </div>
                        </td>
                        <td class="text-right">
                          <div class="padding">
                            <span style="width: 150px">
                              <input type="text" appEditPrice (input)="markEdited()"
                              (blur)="productPriceChange(i, lineItem.id, lineItem.price.amount, $event, itemIndex)"
                              value="{{lineItem.price.amount | currencyFormat:'price'}}"
                              *ngIf="!isEditDisabled()">
                              <span *ngIf="isEditDisabled()">{{lineItem.price.amount
                                | currencyFormat:"price"}}</span>
                              <span> {{lineItem.price.amount | currencyFormat:"currency"}}</span>
                            </span>
                          </div>
                        </td>
                        <td class="text-right">
                          <div class="padding"><span style="width: 100px">{{lineItem.listPrice.amount | currencyFormat:'price'}} {{utils.getCurrency(currency)}}</span></div>
                        </td>
                        <td>
                          <div class="table-checkbox">
                            <div class="normal-checkbox disabled">
                              <input type="checkbox" [checked]="!lineItem.refundable">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          @if(!lineItem.cancelled) {
                            <div class="table-checkbox">
                              <div class="normal-checkbox" [ngClass]="{'disabled': isEditDisabled()}">
                                <input type="checkbox" [(ngModel)]="lineItem.toBeCancelled" (click)="toggleItemCancel(i, itemIndex, $event)">
                                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              </div>
                            </div>
                          }
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
      
            @if(!isCancelled() && orders.offerSelectedList && orders.offerMenu && orders.orderProductOffer) {
              <tfoot class="z-index-format">
                <tr class="total-row" *ngIf="!isEditDisabled() ||isAllowExternalReservationAddExtra()">
                  <td class="text-left extra-secif">
                    <div style="padding: 0 10px; width: 200px">
                      <dropdown-menu
                        [menuList]="orders.offerMenu[i]"
                        [selectedItem]="orders.offerSelectedList[i]"
                        [fullWidth]="true"
                        (selectItem)="selectOfferMenu($event, i)"
                      ></dropdown-menu>
                    </div>
                  </td>
                  <td>
                    <div class="padding">
                      <div class="warning-icon error-color" *ngIf="orderOfferSelection[i].notAvailable" tooltip="Not available on this date" placement="bottom">
                        <fa-icon type="solid" icon="triangle-exclamation"></fa-icon>
                      </div>
                      <div style="height: 40px; width: 200px; text-align: right; display: inline-block;">
                        <dropdown-menu
                          menuType="datepicker"
                          [initialDate]="orderOfferSelection[i].selectedDate"
                          alignment="adaptive"
                          [minDate]="utils.newDate(reservation.startDate)"
                          [maxDate]="utils.newDate(reservation.endDate)"
                          [fullWidth]="true"
                          [allowEmptyDate]="false"
                          (selectDate)="setOfferDate($event, i)"
                        ></dropdown-menu>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="padding">
                      <ng-container *ngIf="orderOfferSelection[i].price">
                        <input appEditPrice type="text" (input)="offerPriceChange($event, i)"
                          [value]="orderOfferSelection[i].price | currencyFormat:'price'">
                        <span> {{orderOfferSelection[i].price | currencyFormat:'currency'}}</span>
                      </ng-container>
                    </div>
                  </td>
                  <td>
                    <div class="padding">
                      <span *ngIf="orderOfferSelection[i].listPrice">{{orderOfferSelection[i].listPrice |
                        currencyFormat:'fullPrice'}}</span>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div class="padding">
                      <button type="button" class="btn btn-green btn-big" [disabled]="!orderOfferSelection[i].enableAdd"
                        (click)="addOfferToOrder(i)"><fa-icon type="solid" icon="plus"></fa-icon>Add</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            }
          </table>
        </div>
      }
    }
    
    <ng-container *ngIf="!isEditDisabled() && inputEdited || isAllowExternalReservationAddExtra() && inputEdited">
      <div class="text-right" style="margin-bottom: 20px; margin-top: 30px">
        <button type="button" (click)="openCustomModal('saveChangesAlert')" [disabled]="disabledBtnWhileCallingAPI"
          class="btn btn-green btn-big left-margin"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Save changes</button>
        <button type="button" (click)="openCustomModal('revertChangesAlert')" class="btn btn-green btn-big left-margin"><fa-icon
            type="solid" icon="rotate-left"></fa-icon>Revert changes</button>
      </div>
    </ng-container>
  </div>

</div>
<app-footer></app-footer>

<!-----------CUSTOM MODALS----------->
<!-- Refund modal -->
<custom-modal modalName="refundModal" modalTitle="Refund options" modalSize="md" modalOrder="0">
  <ng-container *ngIf="reservation && refundGiftcardDate">
    <div class="custom-modal-body">
      <inline-alert alertMessage="Please resolve pending refunds in Payments section first!" alertType="error"
        *ngIf="hasPendingRefund"></inline-alert>
      <div style="margin-bottom: 10px">Refund for unsatisfied service</div>
      <form [formGroup]="refundForm">
        <div class="text-right" style="padding-right: 15px;"><b>Remain</b></div>
        <div class="centered-flexbox refund-form" [ngClass]="{selected: refundFormSelection == 'moneyAmount'}">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund" (click)="changeRefundOption('moneyAmount')"
                [checked]="refundFormSelection == 'moneyAmount'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">By money amount</div>
            </div>
          </div>
          <div class="flex-price">
            <div class="flex-item">
              <input type="text" class="form-control custom-input" (input)="calculateRemain('money', $event)"
                [ngClass]="{'invalid': moneyAmount.invalid}" formControlName="moneyAmount"
                style="text-align: right; padding-left: 35px;">
              <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
            </div>
            <div class="flex-item item-remain">
              <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'moneyAmount'}">{{refundRemain.money
                | currencyFormat: 'fullPriceLeft'}}</div>
            </div>
          </div>
        </div>
        <div class="centered-flexbox refund-form" [ngClass]="{selected: refundFormSelection == 'percentageAmount'}">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund" (click)="changeRefundOption('percentageAmount')"
                [checked]="refundFormSelection == 'percentageAmount'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">By percentage</div>
            </div>
          </div>
          <div class="flex-price">
            <div class="flex-item">
              <input type="text" class="form-control custom-input" (input)="calculateRemain('percentage', $event)"
                [ngClass]="{'invalid': percentageAmount.invalid}" formControlName="percentageAmount"
                style="text-align: right; padding-right: 35px;">
              <div class="percentage-icon-input">%</div>
            </div>
            <div class="flex-item item-remain">
              <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'percentageAmount'}">
                {{refundRemain.percentage | currencyFormat: 'fullPriceLeft'}}</div>
            </div>
          </div>
        </div>
        @if(hotelConfig.feature.giftCard) {
          <div class="centered-flexbox refund-form"
          [ngClass]="{selected: refundFormSelection == 'giftcardAmount'}">
            <div class="flex-item item-checkbox">
              <div class="normal-checkbox radio-style">
                <input type="radio" name="refund" (click)="changeRefundOption('giftcardAmount')"
                  [checked]="refundFormSelection == 'giftcardAmount'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">By giftcard</div>
              </div>
            </div>
            <div class="flex-price">
              <div class="flex-item">
                <input type="text" class="form-control custom-input" (input)="calculateRemain('giftcard', $event)"
                  [ngClass]="{'invalid': giftcardAmount.invalid}" formControlName="giftcardAmount"
                  style="text-align: right; padding-left: 35px;">
                <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
                <label>Valid through</label>
                <div style="height: 40px">
                  <dropdown-menu
                    menuType="datepicker"
                    [initialDate]="giftCardExp"
                    adptiveType="modal"
                    alignment="adaptive"
                    [allowEmptyDate]="false"
                    [disableMenu]="refundFormSelection != 'giftcardAmount'"
                    [minDate]="refundGiftcardDate.minDate"
                    (selectDate)="dateChange($event, 'giftCardExp')"
                  ></dropdown-menu>
                </div>
              </div>
              <div class="flex-item item-remain">
                <div class="text-right" [ngClass]="{disabled: refundFormSelection != 'giftcardAmount'}">
                  {{refundRemain.giftcard | currencyFormat: 'fullPriceLeft'}}</div>
              </div>
            </div>
          </div>
        }
        <div class="refund-padding">
          <label>Reason</label>
          <input type="text" [ngClass]="{'invalid': reason.invalid}" formControlName="reason"
            class="form-control custom-input">
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="submit" class="btn btn-none-outline-green" (click)="closeCustomModal('refundModal')">Cancel</button>
      <button type="submit" class="btn btn-green" (click)="startRefund()"
        [disabled]="refundForm.invalid || hasPendingRefund || disabledBtnWhileCallingAPI">Refund</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Notification modal -->
<custom-modal modalName="notificationModal" [modalTitle]="getNotificationModalTitle()">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <form [formGroup]="notificationForm">
        <div class="centered-flexbox notification-form"
          *ngIf="hotelConfig.MUIfeature.sendNotificationMethods.includes('email')">
          <div class="flex-item-label">
            <div class="normal-checkbox modal-fix">
              <input type="checkbox" [checked]="notification.email" (click)="notificationMethodChange('email', $event)">
              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
              <div class="checkbox-label">Email</div>
            </div>
          </div>
          <div class="flex-item">
            <input type="text" [ngClass]="{'invalid':notificationEmail.invalid}" class="form-control custom-input"
              formControlName="email">
          </div>
        </div>
        <div class="centered-flexbox notification-form"
          *ngIf="!sendExtra && hotelConfig.MUIfeature.sendNotificationMethods.includes('sms')">
          <div class="flex-item-label">
            <div class="normal-checkbox modal-fix">
              <input type="checkbox" [checked]="notification.sms" (click)="notificationMethodChange('sms', $event)">
              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
              <div class="checkbox-label">SMS</div>
            </div>
          </div>
          <div class="flex-item">
            <input type="text" [ngClass]="{'invalid':notificationSMS.invalid}" class="form-control custom-input"
              formControlName="mobile">
          </div>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="submit" class="btn btn-none-outline-green"
        (click)="closeCustomModal('notificationModal')">Cancel</button>
      <button type="submit" class="btn btn-green" [disabled]="(!notification.sms && !notification.email)"
        (click)="sendConfirmation()">Send</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Customer edit modal -->
<custom-modal modalName="customerEditModal" modalSize="md" modalOrder="0" modalTitle="Edit customer information" (modalEvent)="handleModalStatus($event)">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <form [formGroup]="customerEditForm">
        <div class="row customer-edit-container">
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                First Name
              </div>
              <div class="item-input">
                <input class="custom-input form-control" [ngClass]="{'invalid': firstName.invalid}"
                  formControlName="firstName" type="text">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                Last Name
              </div>
              <div class="item-input">
                <input class="custom-input form-control" [ngClass]="{'invalid': lastName.invalid}"
                  formControlName="lastName" type="text">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="customer-edit-item">
              <div class="item-label">
                Real Email
              </div>
              <div class="item-input">
                <input class="custom-input form-control" [ngClass]="{'invalid': realEmail.invalid}"
                  formControlName="realEmail" type="text">
                <div class="input-error-desc" *ngIf="realEmail.invalid && editCustomerBlocked.includes('EMAIL')">Email
                  is blocked</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="customer-edit-item">
              <div class="item-label">
                Mobile
              </div>
              <div class="item-input">
                <div class="row">
                  <div class="col-5 col-sm-6">
                    <dropdown-menu
                      [menuDialCodeList]="dialCodeList"
                      [selectedDialCodeItem]="selectedDialCode"
                      [searchable]="true" [fullWidth]="true"
                      [minWidth]="244.33" adaptiveType="modal"
                      menuType="dialCode" (selectDialCodeItem)="selectDialCode($event, true)"
                      [modalOpened]="modalStatus"></dropdown-menu>
                  </div>
                  <div class="col-7 col-sm-6">
                    <input class="custom-input form-control" [ngClass]="{'invalid': mobile.invalid}"
                      formControlName="mobile" type="text">
                    <div class="input-error-desc" *ngIf="mobile.invalid && editCustomerBlocked.includes('MOBILE')">
                      Mobile is blocked</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                Nationality
              </div>
              <div class="item-input">
                <dropdown-menu [menuId]="'edit-customer-nationality'" [menuList]="countryList"
                  [selectedItem]="selectedEditCustomerNationality" [fullWidth]="true" [searchable]="true"
                  adaptiveType="modal" (selectItem)="editCustomerSelectNationality($event)"></dropdown-menu>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ng-template [ngIf]="hasNationality">
              <div class="customer-edit-item" [hidden]="!isSSNEdit">
                <div class="item-label">
                  SSN
                </div>
                <div class="item-input">
                  <input class="custom-input form-control" formControlName="ssn" type="text"
                    [ngClass]="{'invalid': ssn.invalid}">
                  <div class="input-error-desc" *ngIf="ssn.invalid && editCustomerBlocked.includes('SSN')">SSN is
                    blocked</div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="hasNationality">
              <div class="customer-edit-item" [hidden]="isSSNEdit">
                <div class="item-label">
                  Passport number
                </div>
                <div class="item-input">
                  <input class="custom-input form-control" formControlName="passportNumber" type="text"
                    [ngClass]="{'invalid': passportNumber.invalid}">
                  <div class="input-error-desc"
                    *ngIf="passportNumber.invalid && editCustomerBlocked.includes('PASSPORT_NUMBER')">Passport number is
                    blocked</div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                Address
              </div>
              <div class="item-input">
                <input class="custom-input form-control" formControlName="address" type="text">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                City
              </div>
              <div class="item-input">
                <input class="custom-input form-control" formControlName="city" type="text">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                Postal Code
              </div>
              <div class="item-input">
                <input class="custom-input form-control" formControlName="postalCode" type="text">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="customer-edit-item">
              <div class="item-label">
                Country
              </div>
              <div class="item-input">
                <dropdown-menu [menuId]="'edit-customer-country'" [menuList]="countryList"
                  [selectedItem]="selectedEditCustomerCountry" [fullWidth]="true" [searchable]="true"
                  adaptiveType="modal" (selectItem)="editCustomerSelectCountry($event)"></dropdown-menu>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="customer-edit-item">
              <div class="item-label">
                Organization
              </div>
              <div class="item-input">
                <input class="custom-input" formControlName="companyName" type="text">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="toggle-booking-channel">
              <button class="btn btn-none" (click)="toggleDisplayBookingChannel()">
                <span *ngIf="!isShowBookingChannel"><fa-icon type="solid" icon="chevron-down"></fa-icon></span><span
                  *ngIf="isShowBookingChannel"><fa-icon type="solid"
                    icon="chevron-up"></fa-icon></span>{{isShowBookingChannel ? "Hide" : "Show"}} OTA fields</button>
            </div>
          </div>
          <!--  If reservation is external, disable changes -->
          <ng-container *ngIf="isShowBookingChannel">
            <div class="col-12 col-md-6">
              <div class="customer-edit-item">
                <div class="item-label">
                  Channel
                </div>
                <div class="item-input">
                  <dropdown-menu [menuList]="otaChannelList" [selectedItem]="selectedOtaChannel"
                    [disableMenu]="isBookingChannel" [fullWidth]="true" adaptiveType="modal"
                    (selectItem)="selectOtaChannel($event)"></dropdown-menu>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="customer-edit-item">
                <div class="item-label">
                  OTA reservation number
                </div>
                <div class="item-input">
                  <input class="custom-input form-control" [ngClass]="{'invalid': bookingChannelReservationId.invalid}"
                    formControlName="bookingChannelReservationId" type="text">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="customer-edit-item">
                <div class="item-label">
                  Virtual Email
                </div>
                <div class="item-input">
                  <input class="custom-input" formControlName="emailVirtual" type="text">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('customerEditModal')">Cancel</button>
      <button type="submit" class="btn btn-green" [disabled]="!customerEditForm.valid || isCustomerSave"
        (click)="saveCustomerEdit()">Save</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Create payment link modal -->
<custom-modal modalName="paymentLinkModal" modalSize="xl" modalOrder="0" modalTitle="Create payment link">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body no-footer">
      <ng-container *ngIf="hotelConfig.MUIfeature.partialPaymentLink">
        Select amount to be paid for payment link
        <div class="payment-link-radio-inline">
          <div class="radio-item" *ngFor="let option of paymentLinkOptions">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="type-selection" [checked]="selectedPaymentLinkOption.value === option.value"
                (click)="setPaymentLinkOption(option)">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">{{option.label}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Balance (Full amount) -->
      <div *ngIf="selectedPaymentLinkOption.value === 'full'" style="padding: 15px 0">
        <div>Customer will use this payment link to pay the remaining balance of the reservation.</div>
        <div class="input-flex">
          <div class="flex-input">
            <label>Payment link</label>
            <input type="text" (click)="selectAllPaymentLink($event)" class="custom-input form-control"
              [value]="paymentLink" readonly>
          </div>
          <div class="flex-button">
            <button type="submit" class="btn btn-green" (click)="copyPaymentLink(true, paymentLink)">Copy</button>
          </div>
        </div>
      </div>
      <!-- Partial amount -->
      <div *ngIf="selectedPaymentLinkOption.value === 'partial'" style="padding: 15px 0">
        <div class="row">
          <div class="col-12 col-sm-5">
            <form [formGroup]="partialPaymentForm">
              <div class="input-flex">
                <div class="flex-input">
                  <label>Amount</label>
                  <input type="text" formControlName="amount" [ngClass]="{'invalid': partialAmount.invalid}"
                    [disabled]="generatingLink" class="form-control custom-input"
                    style="text-align: right; padding-left: 35px;">
                  <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
                </div>
                <div class="flex-button">
                  <button type="submit" class="btn btn-green" (click)="generatePaymentLink('partial')"
                    [disabled]="generatingLink">Generate link</button>
                </div>
              </div>
              <div style="margin-top: 10px;">
                Maximum amount: <b>{{totalBalance | currencyFormat:'fullPrice'}}</b>
              </div>
            </form>
          </div>
          <div class="col-12 col-sm-7">
            <div class="input-button-with-container">
              <div class="input-flex">
                <div class="flex-input">
                  <label>Generated payment link</label>
                  <input type="text" (click)="selectAllPaymentLink($event)" class="custom-input form-control"
                    [disabled]="!paymentLink" [value]="paymentLink" readonly>
                </div>
                <div class="flex-button">
                  <button type="submit" class="btn btn-green" [disabled]="!paymentLink"
                    (click)="copyPaymentLink(true, paymentLink)">Copy</button>
                </div>
                <div class="loading-container loading" [ngClass]="{'loading': generatingLink || !paymentLink}">
                  <span *ngIf="!generatingLink && !paymentLink">Input amount and click Generate link</span>
                  <span *ngIf="generatingLink">Generating link...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-history-container" *ngIf="paymentLinkHistory.length">
          <div class="container-header-title">Payment link history</div>
          <input type="text" class="custom-input form-control" placeholder="Search payment link ID"
            style="margin: 15px 0; max-width: 400px;" [(ngModel)]="paymentIdSearchValue"
            (ngModelChange)="filterPaymentHistory()">
          <div style="text-align: center; margin-top: 25px" *ngIf="!filteredPaymentLinkHistory.length">
            <h4>No payment link ID matches with the query</h4>
          </div>
          <div class="custom-table-wrapper" *ngIf="filteredPaymentLinkHistory.length">
            <table class="custom-table">
              <thead>
                <tr>
                  <th width="190px">ID</th>
                  <th width="110px" class="text-right">Amount</th>
                  <th width="100px">Created</th>
                  <th width="100px">Expire</th>
                  <th>Status</th>
                  <th width="120px">Payment link</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let linkItem of filteredPaymentLinkHistory">
                  <td [style]="{color: !linkItem.linkAvailable ? 'red' : '#424F68'}">{{linkItem.uuid}}</td>
                  <td style="text-align: right;">{{linkItem.amount}}</td>
                  <td>{{linkItem.created}}</td>
                  <td>{{linkItem.expire}}</td>
                  <td><span [style]="{color: !linkItem.linkAvailable ? 'red' : '#424F68'}">{{linkItem.status}}</span>
                  </td>
                  <td>
                    <div class="link-container" *ngIf="linkItem.linkAvailable"
                      (click)="copySelectedLinkUuid(linkItem.uuid)">
                      <div class="link-url">Copy link</div>
                      <div class="link-icon">
                        <fa-icon type="solid" icon="copy"></fa-icon>
                      </div>
                    </div>
                    <div *ngIf="!linkItem.linkAvailable" style="color: red">
                      Not available
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</custom-modal>

<!-- Additional service modal -->
<custom-modal modalName="additionalServicesModal" modalTitle="Add additional services" modalSize="xl" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body no-footer">
      <div class="additional-service-container">
        <div class="modal-title">Select products to add to reservation</div>
        <div class="service-item-row">
          <div class="row-item-action">
            <button><fa-icon type="solid" icon="circle-xmark"></fa-icon></button>
          </div>
          <div class="row-item-info">
            <div class="item-product">
              <label>Service name</label>
              <div class="btn-group mui-menu full-width" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle">
                  Airport transfer <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li>Airport transfer</li>
                  <li>Breakfast (Espresso house)</li>
                  <li>Cleaning</li>
                </ul>
              </div>
            </div>
            <div class="item-date">
              <label>Date</label>
              <div class="datepicker-input big-icon">
                <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                  [minDate]="utils.newDate(reservation.startDate)" [maxDate]="utils.newDate(reservation.endDate)"
                  [bsConfig]="{ isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true }">
                <button type="button">
                  <fa-icon type="regular" icon="calendar-days"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row-item-price">
            <div class="item-quantity">
              <label>Quantity</label>
              <input type="text" class="form-control custom-input">
            </div>
            <div class="item-unit-price">
              <label>Unit price</label>
              <input type="text" class="form-control custom-input">
              <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
            </div>
            <div class="item-vat">
              <label>VAT</label>
              <div class="btn-group mui-menu full-width" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle">
                  24% <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li>10%</li>
                  <li>14%</li>
                  <li>24%</li>
                </ul>
              </div>
            </div>
            <div class="item-total-price">
              <label>Total price</label>
              <input type="text" class="form-control custom-input">
              <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
            </div>
          </div>
          <div class="row-item-desc">
            <div class="item-desc">
              <label>Description</label>
              <textarea class="form-control custom-input"></textarea>
            </div>
          </div>
          <div class="row-item-checkbox">
            <div class="item-refundable">
              <div class="normal-checkbox">
                <input type="checkbox">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">Refundable</div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-group mui-menu full-width add-menu" dropdown>
          <button dropdownToggle type="button" class="btn dropdown-toggle">
            Add new product <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li>Airport transfer</li>
            <li>Breakfast (Espresso house)</li>
            <li>Cleaning</li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual payment modal -->
<custom-modal modalName="paymentModal" modalTitle="Manual payment" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="payment-type-radio-wrapper"
        *ngIf="this.manualPaymentTypeList.length && hotelConfig.MUIfeature.manualPaymentPreset">
        <ng-container *ngFor="let titleItem of manualPaymentTypeList">
          <div class="payment-type-radio-item">
            <input type="radio" [checked]="titleItem === this.selectedPaymentTypeRadio"
              (click)="handleRadioCheckboxManualPaymentType(titleItem)">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">{{titleItem}}</div>
            <button *ngIf="this.editPaymentTypeMode" class="btn btn-none icon-only delete-btn"
              (click)="this.handleDeletePaymentType(titleItem)">
              <fa-icon type="solid" icon="circle-xmark"></fa-icon>
            </button>
          </div>
        </ng-container>
        <div class="payment-type-action">
          <div *ngIf="!this.addPaymentTypeMode" class="normal">
            <button *ngIf="!this.editPaymentTypeMode" class="btn btn-none-outline-green"
              (click)="this.editPaymentTypeMode = true">Edit</button>
            <button *ngIf="this.editPaymentTypeMode" class="btn btn-none-outline-green"
              (click)="this.editPaymentTypeMode = false">Cancel edit</button>
            <button [disabled]="this.editPaymentTypeMode" class="btn btn-green"
              (click)="this.handleAddPaymentTypeMode()"><fa-icon type="solid" icon="plus"></fa-icon>Add payment
              type</button>
          </div>
          <div *ngIf="this.addPaymentTypeMode" class="add">
            <input id="paymentTypeInput" type="text" [(ngModel)]="this.inputAddPaymentType"
              class="form-control custom-input">
            <button class="btn btn-green" [disabled]="this.inputAddPaymentType.length === 0"
              (click)="this.handleAddPaymentType()">Add</button>
            <button class="btn btn-none-outline-green" (click)="this.handleCancelPaymentTypeMode()">Cancel</button>
          </div>
        </div>
      </div>
      <form [class.disable-class]="this.addPaymentTypeMode || this.editPaymentTypeMode" [formGroup]="addPaymentForm">
        <div class="row">
          <div class="col-md-6">
            <label>Date</label>
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="manualPaymentDate"
              alignment="adaptive"
              [allowEmptyDate]="false"
              (selectDate)="dateChange($event, 'manualPaymentDate')"
            ></dropdown-menu>
          </div>
          <div class="col-md-6">
            <label>Amount</label>
            <input type="text" formControlName="amount" [ngClass]="{'invalid': paymentAmount.invalid}"
              class="form-control custom-input" style="text-align: right; padding-left: 35px;">
            <div class="currency-icon-input">{{utils.getCurrency(currency)}}</div>
          </div>
          <div class="col-md-12" style="margin: 0">
            <div class="text-right" style="font-size: 12pt; margin-top: 5px">{{getPaymentAmountRangeNote()}}</div>
          </div>
          <div class="col-md-12">
            <label>Payment provider</label>
            <input type="text" formControlName="paymentProvider" [ngClass]="{'invalid': paymentProvider.invalid}"
              class="form-control custom-input">
          </div>
          <div class="col-md-12">
            <label>Payment reference</label>
            <input type="text" formControlName="paymentReference" [ngClass]="{'invalid': paymentReference.invalid}"
              class="form-control custom-input">
          </div>
          <div style="display: none;">
            <input type="text" formControlName="paymentType">
          </div>
          <!-- https://projectnelson.atlassian.net/browse/MUI-67 -->
          <!-- comment #49 -->
          <div class="col-md-12" *ngIf="isExternalWithExtra()">
            <label>Payment for</label>
            <div class="normal-checkbox radio-style">
              <input type="radio" name="type-selection" (click)="paymentForChange(false)"
                [checked]="!isManualPaidForOtaExtras">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Room payment</div>
            </div>
            <div class="normal-checkbox radio-style" *ngIf="isExternalWithExtra()">
              <input type="radio" name="type-selection" (click)="paymentForChange(true)"
                [checked]="isManualPaidForOtaExtras">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Extra payment</div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('paymentModal')">Cancel</button>
      <button class="btn btn-green" (click)="addPayment()">Add payment</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Integrated invoice modal -->
<custom-modal modalName="integratedInvoiceModal" modalTitle="Integrated invoice" modalSize="xl" modalOrder="0">
  <ng-container *ngIf="reservation && invoiceValidate && hotelConfig.MUIfeature.manualPayment.includes('integrated')">
    <div class="custom-modal-body">
      <div class="custom-modal-invoice">
        <div class="notice-container" *ngIf="currentInvoiceId">
          <div class="notice-icon">
            <fa-icon type="solid" icon="circle-info"></fa-icon>
          </div>
          <div class="notice-content">Editing of invoices and credit invoice creation can only be done in Fennoa.</div>
        </div>
        <form [formGroup]="invoiceForm">
          <div class="row">
            <!-- Column 1 -->
            <div class="col-12 col-sm-6">
              <div class="body-title">Invoice recipient</div>
              <label>Customer</label>
              <div class="search-container" *ngIf="invoiceVar">
                <input type="text" class="custom-input form-control" (click)="showResult()" (blur)="hideResult()"
                  [(ngModel)]="invoiceVar.searchKeyword" (ngModelChange)="searchCustomer()"
                  [ngModelOptions]="{standalone: true}" [disabled]="currentInvoiceId">
                <div class="search-icon">
                  <fa-icon type="solid" icon="magnifying-glass"></fa-icon>
                </div>
                <div class="search-result" *ngIf="invoiceVar.showSearch">
                  <div class="result-error" *ngIf="invoiceVar.filterResult && invoiceVar.filterResult.length == 0">No
                    existing customers match with your keyword</div>
                  <ng-container *ngFor="let customer of invoiceVar.filterResult; let customerIndex = index">
                    <div class="result-item" (click)="selectFennoaCustomer(customer)"
                      [ngClass]="{active: (customer.Customer.customer_no + '-' + customer.Customer.name) == invoiceVar.searchKeyword}">
                      {{customer.Customer.customer_no}} - {{customer.Customer.name}}</div>
                  </ng-container>
                </div>
              </div>
              <div class="normal-checkbox right-align" [ngClass]="{'disabled':currentInvoiceId}">
                <input type="checkbox" [checked]="invoiceVar && invoiceVar.copyReservation" (click)="copyReservation()"
                  [disabled]="currentInvoiceId">
                <div class="checkbox-label">Copy info from reservation</div>
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
              </div>
              <label>Name</label>
              <input type="text" class="custom-input form-control" (input)="inputCheckExisting()" formControlName="name"
                [ngClass]="{'invalid': !invoiceValidate.name}" (click)="removeInvoiceInvalid()">
              <div class="warning-text" *ngIf="invoiceVar && invoiceVar.existCustomerAlert">Customer already exists, if
                you do not select existing customers, this customer will be created.</div>
              <label>Street address</label>
              <input type="text" class="custom-input form-control" formControlName="address"
                [ngClass]="{'invalid': !invoiceValidate.address}" (click)="removeInvoiceInvalid()">
              <div class="row">
                <div class="col-md-6">
                  <label>Postal code</label>
                  <input type="text" class="custom-input form-control" formControlName="postalcode"
                    [ngClass]="{'invalid': !invoiceValidate.postal}" (click)="removeInvoiceInvalid()">
                </div>
                <div class="col-md-6">
                  <label>Post office</label>
                  <input type="text" class="custom-input form-control" formControlName="city"
                    [ngClass]="{'invalid': !invoiceValidate.city}" (click)="removeInvoiceInvalid()">
                </div>
              </div>
              <div class="row">
                <!-- Country -->
                <div class="col-md-6">
                  <label>Country</label>
                  <dropdown-menu [menuId]="'integrated-invoice-country'" [menuList]="countryList"
                    [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceCountry" [fullWidth]="true"
                    [searchable]="true" adaptiveType="modal"
                    (selectItem)="selectInvoiceCountry($event)"></dropdown-menu>
                </div>
                <!-- Language -->
                <div class="col-md-6">
                  <label>Language</label>
                  <dropdown-menu [menuId]="'integrated-invoice-language'" [menuList]="langList"
                    [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceLang" [fullWidth]="true"
                    adaptiveType="modal" (selectItem)="selectInvoiceLang($event)"></dropdown-menu>
                </div>
              </div>
              <!-- Delivery method -->
              <label>Delivery method</label>
              <dropdown-menu [menuId]="'integrated-invoice-delivery-method'" [menuList]="invoiceDeliveryMethodList"
                [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceDeliveryMethod" [fullWidth]="true"
                adaptiveType="modal" (selectItem)="selectInvoiceDeliveryMethod($event)"></dropdown-menu>
              <div class="e-invoice-address-for-email"
                *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value === 'email'">
                <label>Email</label>
              </div>
              <div class="e-invoice-address"
                *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value !== 'email'">
                <label>E-invoice address</label>
              </div>
              <input type="text" class="custom-input form-control" formControlName="einvoice_address"
                [ngClass]="{'invalid': !invoiceValidate.eAddress}" (click)="removeInvoiceInvalid()">
              <div class="e-invoice-operator"
                *ngIf="selectedInvoiceDeliveryMethod && selectedInvoiceDeliveryMethod.value !== 'email'">
                <label>E-invoice operator</label>
                <dropdown-menu menuId="integrated-e-invoice-operator" [menuList]="eInvoiceOperatorList"
                  [disableMenu]="disableInvoiceForm" [highlightError]="!invoiceValidate.eOperator"
                  [selectedItem]="selectedEInvoiceOperator" [searchable]="true" [fullWidth]="true" adaptiveType="modal"
                  (selectItem)="selectEInvoiceOperator($event)"></dropdown-menu>
              </div>

            </div>
            <!-- Column 2 -->
            <div class="col-12 col-sm-6">
              <div class="invoice-container" *ngIf="currentInvoiceId">
                <div class="invoice-info">
                  <div class="info-text" *ngIf="currentInvoiceNumber">Invoice ID (Fennoa): {{currentInvoiceNumber}}
                  </div>
                  <div class="info-text" *ngIf="!currentInvoiceNumber">Draft invoice</div>
                  <div class="info-text">Invoice status: {{invoiceStatus}}</div>
                </div>
              </div>
              <div class="body-title">Invoice details</div>
              <label>Reservation total</label>
              <div class="selector-container bottom-border">
                <div class="selector-item">
                  <dropdown-menu menuId="integrated-invoice-type" [menuList]="invoiceTypeList"
                    [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceType" [buttonFontSize]="'16px'"
                    [fullWidth]="true" adaptiveType="modal" (selectItem)="selectInvoiceType($event)"></dropdown-menu>
                </div>
                <div class="selector-item input-percentage-item">
                  <div class="price-input">
                    <input class="custom-input form-control" appEditPercentage type="text"
                      [(ngModel)]="invoicePaymentPercentage" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="invoicePaymentChange('percent')"
                      [disabled]="disableInvoiceForm || (selectedInvoiceType !== undefined && ['FULL', 'CREDIT_INVOICE', 'NONE'].includes(selectedInvoiceType.value))">
                    <div class="symbol">%</div>
                  </div>
                </div>
                <div class="selector-item input-item">
                  <div class="price-input">
                    <input class="custom-input form-control" appEditPriceWithZero type="text"
                      [(ngModel)]="invoicePaymentAmount" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="invoicePaymentChange('amount')"
                      [disabled]="disableInvoiceForm || (selectedInvoiceType !== undefined && ['FULL', 'CREDIT_INVOICE', 'NONE'].includes(selectedInvoiceType.value))">
                    <div class="symbol">{{utils.getCurrency(currency)}}</div>
                  </div>
                </div>
                <div class="action-button"
                  *ngIf="selectedInvoiceType && selectedInvoiceType.value !== 'CREDIT_INVOICE' && !showInvoiceItem && !disableInvoiceForm">
                  <button class="success-color" (click)="toggleInvoiceItem()">
                    <fa-icon type="solid" icon="circle-plus"></fa-icon>
                  </button>
                </div>
              </div>
              <ng-container
                *ngIf="selectedInvoiceType && selectedInvoiceType.value !== 'CREDIT_INVOICE' && showInvoiceItem">
                <label>Additional items</label>
                <!-- Selected items -->
                <ng-container *ngFor="let invoiceItem of invoiceItemList; let invoiceIndex = index">
                  <div class="selector-container">
                    <div class="selector-item">
                      <dropdown-menu [menuId]="'integrated-invoice-additional'" [menuList]="invoiceAdditionalList"
                        [disableMenu]="currentInvoiceId" [selectedItem]="invoiceItem.selectedMenu" [fullWidth]="true"
                        adaptiveType="modal" (selectItem)="selectInvoiceItem(invoiceIndex, $event)"></dropdown-menu>
                    </div>
                    <div class="selector-item input-item">
                      <div class="price-input">
                        <input class="custom-input form-control" appEditPriceWithZero type="text"
                          [(ngModel)]="invoiceItem.value" [ngModelOptions]="{standalone: true}"
                          [disabled]="disableInvoiceForm">
                        <div class="symbol">{{utils.getCurrency(currency)}}</div>
                      </div>
                    </div>
                    <div class="action-button" *ngIf="!disableInvoiceForm">
                      <button class="error-color" (click)="removeInvoiceItem(invoiceIndex)">
                        <fa-icon type="solid" icon="circle-xmark"></fa-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <!-- Add item -->
                <div class="selector-container" *ngIf="!disableInvoiceForm">
                  <div class="selector-item">
                    <dropdown-menu [menuId]="'integrated-invoice-add-additional'" [menuList]="invoiceAdditionalList"
                      [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceAdditional" [fullWidth]="true"
                      adaptiveType="modal" (selectItem)="addInvoiceItem($event)"></dropdown-menu>
                  </div>
                  <div class="action-button" *ngIf="invoiceItemList.length === 0">
                    <button class="error-color" (click)="toggleInvoiceItem()">
                      <fa-icon type="solid" icon="circle-xmark"></fa-icon>
                    </button>
                  </div>
                </div>

              </ng-container>

              <ng-container *ngIf="selectedInvoiceType && selectedInvoiceType.value === 'CREDIT_INVOICE'">
                <label>Invoice to credit</label>
                <div class="search-container dropdown">
                  <div class="delivery-input">
                    <div class="wrap-field-dropdown">
                      <input type="text" class="custom-input form-control" (click)="showResultCreditInvoice()"
                        (blur)="hideResultCreditInvoice()" [(ngModel)]="creditInvoiceVar.searchKeyword"
                        (ngModelChange)="searchCreditInvoice()" [ngModelOptions]="{standalone: true}"
                        [disabled]="disableInvoiceForm" [ngClass]="{'invalid': !invoiceValidate.creditInvoice}">
                      <span><fa-icon type="solid" icon="chevron-down"></fa-icon></span>
                    </div>
                  </div>
                  <div class="search-result" *ngIf="creditInvoiceVar.showSearch">
                    <div class="result-error" *ngIf="creditInvoiceVar.filterResult.length == 0">No credit invoices match
                      with your keyword</div>
                    <ng-container *ngFor="let creditInvoice of creditInvoiceVar.filterResult; let methodIndex = index">
                      <div class="result-item" (click)="selectCreditInvoice(methodIndex)"
                        [ngClass]="{active: creditInvoice.name == creditInvoiceVar.searchKeyword}">{{
                        creditInvoice.invoiceNo }} - {{ creditInvoice.amount }} {{utils.getCurrency(currency)}}</div>
                    </ng-container>
                  </div>
                </div>
                <table class="custom-table" style="margin-top:15px"
                  *ngIf="invoiceData && creditInvoiceVar.selectedInvoice">
                  <thead>
                    <th>
                      <div>Products</div>
                    </th>
                    <th style="width: 130px">
                      <div>Quantity</div>
                    </th>
                    <th style="width: 130px">
                      <div>Price</div>
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let invoice of invoiceData.SalesInvoiceRow">
                      <td>
                        {{getFennoaCreditProductName(invoice.code)}}
                      </td>
                      <td style="text-align: right;">
                        {{invoice.quantity}}
                      </td>
                      <td style="text-align: right;">
                        {{getFennoaCreditPrice(invoice.price_with_vat)}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <div class="terms-of-payment">
                <label>Terms of payment</label>
                <dropdown-menu [menuId]="'integrated-invoice-terms-of-payment'" [menuList]="invoiceTermsOfPaymentList"
                  [disableMenu]="currentInvoiceId" [selectedItem]="selectedInvoiceTermsOfPayment" [fullWidth]="true"
                  adaptiveType="modal" (selectItem)="selectInvoiceTermsOfPayment($event)"></dropdown-menu>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Invoice date</label>
                  <dropdown-menu
                    menuType="datepicker"
                    [initialDate]="invoiceDate"
                    [secondDate]="dueDate"
                    [highlightError]="invoiceDateError"
                    alignment="adaptive"
                    [allowEmptyDate]="false"
                    (selectDate)="dateChange($event, 'invoiceDate')"
                  ></dropdown-menu>
                </div>
                <div class="col-md-6">
                  <label>Due date</label>
                  <dropdown-menu
                    menuType="datepicker"
                    [initialDate]="dueDate"
                    [secondDate]="invoiceDate"
                    [highlightError]="dueDateError"
                    alignment="adaptive"
                    [allowEmptyDate]="false"
                    (selectDate)="dateChange($event, 'dueDate')"
                  ></dropdown-menu>
                </div>
              </div>
              <!-- Your reference -->
              <div class="your-reference">
                <label>Your reference</label>
                <input type="text" class="custom-input form-control" (input)="inputCheckExisting()"
                  formControlName="your_reference">
              </div>
              <!-- <label>VAT handling</label>
              <input type="text" class="custom-input" formControlName="vat_number"> -->
              <!-- <div class="text-right"><label class="clickable">Show more fields</label></div> -->
              <label>Description (optional)</label>
              <textarea type="text" rows="3" class="form-control" style="resize: none" formControlName="description"
                [ngClass]="{'invalid': !invoiceValidate.description}" (click)="removeInvoiceInvalid()"></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="custom-modal-footer">
      <button class="btn btn-none-outline-green" [disabled]="invoiceSaving" *ngIf="!currentInvoiceId"
        (click)="clearInvoiceForm()">Clear</button>
      <button class="btn btn-green" [disabled]="currentInvoiceId || invoiceSaving" (click)="saveInvoice()">Save</button>
      <button class="btn btn-green" [disabled]="!currentInvoiceId || invoiceApproved || invoiceApproving"
        (click)="approveSendInvoice()">Approve & Send</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual invoice modal -->
<custom-modal modalName="manualInvoiceModal" modalTitle="Manual invoice" modalSize="xl" modalOrder="0">
  <ng-container *ngIf="reservation && invoiceManualValidate && hotelConfig.MUIfeature.manualPayment.includes('manual')">
    <div class="custom-modal-body">
      <form [formGroup]="reservationSearch">
        @if(!isHiddenSearch) {
          <div class="container-title" style="padding: 0">Search reservations</div>
          <div class="row" style="margin-bottom: 15px">
            <div class="col-md-6">
              <label>Search keyword</label>
              <input class="form-control custom-input" formControlName="searchQuery">
            </div>
            <div class="col-md-3">
              <label>Start date</label>
              <dropdown-menu
                menuType="datepicker"
                [initialDate]="startDate"
                [secondDate]="endDate"
                alignment="adaptive"
                [allowEmptyDate]="false"
                (selectDate)="dateChange($event, 'startDate')"
              ></dropdown-menu>
            </div>
            <div class="col-md-3">
              <label>End date</label>
              <dropdown-menu
                menuType="datepicker"
                [initialDate]="endDate"
                [secondDate]="startDate"
                alignment="adaptive"
                [allowEmptyDate]="false"
                (selectDate)="dateChange($event, 'endDate')"
              ></dropdown-menu>
            </div>
            <br />
            <div class="col-md-6">
              <label>Filters</label>
              <dropdown-menu
                menuType="filter"
                [filterList]="filterList"
                [fullWidth]="true"
                [minWidth]="250"
                (updatedList)="handleUpdatedFilters($event)"
              ></dropdown-menu>
            </div>
            <div class="col-md-3">
              <ng-container>
                <label>Customer type</label>
                <div class="box-selector">
                  <div class="custom-radio" *ngFor="let object of searchObjects; let i = index">
                    <input type="checkbox" name="reservation" [checked]="object.checked"
                      (click)="changeObject(i,$event)">
                    <div class="radio-box">{{object.label}}</div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-md-3">
              <button type="button" [disabled]="isLoadingInvoiceManual || isMoreLoading" class="btn btn-green label-offset full-width" (click)="search('search')"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
            </div>
          </div>
        }
        <div class="toggle-manual-search">
          <button class="btn btn-none" (click)="showSearchText()">
            <span *ngIf="isHiddenSearch">
              <fa-icon type="solid" icon="chevron-down"></fa-icon>
            </span>
            <span *ngIf="!isHiddenSearch">
              <fa-icon type="solid" icon="chevron-up"></fa-icon>
            </span>
            {{!isHiddenSearch ? "Hide" : "Show"}} search fields
          </button>
        </div>

        <div class="container-title" style="padding: 0">Selected reservations for invoice</div>
        <div class="selected-reservation-invoice">
          @for(result of reservationCodes; track result) {
            <div class="filter-content">
              <div class="filter-item">
                <div class="filter-label">{{result}}</div>
                <div (click)="removeInvoice(result)">
                  <fa-icon type="solid" icon="xmark"></fa-icon>
                </div>
              </div>
            </div>
          }
        </div>
        <div class="row">
          <div class="col-md-12 mt-20">
            <div class="table-scroll">
              <table class="custom-table" *ngIf="!isLoadingInvoiceManual && searchResult && searchResult.length > 0">
                <thead>
                  <th style="width: 20px">
                    <div class="normal-checkbox" (click)="checkAllReservationsForInvoiceManual(false)">
                      <input type="checkbox" [checked]="isCheckAllReservations">
                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                    </div>
                  </th>
                  <th style="width: 150px">
                    Reservation
                  </th>
                  <th [ngClass]="{sortActive: orderBy == 'CONTACT_NAME'}">
                    <div class="flex-container">
                      <div class="table-title">Booker Information</div>
                      <div class="table-sort" (click)="toggleSorting('CONTACT_NAME')">
                        <fa-icon type="solid"
                          [icon]="orderBy == 'CONTACT_NAME' && orderDir == 'DESC' ? 'arrow-up-wide-short' : 'arrow-down-short-wide'"></fa-icon>
                      </div>
                    </div>
                  </th>
                  <th style="width: 100px" [ngClass]="{sortActive: orderBy == 'CHECK_OUT'}">
                    <div class="flex-container">
                      <div class="table-title">Check out</div>
                      <div class="table-sort" (click)="toggleSorting('CHECK_OUT')">
                        <fa-icon type="solid"
                          [icon]="orderBy == 'CHECK_OUT' && orderDir == 'DESC' ? 'arrow-up-wide-short' : 'arrow-down-short-wide'"></fa-icon>
                      </div>
                    </div>
                  </th>
                  <th style="width: 200px">
                    Guests
                  </th>
                  <th style="width: 125px">
                    Total (remain)
                  </th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let result of searchResult; let i = index">
                    <tr [ngClass]="{graybg: result.reservationState != 'CONFIRMED'}">
                      <td>
                        <div class="normal-checkbox" (click)="checkReservationForInvoiceManual(i)">
                          <input type="checkbox" [checked]="result.isChecked">
                          <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        </div>
                      </td>
                      <td>
                        <b>{{result.reservationCode}}</b>
                        <br>{{getChannelLabel(result.bookingChannel)}}
                        <ng-container *ngIf="result.bookingChannelReservationId">
                          <br><br>External channel reservation number: <b>{{result.bookingChannelReservationId}}</b>
                        </ng-container>
                      </td>
                      <td>
                        {{result.customer.firstName+' '+result.customer.lastName}}
                        <br *ngIf="(result.customer.emailReal)">{{result.customer.emailReal}}
                        <br *ngIf="(result.customer.emailVirtual)">{{result.customer.emailVirtual}}
                        <br>{{result.customer.mobile}}
                        <br *ngIf="(result.customer.companyName)">{{result.customer.companyName}}
                      </td>
                      <td> {{utils.convertStringDate(result.checkOut)}}</td>
                      <td>
                        <div *ngFor="let room of result.guestsByRoomLabel | keyvalue">
                          <b>Room {{room.key}}</b>
                          <div *ngFor="let guest of convertToArray(room.value)">
                            <div *ngIf="(guest.firstName !== 'NA' || guest.lastName !== 'NA')">{{guest.firstName + ' ' +
                              guest.lastName }}</div>
                          </div><br>
                        </div>
                      </td>
                      <td>
                        {{ result.remainingAmount.toFixed(2) }} {{utils.getCurrency(currency)}}
                      </td>
                    </tr>
                  </ng-container>
                  <tr class="invoicing-surcharge-row">
                    <th>
                      <div class="normal-checkbox" (click)="checkInvoicingSurcharge()">
                        <input type="checkbox" [checked]="includeSurcharge">
                        <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                      </div>
                    </th>
                    <th class="invoicing-surcharge-text">Invoicing surcharge</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="invoicing-surcharge-text">
                      <input type="text" (blur)="inputSurchargePrice($event)"
                        value="{{ surchargePrice.toFixed(2) }}">{{utils.getCurrency(currency)}}
                    </th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="dark-bg">
                    <td></td>
                    <td class="format-for-total-invoice">Total to invoice</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="format-for-total-invoice">{{ sumTotalRemainingPrice.toFixed(2) }}
                      {{utils.getCurrency(currency)}}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- Loading Invoice manual icon -->
            <div class="center-item" *ngIf="isLoadingInvoiceManual">
              <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
            </div>
          </div>
        </div>
      </form>
      <div class="table-pagination text-center" *ngIf="cleanEvent.totalItem > cleanEvent.items">
        <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == 1}"
          (click)="singlePageChange('first')">First</span>
        <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == 1}"
          (click)="singlePageChange('prev')">Prev</span>
        <span class="page-link dot"
          *ngIf="cleanEvent.current !== 1 && cleanEvent.finalPage > cleanEvent.showNav">...</span>
        <span class="page-link" *ngFor="let page of cleanEvent.numberOfPage"
          [ngClass]="{'active':cleanEvent.current == page}" (click)="changePage(page)">{{ page }}</span>
        <span class="page-link dot"
          *ngIf="cleanEvent.current + (this.cleanEvent.showNav - 1) < cleanEvent.finalPage">...</span>
        <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}"
          (click)="singlePageChange('next')">Next</span>
        <span class="page-link nav-control" [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}"
          (click)="singlePageChange('last')">Last</span>
      </div>
      <form [formGroup]="invoiceSaloForm">
        <div class="container-title" style="padding-top: 30px">Invoice information</div>
        <div class="row">
          <div class="col-md-6">
            <label>Name</label>
            <input type="text" class="custom-input" (input)="inputCheckExisting()"
              [ngClass]="{'invalid': !invoiceManualValidate.name}" formControlName="name"
              (click)="removeInvoiceManualInvalid()">
            <div class="warning-text" *ngIf="invoiceSaloVar && invoiceSaloVar.existCustomerAlert">Customer already
              exists, if you do not select existing customers, this customer will be created.</div>

            <label>Street address</label>
            <input type="text" class="custom-input" formControlName="address"
              [ngClass]="{'invalid': !invoiceManualValidate.address}" (click)="removeInvoiceManualInvalid()">

            <div class="row">
              <div class="col-md-6">
                <label>Postal code</label>
                <input type="text" class="custom-input" formControlName="postalcode"
                  [ngClass]="{'invalid': !invoiceManualValidate.postal}" (click)="removeInvoiceManualInvalid()">
              </div>
              <div class="col-md-6">
                <label>Post office</label>
                <input type="text" class="custom-input" formControlName="city"
                  [ngClass]="{'invalid': !invoiceManualValidate.city}" (click)="removeInvoiceManualInvalid()">
              </div>
            </div>
            <div class="row">
              <!-- Country -->
              <div class="col-md-6">
                <label>Country</label>
                <dropdown-menu [menuId]="'manual-invoice-country'" [menuList]="countryList"
                  [selectedItem]="selectedInvoiceCountry" [fullWidth]="true" [searchable]="true" adaptiveType="modal"
                  (selectItem)="selectInvoiceCountry($event)"></dropdown-menu>
              </div>
              <div class="col-md-6">
                <label>Language</label>
                <dropdown-menu [menuId]="'manual-invoice-language'" [menuList]="langList"
                  [selectedItem]="selectedInvoiceLang" [fullWidth]="true" adaptiveType="modal"
                  (selectItem)="selectInvoiceLang($event)"></dropdown-menu>
              </div>
            </div>
            <label>Organization</label>
            <input type="text" class="custom-input" formControlName="companyName">
          </div>
          <div class="col-md-6">
            <div class="terms-of-payment dropdown">
              <label>Terms of payment</label>
              <dropdown-menu [menuId]="'manual-invoice-terms-of-payment'" [menuList]="invoiceTermsOfPaymentList"
                [selectedItem]="selectedInvoiceTermsOfPayment" [fullWidth]="true" adaptiveType="modal"
                (selectItem)="selectInvoiceTermsOfPayment($event)"></dropdown-menu>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Invoice date</label>
                <dropdown-menu
                  menuType="datepicker"
                  [initialDate]="invoiceDate"
                  [secondDate]="dueDate"
                  [highlightError]="invoiceDateError"
                  alignment="adaptive"
                  [allowEmptyDate]="false"
                  (selectDate)="dateChange($event, 'invoiceDate')"
                ></dropdown-menu>
              </div>
              <div class="col-md-6">
                <label>Due date</label>
                <dropdown-menu
                  menuType="datepicker"
                  [initialDate]="dueDate"
                  [secondDate]="invoiceDate"
                  [highlightError]="dueDateError"
                  alignment="adaptive"
                  [allowEmptyDate]="false"
                  (selectDate)="dateChange($event, 'dueDate')"
                ></dropdown-menu>
              </div>
            </div>
            <!-- Your reference -->
            <div class="your-reference">
              <label>Your reference</label>
              <input type="text" class="custom-input" (input)="inputCheckExisting()" formControlName="your_reference">
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="custom-modal-footer">
      <button class="btn btn-none-outline-green grow-flex format-invoice-manual-btn" [disabled]="invoiceSaving"
        (click)="clearInvoiceForm()">Clear</button>
      <button class="btn btn-green grow-flex" [disabled]="isLoadingCreatePdf"
        (click)="checkCreatePdfForInvoiceManual()">Create PDF</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual Download PDF modal -->
<custom-modal modalName="manualDownloadPDFModal" modalTitle="Download PDF" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div style="padding-top: 0; padding-bottom: 10px; margin-top: -5px;">Select invoice to download</div>
      <ng-container *ngFor="let manualInvoice of manualInvoices; let i = index">
        <div class="centered-flexbox credit-invoice-form"
          [ngClass]="{selected: downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo}"
          *ngIf="!isLoadingInvoiceManual">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund2" (click)="changedownloadCreditInvoiceOption(manualInvoice)"
                [checked]="downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Invoice {{manualInvoice.invoiceNo}} created on {{manualInvoice.created |
                date:'dd.MM.yyyy'}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div style="margin-bottom: 10px; width: 150px">
        <label>Language</label>
        <dropdown-menu [menuId]="'manual-invoice-download-lang'" [menuList]="langList"
          [selectedItem]="selectedInvoiceLang" [fullWidth]="true" adaptiveType="modal"
          (selectItem)="selectInvoiceLang($event)"></dropdown-menu>
      </div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('manualDownloadPDFModal')">Cancel</button>
      <button type="submit" class="btn btn-green"
        [disabled]="!downloadCreditInvoiceFormSelection || isLoadingInvoiceManual"
        (click)="downloadForCreditInvoice()">Download</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual create credit invoice modal -->
<custom-modal modalName="manualCreateCreditInvoiceModal" modalTitle="Create credit invoice" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div style="padding-top: 0; padding-bottom: 10px; margin-top: -5px;">Select invoice to credit</div>
      <ng-container *ngFor="let manualInvoice of manualInvoices; let i = index">
        <div class="centered-flexbox credit-invoice-form"
          [ngClass]="{selected: downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo}"
          *ngIf="!isLoadingInvoiceManual">
          <div class="flex-item item-checkbox">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="refund" (click)="changedownloadCreditInvoiceOption(manualInvoice)"
                [checked]="downloadCreditInvoiceFormSelection == manualInvoice.invoiceNo">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Invoice {{manualInvoice.invoiceNo}} created on {{manualInvoice.created |
                date:'dd.MM.yyyy'}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row" style="margin-top: 5px; margin-bottom: 10px">
        <div class="col-12 col-sm-6">
          <label>Set credit invoice date</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="createCreditDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            [minDate]="minCreateCreditDate"
            (selectDate)="dateChange($event, 'createCreditDate')"
          ></dropdown-menu>
        </div>
        <div class="col-12 col-sm-6">
          <label>Language</label>
          <dropdown-menu [menuId]="'manual-invoice-create-lang'" [menuList]="langList"
            [selectedItem]="selectedInvoiceLang" [fullWidth]="true" adaptiveType="modal"
            (selectItem)="selectInvoiceLang($event)"></dropdown-menu>
        </div>
      </div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('manualCreateCreditInvoiceModal')">Cancel</button>
      <button type="submit" class="btn btn-green"
        [disabled]="!downloadCreditInvoiceFormSelection || isLoadingInvoiceManual"
        (click)="createCreditInvoiceFunc()">Create</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Add payment menu modal -->
<custom-modal modalName="addPaymentMenuModal" modalTitle="Add payment" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body no-footer button-menu-list">
      <ng-container *ngFor="let item of addPaymentActionMenu">
        <div class="body-item" *ngIf="item.show" (click)="openSelectedPaymentType(item.value)">
          <div class="item-container">
            <div class="item-icon">
              <fa-icon [type]="item.iconType" [icon]="item.icon" width="35"></fa-icon>
            </div>
            <div class="item-title">
              {{item.title}}
              <div class="item-desc">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</custom-modal>

<!-- Activity log modal -->
<custom-modal modalName="activityLogModal" modalTitle="Activity log" modalSize="lg" modalOrder="0" position="top">
  <ng-container *ngIf="reservation && activityLogResponse.length">
    <div class="custom-modal-body no-footer">
      <activity-log [reservationCode]="reservation.reservationCode" [reservationUuid]="reservation.uuid"
        [reservationProducstById]="resLogProdById" [reservationCreated]="resLogCreated"
        [activityLogResponse]="activityLogResponse"></activity-log>
    </div>
  </ng-container>
</custom-modal>

<!-- Activity log 2 modal -->
<custom-modal modalName="activityLog2Modal" modalTitle="Activity log" modalSize="lg" modalOrder="0" customModalClass="activity-log" position="top">
  <ng-container *ngIf="reservation && activityLog2Response.length">
    <div class="custom-modal-body no-footer">
      <activity-log-2
        [activityLogResponse]="activityLog2Response"
        [hotel]="reservation.hotel"
        [products]="reservation.productsById"
      ></activity-log-2>
    </div>
  </ng-container>
</custom-modal>

<!-- Default modal -->
<custom-modal modalName="defaultModal" modalTitle="Default" modalSize="" modalOrder="0">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">

    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('defaultModal')">Cancel</button>
    </div>
  </ng-container>
</custom-modal>

<!-----------CUSTOM ALERTS----------->
<!-- Result alert -->
<custom-modal modalName="resultAlert" modalSize="alert-modal" modalOrder="101" [backdropClose]="false"
  [alertType]="resultElements.type">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">{{resultElements.title}}</div>
      <div class="alert-desc">{{resultElements.desc}}</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
    </div>
  </ng-container>
</custom-modal>
<!-- Loading alert -->
<custom-modal modalName="loadingAlert" modalSize="alert-modal" modalOrder="100" [backdropClose]="false"
  alertType="loading">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">{{loadingElements.title}}</div>
      <div class="alert-desc" *ngIf="loadingElements.desc">{{loadingElements.desc}}</div>
    </div>
  </ng-container>
</custom-modal>

<!-- Cancel reservation alert -->
<custom-modal modalName="cancelReservationAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <ng-container *ngIf="!confirmedCancel">
      <div class="custom-modal-body">
        <div class="alert-title">Cancel reservation</div>
        <div class="alert-desc">Are you sure you want to cancel this reservation?</div>
      </div>
      <div class="custom-modal-footer">
        <button type="button" class="btn btn-none-outline-green" [disabled]="isLoadingCancel"
          (click)="closeCustomModal('cancelReservationAlert')">No</button>
        <button type="button" class="btn btn-green" [disabled]="isLoadingCancel" (click)="confirmCancel()">Yes</button>
      </div>
    </ng-container>
    <ng-container *ngIf="confirmedCancel">
      <div class="custom-modal-body">
        <div class="alert-icon">
          <fa-icon type="solid" icon="circle-question"></fa-icon>
        </div>
        <div class="alert-title">Cancel reservation</div>
        <div class="alert-desc">Are you sure the room is clean?</div>
      </div>
      <div class="custom-modal-footer">
        <button type="button" [disabled]="isLoadingCancel" class="btn btn-none-outline-green"
          (click)="cancelReservation(false)">Don't know</button>
        <button type="button" [disabled]="isLoadingCancel" class="btn btn-green"
          (click)="cancelReservation(true)">Yes</button>
      </div>
    </ng-container>
  </ng-container>
</custom-modal>

<!-- Reset door code alert -->
<custom-modal modalName="resetDoorCodeAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Reset door code</div>
      <div class="alert-desc">Are you sure that you want to reset the door code of <b>Room
          {{resetDoorCodeValue.roomNumber}}</b>?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('resetDoorCodeAlert')">Cancel</button>
      <button type="button" class="btn btn-green"
        (click)="resetDoorCode(resetDoorCodeValue.orderId,resetDoorCodeValue.uuid, resetDoorCodeValue.roomNumber)">Reset</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Allow for manually ending a on-going room usage earlier than the original end time -->
<custom-modal modalName="overlapOnGoingAlert" modalSize="alert-modal" modalOrder="5" [backdropClose]="false"
  alertType="warning">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Ongoing reservation</div>
      <div class="alert-desc">Do you want allow guest to enter anyway?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('overlapOnGoingAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="overlapOnGoingReservation()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual refund alert -->
<custom-modal modalName="manualRefundAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="warning">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Manual refund</div>
      <div class="alert-desc">Are you sure that you want to mark this pending refund as completed?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('manualRefundAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="completeManualRefund()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Confirm incorrect SSN alert -->
<custom-modal modalName="confirmIncorrectSSNAlert" modalSize="alert-modal" modalOrder="5" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Invalid Finnish SSN</div>
      <div class="alert-desc">This is not a valid Finnish SSN, do you want to save anyway?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('confirmIncorrectSSNAlert');openCustomModal('customerEditModal')">No</button>
      <button type="button" class="btn btn-green" (click)="saveInfo()" [disabled]="isCustomerSave">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Confirm blocked reservation alert -->
<custom-modal modalName="confirmBlockedAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="warning">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Confirm reservation for a blocked customer</div>
      <div class="alert-desc">You are confirming a reservation with a blocked customer, this customer will be
        automatically unblocked. Do you want to continue?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('confirmBlockedAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="startConfirmReservation(true)">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Unblock alert -->
<custom-modal modalName="unblockAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Unblock reservation</div>
      <div class="alert-desc">All customer instances belong to the email <b>{{reservation.customer.emailReal}}</b> and
        phone number <b>{{reservation.customer.mobile}}</b> will be unblocked. Do you want to continue?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('unblockAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="unblockReservation()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Cancel lineItems alert -->
<custom-modal modalName="cancelLineItemAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation && !confirmedCancel">
    <div class="custom-modal-body">
      <div class="alert-title">Items selected for cancellation</div>
      <div class="alert-desc">Do you want to refund those items?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('cancelLineItemAlert')">Go
        back</button>
      <button type="button" class="btn btn-outline-green" (click)="confirmCancelLineItem(false)">No</button>
      <button type="button" class="btn btn-green" (click)="confirmCancelLineItem(true)">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Manual invoice existed alert -->
<custom-modal modalName="manualInvoiceExistAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="warning">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Invoice existed</div>
      <div class="alert-desc">One of the reservations have already been invoiced. Creating a new invoice will
        automatically generate a credit invoice. Are you sure to continue?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('manualInvoiceExistAlert');openCustomModal('manualInvoiceModal')">No</button>
      <button type="button" class="btn btn-green" (click)="createPdfForInvoiceManual()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Proceed to refund alert -->
<custom-modal modalName="proceedRefundAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Proceed to refund</div>
      <div class="alert-desc">Are you sure you want to process all pending refunds?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('proceedRefundAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="proceedToRefund()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Cancel changes alert -->
<custom-modal modalName="cancelChangesAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Cancel changes</div>
      <div class="alert-desc">Are you sure you want to cancel changes, all recent saved changes will be reverted?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('cancelChangesAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="cancelChange()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Save changes alert -->
<custom-modal modalName="saveChangesAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Save changes</div>
      <div class="alert-desc">Are you sure you want to save changes?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('saveChangesAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="saveReservationCheck()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Revert changes alert -->
<custom-modal modalName="revertChangesAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="question">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title">Revert changes</div>
      <div class="alert-desc">Are you sure you want to revert all the changes?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('revertChangesAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="revertReservation()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Default alert -->
<custom-modal modalName="defaultAlert" modalSize="alert-modal" modalOrder="1" [backdropClose]="false"
  alertType="warning">
  <ng-container *ngIf="reservation">
    <div class="custom-modal-body">
      <div class="alert-title"></div>
      <div class="alert-desc"></div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green"
        (click)="closeCustomModal('defaultAlert')">Cancel</button>
    </div>
  </ng-container>
</custom-modal>