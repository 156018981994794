import { CanActivateFn, Router } from '@angular/router';
import { GlobalUserSession } from './globalAuth';
import { jwtDecode } from 'jwt-decode';
import { TokenDecodedType } from '../@types/auth';
import { RolePermission } from './rolesPermission';
import { inject } from '@angular/core';
import { Auth2Service } from '../services/auth2.service';
import { cloneDeep } from 'lodash';

export const authenticateGuard: CanActivateFn = (route, state) => {
  const auth2Service = inject(Auth2Service);
  const router = inject(Router);
  const currentPath = route.url[0].path;
  if(["login", "forgot", "redirect-login"].includes(currentPath)) {
    return true;
  } else {
    let getToken = cloneDeep(route.queryParams['token'] ? route.queryParams['token'] : window.localStorage.getItem("jwt"));
    let getRefreshToken = cloneDeep(route.queryParams['refreshtoken'] ? route.queryParams['token'] : window.localStorage.getItem("jwt"));
    if(getToken) {
      localStorage.setItem("jwt", getToken);
      try {
        const decodeJwt = jwtDecode<TokenDecodedType>(getToken);
        const userRole = decodeJwt.roles;
        let pageType:string | null = null;
        let adminPageAccessList:string[] = [];
        let clientPageAccessList:string[] = [];
        RolePermission.adminPortalPageList.forEach(page => {
          if(page.requiredRoles.includes(userRole)) {
            adminPageAccessList.push(page.pageName);
          }
          if(currentPath === page.pageName) {
            pageType = "admin";
          }
        })
        RolePermission.clientPageList.forEach(page => {
          if(page.requiredRoles.includes(userRole)) {
            clientPageAccessList.push(page.pageName);
          }
          if(currentPath === page.pageName) {
            pageType = "client";
          }
        })

        let haveAccess = false;
        if(adminPageAccessList.includes(currentPath) || clientPageAccessList.includes(currentPath)) {
          haveAccess = true;
        } else if (pageType) {
          switch (pageType) {
            case "admin":
              router.navigateByUrl("/" + adminPageAccessList[0]);
              break;
            case "client":
              router.navigateByUrl(`/${clientPageAccessList[0]}?token=${getToken}&refreshtoken=${getRefreshToken}`);
              break;
          
            default:
              auth2Service.logOutToAdminLogin();
              break;
          }
        } else {
          router.navigateByUrl("/404")
        }
        return haveAccess;
      } catch (error) {
        return true; 
      }
    } else {
      return true;
    }
  }
};
