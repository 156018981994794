<app-header activePage="usage"></app-header>

<div class="page-header-container">
  <div class="page-header-inner">

    <div class="page-title-with-btn-container">
      <div class="page-twb-title">
        <div class="view-title">Usage</div>
      </div>
    </div>

    <div class="row" *ngIf="hotels">
      <div class="col-12 col-sm-6" *ngIf="hotels.length > 1">
        <label for="select-report">Search by hotel</label>
        <dropdown-menu
          [menuList]="hotelList"
          [selectedItem]="selectedHotelItem"
          [fullWidth]="true"
          (selectItem)="selectHotel($event)"
        ></dropdown-menu>
      </div>
      <div class="col-6 col-sm-3">
        <label>Start date</label>
        <dropdown-menu
          menuType="datepicker"
          [initialDate]="startDatePicker"
          [secondDate]="endDatePicker"
          alignment="adaptive"
          [allowEmptyDate]="false"
          (selectDate)="setDateFromDropdown($event, 'startDate')"
        ></dropdown-menu>
      </div>
      <div class="col-6 col-sm-3">
        <label>End date</label>
        <dropdown-menu
          menuType="datepicker"
          [initialDate]="endDatePicker"
          [secondDate]="startDatePicker"
          alignment="adaptive"
          [allowEmptyDate]="false"
          (selectDate)="setDateFromDropdown($event, 'endDate')"
        ></dropdown-menu>
      </div>
    </div>

    <div class="bottom-filter">
      <div class="bottom-filter-left">
        <button type="button" class="btn btn-outline-green" (click)="openEditChannel()" *ngIf="hasChannelRole"><fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit Channels</button>
        @if(hotelConfig.feature.eciLco) {
          <button type="button" class="btn btn-outline-green" (click)="openEditEciLco()" *ngIf="hasEciLcoRole"><fa-icon type="solid" icon="bell-concierge"></fa-icon>ECI/LCO</button>
        }
      </div>
      <div class="bottom-filter-right">
        <button type="button" class="btn btn-green" (click)="getUsageData(true)" [disabled]="disabledBtnWhileCallingAPI">
          <fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search usage
        </button>
      </div>
    </div>

  </div>
</div>

<div class="wrapper">
  <div class="wrapper-table-container">
    <div class="inner-table-container">
      <div class="container-fluid">
        <div class="row" style="padding-top: 15px;">
          <div class="col-12">
            <div class="legend-container">
              <div class="legend-list">
                <ng-container>
                  <div class="list-item">
                    <div class="item-color reservation"></div>
                    <div class="item-text">Reservation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color long-term"></div>
                    <div class="item-text">Long-term Reservation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color pending"></div>
                    <div class="item-text">Pending</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color manual-allocation"></div>
                    <div class="item-text">Manual allocation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color mismatch"></div>
                    <div class="item-text">Overloaded</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color maintenance"></div>
                    <div class="item-text">Maintenance</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color maintenance cleaning"></div>
                    <div class="item-text">To clean</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color allotment"></div>
                    <div class="item-text">Hard allotment</div>
                  </div>
                  <div class="list-item" *ngIf="hotelConfig.feature.eciLco">
                    <div class="item-color eci-lco"></div>
                    <div class="item-text">ECI/LCO</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <ng-template #filterTemplate>
              <!-- Reservation type -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Reservation type</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('reservationType', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('reservationType')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterReservationType" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('reservationType', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Room type -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Room type</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio">
                      <input type="checkbox" (click)="changeFilterAll('room', $event)" [checked]="checkFilterAll('room')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterRoomType" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('room', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{utils.showRoomType(filter.label)}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Capacity -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Capacity</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('capacity', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('capacity')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterCapacity" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('capacity', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Availability -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Availability</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('avail', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('avail')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterAvail" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('avail', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Payment -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Payment</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('payment', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('payment')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterPayment" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('payment', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Booking channel -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Channel</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio">
                      <input type="checkbox" (click)="changeFilterAll('channel', $event)" [checked]="checkFilterAll('channel')">
                      <div class="radio-box">
                        <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterBookingChannel" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('channel', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
            <button type="button" class="btn btn-outline-green height-40 high-zindex" [outsideClick]="true" [popover]="filterTemplate" placement="bottom left" container="body" containerClass="filterPopover" (onHidden)="getUsageWithFilter()">
              <fa-icon type="solid" icon="filter-list"></fa-icon>Filter rooms
            </button>
          </div>
          <div class="col-12 col-sm-6" style="text-align: right;padding-top: 10px;">
            <div class="normal-checkbox high-zindex" style="display: inline-flex">
              <input type="checkbox" (click)="toggleAllotment()" >
              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
              <div class="checkbox-label">Show allotment</div>
            </div>
          </div>
        </div>
        <!-- 
          Horizontal table scroll for future use
          <div class="usage-table" [ngClass]="{'fit-table': fitTable}" *ngIf="availabilityData"> 

          Horizontal table scroll/fit toggle for future use
          <button class="btn btn-outline-green usage-expand-btn" (click)="toggleTableSetting()">Fit table</span></button>    
        -->
        <div class="usage-table" *ngIf="!tableLoading">
          <div class="table-head-container" (scroll)="tableScroll('head')">
            <div class="table-head" [style]="{width: tableWidth}">
              <div class="head-row date-row">
                <div class="row-title trigger-hide-availability" style="height: 55px">
                  <button type="button" class="btn btn-outline-green usage-expand-btn" (click)="switchTriggerHideAvailability()">
                    <span *ngIf="!triggerHideAvailability">
                      <fa-icon type="solid" icon="chevron-down"></fa-icon>
                    </span>
                    <span *ngIf="triggerHideAvailability">
                      <fa-icon type="solid" icon="chevron-up"></fa-icon>
                    </span>
                    {{triggerHideAvailability ? 'Collapse' : 'Expand'}}</button>
                </div>
                <div class="row-item" *ngFor="let date of allDates">
                  <div class="item-date">{{date}}</div>
                </div>
                <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
              </div>
              <ng-container *ngIf="availabilityData">
                <div class="head-row" id="normal-row" *ngIf="triggerHideAvailability && hotelConfig.MUIfeature.OTAServices.includes('BOOKINGCOM')">
                  <div class="row-title">Booking.com</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.BOOKINGCOM"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability && hotelConfig.MUIfeature.OTAServices.includes('EXPEDIA')">
                  <div class="row-title">Expedia</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.EXPEDIA"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability">
                  <div class="row-title">Nelson</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.NELSON"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability">
                  <div class="row-title">Maintenance</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.maintenance.dates">{{channel.used}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" [ngClass]="{'allotment-border': allotmentRows}" *ngIf="triggerHideAvailability">
                  <div class="row-title">Reserved</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.reserved">{{channel.totalReservedRoom}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row border-bottom" *ngIf="!triggerHideAvailability">
                </div>
                <ng-container *ngIf="allotmentRows && allotmentData">
                  <ng-container *ngFor="let allotment of allotmentData">
                    <div class="head-row" *ngIf="allotment.allotmentType == 'SOFT'">
                      <div class="row-title" tooltip="Edit {{allotment.allotmentName}}" placement="top" container="body" routerLink="/allotment/{{allotment.allotmentId}}">{{allotment.allotmentName}}</div>
                      <div class="row-item" *ngFor="let num of allotment.hotelAllotmentDates">
                        <span *ngIf="num.availability">{{num.used}}/{{num.availability}}</span>
                      </div>
                      <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                    </div>
                  </ng-container>
                  <div class="head-row">
                    <div class="row-title">Hard Allotment</div>
                    <div class="row-item" *ngFor="let channel of availabilityData.channels.GROUPS">
                      {{channel.available}}/{{channel.available + channel.used}}</div>
                    <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="custom-usage-container" [style]="{pointerEvents: showUsageHover ? 'auto' : 'none'}" (scroll)="tableScroll('hover')">
            <div [style]="{width: tableWidth}">
              <div class="flex-container">
                <div class="container-offset"></div>
                <div class="usage-hover-container">
                  <div class="custom-usage-hover" [style]="{left: usageHoverLeft, top: usageHoverTop, opacity: showUsageHover ? 1 : 0}" (mouseenter)="hoverUsageDirect()" (mouseleave)="hoverUsageLeave()">
                    <div class="hover-content" *ngIf="selectedHoverUsage">
                      <div class="usage-title">
                        <span *ngIf="selectedHoverUsage.reservationCode">{{selectedHoverUsage.customerFirstName}} {{selectedHoverUsage.customerLastName}}</span>
                        <span *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">Hard allotment</span>
                        <span *ngIf="selectedHoverUsage.maintenanceCategory">{{getMaintenanceName(selectedHoverUsage.maintenanceCategory)}}</span>
                      </div>
                      <div class="usage-info">
                        <div class="info-content">
                          <span *ngIf="selectedHoverUsage.reservationCode">{{selectedHoverUsage.reservationCode}}</span>
                          <span *ngIf="selectedHoverUsage.maintenanceCategory">{{ selectedHoverUsage.maintenanceReason }}</span>
                          <span *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">{{ selectedHoverUsage.allotmentName }}</span>
                        </div>
                        <div class="info-payment unpaid" *ngIf="selectedHoverUsage.reservationCode && selectedHoverUsage.remainingAmount > 0 && hasReservationRole">
                          <button (click)="openReservationWithPayment(selectedHoverUsage.reservationUuid)"><fa-icon type="solid" icon="cart-circle-exclamation"></fa-icon>Unpaid</button>
                        </div>
                        <div class="info-payment paid" *ngIf="selectedHoverUsage.reservationCode && selectedHoverUsage.remainingAmount <= 0">
                          <button ><fa-icon type="solid" icon="cart-circle-check"></fa-icon>Paid</button>
                        </div>
                        <div class="info-wheelchair" *ngIf="selectedHoverUsage.requestedForDisabled">
                          <fa-icon type="solid" icon="wheelchair"></fa-icon>
                          <span>Wheelchair accessible</span>
                        </div>
                        @if(selectedHoverUsage.labelDisplay.length) {
                          <div class="usage-label" [style]="selectedHoverUsage.usageDisplay.labelLeftOffset">
                            @for(label of selectedHoverUsage.labelDisplay;track label) {
                              @if(label.id !== "UNKNOWN") {
                                <div class="info-wheelchair">
                                  <fa-icon [type]="label.iconType" [icon]="label.iconName"></fa-icon>
                                  <span>{{label.name}}</span>
                                </div>
                              } @else {
                                <div class="info-wheelchair">
                                  <fa-icon type="solid" icon="question"></fa-icon>
                                  <span>{{label.name}}</span>
                                </div>
                              }
                            }
                          </div>
                        }
                      </div>
                      <div class="usage-time">
                        <div class="time-container">
                          <div class="time-item">
                            <div class="item-date">{{getDate(convertDate(selectedHoverUsage.range.start), 'date')}}</div>
                            {{getDate(convertDate(selectedHoverUsage.range.start), 'time')}}
                          </div>
                          <div class="time-arrow">
                            <fa-icon type="solid" icon="arrow-right"></fa-icon>
                          </div>
                          <div class="time-item">
                            <div class="item-date">{{getDate(convertDate(selectedHoverUsage.range.end), 'date')}}</div>
                            {{getDate(convertDate(selectedHoverUsage.range.end), 'time')}}
                          </div>
                        </div>
                      </div>
                      <div class="usage-guest" *ngIf="selectedHoverUsage.reservationCode">
                        <div class="guest-title">Guests: {{selectedHoverUsage.numberOfGuests}}</div>
                        <ng-container *ngFor="let guest of selectedHoverUsage.guestNames">
                          <div class="guest-item" *ngIf="guest !== ' '">{{guest}}</div>
                        </ng-container>
                      </div>
                      <div class="usage-comment" *ngIf="selectedHoverUsage.comments !== null && selectedHoverUsage.comments.length">
                        <div class="comment-title">Comments<a *ngIf="selectedHoverUsage.reservationUuid && hasReservationRole" class="glyphicon glyphicon-new-window" routerLink="/reservations/{{selectedHoverUsage.reservationUuid}}"></a></div>
                        <ng-container *ngFor="let comment of selectedHoverUsage.comments">
                          <div class="comment-item"><b>{{comment.author.name}}:</b> {{comment.data.comment}}</div>
                        </ng-container>
                      </div>
                      <div class="usage-guest" *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">
                        <div class="guest-title">EXP: {{convertDate(selectedHoverUsage.allotmentExpiryDateTime)}}</div>
                      </div>
                    </div>
                    <div class="hover-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-body-container" *ngIf="usageData" (scroll)="tableScroll('body')">
            <div class="table-body" [style]="{width: tableWidth}">
              <div class="body-container">
                <div class="column-room">
                  @for(room of usageData; track room.roomId; let roomIndex = $index) {
                    <div class="room-item" [attr.roomleftindex]="roomIndex">
                      <div class="item-room-number" [outsideClick]="true"
                        *ngIf="hasRoomInfoRole"
                        [popover]="roomTemplate" [adaptivePosition]="false" placement="right" containerClass="room-popover"
                        container="body" (click)="getRoomInfo(room, roomIndex, room.roomId)">
                        <span>{{room.label}}</span>
                      </div>
                      <div class="item-room-number" *ngIf="!hasRoomInfoRole">
                        <span>{{room.label}}</span>
                      </div>
                      <div class="item-capacity" tooltip="{{utils.showRoomTitle(room.productName)}}" [adaptivePosition]="false" placement="right">
                        <fa-icon type="regular" icon="bed"></fa-icon>
                        {{room.capacity}}
                      </div>
                      <button class="item-mark-room" [outsideClick]="true" [popover]="cleanTemplate" [adaptivePosition]="true" placement="right" containerClass="clean-popover" container="body" (click)="getCleanEvent(room, roomIndex, room.roomId)">
                        <span class="success-color" *ngIf="room.clean">
                          <fa-icon type="solid" icon="circle-check"></fa-icon>
                        </span>
                        <span class="warning-color" *ngIf="!room.clean">
                          <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
                        </span>
                      </button>
                      <div class="item-wheelchair">
                        <span *ngIf="room.forDisabled">
                          <fa-icon type="solid" icon="wheelchair"></fa-icon>
                        </span>
                      </div>
                    </div>
                  }
                </div>
                <div class="usage-area-border" *ngIf="usageData">
                  <div class="usage-past" [style]="{width: '1px', left: pastWidth + '%'}"></div>
                  @for(date of allDates; track date) {
                    <div class="border-item"></div>
                  }
                  <div class="usage-item">
                    @for(room of usageData; track room.label; let roomIndex = $index) {
                      <div class="item-row" [attr.roomindex]="roomIndex" (dragenter)="usageDragenter($event, roomIndex)">
                        <div class="wrapper-square-item">
                          @for(date of allDates; track date; let shortcutIndex = $index) {
                            <div class="square-container" (mouseup)="mouseUpResizeShortcut(room, roomIndex, $event)" (mousedown)="mouseDownResizeShortcut(shortcutIndex, $event)" (mousemove)="mouseMoveResizeShortcut($event)">
                              @if(shortcutIndex + 1 < allDates.length) {
                                <div class="square-item" [style]="{left: percentLeftShortcut + '%'}">
                                  <div class="shortcut" (click)="selectedShortcut(roomIndex, shortcutIndex)" [style]="{minWidth: percentShortcutLength + '%'}">
                                    @if(shortcutRoomIndex === roomIndex && shortcutRoomPosIndex === shortcutIndex) {
                                      <div class="shortcut-action" [ngClass]="[shortcutTopPosition ? 'top' : 'bottom']">
                                        <button class="btn btn-red icon-only" (click)="removeAllShortcut()">
                                          <fa-icon type="solid" icon="xmark"></fa-icon>
                                        </button>
                                        <button class="btn btn-green icon-only" (click)="openShortcutModal()">
                                          <fa-icon type="solid" icon="check"></fa-icon>
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          }
                        </div>
                        @for(usage of room.usages; track usage.usageId; let usageIndex = $index) {
                          <div class="usage-row" [class]="usage.usageDisplay.usageColorClass"
                          [style]="usage.usageDisplay.usageLength" [ngClass]="{
                            'selected': selectedUsage == (usage.reservationUuid ? usage.reservationUuid : (usage.maintenanceReservationId ? usage.usageDisplay.usageColorClass + usage.maintenanceReservationId : usage.usageDisplay.usageColorClass + usage.allotmentId)),
                            'cleaning': usage.maintenanceCategory === 'TO_CLEAN'
                          }"
                          (click)="highlightUsage(roomIndex, usageIndex)"
                          (mouseenter)="hoverUsage(roomIndex, usageIndex)"
                          (mouseleave)="hoverUsageLeave()"
                          [draggable]="usage.usageDisplay.draggable"
                          (mousedown)="usage.usageDisplay.draggable"
                          (dragstart)="usageDragstart(roomIndex, usageIndex, $event)"
                          (dragend)="usageDrop($event)" [attr.usageid]="usage.usageId">
                            <div class="usage-eci" [style]="usage.usageDisplay.eciLength"></div>
                            <div class="usage-lco" [style]="usage.usageDisplay.lcoLength"></div>
          
                            <a class="glyphicon" *ngIf="usage.reservationUuid && hasReservationRole" routerLink="/reservations/{{usage.reservationUuid}}">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </a>
                            <span class="glyphicon" *ngIf="usage.reservationUuid && !hasReservationRole">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </span>
          
                            <a *ngIf="usage.maintenanceReservationId && hasMaintenanceRole" class="glyphicon" routerLink="/maintenance/{{usage.maintenanceReservationId}}">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </a>
                            <span *ngIf="usage.maintenanceReservationId && !hasMaintenanceRole" class="glyphicon">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </span>
          
                            <a *ngIf="usage.allotmentId && usage.maintenanceReservationId === null && usage.roomRequestId === null && hasAllotRole" class="glyphicon" routerLink="/allotment/{{usage.allotmentId}}">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </a>
                            <span *ngIf="usage.allotmentId && usage.maintenanceReservationId === null && usage.roomRequestId === null && !hasAllotRole" class="glyphicon">
                              <fa-icon type="solid" icon="link"></fa-icon>
                            </span>

                            @if(usage.usageDisplay.usageLength.rawWidth > 6) {
                              @if(usage.labelDisplay.length) {
                                <div class="usage-label" [style]="usage.usageDisplay.labelLeftOffset">
                                  @for(label of usage.labelDisplay;track label) {
                                    @if(label.id !== "UNKNOWN") {
                                      <div class="label-item">
                                        <fa-icon [type]="label.iconType" [icon]="label.iconName"></fa-icon>
                                      </div>
                                    }
                                  }
                                </div>
                              }
                            }
          
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="center-item" *ngIf="tableLoading">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
</div>
<app-footer></app-footer>

<!-- Room info popover -->
<ng-template #roomTemplate>
  @if(viewRoomInfo) {
    <div class="pop-header">
      <div class="inline-responsive">
        <div class="header-title">Room Info Management</div>
      </div>
      <div class="inline-responsive" *ngIf="!roomInfoStatus.loading">
        <div class="header-label" *ngIf="hotels.length > 1">{{utils.hotelDisplayByLabel(hotelConfig, hotels, roomInfo.hotelLabel)}}</div>
        <div class="header-label">{{roomInfo.label}}</div>
        <div class="header-label">{{ utils.showRoomType(roomInfo.roomType) }}</div>
        <div class="header-label" *ngIf="roomInfo.building">{{ roomInfo.building }}</div>
      </div>
    </div>
    <div class="center-item" *ngIf="roomInfoStatus.loading">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
    <div class="pop-body" *ngIf="!roomInfoStatus.loading">
      <div class="body-desc-container">
        <div class="row row-no-gutters">
          <div class="col-7 col-sm-4">
            <div class="desc-title">Room description:</div>
            <div class="desc-text">{{roomInfo.specificInfo}}</div>
          </div>
          <div class="col-5 col-sm-5" style="text-align: right;">
            <button class="btn btn-none-outline-green edit-btn" [ngClass]="{'hide':roomInfoStatus.roomDescription}"
              (click)="editDescription(roomInfo.specificInfo)"><fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit</button>
            <div [ngClass]="{'hide':!roomInfoStatus.roomDescription}">
              <button class="btn btn-green icon-only" [disabled]="!roomInfoStatus.descriptionInput"
                (click)="saveDescription(viewRoomInfo.room.roomId)"><fa-icon type="solid" icon="check"></fa-icon></button>
              <button class="btn btn-green icon-only" (click)="cancelDescription()"><fa-icon type="solid" icon="xmark"></fa-icon></button>
            </div>
          </div>
          <div class="col-12" *ngIf="roomInfoStatus.roomDescription">
            <input type="text" class="form-control custom-input"
              [(ngModel)]="roomInfoStatus.descriptionInput"
              (input)="checkDescription(roomInfo.specificInfo)">
          </div>
        </div>
      </div>
      <div class="body-capacity-container">
        <div class="row row-no-gutters">
          <div class="col-6 col-sm-4">
            <div class="desc-title"><b>Capacity:</b> {{roomInfo.capacity}}</div>
          </div>
          <div class="col-6 col-sm-5" *ngIf="roomInfo.productName != 'Omena Single'" style="text-align: right;">
            <button class="btn btn-none-outline-green edit-btn" [ngClass]="{'hide':roomInfoStatus.roomCapacity}"
              (click)="editCapacity(roomInfo.capacity)"><fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit</button>
            <div [ngClass]="{'hide':!roomInfoStatus.roomCapacity}">
              <div class="capacity-dropdown">
                <dropdown-menu
                  [menuList]="capacityList"
                  [selectedItem]="selectedCapacity"
                  [fullWidth]="true"
                  (selectItem)="selectCapacity($event)"
                  [preventPropagation]="true"
                ></dropdown-menu>
              </div>
              <button type="button" class="btn btn-green icon-only" (click)="saveCapacity(viewRoomInfo.room.roomId, viewRoomInfo.roomIndex)"><fa-icon type="solid" icon="check"></fa-icon></button>
              <button class="btn btn-green icon-only" (click)="cancelCapacity()"><fa-icon type="solid" icon="xmark"></fa-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div class="body-allocation-container">
        <div class="allocation-item">
          <div class="alloc-title"><b>Room allocation:</b></div>
        </div>
        <div class="allocation-item">
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="roomInfo.isDePrioritized" (change)="toggleDePrioritizeRoom(roomInfo.roomId, $event)" >
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
            <div class="checkbox-label alloc-title"><b>De-prioritize this room</b></div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="roomInfo.historicalLogs.length > 0">
        <div class="text-button">History</div>
        <div class="custom-table history-table">
          <table class="custom-table">
            <colgroup>
              <col style="width: 140px">
              <col>
              <col style="width: 300px">
            </colgroup>
            <thead>
              <th>Time</th>
              <th>Author</th>
              <th>Description</th>
            </thead>
            <tbody>
              <tr *ngFor="let log of roomInfo.historicalLogs">
                <td>{{convertDate(log.created)}}</td>
                <td>{{log.author}}</td>
                <td>{{getLogDescription(log.operation, log.oldValues, log.newValues)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  }
</ng-template>

<!-- Clean status popup -->
<ng-template #cleanTemplate>
  @if(viewRoomClean) {
    <div class="btn-status-container">
      <div class="row no-gutters">
        <div class="col-6">
          <button class="btn-status btn-dirty" [ngClass]="{'active': !viewRoomClean.room.clean}" (click)="markCleanStatus(false, viewRoomClean.room.roomId, viewRoomClean.roomIndex, false)">
            <fa-icon type="solid" icon="circle-exclamation"></fa-icon>Dirty
          </button>
        </div>
        <div class="col-6">
          <button class="btn-status btn-clean" [ngClass]="{'active': viewRoomClean.room.clean}" (click)="markCleanStatus(true, viewRoomClean.room.roomId, viewRoomClean.roomIndex, false)">
            <fa-icon type="solid" icon="circle-check"></fa-icon>Clean
          </button>
        </div>
        <div class="col-12">
          <button class="btn btn-outline-green full-width wrap-text" (click)="createMaintenance(viewRoomClean.roomIndex)"><fa-icon type="solid" icon="plus"></fa-icon>New maintenance</button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="cleanEventData.length > 0">
      <button class="text-button" (click)="cleanEvent.show = !cleanEvent.show">Cleaning history</button>
      <div class="custom-table clean-table" *ngIf="cleanEvent.show">
        <table>
          <thead>
            <th class="col-sm-3 col-md-3">Time</th>
            <th class="col-sm-3 col-md-3">User</th>
            <th class="col-sm-3 col-md-3">Status</th>
            <th class="col-sm-3 col-md-3">Source</th>
          </thead>
          <tbody>
            <tr *ngFor="let event of cleanEventData">
              <td class="col-sm-3 col-md-3">{{convertDate(event.created)}}</td>
              <td class="col-sm-3 col-md-3" tooltip="{{event.sourceReference}}" placement="right">{{ truncateEmail(event.sourceReference) }}</td>
              <td class="col-sm-3 col-md-3">{{event.status | titlecase}}</td>
              <td class="col-sm-3 col-md-3">{{event.source | cleanSource}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-pagination" *ngIf="cleanEvent.totalItem > 2 && cleanEvent.show">
        <span [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('first', viewRoomClean.room.roomId)">First</span>
        <span [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('prev', viewRoomClean.room.roomId)">Prev</span>
        <span *ngIf="cleanEvent.hasDotLeft">...</span>
        <span *ngFor="let page of cleanEvent.pageArray" (click)="changePage(page, viewRoomClean.room.roomId)"
          [ngClass]="{'active':cleanEvent.current == page}">{{page}}</span>
        <span *ngIf="cleanEvent.hasDot">...</span>
        <span [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('next', viewRoomClean.room.roomId)">Next</span>
        <span [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('last', viewRoomClean.room.roomId)">Last</span>
      </div>
    </ng-container>
  }
</ng-template>

<!-----------CUSTOM MODALS----------->

<!-- Edit channels modal -->
<custom-modal
  modalName="editChannelsModal"
  modalTitle="Edit channels"
  modalSize="xl"
  modalOrder="0"
  (modalEvent)="handleModalEvent($event)"
>
  <ng-container *ngIf="hotels && modalStartDate && modalEndDate && openedModal === 'channel'">
    <div class="custom-modal-body">
      <div class="row">
        <div class="col-6 col-sm-3" *ngIf="hotels.length > 1">
          <label for="select-report">Search by hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotelItem"
            [fullWidth]="true"
            (selectItem)="selectHotel($event, true)"
          ></dropdown-menu>
        </div>
        <div class="col-6 col-sm-3">
          <label for="select-report">Room type</label>
          <dropdown-menu
            [menuList]="roomTypeList"
            [selectedItem]="selectedRoomType"
            [fullWidth]="true"
            (selectItem)="selectRoomType($event)"
          ></dropdown-menu>
        </div>
        <div class="col-6 col-sm-3">
          <label>Start date</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="modalStartDate"
            [secondDate]="modalEndDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="modalDateChange($event, 'startDate', 'channel')"
          ></dropdown-menu>
        </div>
        <div class="col-6 col-sm-3">
          <label>End date</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="modalEndDate"
            [secondDate]="modalStartDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="modalDateChange($event, 'endDate', 'channel')"
          ></dropdown-menu>
        </div>
      </div>

      <div class="edit-channel-loading" *ngIf="modalLoading">
        <div class="center-item">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
      </div>

      <ng-container *ngIf="!modalLoading && modalData">
        <div class="scrolling-table" id="table1" (scroll)="modalTableScroll('table1')" style="margin-top: 20px;">
          <table>
            <thead>
              <th class="title">Summary</th>
              <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
                <div style="width: 70px">{{day.fullDate}}</div>
              </th>
            </thead>
            <tbody>
              <tr>
                <td class="title"><span tooltip="= total (this hotel) - maintenance - reserved" placement="top">Available</span></td>
                <td *ngFor="let reserved of modalData.reserved" [ngClass]="{'weekend': checkWeekend(reserved.date)}">
                  {{reserved.totalAvailableRoom}}</td>
              </tr>
              <tr>
                <td class="title"><span tooltip="= used (nelson) + used (booking.com) + used (expedia) + used (legacy)" placement="top">Reserved</span></td>
                <td *ngFor="let reserved of modalData.reserved" [ngClass]="{'weekend': checkWeekend(reserved.date)}">
                  {{reserved.totalReservedRoom}}</td>
              </tr>
              <tr>
                <td class="title"><span tooltip="The number of rooms that under maintenance" placement="top">Maintenance</span></td>
                <td *ngFor="let maintenance of modalData.maintenance.dates"
                  [ngClass]="{'weekend': checkWeekend(maintenance.date)}">{{maintenance.used}}</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="mui-legend-container" style="margin-bottom: 10px">
          <div class="legend-list">
            <div class="list-item">
              <div class="item-color sold-out"></div>
              <div class="item-text">Sold out</div>
            </div>
            <div class="list-item">
              <div class="item-color modified"></div>
              <div class="item-text">Modified by system</div>
            </div>
          </div>
        </div>
  
        <div class="scrolling-table" id="table2" (scroll)="modalTableScroll('table2')">
          <table>
            <thead>
              <th class="title">
                <div style="width: 120px">External</div>
              </th>
              <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
                <div style="width: 70px">{{day.fullDate}}</div>
              </th>
            </thead>
            <tbody>
              <tr>
                <td class="title"><span tooltip="The total rooms to sell that the user has set" placement="top">Desired</span></td>
                <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index" [ngClass]="{'weekend': checkWeekend(buffer.date)}">
                  <div class="custom-input-number">
                    <input type="text" class="form-control"
                    [(ngModel)]="buffer.buffer" (change)="channelEdit($event, bufferIndex, buffer.date)">
                  </div>
                </td>
              </tr>
              <tr>
                <td class="title"><span tooltip="The total rooms that is currently possible to sell. Updated by the system." placement="top">Actual</span></td>
                <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index" [ngClass]="{'weekend': checkWeekend(buffer.date)}" style="padding: 0">
                  <div class="nprice-value" [ngClass]="{'sold-out': checkExternalSoldOut(buffer.currentBuffer, bufferIndex), 'modified-by-system': buffer.modified}">
                    <div class="value-content">
                      {{buffer.currentBuffer}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="hotelConfig.MUIfeature.OTAServices.includes('BOOKINGCOM')">
                <td class="title"><span tooltip="Total rooms sold on Booking.com" placement="top">Booking.com</span></td>
                <td *ngFor="let item of modalData.channels.BOOKINGCOM" [ngClass]="{'weekend': checkWeekend(item.date)}">
                  {{item.used}}</td>
              </tr>
              <tr *ngIf="hotelConfig.MUIfeature.OTAServices.includes('EXPEDIA')">
                <td class="title"><span tooltip="The number of rooms that have been sold on Expedia" placement="top">Expedia</span></td>
                <td *ngFor="let item of modalData.channels.EXPEDIA" [ngClass]="{'weekend': checkWeekend(item.date)}">
                  {{item.used}}</td>
              </tr>
              <tr>
                <td class="title"><span tooltip="= total - used (Booking.com) - used (expedia)" placement="top">Available</span></td>
                <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                  [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('externalAvailable', buffer.currentBuffer, bufferIndex) < 0}"
                  [attr.id]="'ex-'+buffer.date">{{calculateTable('externalAvailable', buffer.currentBuffer, bufferIndex)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="inline-edit-container" style="margin-bottom: 20px">
          <div class="inline-text">External channel threshold:</div>
          <div class="inline-text" *ngIf="!editExternalThreshold">{{modalData.thresholdMap[modalSelectedType]}}</div>
          <div class="action-button" *ngIf="!editExternalThreshold">
            <button class="btn btn-none-outline-green" (click)="toggleEditExternalThreshold()">
              <fa-icon type="solid" icon="pen-to-square"></fa-icon>Edit
            </button>
          </div>
          <div class="edit-field" *ngIf="editExternalThreshold">
            <div class="action-button">
              <div class="input-field">
                <input class="form-control" [ngClass]="{'error': editETError}" [(ngModel)]="editedExternalThreshold" (ngModelChange)="validateET()">
              </div>
              <div>
                <button class="btn btn-green icon-only" [disabled]="editETError" (click)="saveExternalThreshold()">
                  <fa-icon type="solid" icon="check"></fa-icon>
                </button>
              </div>
              <div>
                <button class="btn btn-outline-green icon-only" (click)="toggleEditExternalThreshold()">
                  <fa-icon type="solid" icon="xmark"></fa-icon>
                </button>
              </div>
            </div>
            <div class="error-text">{{editETError}}</div>
          </div>
        </div>
  
        <div class="scrolling-table" id="table3" (scroll)="modalTableScroll('table3')">
          <table>
            <thead>
              <th class="title">
                <div style="width: 120px">Direct</div>
              </th>
              <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
                <div style="width: 70px">{{day.fullDate}}</div>
              </th>
            </thead>
            <tbody>
              <tr>
                <td class="title"><span tooltip="The number of rooms that have been sold by this channel" placement="top">Reserved</span></td>
                <td *ngFor="let item of modalData.channels.NELSON" [ngClass]="{'weekend': checkWeekend(item.date)}">
                  {{item.used}}</td>
              </tr>
              <tr>
                <td class="title"><span tooltip="= total - used" placement="top">Available</span></td>
                <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                  [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('nelsonAvailable', buffer.currentBuffer, bufferIndex) < 0}"
                  [attr.id]="'ex-'+buffer.date">{{calculateTable('nelsonAvailable', buffer.currentBuffer, bufferIndex)}}</td>
              </tr>
              <tr>
                <td class="title"><span tooltip="= available (this hotel) - total (external) - maintenance" placement="top">Total</span></td>
                <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                  [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('nelsonTotal', buffer.currentBuffer, bufferIndex) < 0}"
                  [attr.id]="'ex-'+buffer.date">{{calculateTable('nelsonTotal', buffer.currentBuffer, bufferIndex)}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-7 col-sm-2" style="margin-bottom: 15px">
            <label style="margin:0">Set availability for multiple days</label>
          </div>
          <div class="col-5 col-sm-2" style="margin-bottom: 15px">
            <div class="custom-input-number">
              <input type="number" class="form-input custom-input" [(ngModel)]="modalSetBuffer">
            </div>
          </div>
          <div class="col-6 col-sm-3" style="margin-bottom: 15px">
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="modalSetStartDate"
              [secondDate]="modalSetEndDate"
              alignment="adaptive"
              [allowEmptyDate]="false"
              (selectDate)="modalDateChange($event, 'setStartDate', 'channel')"
            ></dropdown-menu>
          </div>
          <div class="col-6 col-sm-3" style="margin-bottom: 15px">
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="modalSetEndDate"
              [secondDate]="modalSetStartDate"
              alignment="adaptive"
              [allowEmptyDate]="false"
              (selectDate)="modalDateChange($event, 'setEndDate', 'channel')"
            ></dropdown-menu>
          </div>
          <div class="col-12 col-sm-2">
            <button class="btn btn-green btn-big full-width" (click)="setMultipleBuffer()">Apply</button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('editChannelsModal')">Close</button>
      <ng-container *ngIf="!modalLoading">
        <button type="submit" class="btn btn-outline-green" style="margin-right: 10px;" [disabled]="disabledBtnWhileCallingAPI" (click)="saveBufferChange(true)">Save and close</button>
        <button type="submit" class="btn btn-green" [disabled]="disabledBtnWhileCallingAPI" (click)="saveBufferChange()">Save</button>
      </ng-container>
    </div>
  </ng-container>
</custom-modal>

<!-- ECI/LCO modal -->
<custom-modal
  modalName="eciLcoModal"
  modalTitle="Early check-in/Late check-out"
  modalSize="xl"
  modalOrder="0"
  (modalEvent)="handleModalEvent($event)"
>
  <ng-container *ngIf="hotels && modalStartDate && modalEndDate && openedModal === 'eci'">
    <div class="custom-modal-body">

      <div class="row">
        <div class="col-12 col-sm-4" *ngIf="hotels.length > 1">
          <label for="select-report">Search by hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotelItem"
            [fullWidth]="true"
            (selectItem)="selectHotel($event, true)"
          ></dropdown-menu>
        </div>
        <div class="col-6 col-sm-4">
          <label>Start date</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="modalStartDate"
            [secondDate]="modalEndDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="modalDateChange($event, 'startDate', 'eciLco')"
          ></dropdown-menu>
        </div>
        <div class="col-6 col-sm-4">
          <label>End date</label>
          <dropdown-menu
            menuType="datepicker"
            [initialDate]="modalEndDate"
            [secondDate]="modalStartDate"
            alignment="adaptive"
            [allowEmptyDate]="false"
            (selectDate)="modalDateChange($event, 'endDate', 'eciLco')"
          ></dropdown-menu>
        </div>
      </div>
      <div class="container-header-title" style="margin-top: 15px; margin-bottom: 5px">{{selectedHotelInfo.name}}</div>

      <div class="edit-channel-loading" *ngIf="modalLoading">
        <div class="center-item">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
      </div>

      <ng-container *ngIf="!modalLoading && modalData">
        <div class="hotel-desc" style="margin-bottom: 20px">Additional room for early check-in:
          {{modalData.additionalEci.amount}} ({{modalData.additionalEci.date | date:'dd.MM'}})</div>
  
        <div class="table-container">
          <div class="table-left">
            <div class="table-item date-title">ECI/LCO</div>
            <div class="table-item">Guarantee</div>
          </div>
          <div class="scrolling-table eci-table" id="table1" (scroll)="modalTableScroll('table1')">
            <table>
              <thead>
                <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
                  <div>{{day.fullDate}}</div>
                </th>
              </thead>
              <tbody>
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  [attr.id]="'ecilco-'+info.date">
                  <div>
                    {{convertToNumber(info.eciInfos.pm200.guaranteedBuffer) +
                    info.lcoInfos.pm200.guaranteedBuffer}}/{{modalData.commonBuffer}}
                  </div>
                </td>
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="table-container">
          <div class="table-left">
            <div class="eci-flex">
              <div class="eci">
                14:00 ECI
              </div>
              <div class="right-half">
                <div class="table-item">Guarantee</div>
                <div class="table-item">Used</div>
                <div class="table-item">Available</div>
                <div class="table-item">Open</div>
              </div>
            </div>
          </div>
          <div class="scrolling-table eci-table" id="table2" (scroll)="modalTableScroll('table2')">
            <table>
              <tbody>
                <tr>
                  <td *ngFor="let info of modalData.infos; let infoIndex = index"
                    [ngClass]="{'weekend': checkWeekend(info.date)}" [attr.id]="'eci-'+info.date">
                    <div *ngIf="!hasEciLcoEditRole">{{info.eciInfos.pm200.guaranteedBuffer}}</div>
                    <div class="custom-input-number" *ngIf="hasEciLcoEditRole">
                      <input type="text" class="form-control" [(ngModel)]="info.eciInfos.pm200.guaranteedBuffer"
                        (change)="eciEdit($event, infoIndex, info.date)">
                    </div>
                    
                  </td>
                </tr>
                <tr>
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}">
                    <div>{{info.eciInfos.pm200.used}}</div>
                  </td>
                </tr>
                <tr>
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    [attr.id]="'eci-left-'+info.date">
                    <div>{{getMaxNumber(info.eciInfos)}}</div>
                  </td>
                </tr>
                <tr *ngIf="!hasEciLcoEditRole">
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    [attr.id]="'eci-enable-'+info.date">
                    <div *ngIf="info.eciInfos.pm200.enabled"><span class="success-color"><fa-icon type="solid" icon="check"></fa-icon></span></div>
                    <div *ngIf="!info.eciInfos.pm200.enabled"><span class="error-color"><fa-icon type="solid" icon="xmark"></fa-icon></span></div>
                  </td>
                </tr>
                <tr class="clickable" *ngIf="hasEciLcoEditRole">
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    (click)="info.eciInfos.pm200.enabled = !info.eciInfos.pm200.enabled"
                    [attr.id]="'eci-enable-'+info.date">
                    <div *ngIf="info.eciInfos.pm200.enabled"><span class="success-color"><fa-icon type="solid" icon="check"></fa-icon></span></div>
                    <div *ngIf="!info.eciInfos.pm200.enabled"><span class="error-color"><fa-icon type="solid" icon="xmark"></fa-icon></span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="table-container">
          <div class="table-left">
            <div class="eci-flex">
              <div class="eci lco">
                14:00 LCO
              </div>
              <div class="right-half">
                <div class="table-item">Guarantee</div>
                <div class="table-item">Used</div>
                <div class="table-item">Available</div>
                <div class="table-item">Open</div>
              </div>
            </div>
          </div>
          <div class="scrolling-table eci-table" id="table3" (scroll)="modalTableScroll('table3')">
            <table>
              <tbody>
                <tr>
                  <td *ngFor="let info of modalData.infos; let infoIndex = index"
                    [ngClass]="{'weekend': checkWeekend(info.date)}" [attr.id]="'lco-'+info.date">
                    <div *ngIf="!hasEciLcoEditRole">{{info.lcoInfos.pm200.guaranteedBuffer}}</div>
                    <div class="custom-input-number" *ngIf="hasEciLcoEditRole">
                      <input type="text" class="form-control" [(ngModel)]="info.lcoInfos.pm200.guaranteedBuffer"
                        (change)="lcoEdit($event, infoIndex, info.date)">
                    </div>
                    
                  </td>
                </tr>
                <tr>
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}">
                    <div>{{info.lcoInfos.pm200.used}}</div>
                  </td>
                </tr>
                <tr>
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    [attr.id]="'lco-left-'+info.date">
                    <div>{{getMaxNumber(info.lcoInfos)}}</div>
                  </td>
                </tr>
                <tr *ngIf="!hasEciLcoEditRole">
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    [attr.id]="'lco-enable-'+info.date">
                    <div *ngIf="info.lcoInfos.pm200.enabled"><span class="success-color"><fa-icon type="solid" icon="check"></fa-icon></span></div>
                    <div *ngIf="!info.lcoInfos.pm200.enabled"><span class="error-color"><fa-icon type="solid" icon="xmark"></fa-icon></span></div>
                  </td>
                </tr>
                <tr class="clickable" *ngIf="hasEciLcoEditRole">
                  <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                    (click)="info.lcoInfos.pm200.enabled = !info.lcoInfos.pm200.enabled"
                    [attr.id]="'lco-enable-'+info.date">
                    <div *ngIf="info.lcoInfos.pm200.enabled"><span class="success-color"><fa-icon type="solid" icon="check"></fa-icon></span></div>
                    <div *ngIf="!info.lcoInfos.pm200.enabled"><span class="error-color"><fa-icon type="solid" icon="xmark"></fa-icon></span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <ng-container *ngIf="hasEciLcoEditRole">
          <label>Action on multiple days</label>
          <div class="row">
            <div class="col-6 col-sm-2" style="margin-bottom: 15px;">
              <div class="normal-checkbox radio-style">
                <input type="radio" name="type" [checked]="eciAction.type == 'eci'" (click)="eciAction.type = 'eci'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">ECI</div>
              </div>
              <div class="normal-checkbox radio-style">
                <input type="radio" name="type" [checked]="eciAction.type == 'lco'" (click)="eciAction.type = 'lco'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">LCO</div>
              </div>
            </div>
            <div class="col-6 col-sm-3 col-md-2" style="margin-bottom: 15px;">
              <div class="normal-checkbox radio-style">
                <input type="radio" name="set" [checked]="eciAction.set == 'value'" (click)="eciAction.set = 'value'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">Set to value</div>
              </div>
              <div class="custom-input-number"  style="margin-bottom: 10px">
                <input type="number" class="form-input custom-input"
                [(ngModel)]="eciAction.setValue">
              </div>
          
              <div class="normal-checkbox radio-style">
                <input type="radio" name="set" [checked]="eciAction.set == 'close'" (click)="eciAction.set = 'close'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">Set to close</div>
              </div>
              <div class="normal-checkbox radio-style">
                <input type="radio" name="set" [checked]="eciAction.set == 'open'" (click)="eciAction.set = 'open'">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">Set to open</div>
              </div>
            </div>
            <div class="col-6 col-sm-3" style="margin-bottom: 15px;">
              <label>Start date</label>
              <dropdown-menu
                menuType="datepicker"
                [initialDate]="modalSetStartDate"
                [secondDate]="modalSetEndDate"
                alignment="adaptive"
                [allowEmptyDate]="false"
                (selectDate)="modalDateChange($event, 'setStartDate', 'eciLco')"
              ></dropdown-menu>
              <label style="margin-top: 20px">End date</label>
              <dropdown-menu
                menuType="datepicker"
                [initialDate]="modalSetEndDate"
                [secondDate]="modalSetStartDate"
                alignment="adaptive"
                [allowEmptyDate]="false"
                (selectDate)="modalDateChange($event, 'setEndDate', 'eciLco')"
              ></dropdown-menu>
            </div>
            <div class="col-6 col-sm-2" style="margin-bottom: 15px;">
              <label>Weekdays</label>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Mon" (click)="toggleEciWeekday('Mon')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">MON</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Tue" (click)="toggleEciWeekday('Tue')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">TUE</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Wed" (click)="toggleEciWeekday('Wed')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">WED</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Thu" (click)="toggleEciWeekday('Thu')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">THU</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Fri" (click)="toggleEciWeekday('Fri')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">FRI</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Sat" (click)="toggleEciWeekday('Sat')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">SAT</div>
              </div>
              <div class="normal-checkbox modal-fix">
                <input type="checkbox" [checked]="eciAction.weekdays.Sun" (click)="toggleEciWeekday('Sun')">
                <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                <div class="checkbox-label">SUN</div>
              </div>
            </div>
            <div class="col-12 col-sm-2" style="margin-bottom: 15px;">
              <button class="btn btn-green btn-big full-width" (click)="applyChange()">Apply</button>
            </div>
          </div>
        </ng-container>
  
      </ng-container>
      
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('eciLcoModal')">Close</button>
      <ng-container *ngIf="!modalLoading && hasEciLcoEditRole">
        <button type="submit" class="btn btn-outline-green" (click)="saveEciChange(true)" [disabled]="disabledBtnWhileCallingAPI">Save and close</button>
        <button type="submit" class="btn btn-green" (click)="saveEciChange()" [disabled]="disabledBtnWhileCallingAPI">Save</button>
      </ng-container>
    </div>
  </ng-container>
</custom-modal>

<!-- Default modal -->
<custom-modal
  modalName="defaultModal"
  modalTitle="Default"
  modalSize=""
  modalOrder="0"
>
  <ng-container>
    <div class="custom-modal-body">
      
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('defaultModal')">Cancel</button>
    </div>
  </ng-container>
</custom-modal>

<!-----------CUSTOM ALERTS----------->
<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
>
<ng-container>
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
  </div>
</ng-container>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">{{loadingElements.title}}</div>
      <div class="alert-desc" *ngIf="loadingElements.desc">{{loadingElements.desc}}</div>
    </div>
  </ng-container>
</custom-modal>

<!-- Dirty change room alert -->
<custom-modal
  modalName="dirtyChangeRoomAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">Move to dirty room</div>
      <div class="alert-desc">Room <b>{{dirtyRoomList}}</b> is dirty, are you sure you want to move to this room?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('dirtyChangeRoomAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="startMoveRoom('change')">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Dirty swap room alert -->
<custom-modal
  modalName="dirtySwapRoomAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">Swap to dirty room</div>
      <div class="alert-desc">Room <b>{{dirtyRoomList}}</b> are dirty, are you sure you want to swap the rooms?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('dirtySwapRoomAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="startMoveRoom('swap')">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Swap room confirm alert -->
<custom-modal
  modalName="swapRoomAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">Swap room</div>
      <div class="alert-desc" *ngIf="!isNotifySwapRoomAcrossRoomTypes">
        Are you sure you want to change room <b>{{swapRoomAlert.roomFrom}}</b> to room <b>{{swapRoomAlert.roomTo}}</b> (and room <b>{{swapRoomAlert.roomTo}}</b> to room <b>{{swapRoomAlert.roomFrom}}</b>)?
      </div>
      <div class="alert-desc" *ngIf="isNotifySwapRoomAcrossRoomTypes">
        The room price will be changed after swapping <br/>
        <b>{{swapRoomAlert.roomFrom}}</b> to room <b>{{swapRoomAlert.roomTo}}</b> (and room <b>{{swapRoomAlert.roomTo}}</b> to room <b>{{swapRoomAlert.roomFrom}}</b>):
        <br/>
        <b *ngIf="swapRoomAlert.balanceRoomFrom != 0.00">- Room {{swapRoomAlert.roomFrom}} will be {{ swapRoomAlert.balanceRoomFrom > 0 ? 'increased' : 'decreased'}} {{swapRoomAlert.balanceRoomFrom}} {{utils.getCurrency(currency)}}</b> <br/>
        <b *ngIf="swapRoomAlert.balanceRoomTo != 0.00">- Room {{swapRoomAlert.roomTo}} will be {{ swapRoomAlert.balanceRoomTo > 0 ? 'increased' : 'decreased'}} {{swapRoomAlert.balanceRoomTo}} {{utils.getCurrency(currency)}}</b>
      </div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('swapRoomAlert')">Cancel</button>
      <button *ngIf="isNotifySwapRoomAcrossRoomTypes" type="button" class="btn btn-green" (click)="swapCheckDirty(true)">Override</button>
      <button *ngIf="isNotifySwapRoomAcrossRoomTypes" type="button" class="btn btn-green" (click)="swapCheckDirty(false)">Save</button>
      <button *ngIf="!isNotifySwapRoomAcrossRoomTypes"type="button" class="btn btn-green" (click)="swapCheckDirty()">Save</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Room already clean alert -->
<custom-modal
  modalName="roomAlreadyCleanAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <ng-container>
    <div class="custom-modal-body">
      <div class="alert-title">Change room usage status</div>
      <div class="alert-desc">Room already marked {{ capitalizeFirstLetter(currentRoomUsageStatus) }}. Do you want to mark it {{ capitalizeFirstLetter(currentRoomUsageStatus) }} again?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('roomAlreadyCleanAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="changeRoomUsageStatus()">Yes</button>
    </div>
  </ng-container>
</custom-modal>

<!-- Change room alert -->
<custom-modal
  modalName="changeRoomAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <div class="custom-modal-body">
    <div class="alert-title">Change room</div>
    <div class="alert-desc">New room rate total <b>{{ changeRoomAlert.balanceRoom }} {{utils.getCurrency(currency)}}</b>.<br/>Are you sure you want to change room?</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('changeRoomAlert')">Cancel</button>
    <button type="button" class="btn btn-outline-green" (click)="actionChangeRoomAcrossRoomTypes(true)">Override</button>
    <button type="button" class="btn btn-green" (click)="actionChangeRoomAcrossRoomTypes(false)">Accept</button>
  </div>
</custom-modal>

<!-- Failed re-allocation alert -->
<custom-modal
  modalName="failedReAllocationAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <div class="custom-modal-body">
    <div class="alert-title">Room re-allocation failed</div>
    <div class="alert-desc">
      <p *ngFor="let failedData of failedReAllocationData">Re-allocation failed for booking {{failedData.reservationCode}} (this is reservation code) on {{getDateRange(failedData)}}. Please re-allocate this booking manually.</p>
    </div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('failedReAllocationAlert')">Close</button>
  </div>
</custom-modal>

<!-- Shortcut select alert -->
<custom-modal
  modalName="shortcutAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <div class="custom-modal-body">
    <div class="alert-title">Room {{roomSelected.join(',')}}</div>
    <div class="alert-desc">
      New usage selected with the following date range
      <br><b>{{labelRangeDateSelected()}}</b>
      <br>Please select an action for this usage
    </div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('shortcutAlert')">Cancel</button>
    <button type="button" class="btn btn-yellow" *ngIf="hasMaintenanceRole" (click)="shortcutCreateMaintenance()">New maintenance</button>
    <button type="button" (click)="bookNow()" *ngIf="hasReservationEditRole" [disabled]="disableBooknow" class="btn btn-green">Book now</button>
  </div>
</custom-modal>

<!-- Default alert -->
<custom-modal
  modalName="Alert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
>
  <div class="custom-modal-body">
    <div class="alert-title"></div>
    <div class="alert-desc"></div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('Alert')">Cancel</button>
  </div>
</custom-modal>