<app-header activePage="reports"></app-header>

<div class="page-header-container">
  <div class="page-header-inner">

    <div class="page-title-with-btn-container">
      <div class="page-twb-title">
        <div class="view-title">Reports</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 col-lg-4">
        <label for="select-report">Select Report</label>
        <dropdown-menu
          [menuList]="reportList"
          [selectedItem]="selectedReport"
          [fullWidth]="true"
          (selectItem)="selectReport($event)"
        ></dropdown-menu>
      </div>

      <div class="col-md-5 col-lg-8 sort-date">
        <label>Shortcuts</label>
        <div class="shortcuts">
          <button class="btn" download="sales-report.csv" [class]="activeClassFilter==='week' ? 'btn-green' : 'btn-outline-green'" (click)="lastWeek($event)" [disabled]="fillTimeDisable">
            <fa-icon type="regular" icon="calendar-arrow-down"></fa-icon>Last Week
          </button>
          <button class="btn" download="sales-report.csv" [class]="activeClassFilter==='month' ? 'btn-green' : 'btn-outline-green'" (click)="lastMonth($event)" [disabled]="fillTimeDisable">
            <fa-icon type="regular" icon="calendar-arrow-down"></fa-icon>Last Month
          </button>
          <button class="btn" download="sales-report.csv" [class]="activeClassFilter==='start-month' ? 'btn-green' : 'btn-outline-green'" (click)="startOfThisMonth($event)" [disabled]="fillTimeDisable">
            <fa-icon type="regular" icon="calendar-arrow-down"></fa-icon>Start of Month
          </button>
          <button class="btn" download="sales-report.csv" [class]="activeClassFilter==='start-year' ? 'btn-green' : 'btn-outline-green'" (click)="startOfYear($event)" [disabled]="fillTimeDisable">
            <fa-icon type="regular" icon="calendar-arrow-down"></fa-icon>Start of Year
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <label for="start-date">From</label>
        <dropdown-menu
          menuType="datepicker"
          [initialDate]="startDate"
          [secondDate]="endDate"
          alignment="adaptive"
          [disableMenu]="!requireStartDate || isCleaningReport"
          [allowEmptyDate]="false"
          (selectDate)="dateChange($event, 'startDate')"
        ></dropdown-menu>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <label for="until">Until</label>
        <dropdown-menu
          menuType="datepicker"
          [initialDate]="endDate"
          [secondDate]="startDate"
          alignment="adaptive"
          [disableMenu]="!requireEndDate"
          [allowEmptyDate]="false"
          [minDate]="minDateOfReportDate"
          (selectDate)="dateChange($event, 'endDate')"
        ></dropdown-menu>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <label for="reportDate">Date</label>
        <dropdown-menu
          menuType="datepicker"
          [initialDate]="reportDate"
          alignment="adaptive"
          [disableMenu]="!requireReportDate || !requireStartDate"
          [allowEmptyDate]="false"
          (selectDate)="dateChange($event, 'reportDate')"
        ></dropdown-menu>
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <label for="report-time">Time</label>
        <dropdown-menu
          menuType="timepicker"
          [initialDate]="reportDate"
          alignment="adaptive"
          [fullWidth]="true"
          (selectTime)="setDateFromDropdown($event)"
          [disableMenu]="!requireReportTime"
        ></dropdown-menu>
      </div>
      <!-- Hotels -->
      @if (hotels.length > 1) {
        <div class="col-6 col-sm-4 col-md-2">
          <label for="hotel">Hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotelItem"
            [fullWidth]="true"
            (selectItem)="selectHotel($event)"
          ></dropdown-menu>
        </div>
      }
      <!-- -------------- -->
    </div>
    <div class="bottom-filter">
      <div class="bottom-filter-left"></div>
      <div class="bottom-filter-right">
        <button class="btn btn-green btn-big" (click)="downloadGuestsInHouse($event)" [disabled]="downloadGuestsInHouseDisabled || isLoadingDownload">
          <fa-icon type="solid" icon="file-arrow-down"></fa-icon>Download Guests In-House
        </button>
        <button class="btn btn-green btn-big" (click)="downloadReport($event)" [disabled]="downloadDisabled  || isLoadingDownload">
          <fa-icon type="solid" icon="file-arrow-down"></fa-icon>Download Report
        </button>
      </div>
    </div>

  </div>
</div>

<div class="main-container" style="min-height: 20vh!important;">
  <div class="wrapper-inner-main-container">
    <!-- Loading icon -->
    @if (isLoadingDownload) {
      <div class="center-item">
        <ng-lottie
          [options]="options"
          width="200px"
          height="200px"
        ></ng-lottie>
      </div>
    }
  </div>
</div>
<app-footer></app-footer>
