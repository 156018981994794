import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ignoreBearer = req.url.includes('/api/user') || req.url.includes('/api/tenant');  //Ignore bearer in token for AWS requests
    let authReq = req;
    const token = window.localStorage.getItem("jwt");
    if (token != null && !["/api/user/changeuserpassword"].includes(req.url)) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, (ignoreBearer ? '' : 'Bearer ') + token) });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];