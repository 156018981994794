<div class="activity-log-2-container">
  <div class="modal-top-sticky">
    <div class="activity-log-filter">
      <div class="filter-item">
        <div style="width: 160px;display: inline-block;">
          <label>Sort</label>
          <dropdown-menu
            menuId="sortList"
            [menuList]="sortList"
            [selectedItem]="selectedSort"
            (selectItem)="selectSort($event)"
            adaptiveType="modal"
            [fullWidth]="true"
          ></dropdown-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="activity-log-content">
    @for(date of activityDateGroupFiltered; track date.date) {
      <div class="date-group">
        <div class="date-title">{{date.date}}</div>
        <div class="timeline-line"></div>
        @for(activity of date.activityList; track activity.id) {
          <activity-log-item
            [activity]="activity"
            [hotel]="hotel"
            [products]="products"
            [prevActivity]="activity.closestActivityIndex ? activityLogFull[activity.closestActivityIndex] : null"
          ></activity-log-item>
        }
      </div>
    }
  </div>
</div>