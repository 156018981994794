@if(activityItem) {
  <div class="activity-item">
    <div class="item-header">
      <div class="item-dot" [class]="activityItem.avatarColor">
        <div class="dot-avatar">
          @if(activityItem.avatarColor === "system") {
            <fa-icon type="solid" icon="gear"></fa-icon>
          } @else if(activityItem.avatarColor === "user") {
            <fa-icon type="solid" icon="user"></fa-icon>
          } @else {
            {{activityItem.avatar}}
          }
        </div>
        <div class="dot-time">{{activityItem.time}}</div>
      </div>
      <div class="item-action" (click)="toggleAccordion()">
        <div class="action-text">
          <div class="content-title">{{activityItem.action}}</div>
          <div class="content-author">by {{activityItem.authorName}}</div>
        </div>
        @if(activityItem.content) {
          <div class="action-arrow" [ngClass]="{'rotate': accordionOpened}">
            <fa-icon type="solid" icon="chevron-down"></fa-icon>
          </div>
        }
      </div>
    </div>
    @if(activityItem.content) {
      <div class="item-content" [style]="{height: accordionHeight + 'px'}">
        <div class="accordion-content" [ngClass]="{'hidden': !accordionOpened}" #accordionContent>
          @if(activityType === "PARAGRAPH") {
            <div class="content-paragraph">
              <activity-item-paragraph
                [content]="activityItem.content"
              ></activity-item-paragraph>
            </div>
          }
          @if(activityType === "COMPARE") {
            <div class="compare-table mui-table">
              <table>
                <thead>
                  <tr>
                    <th style="width: 200px">Attribute</th>
                    <th>Previous value</th>
                    <th>New value</th>
                  </tr>
                </thead>
                <tbody>
                  @for(row of activityItem.content; track $index) {
                    <tr>
                      <td><b>{{row.attribute}}</b></td>
                      <td>{{row.previous}}</td>
                      <td>{{row.current}}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
          @if(activityType === "RESERVATION") {
            <div class="content-reservation">

              @for(compare of activityItem.content.compare; track $index) {
                @if(compare.values.length) {
                  <div class="content-title">{{compare.title}}</div>
                  <div class="compare-table mui-table">
                    <table>
                      <thead>
                        <tr>
                          <th style="width: 200px">Attribute</th>
                          <th>Previous value</th>
                          <th>New value</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for(row of compare.values; track $index) {
                          <tr>
                            <td><b>{{row.attribute}}</b></td>
                            <td>{{row.previous}}</td>
                            <td>{{row.current}}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }
              }

              <div class="content-title">Rooms</div>
              @if(activityItem.content.orders.added.length) {
                <div class="content-subtitle">Added rooms</div>
                <div style="padding-left: 20px;">
                  @for(room of activityItem.content.orders.added; track $index) {
                    <div class="content-subtitle">{{room.title}}</div>
                    <div class="content-paragraph">
                      <activity-item-paragraph
                        [content]="room.values"
                      ></activity-item-paragraph>
                      <activity-log-lineitem
                        [content]="room.lineItems"
                      ></activity-log-lineitem>
                    </div>
                  }
                </div>
              }
              @if(activityItem.content.orders.changed.length) {
                <div class="content-subtitle">Updated rooms</div>
                <div style="padding-left: 20px;">
                  @for(room of activityItem.content.orders.changed; track $index) {
                    <div class="content-subtitle">{{room.roomLabel}}</div>
                    <div class="content-paragraph">
                      <activity-item-paragraph
                        [content]="room.values"
                      ></activity-item-paragraph>
                      <activity-log-lineitem
                        [content]="room.lineItems"
                      ></activity-log-lineitem>
                    </div>
                  }
                </div>
              }
              @if(activityItem.content.orders.removed.length) {
                <div class="content-subtitle red">Removed rooms</div>
                <div style="padding-left: 20px;">
                  @for(room of activityItem.content.orders.removed; track $index) {
                    <div class="content-subtitle">{{room.title}}</div>
                    <div class="content-paragraph">
                      <activity-item-paragraph
                        [content]="room.values"
                      ></activity-item-paragraph>
                      <activity-log-lineitem
                        [content]="room.lineItems"
                      ></activity-log-lineitem>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
}