<app-header activePage="maintenance"></app-header>
<div class="main-container">
  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Maintenance</h1>
        </div>
        <div class="page-twb-button">
          <button class="btn btn-green" routerLink="/maintenance/create"><fa-icon type="solid" icon="plus"></fa-icon>Create</button>
        </div>
      </div>
      <form [formGroup]="searchForm">
        <div class="row">
          @if (hotels.length > 1) {
            <div class="col-md-4 col-lg-2">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <div class="col-md-8 col-lg-8">
            <div class="row">
              <div class="col-sm-6">
                <label>Start date</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="startDate"
                      [secondDate]="endDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="startDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <label>End date</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="endDate"
                      [secondDate]="startDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="endDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-2">
            <label>Category</label>
            <dropdown-menu
              menuType="filter"
              [filterList]="categoryFilterList"
              [fullWidth]="true"
            ></dropdown-menu>
          </div>
          <div class="col-sm-4 col-md-6 col-lg-10"></div>
          <div class="col-sm-4 col-md-2 col-lg-2">
            <button type="submit" class="btn btn-green full-width input-height" style="margin-top: 27px" (click)="onSearch('new')">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        @if (searchResult.length > 0) {
          <div class="custom-table-wrapper">
            <table class="custom-table" [ngClass]="{hide: !showResult}">
              <thead>
                <tr>
                  <th style="width:110px">Hotel</th>
                  <th>Room</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Created by</th>
                  <th style="width:180px">Created time</th>
                  <th>Category</th>
                  <th>Reason</th>
                  <th>Usage</th>
                </tr>
              </thead>
              <tbody>
                @for (result of searchResult; track result) {
                  <tr routerLink="/maintenance/{{result.id}}">
                    @if (hotels.length > 1) {
                      <td>{{utils.hotelDisplayByLabel(hotelConfig, hotels, result.hotel.dropdownHotelName)}}</td>
                    }
                    <td>
                      <ul class="nopadding">
                        @for (room of result.rooms; track room) {
                          <li>{{room}}</li>
                        }
                      </ul>
                    </td>
                    <td>{{result.startDate}}</td>
                    <td>{{result.endDate}}</td>
                    <td>{{result.createdBy}}</td>
                    <td>{{result.createdTime}}</td>
                    <td>{{result.category}}</td>
                    <td>{{result.reason}}</td>
                    <td>
                      <div (click)="openUsage(result.hotel.hotelId, result.id, result.sendStartDate, result.sendEndDate)">
                        <fa-icon type="solid" icon="up-right-from-square"></fa-icon>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="show-more">
            <label>Showing {{currentCount}}/{{totalCount}}</label>
            @if (currentCount != totalCount && totalCount > 10) {
              <button class="btn btn-green large-btn show-more-btn"
                (click)="onSearch('more')"><fa-icon type="regular" icon="circle-ellipsis"></fa-icon>Show {{remainingCount}}
              more</button>
            }
          </div>
        }
      </div>
    </div>
  </div>
  @if (isSearching) {
    <div class="center-item">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  }
</div>
<app-footer></app-footer>