<div class="view-subtitle">Discount Voucher</div>
<div class="row no-gutters">
  <div class="col-12 col-sm-8 col-md-9" id="discount-table">
    <div class="voucher-table">
      <table class="custom-table table-input discount-table">
        <thead>
          <tr>
            <th rowspan="2" class="name">Voucher code</th>
            <th colspan="2" class="date">Booking Period</th>
            <th colspan="2" class="date">Stay Period</th>
            <th rowspan="2" class="amount">Nights per voucher</th>
            <th rowspan="2">Used</th>
            <th rowspan="2" class="edit">Edit</th>
            <th rowspan="2" class="action">Action</th>
          </tr>
          <tr>
            <th class="date-col">Start Date</th>
            <th class="date-col">End Date</th>
            <th class="date-col">Start Date</th>
            <th class="date-col">End Date</th>
          </tr>
        </thead>
        <tbody>
          @for (discount of discountResult; track discount) {
            <tr class="new-row" [attr.data-voucherId]="discount.id" (click)="viewVoucher(discount)">
              <td>
                <div class="name" tooltip="{{discount.key}}" placement="right" (click)="copyVoucher(discount.key)">
                {{discount.key}}</div>
              </td>
              @if (selectedVoucher != discount.id || !editDetail) {
                <td>{{utils.convertStringDate(discount.validFrom)}}</td>
                <td>{{utils.convertStringDate(discount.validUntil)}}</td>
                <td>{{getRestriction(discount.restrictions,'bookStart')}}</td>
                <td>{{getRestriction(discount.restrictions,'bookEnd')}}</td>
              }
              @if (selectedVoucher == discount.id && editDetail) {
                <td class="date-input">
                  <div (click)="changeInputStatus('startReservationPeriod')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="resStartDate"
                      [secondDate]="resEndDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateStartDate($event, 'reservation')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('endReservationPeriod')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="resEndDate"
                      [secondDate]="resStartDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateEndDate($event, 'reservation')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('dateFrom')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="bookStartDate"
                      [secondDate]="bookEndDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateStartDate($event, 'booking')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('dateUntil')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="bookEndDate"
                      [secondDate]="bookStartDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateEndDate($event, 'booking')"
                    ></dropdown-menu>
                  </div>
                </td>
              }
              <td>{{discount.amount}}</td>
              <td>{{discount.used}}</td>
              <td class="edit">
                <button class="btn btn-orange icon-only" [disabled]="selectedVoucher == discount.id && editDetail"
                  (click)="editVoucher(discount)"><fa-icon type="solid" icon="pen-to-square"></fa-icon></button>
                </td>
                <td class="action">
                  @if (selectedVoucher == discount.id) {
                    <button class="btn btn-red icon-only" (click)="deselectVoucher()"><fa-icon type="solid" icon="xmark"></fa-icon></button>
                  }
                  @if (selectedVoucher != discount.id) {
                    <button class="btn btn-red icon-only" (click)="deleteVoucherModal(discount)"><fa-icon type="solid" icon="trash-can"></fa-icon></button>
                  }
                </td>
              </tr>
            }
            <!--New row-->
            @if (newDetailShow) {
              <tr class="new-row">
                <td>
                  @if (!newDetail100Show) {
                    <input type="text" name="voucherKey" (input)="validateInput('voucherKey',$event,'object')">
                  }
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('startReservationPeriod')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="resStartDate"
                      [secondDate]="resEndDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateStartDate($event, 'reservation')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('endReservationPeriod')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="resEndDate"
                      [secondDate]="resStartDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateEndDate($event, 'reservation')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('dateFrom')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="bookStartDate"
                      [secondDate]="bookEndDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateStartDate($event, 'booking')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td class="date-input">
                  <div (click)="changeInputStatus('dateUntil')">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="bookEndDate"
                      [secondDate]="bookStartDate"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="updateEndDate($event, 'booking')"
                    ></dropdown-menu>
                  </div>
                </td>
                <td><input type="text" name="voucherAmount" (input)="validateInput('voucherAmount',$event,'object')"></td>
                <td>0</td>
                <td class="edit">
                  @if (!newDetailShow) {
                    <button class="btn btn-orange icon-only"><fa-icon type="solid" icon="pen-to-square"></fa-icon></button>
                  }
                </td>
                <td class="action">
                  <button class="btn btn-red icon-only" (click)="toggleNewDetail('hide')"><fa-icon type="solid" icon="xmark"></fa-icon></button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      @if (isLoading) {
        <div class="center-item">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
      }

      <div class="new-bar">
        <button class="btn btn-green btn-big" style="margin-right: 79px;" (click)="toggleNewDetail('show')"><fa-icon type="solid" icon="plus"></fa-icon>New voucher</button>

        <button class="btn btn-green btn-big" (click)="toggle100voucher()">Create batch</button>

        @if (voucher100show) {
          <span>
            <div class="custom-float-input">
              <div class="custom-input-number">
                <input name="voucher100number" type="number" placeholder=" " min="1" [(ngModel)]="voucher100number">
              </div>
              <label>Number of vouchers</label>
            </div>
            <button class="btn btn-green btn-big" (click)="toggleNewDetail('show', true)"><fa-icon type="solid" icon="plus"></fa-icon>Create vouchers</button>
          </span>
        }
      </div>

      @if (discountCount > 0) {
        <div class="show-more">
          <label>Showing {{discountResult.length}}/{{discountCount}}</label>
          @if (discountCount > 10 && discountResult.length != discountCount) {
            <button class="btn btn-green large-btn show-more-btn" (click)="callShowMore()"><fa-icon type="solid" icon="ellipsis"></fa-icon>Show {{discountCount - discountResult.length >= 10 ? 10 : discountCount - discountResult.length}} more</button>
          }
        </div>
      }
    </div>
    <div class="col-12 col-sm-4 col-md-3">
      <div id="discount-details" [ngClass]="{'init-discount-height': discountResult.length > 0 && (newDetailShow || selectedVoucher !== -1)}">
        <div class="top-sticky">
          <app-discount-details (sendParent)="getVoucher($event)" [loading]="disabledBtnWhileCallingAPI" [sendVoucher]="sendVoucher"></app-discount-details>
        </div>
      </div>
    </div>
</div>

<!-- Confirm alert -->
<custom-modal
  modalName="confirmAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="validityType === 'validity' ? 'warning' : 'question'"
>
  <div class="custom-modal-body">
    <div class="alert-title">{{validityTitle}}</div>
    <div class="alert-desc">{{validityDesc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('confirmAlert')">Cancel</button>
    @if (validityType == 'validity') {
      <button type="button" class="btn btn-green" (click)="saveVoucher('save')">OK</button>
    }
    @if (validityType == 'delete') {
      <button type="button" class="btn btn-green" (click)="confirmDeleteVoucher()">OK</button>
    }
  </div>
</custom-modal>