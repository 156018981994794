<app-header activePage="customers"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Blocked customer details</div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content">

    <form [formGroup]="editCustomerForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label for="firstName">First name</label>
          <input type="text" formControlName="firstName" class="firstName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isFirstName ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="firstNameOnChanges()">
        </div>
        <div class="col-12 col-sm-6">
          <label for="lastName">Last name</label>
          <input type="text" formControlName="lastName" class="lastName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isLastName ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="lastNameOnChanges()">
        </div>
        <div class="col-12 col-sm-6">
          <label for="mobile">Mobile number</label>
          <div class="row">
            <div class="col-4 col-md-6">
              <dropdown-menu
                [menuDialCodeList]="dialCodeList"
                menuType="dialCode"
                [minWidth]="265.5"
                [selectedDialCodeItem]="selectedDialCode"
                [searchable]="true"
                [fullWidth]="true"
                (selectDialCodeItem)="selectDialCode($event)"
              ></dropdown-menu>
            </div>
            <div class="col-8 col-md-6">
              <div class="custom-input-number">
                <input type="number" formControlName="mobile"
                  class="mobile form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isMobile ? 'has-not-choose-option-filter' :''}}"
                  (ngModelChange)="mobileOnChanges($event)">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label for="email">Email address</label>
          <input type="email" formControlName="email" class="email form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isEmail ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="emailOnChanges()">
        </div>
        <div class="col-12 col-sm-6">
          <label for="ssn">SSN</label>
          <input type="text" formControlName="ssn" class="ssn form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched  {{ !isSSN ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="ssnOnChanges()">
        </div>
        <div class="col-12 col-sm-6">
          <label for="passport">Passport number</label>
          <input type="text" formControlName="passport" class="passport form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched  {{ !isPassport ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="passportOnChanges()">
        </div>
        <div class="col-12">
          <div class="button-list" style="margin-top: 7.5px">
            <button type="button" class="btn btn-none-outline-green btn-big" (click)="resetInfo()">
              <fa-icon type="solid" icon="rotate-left"></fa-icon>Reset customer info
            </button>
            <button type="submit" class="btn btn-green btn-big" [disabled]="disabledBtnWhileCallingAPI">
              <fa-icon type="solid" icon="floppy-disk"></fa-icon>Save customer details
            </button>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <label for="state">State</label>
          <dropdown-menu
            [menuList]="stateList"
            [selectedItem]="selectedState"
            [fullWidth]="true"
            (selectItem)="selectState($event)"
          ></dropdown-menu>
        </div>
        <div class="col-12 col-sm-9">
          <label for="reason">Reason</label>
          <input type="text" class="reason form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched" formControlName="reason">
        </div>
        <div class="col-12">
          <div class="button-list" style="margin-top: 7.5px">
            <button type="button" class="btn btn-none-outline-green btn-big" (click)="clearState()">
              <fa-icon type="solid" icon="rotate-left"></fa-icon>Reset block state
            </button>
            <button type="button" class="btn btn-green btn-big" [disabled]="!(isReason) || disabledBtnWhileCallingAPI" (click)="addHistory()">
              <fa-icon type="solid" icon="floppy-disk"></fa-icon>Save block state
            </button>
          </div>
        </div>
      </div>
    </form>

  </div>

  <div class="wrapper-outer-table" >
    @if (blocklistHistories.length > 0) {
      <div class="outer-table">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="container-title">History</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="custom-table-container">
                <table class="table table-bordered table-hover table-striped custom-table">
                  <thead>
                    <tr>
                      <th class="table-customers">Author</th>
                      <th class="table-customers">Date</th>
                      <th class="table-customers">State</th>
                      <th class="table-customers">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (history of blocklistHistories; track history) {
                      <tr>
                        <td>{{ history.author }}</td>
                        <td>{{ history.created | date:'dd.MM.yyyy HH:mm:ss' }}</td>
                        <td>{{ history.state }}</td>
                        <td>{{ history.reason }}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div class=" row-bottom show-back">
              <div class="col-12 text-left" >
                <label class="customer-total-count" (click)="backToCustomerCard()"><fa-icon type="solid" icon="chevron-left"></fa-icon> Back</label>
              </div>
            </div>
            <!-- Loading icon -->
            @if (isLoading) {
              <div class="center-item">
                <ng-lottie
                  [options]="options"
                  width="200px"
                  height="200px"
                ></ng-lottie>
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>

</div>