<app-header activePage="breakfast"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container" style="margin-bottom: 0;">
        <div class="page-twb-title">
          <div class="view-title">Breakfast</div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content">
    <div class="row">
      <div class="col-12 col-sm-7">
        <!-- Select hotel-->
        <div class="row">
          @if (hotels.length > 1) {
            <div class="col-6">
              <label for="select-report">Hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <!-- Select month -->
          <div class="col-6">
            <label for="month-picker">Month</label>
            <dropdown-menu
              menuType="datepicker"
              [initialDate]="inputMonth"
              alignment="adaptive"
              [allowEmptyDate]="false"
              minMode="month"
              (selectDate)="monthChange($event)"
            ></dropdown-menu>
            <!-- <div class="input-group">
              <input type="text" placeholder="Select date" class="form-control text-input nls-input" formControlName="monthYear"
                (ngModelChange)="dateInputChange($event)">
              <span class="input-group-btn">
                <button class="btn btn-green icon-only datepicker-btn" bsDatepicker [(bsValue)]="inputMonth" (bsValueChange)="updateCal($event)"
                  [bsConfig]="{ isAnimated: true, minMode:'month', containerClass:'theme-red end-cal-month' }">
                  <fa-icon type="regular" icon="calendar-days"></fa-icon>
                </button>
              </span>
            </div> -->
          </div>
        </div>
        <div class="wrapper-calendar">
          <div class="divider"></div>
          <ul class="custom-calendar">
            @for (day of days; track day) {
              <li class="days">{{day}}</li>
            }
            @for (day of days; track day) {
              <li class="empty-days"></li>
            }
            @for (date of dates; track date) {
              <li
                [ngClass]="{outsideMonth: !isSameMonth(date.day), unavailableDay: !date.available, today: isToday(date.day)}"
                (click)="onselectTime(date)">
                {{date.day | date:'d'}}
                <div class="day-price">{{utils.getCurrency(currency)}} {{date.price}}</div>
              </li>
            }
          </ul>
        </div>
      </div>
      <div class="col-12 col-sm-5">
        <form [formGroup]="actionForm">
          <div class="row">
            <!-- Action -->
            <div class="col-6">
              <label for="select-report">Action</label>
              <dropdown-menu
                [menuList]="actionList"
                [selectedItem]="selectedAction"
                [fullWidth]="true"
                (selectItem)="selectAction($event)"
              ></dropdown-menu>
            </div>
            <!-- Set Price -->
            <div class="breakfast-price-field col-6">
              @if (selectedAction !== undefined && selectedAction.value === 'setPrice') {
                <label>Price</label>
                <input type="text" class="form-control searchQuery custom-input" formControlName="actionPrice" (ngModelChange)="checkPriceValidWhenChoosingPriceAction()">
                <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
              }
            </div>
            <!-- Start date -->
            <div class="col-6">
              <label for="start-date">Start date</label>
              <dropdown-menu
                menuType="datepicker"
                [initialDate]="startDate"
                [secondDate]="endDate"
                alignment="adaptive"
                [allowEmptyDate]="false"
                (selectDate)="dateChange($event, 'startDate')"
              ></dropdown-menu>
            </div>
            <!-- End date -->
            @if (!infiniteEnd) {
              <div class="col-6">
                <label for="end-date">End date</label>
                <dropdown-menu
                  menuType="datepicker"
                  [initialDate]="endDate"
                  [secondDate]="startDate"
                  alignment="adaptive"
                  [allowEmptyDate]="false"
                  (selectDate)="dateChange($event, 'endDate')"
                ></dropdown-menu>
              </div>
            }
            <!-- Show Only on specific weekdays checkbox when choosing mark unavailable action -->
            <div class="col-12" style="margin: 15px 0;">
              @if (selectedAction !== undefined && (selectedAction.value === 'markUnavailable' || selectedAction.value === 'setPrice')) {
                <div class="normal-checkbox" style="margin-bottom: 15px;">
                  <input type="checkbox" [checked]="onlyWeekdays" (click)="updateActionCheckbox('onlyWeekdays')">
                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                  <div class="checkbox-label">Only on specific weekdays</div>
                </div>
              }
              @if (selectedAction !== undefined && selectedAction.value === 'setPrice') {
                <div class="normal-checkbox">
                  <input type="checkbox" [checked]="infiniteEnd" (click)="updateActionCheckbox('infiniteEnd')">
                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                  <div class="checkbox-label">Infinite end date</div>
                </div>
              }
              @if (onlyWeekdays) {
                <div class="box-selector" style="margin: 15px 0;">
                  @for (weekday of selectedWeekdays; track weekday) {
                    <div class="custom-radio">
                      <input type="checkbox" formControlName="selectedWeekday"
                        (ngModelChange)="weekdayChange($event,weekday.label)">
                      <div class="radio-box">{{weekday.label}}</div>
                    </div>
                  }
                </div>
              }
            </div>
            <div class="col-12 text-right">
              <button type="button" class="btn btn-outline-green btn-big mr-10" (click)="onOpenEmailModal()"><fa-icon type="solid" icon="envelope"></fa-icon>Restaurant
            emails</button>
            <button type="submit" class="btn btn-green btn-big " (click)="onUpdateAction()"
              [disabled]="(!isValidPriceWhenChoosingPriceAction && selectedAction !== undefined && selectedAction.value === 'setPrice') || !isFormValid || disabledBtnWhileCallingAPI"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- Availability modal -->
<custom-modal
  modalName="availabilityModal"
  modalTitle="Breakfast availability"
  modalOrder="0"
>
@if (selectedDate) {
  <div class="custom-modal-body">
    <form [formGroup]="updateBreakfastForm">
      <div class="selected-date">
        @if (hotels.length > 1) {
          Hotel: {{utils.hotelDisplay(hotelConfig, hotels, selectedHotel ? selectedHotel.value : "")}}
        }
        @if (hotels.length > 1) {
          <br>
            }{{selectedDate.day | date:'yyyy-MM-dd'}}
          </div>
          <div class="row no-gutters">
            <div class="col-6">
              <div class="border-custom-radio">
                <input type="radio" value="available" formControlName="selectedAvailable" (click)="updateRadioForAvaibleBreakfast('available')">
                <div class="radio-box">Available</div>
              </div>
            </div>
            <div class="col-6">
              <div class="border-custom-radio">
                <input type="radio" value="notAvailable" formControlName="selectedAvailable" (click)="updateRadioForAvaibleBreakfast('notAvailable')">
                <div class="radio-box">NOT Available</div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6">
              <label>
                <div>
                  Breakfast price
                  @if (!isValidPrice) {
                    <span class="price-invalid"><br>(Price format is invalid!)</span>
                  }
                </div>
              </label>
            </div>
            <div class="col-6 breakfast-price-field {{ isDisablePriceField ? 'disabled' : ''}} ">
              <input type="text" class="height-40 form-control text-input nls-input right-align" formControlName="selectedPrice" (ngModelChange)="checkPriceValid()">
              <div class="dialog-euro-icon">{{utils.getCurrency(currency)}}</div>
            </div>
          </div>
        </form>
      </div>
    }
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-green" [disabled]="!(isValidPrice)" (click)="onUpdateSingleDay()">Save</button>
    </div>
  </custom-modal>

<!-- Restauran email modal -->
<custom-modal
  modalName="restaurantEmailModal"
  modalTitle="Breakfast report email"
  modalOrder="0"
>
<div class="custom-modal-body">
  <form [formGroup]="emailForm">
    <div class="row no-gutters">
      @if (hotels.length > 1) {
        <div class="col-12 col-sm-3">
          <label for="select-report">Hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotel"
            [fullWidth]="true"
            adaptiveType="modal"
            (selectItem)="selectHotel($event)"
          ></dropdown-menu>
        </div>
      }
      <div class="col-12 col-sm-9">
        <label for="hotel">Email</label>
        <input type="text" class="form-control custom-input" formControlName="selectedEmail">
      </div>
    </div>
  </form>
</div>
<div class="custom-modal-footer">
  <button type="button" class="btn btn-green" [disabled]="emailForm.invalid" (click)="onUpdateEmail()">Save</button>
</div>
</custom-modal>

<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
>
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
  </div>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
>
  <div class="custom-modal-body">
    <div class="alert-title">{{loadingElements.title}}</div>
    @if (loadingElements.desc) {
      <div class="alert-desc">{{loadingElements.desc}}</div>
    }
  </div>
</custom-modal>