<app-header activePage="allotments"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">

      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <div class="view-back">
            <back-button
              buttonTitle="Back to Allotments"
              targetUrl="/allotments"
            ></back-button>
          </div>
          <div class="view-title">Edit Allotment</div>
          @if (currentAllotment.cancelled) {
            <div class="view-desc">(This allotment has been cancelled)</div>
          }
        </div>
      </div>

      <form [formGroup]="addAllotmentForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="allotment-input-container">
              <div class="item-title">
                <label>Name</label>
              </div>
              <div class="item-content">
                <input type="text" class="form-control custom-input" id="alm-name" [ngClass]="{'error-glowing': !isValidName}" formControlName="name" no-special-character>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="allotment-input-container">
              <div class="item-title">
                <label>Type</label>
              </div>
              <div class="item-content">
                <dropdown-menu
                  [menuList]="allotmentTypeList"
                  [selectedItem]="selectedAllotmentType"
                  [fullWidth]="true"
                  [disableMenu]="true"
                ></dropdown-menu>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="allotment-input-container top-align">
              <div class="item-title">
                <label>Description</label>
              </div>
              <div class="item-content">
                <textarea type="text" class="custom-input custom-textarea" id="alm-description" formControlName="description" rows="3" (input)="triggerChanges()"></textarea>
              </div>
            </div>
          </div>
          @if (hotelActived && hotelActived.allotmentType === 'HARD') {
            <div class="col-12">
              <div class="allotment-input-container">
                <div class="item-title">
                  <label>Group contact email</label>
                </div>
                <div class="item-content">
                  <input type="text" class="form-control custom-input nls-input text-input" id="alm-email" (input)="triggerChanges()"
                    [ngClass]="{'error-glowing': !isValidEmail}" formControlName="email" required>
                </div>
              </div>
            </div>
          }

        </div>

        @if (hotels.length > 1 && selectedAllotmentType && selectedAllotmentType.value === "SOFT") {
          <div class="allotment-input-container top-align">
            <div class="item-title"><label for="alm-hotels" class="control-label">Hotels</label></div>
            <div class="item-content">
              <div class="row">
                @for (hotelChecked of hotels; track hotelChecked) {
                  <div class="col-6 col-sm-4">
                    <div class="normal-checkbox" style="margin-top: 10px;">
                      <input type="checkbox" [checked]="hotelChecked.selected" (click)="onHotelCheck(hotelChecked)">
                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                      <div class="checkbox-label">{{ utils.hotelDisplayByHotel(hotelConfig, hotelChecked) }}</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }

      </form>

    </div>
  </div>

  <div class="wrapper-outer-table">

    <div class="outer-table">
      <div class="container-fluid">
        @if (hotels.length > 1 && hasHotelSelected) {
          <!-- Allotment tab -->
          <div class="allotment-tab">
            @for (hotel of hotels; track hotel; let i = $index) {
              @if (hotel.selected) {
                <div class="item-tab" [ngClass]="{'active': hotel.active}" (click)="activeHotelTab(hotel)">
                  <ng-container>
                    {{ utils.hotelDisplayByHotel(hotelConfig, hotel) }}
                  </ng-container>
                </div>
              }
            }
          </div>
        }
        @if (hasHotelSelected) {
          <div>
            @if (hotelActived) {
              <div class="tab-content">
                <div class="container-title">Configuration for {{ utils.hotelDisplayByLabel(hotelConfig, hotels, hotelActived.label) }}</div>
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <div class="allotment-input-container">
                      <div class="item-title">
                        <label>Start date</label>
                      </div>
                      <div class="item-content">
                        <dropdown-menu
                          menuType="datepicker"
                          [initialDate]="startDate"
                          [secondDate]="endDate"
                          alignment="adaptive"
                          [allowEmptyDate]="false"
                          (selectDate)="dateChange($event, 'startDate')"
                        ></dropdown-menu>
                        <!-- <div class="datepicker-input">
                          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                            [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                            (blur)="validateDate()" (bsValueChange)="dateChange('startDate')" [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                            <fa-icon type="regular" icon="calendar-days"></fa-icon>
                          </button>
                        </div> -->
                      </div>
                    </div>
                    <div class="allotment-input-container">
                      <div class="item-title">
                        <label>End date</label>
                      </div>
                      <div class="item-content">
                        <dropdown-menu
                          menuType="datepicker"
                          [initialDate]="endDate"
                          [secondDate]="startDate"
                          alignment="adaptive"
                          [allowEmptyDate]="false"
                          (selectDate)="dateChange($event, 'endDate')"
                        ></dropdown-menu>
                        <!-- <div class="datepicker-input">
                          <input type="text" placeholder="Select end date" class="form-control" appEditDate
                            [(bsValue)]="endDate" bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()"
                            (blur)="validateDate()" (bsValueChange)="dateChange('endDate')" [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                            <fa-icon type="regular" icon="calendar-days"></fa-icon>
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  @if (hotelActived.allotmentType === 'HARD') {
                    <div class="col-12 col-sm-7">
                      <div class="allotment-input-container">
                        <div class="item-title">
                          <label>Release date and time</label>
                        </div>
                        <div class="item-content">
                          <div class="row no-gutters">
                            <div class="col-7">
                              <dropdown-menu
                                menuType="datepicker"
                                [initialDate]="expiryDate"
                                alignment="adaptive"
                                [allowEmptyDate]="false"
                                (selectDate)="updateExpiryDate($event)"
                              ></dropdown-menu>
                              <!-- <div class="datepicker-input">
                                <input type="text" placeholder="Select expiry date" class="form-control" bsDatepicker
                                  appEditDate [ngClass]="{'error-glowing': isExpiryDate }" [(bsValue)]="expiryDate"
                                  #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                                  (bsValueChange)="updateExpiryDate()"
                                  [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                                  <fa-icon type="regular" icon="calendar-days"></fa-icon>
                                </button>
                              </div> -->
                            </div>
                            <div class="col-5">
                              <dropdown-menu
                                menuType="timepicker"
                                [initialDate]="expiryDate"
                                alignment="adaptive"
                                [highlightError]="isExpiryTime"
                                [fullWidth]="true"
                                (selectTime)="setDateFromDropdown($event)"
                              ></dropdown-menu>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="allotment-input-container">
                        <div class="item-title">
                          <label>Set amount of rooms</label>
                        </div>
                        <div class="item-content">
                          <div class="set-amount-hard">
                            <div class="custom-input-number">
                              <input type="number" class="form-control custom-input text-input nls-input"
                                [ngClass]="{'error-glowing': !isValidateForAmountOfRooms }" [(ngModel)]="applyRangeAmountOfRoom" (keydown)="onKeydown($event)"
                                (change)="validateAmountOfRooms(applyRangeAmountOfRoom)" min="1">
                            </div>
                            <div class="set-button">
                              <button class="btn btn-green" (click)="applyAmountOfRoomForMultipleDay(true)">Apply</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                @if (hotelActived.allotmentType !== 'HARD') {
                  <div class="allotment-input-container">
                    <div class="item-title">
                      <label>Day</label>
                    </div>
                    <div class="item-content">
                      <div class="day-of-week-container">
                        @for (hotelChecked of hotelActived.weekDays; track $index) {
                          <div class="dow-item">
                            <div class="normal-checkbox" style="margin-top: 10px;" >
                              <input type="checkbox" [checked]="hotelChecked.selected" (change)="updateDayOfWeek(hotelChecked.code, $event)">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{hotelChecked.code }}</div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                <!--  Amount of room -->
                <div class="allotment-input-container top-align">
                  <div class="item-title">
                    <label>Rooms</label>
                  </div>
                  <div class="item-content">
                    <div class="date-container">
                      @for (date of hotelActived.hotelAllotmentDates; track $index) {
                        @if(date.checked || hotelActived.allotmentType === 'HARD') {
                          <div class="alm-date" [ngClass]="{'weekend': date.day === 'Sat' || date.day === 'Sun'}">
                            <p class="date" ng-bind="date.day">{{ date.day }}</p>
                            <p class="date" ng-bind="date.time"><strong>{{ date.time }}</strong></p>
                            <div class="custom-input-number" style="margin-top: 16px">
                              <input type="number" value="{{ date.availability }}" min="1" (change)="updateAvailabilityForWeekdays($event, date)"
                                [disabled]="hotelActived.allotmentType === 'HARD'">
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </div>
                <!-- Soft allotment -->
                @if (hotelActived.allotmentType !== 'HARD') {
                  <div class="allotment-input-container">
                    <div class="item-title">
                      <label>Set amount of rooms</label>
                    </div>
                    <div class="item-content">
                      <div class="set-amount-container">
                        <div class="amount-input">
                          <div class="custom-input-number">
                            <input type="number" class="form-control custom-input" min="1"
                              [(ngModel)]="applyRangeAmountOfRoom">
                          </div>
                        </div>
                        <div class="amount-start-date">
                          <dropdown-menu
                            menuType="datepicker"
                            [initialDate]="applyRangeStartDate"
                            [secondDate]="applyRangeEndDate"
                            alignment="adaptive"
                            [allowEmptyDate]="false"
                            (selectDate)="dateChange($event, 'rangeStartDate')"
                          ></dropdown-menu>
                          <!-- <div class="datepicker-input">
                            <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                              [(bsValue)]="applyRangeStartDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                              (blur)="validateDate()" (bsValueChange)="rangeDateChange('startDate')" [dateCustomClasses]="datepickerCustomForApplyAmount"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                              <fa-icon type="regular" icon="calendar-days"></fa-icon>
                            </button>
                          </div> -->
                        </div>
                        <div class="amount-end-date">
                          <dropdown-menu
                            menuType="datepicker"
                            [initialDate]="applyRangeEndDate"
                            [secondDate]="applyRangeStartDate"
                            alignment="adaptive"
                            [allowEmptyDate]="false"
                            (selectDate)="dateChange($event, 'rangeEndDate')"
                          ></dropdown-menu>
                          <!-- <div class="datepicker-input">
                            <input type="text" placeholder="Select end date" class="form-control" appEditDate
                              [(bsValue)]="applyRangeEndDate" bsDatepicker #endDateInput="bsDatepicker" [dateCustomClasses]="datepickerCustomForApplyAmount"
                              (input)="disableSearch()" (blur)="validateDate()" (bsValueChange)="rangeDateChange('endDate')"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                              <fa-icon type="regular" icon="calendar-days"></fa-icon>
                            </button>
                          </div> -->
                        </div>
                        <div class="apply-btn-wrapper">
                          <div>
                            <button class="btn btn-green btn-big"
                            (click)="applyAmountOfRoomForMultipleDay(false)">Apply</button>
                          </div>
                          <div>
                            @if (hotelActived.allotmentType !== 'HARD') {
                              <button class="btn btn-green"
                              (click)="applyToAllHotel()">Apply to all hotels</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <!--  Action for soft allotment -->
                @if (hotelActived.allotmentType !== 'HARD') {
                  <div class="bottom-filter">
                    <div class="bottom-filter-left"></div>
                    <div class="bottom-filter-right">
                      <button class="btn btn-outline-red" (click)="openCancelAllotmentModal()" [disabled]="currentAllotment.cancelled">
                        <fa-icon type="solid" icon="trash-can"></fa-icon>Cancel allotment
                      </button>
                      <button class="btn btn-green" (click)="openResetChangesModal()" [disabled]="!detectChanges">
                        <fa-icon type="solid" icon="rotate-left"></fa-icon>Reset changes
                      </button>
                      <button class="btn btn-green" [disabled]="currentAllotment.cancelled || (hotelActived.allotmentType === 'HARD' && selectedRooms.length === 0) || disabledBtnWhileCallingAPI" (click)="editAllotment()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Save changes</button>
                    </div>
                  </div>
                }
                <!-- Hard allotment rooms -->
                @if (currentAllotment.allotmentType === 'HARD') {
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label class="container-title">Rooms</label>
                      <div class="room-move-container">
                        <div class="room-list">
                          <label class="top-margin">Available Rooms</label>
                          <div class="room-select-container" id="leftRoom">
                            @for (room of availableRooms; track room; let i = $index) {
                              <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label" [ngClass]="{active: room.selected}"
                                (click)="selectListRoom(room, $event, true)">Room <b>{{room.label}} </b>({{ room.capacity }})
                              </div>
                            }
                          </div>
                        </div>
                        <div class="room-action">
                          <div class="room-select-arrow">
                            <button type="button" class="btn btn-green icon-only" (click)="selectRoom()"><fa-icon type="solid" icon="chevron-right"></fa-icon></button>
                          </div>
                          <div class="room-select-arrow">
                            <button type="button" class="btn btn-green icon-only" (click)="unselectRoom()" [disabled]="selectedRooms.length <= 1"><fa-icon type="solid" icon="chevron-left"></fa-icon></button>
                          </div>
                        </div>
                        <div class="room-list">
                          <label class="top-margin">Selected Rooms</label>
                          <div class="room-select-container" id="rightRoom">
                            @for (room of selectedRooms; track room; let i = $index) {
                              <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label" [ngClass]="{active: room.selected}"
                                (click)="selectListRoom(room, $event, false)">
                                Room <b>{{room.label}} </b>({{ room.capacity }})
                              </div>
                            }
                          </div>
                        </div>
                        <br/>
                      </div>
                    </div>
                    <!--  action for hard allotment -->
                    <div class="col-12 col-sm-6" style="position: relative;">
                      <div class="button-list hard-button">
                        <button class="btn btn-outline-red" (click)="openCancelAllotmentModal()" [disabled]="currentAllotment.cancelled">
                          <fa-icon type="solid" icon="trash-can"></fa-icon>Cancel allotment
                        </button>
                        <button class="btn btn-outline-green" (click)="goToUsage()">See usage</button>
                        <button class="btn btn-outline-green" (click)="goToVoucher()" [disabled]="currentAllotment.cancelled">Create voucher</button>
                        <button class="btn btn-green" (click)="openResetChangesModal()" [disabled]="!detectChanges">
                          <fa-icon type="solid" icon="rotate-left"></fa-icon>Reset changes
                        </button>
                        <button class="btn btn-green" (click)="editAllotment()" [disabled]="currentAllotment.cancelled || (hotelActived.allotmentType === 'HARD' && selectedRooms.length === 0)">Save</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>

</div>


<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
  </div>
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{loadingElements.title}}</div>
    @if (loadingElements.desc) {
      <div class="alert-desc">{{loadingElements.desc}}</div>
    }
  </div>
</custom-modal>

<!-- Voucher overlap alert -->
<custom-modal
  modalName="voucherOverlapAlert"
  modalSize="alert-modal"
  modalOrder="1"
  [backdropClose]="false"
  alertType="question"
  >
  <div class="custom-modal-body">
    <div class="alert-title">Voucher overlap</div>
    <div class="alert-desc">
      There are vouchers that not overlapped: {{voucherOverlap}}.
      <br>Are you sure you still want to update this allotment?
      </div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('voucherOverlapAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="confirmEditAllotment()">Yes</button>
    </div>
  </custom-modal>

  <!-- Cancel allotment alert -->
  <custom-modal
    modalName="cancelAllotmentAlert"
    modalSize="alert-modal"
    modalOrder="1"
    [backdropClose]="false"
    alertType="question"
    >
    <div class="custom-modal-body">
      <div class="alert-title">Cancel allotment</div>
      <div class="alert-desc">Are you sure you want to cancel this allotment?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('cancelAllotmentAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="cancelAllotment()">Yes</button>
    </div>
  </custom-modal>

  <!-- Reset changes alert -->
  <custom-modal
    modalName="resetChangesAlert"
    modalSize="alert-modal"
    modalOrder="1"
    [backdropClose]="false"
    alertType="question"
    >
    <div class="custom-modal-body">
      <div class="alert-title">Reset changes</div>
      <div class="alert-desc">Are you sure you want to reset changes? Any change progresses will be lost.</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('resetChangesAlert')">No</button>
      <button type="button" class="btn btn-green" (click)="resetChanges()">Yes</button>
    </div>
  </custom-modal>

  <!-- Amount of room change alert -->
  <custom-modal
    modalName="roomAmountChangeAlert"
    modalSize="alert-modal"
    modalOrder="1"
    [backdropClose]="false"
    alertType="question"
    >
    <div class="custom-modal-body">
      <div class="alert-title">Amount changes</div>
      <div class="alert-desc">The amount of rooms has been changed. Would you like to proceed?</div>
    </div>
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-none-outline-green" (click)="cancelUpdateAmount()">No</button>
      <button type="button" class="btn btn-green" (click)="confirmSetAmountOfRoom()">Yes</button>
    </div>
  </custom-modal>