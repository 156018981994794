@if(content) {
  @if(content.added) {
    <div class="content-subtitle-item">Added items</div>
    <div style="padding-left: 20px;">
      @for(item of content.added; track $index) {
        <div class="content-subtitle-item">{{item.title}}</div>
        <div style="padding-left: 20px;">
          <activity-item-paragraph
            [content]="item.values"
          ></activity-item-paragraph>
        </div>
      }
    </div>
  }
  @if(content.removed) {
    <div class="content-subtitle-item red">Removed items</div>
    <div style="padding-left: 20px;">
      @for(item of content.removed; track $index) {
        <div class="content-subtitle-item">{{item.title}}</div>
        <div style="padding-left: 20px;">
          <activity-item-paragraph
            [content]="item.values"
          ></activity-item-paragraph>
        </div>
      }
    </div>
  }
}