import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';
import { CONSTANT } from '../helpers/constants';
import { jwtDecode } from 'jwt-decode';
import { TenantListType, TenantType, TokenDecodedType, UserAuthType } from '../@types/auth';
import { GlobalUserSession, setGlobalUserSession } from '../helpers/globalAuth';
import { TenantService } from './tenant.service';
import { HotelWebConfig } from '../@types/app';
import { StoreService } from './store.service';
import { UTILS } from '../helpers/utils';
import { RolePermission } from '../helpers/rolesPermission';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export type AccessCheckResultType = {
  siteHost:string,
  envName:string,
  allowAccessEnv:boolean,
  tokenValid:boolean,
  accessHotelIds:number[]
}
type TenantValidateDataType = {
  siteHost: string,
  tenantData: TenantType
}

export type NavMenuListType = {
  pageName: string,
  friendlyName: string,
  requiredRoles: string[]
}

@Injectable({
  providedIn: 'root'
})
export class Auth2Service {

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    public utils: UTILS,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  checkEnvAccess(token:string):Observable<AccessCheckResultType> {
    const hotelConfig = this.storeService.getConfig();
    const defaultResult:AccessCheckResultType = {
      siteHost: "",
      envName: "",
      allowAccessEnv: false,
      tokenValid: true,
      accessHotelIds: []
    }
    if(hotelConfig.hotel.hotelChainName === "adminPortal") {
      return of({
        siteHost: "",
        envName: "",
        allowAccessEnv: true,
        tokenValid: true,
        accessHotelIds: [],
        accessTenantIds: []
      })
    } else {
      if(this.utils.isLocalEnvironment()) {
        return of({
          siteHost: "localhost:3001",
          envName: "Local MUI",
          allowAccessEnv: true,
          tokenValid: true,
          accessHotelIds: [1, 2, 3, 4, 7, 8, 9, 10, 11, 5, 12, 6]
        })
      } else {
        try {
          const decodeJwt = jwtDecode<TokenDecodedType>(token);
          return forkJoin({
            siteHost: this.getDomainSiteHost().pipe(
              catchError((err) => {
                console.error("site host api: " + err);
                return of(defaultResult)
              })
            ),
            tenantData: this.tenantService.loadTenants({ tenantids: decodeJwt.tenantids, environmentids: decodeJwt.environmentids }).pipe(
              catchError((err) => {
                console.error("tenant data api: " + err);
                return of(defaultResult)
              })
            )
          }).pipe(
            switchMap((resultData:TenantValidateDataType) => {
              if(resultData.tenantData.tenants.length > 0) {
                let currentTenantIndex = -1;
                let currentEnvIndex = -1;
                resultData.tenantData.tenants.forEach((tenant, tenantIndex) => {
                  tenant.environments.forEach((env, envIndex) => {
                    if(env.sitehost === `https://${resultData.siteHost}`) {
                      currentTenantIndex = tenantIndex;
                      currentEnvIndex = envIndex;
                    }
                  })
                })
                if(currentEnvIndex > -1 && currentTenantIndex > -1) {
                  const selectedEnv = resultData.tenantData.tenants[currentTenantIndex].environments[currentEnvIndex];
                  let tmpAccessHotelIds:number[] = [];
                  selectedEnv.hotels.forEach(hotel => {
                    if(decodeJwt.hotelids.includes(hotel.id)) {
                      tmpAccessHotelIds.push(hotel.saasid);
                    }
                  })
                  return of({
                    siteHost: resultData.siteHost,
                    envName: selectedEnv.name,
                    allowAccessEnv: true,
                    tokenValid: true,
                    accessHotelIds: tmpAccessHotelIds
                  })
                } else {
                  return of(defaultResult)
                }
              } else {
                return of(defaultResult)
              }
            }),
            catchError((err) => {
              console.log("checkEnvError: " + err);
              return of(defaultResult);
            })
          )
        } catch (error) {
          return of({
            siteHost: "",
            envName: "",
            allowAccessEnv: false,
            tokenValid: false,
            accessHotelIds: [],
            accessTenantIds: []
          })
        }
      }
    }
  }

  updateLoginSession(token:string) {
    if(GlobalUserSession) {
      try {
        const decodeJwt = jwtDecode<TokenDecodedType>(token);
        let tmpNewUserSession:UserAuthType = {
          ...GlobalUserSession,
          token: token,
          expire: decodeJwt.exp
        }
        setGlobalUserSession(tmpNewUserSession);
        localStorage.setItem("jwt", token);
      } catch (error) {
        console.log("Update login session: " + error)
      }
    }
  }

  saveLoginSession(token:string, refreshToken:string):Observable<string> {
    localStorage.setItem("jwt", token);
    localStorage.setItem("refresh-jwt", refreshToken);
    try {
      const decodeJwt = jwtDecode<TokenDecodedType>(token);
      return this.checkEnvAccess(token).pipe(
        switchMap((result) => {
          if(result.allowAccessEnv && result.tokenValid) {
            let tenantAccessList = decodeJwt.tenantids.split(",");
            if(this.utils.isLocalEnvironment()) {
              tenantAccessList.push("localhost");
            }
            const tmpUserSession:UserAuthType = {
              token: token,
              refreshToken: refreshToken,
              envName: result.envName,
              name: decodeJwt.name,
              email: decodeJwt.email,
              role: decodeJwt.roles,
              siteHost: result.siteHost,
              allowedHotelIds: result.accessHotelIds,
              allowedTenantIds: tenantAccessList,
              cognitoUsername: decodeJwt['cognito:username'],
              expire: decodeJwt.exp
            }
            setGlobalUserSession(tmpUserSession);
            return of("success")
          } else if(!result.tokenValid) {
            this.logoutAccount();
            return of("tokenInvalid");
          } else if(!result.allowAccessEnv) {
            this.logoutAccount();
            return of("notAllowed");
          } else {
            this.logoutAccount();
            return of("noResult");
          }
        }),
        catchError((err) => {
          console.error("login session failed: " + err)
          this.logoutAccount();
          return of("checkEnvFailed")
        })
      )
    } catch (err) {
      console.log("login function failed: " + err)
      this.logoutAccount();
      return of("tokenInvalid");
    }
  }

  checkCurrentSession():Observable<string> {
    return this.route.queryParamMap.pipe(
      switchMap(params => {
        let getToken = localStorage.getItem("jwt");
        let getRefreshToken = localStorage.getItem("refresh-jwt");
        let paramKeys = params.keys;
        let checkBasicParams = ['token', 'refreshtoken'].every((key:any) => paramKeys.includes(key));
        if(checkBasicParams) {
          getToken = params.get("token");
          getRefreshToken = params.get("refreshtoken");
        }
        if(getToken && getRefreshToken) {
          return this.saveLoginSession(getToken, getRefreshToken).pipe(
            switchMap(result => {
              if(result === "success") {
                this.location.replaceState(this.location.path().split('?')[0]);
                return of("complete");
              } else {
                this.logoutAccount();
                return of("sessionInvalid");
              }
            }),
            catchError((err) => {
              console.error("check session failed: " + err)
              this.logoutAccount();
              return of("sessionInvalid")
            })
          )
        } else {
          this.logoutAccount();
          return of("notLoggedIn")
        }
      })
    )
  }

  logoutAccount() {
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("refresh-jwt");
    window.localStorage.removeItem("updateSession");
    setGlobalUserSession(null);
  }
  logOutToAdminLogin(needLastUrl:boolean = false): void {
    this.logoutAccount();
    let adminEnv = "https://admin.nelson.management";
    if (this.utils.isLocalEnvironment()) {
      adminEnv = "http://localhost:4000"
    }
    let lastUrl = encodeURIComponent(window.location.href.split("?")[0]);
    window.open(`${adminEnv}/#/login?forceSignOut=true${needLastUrl ? "&lastUrl=" + lastUrl : ""}`, "_self");
  }

  loginAccount(username: string, password: string): Observable<any> {
    return this.http.post(CONSTANT.AUTH_API, {
      username,
      password,
      returnaccesstoken: false
    }, httpOptions);
  }

  refreshSession(username: string, refreshtoken: string): Observable<any> {
    return this.http.post(CONSTANT.AUTH_API, {
      username,
      refreshtoken
    }, httpOptions);
  }

  confirmUser(username: string, newpassword: string, session: string): Observable<any> {
    return this.http.post(CONSTANT.CONFIRM_USER_API, {
      username,
      newpassword,
      session,
      challenegname: CONSTANT.NEW_PASSWORD_REQUIRED_CHALLENGE
    }, httpOptions);
  }

  getDomainSiteHost(): Observable<any> {
    const { headers } = httpOptions
    return this.http.get("/api/management/secure/site-host", { headers, responseType: 'text' });
  }

  loginWithAccess(username: string, refreshtoken: string): Observable<any> {
    return this.http.post(CONSTANT.AUTH_API, {
      username,
      refreshtoken,
      returnaccesstoken: true
    }, httpOptions);
  }

  // Menu
  getMenuList(type:string):NavMenuListType[] {
    const hotelConfig: HotelWebConfig = this.storeService.getConfig();
    if(GlobalUserSession) {
      let tmpMenuList:NavMenuListType[] = [];
      if(type === "admin") {
        RolePermission.adminPortalPageList.forEach(menu => {
          if(GlobalUserSession && menu.requiredRoles.includes(GlobalUserSession.role)) {
            tmpMenuList.push(menu);
          }
        })
      }
      if(type === "client") {
        RolePermission.clientPageList.forEach(menu => {
          if(GlobalUserSession && menu.requiredRoles.includes(GlobalUserSession.role) && hotelConfig.MUIfeature.page.includes(menu.pageName)) {
            tmpMenuList.push(menu);
          }
        })
      }
      return tmpMenuList;
    } else {
      return [];
    }
  }
}
