import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';
import { UTILS } from 'src/app/helpers/utils';
import { Auth2Service, NavMenuListType } from 'src/app/services/auth2.service';

@Component({
  selector: 'app-header-user-management',
  templateUrl: './header-user-management.component.html',
  styleUrls: ['../header/header.component.sass']
})
export class HeaderUserManagementComponent implements OnInit {

  isLoggedIn: boolean = false;
  email?: string;
  menuItems: NavMenuListType[] = [];
  avatarText:string = "";
  @Input() activePage:string = "";
  @Output() requestEditUser:EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router,
    private utils: UTILS,
    private auth2Service: Auth2Service
  ) { }

  ngOnInit(): void {
    if(GlobalUserSession) {
      this.avatarText = this.utils.createAvatarFromName(GlobalUserSession.name);
      this.menuItems = this.auth2Service.getMenuList("admin");
    }
  }
  logout(): void {
    this.auth2Service.logoutAccount();
    this.router.navigateByUrl("/login");
  }
  openEditCurrentUser() {
    if(GlobalUserSession && ["NELSON_MANAGER", "CLIENT_ADMIN"].includes(GlobalUserSession.role)) {
      if(this.requestEditUser) {
        this.requestEditUser.emit("self");
      }
    }
  }
}