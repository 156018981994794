import { SegmentListType } from "../components/reservations/reservation-detail/reservation-detail.component";

let GlobalSegmentList:SegmentListType[] = [];
const setGlobalSegmentList = (data:SegmentListType[]) => {
  GlobalSegmentList = data;
}

export {
  GlobalSegmentList,
  setGlobalSegmentList
}