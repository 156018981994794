<app-header activePage="locks"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Door events</h1>
        </div>
      </div>

      @if (!hotelOnly) {
        <h3 class="view-code" style="margin-top: -10px; margin-bottom: 20px;">Code usage: {{currentDoorCode}}</h3>
      }
      <div class="row">
        @if (hotelOnly) {
          <div class="col-12 col-md-3">
            <div class="row">
              @if (hotels.length > 1) {
                <div class="col-12 col-md-5" style="margin-bottom: 10px">
                  <label>Hotel</label>
                  <dropdown-menu
                    [menuList]="hotelList"
                    [selectedItem]="selectedHotel"
                    [fullWidth]="true"
                    (selectItem)="selectHotel($event)"
                  ></dropdown-menu>
                </div>
              }
              <div class="col-12 col-md-7" style="margin-bottom: 10px">
                <label>Select doors</label>
                <div class="door-checkbox-list">
                  <div class="menu-tooltip-list" [ngClass]="{'show': showMenuTooltip}">
                    <div class="menu-title">Selected doors</div>
                    @for (selectedDoor of getAllFilter(); track selectedDoor) {
                      <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                        {{selectedDoor.label}}
                      </div>
                    }
                  </div>
                  <dropdown-menu
                    [fullWidth]="true"
                    menuType="custom"
                    [highlightError]="doorMenuValue === 'No doors'"
                    [menuButtonValue]="doorMenuValue"
                    (eventMenuOpened)="triggerDoorMenu($event)"
                    alignment="left"
                    [minWidth]="300"
                  >
                    <div class="door-item-list">
                      <div class="normal-checkbox" (click)="setAllDoors($event)">
                        <input type="checkbox" [checked]="selectAllDoors">
                        <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                        <div class="checkbox-label">All</div>
                      </div>
                      @for (door of doorsList; track door; let i = $index) {
                        @if (!['floor', 'room', 'elevator'].includes(door.type)) {
                          <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                            <input type="checkbox" [checked]="door.checked">
                            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                            <div class="checkbox-label">{{door.label}}</div>
                          </div>
                        }
                      }
                      @for (floor of allFloors; track floor) {
                        @for (door of doorsList; track door; let i = $index) {
                          <!-- Elevator -->
                          @if (door.type === 'elevator' && door.floor === floor) {
                            <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                              <input type="checkbox" [checked]="door.checked">
                              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                              <div class="checkbox-label">{{door.label}}</div>
                            </div>
                          }
                          <!-- Rooms with entrances -->
                          @if (door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)) {
                            <div class="accordion">
                              <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                              <div class="normal-checkbox main-checkbox" style="height: 32.84px">
                                <div class="checkbox-content" (click)="changeDoor(i,$event)">
                                  <input type="checkbox" [checked]="door.checked">
                                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                  <div class="checkbox-label">{{door.label}}</div>
                                </div>
                                <label class="accordion-arrow" for="floor{{door.floor}}">
                                  <fa-icon type="solid" icon="chevron-right"></fa-icon>
                                </label>
                              </div>
                              <div class="accordion-content">
                                @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                  @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                    <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                      <input type="checkbox" [checked]="roomDoor.checked">
                                      <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                      <div class="checkbox-label">{{roomDoor.label}}</div>
                                    </div>
                                  }
                                }
                              </div>
                            </div>
                          }
                        }
                        <!-- Rooms without entrances -->
                        @if (missingEntranceFloors.includes(floor)) {
                          <div class="accordion">
                            <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                            <div class="normal-checkbox main-checkbox">
                              <div class="checkbox-content">
                                <input type="checkbox" disabled>
                                <div class="checkbox-box disabled"><fa-icon type="solid" icon="check"></fa-icon></div>
                                <div class="checkbox-label">
                                  Floor {{floor}}
                                  <div class="checkbox-desc">(No entrance)</div>
                                </div>
                              </div>
                              <label class="accordion-arrow" for="floor{{floor}}">
                                <fa-icon type="solid" icon="chevron-right"></fa-icon>
                              </label>
                            </div>
                            <div class="accordion-content">
                              @for (roomDoor of doorsList; track roomDoor; let roomIndex = $index) {
                                @if (roomDoor.type === 'room' && roomDoor.floor === floor) {
                                  <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                    <input type="checkbox" [checked]="roomDoor.checked">
                                    <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                                    <div class="checkbox-label">{{roomDoor.label}}</div>
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </dropdown-menu>
                </div>
              </div>
            </div>
          </div>
        }
        <div class="col-12" [ngClass]="{'col-md-9': hotelOnly, 'col-md-12': !hotelOnly}">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="row">
                <div class="col-sm-6" style="margin-bottom: 10px">
                  <label>Start date & time</label>
                  <div class="row no-gutters">
                    <div class="col-7">
                      <dropdown-menu
                        menuType="datepicker"
                        [initialDate]="startDate"
                        [secondDate]="endDate"
                        alignment="adaptive"
                        [allowEmptyDate]="false"
                        (selectDate)="dateChange($event, 'startDate')"
                      ></dropdown-menu>
                    </div>
                    <div class="col-5">
                      <dropdown-menu
                        menuType="timepicker"
                        [initialDate]="startDate"
                        alignment="adaptive"
                        [fullWidth]="true"
                        (selectTime)="setDateFromDropdown($event, 'startDate')"
                      ></dropdown-menu>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" style="margin-bottom: 10px">
                  <label>End date & time</label>
                  <div class="row no-gutters">
                    <div class="col-7">
                      <dropdown-menu
                        menuType="datepicker"
                        [initialDate]="endDate"
                        [secondDate]="startDate"
                        alignment="adaptive"
                        [allowEmptyDate]="false"
                        (selectDate)="dateChange($event, 'endDate')"
                      ></dropdown-menu>
                    </div>
                    <div class="col-5">
                      <dropdown-menu
                        menuType="timepicker"
                        [initialDate]="endDate"
                        alignment="adaptive"
                        [fullWidth]="true"
                        (selectTime)="setDateFromDropdown($event, 'endDate')"
                      ></dropdown-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3" style="margin-bottom: 10px">
              <label>Event list</label>
              <dropdown-menu
                menuType="filter"
                [filterList]="eventFilters"
                [fullWidth]="true"
                [allOption]=true
                [highlightError]="!getAllEvent().length"
                [menuLoading]="eventListLoading"
                (updatedList)="handleUpdatedEventFilters($event)"
              ></dropdown-menu>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="right-btns">
            <button class="btn btn-green input-height" [disabled]="(hotelOnly && !checkSelectedDoor()) || !checkSelectedEvent()" (click)="showEvent('new')">Show events</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    @if (searchResult.length > 0) {
      <div class="custom-table-wrapper">
        <table class="custom-table">
          <thead>
            <tr>
              <th style="width:200px">Door</th>
              <th>Code</th>
              <th>Time</th>
              <th>Event</th>
              <th style="width:200px">Name</th>
              <th>Reservation</th>
            </tr>
          </thead>
          <tbody>
            @for (result of searchResult; track result) {
              <tr>
                <td>{{result.door}}</td>
                <td>{{result.doorCode}}</td>
                <td>{{result.time}}</td>
                <td>{{result.doorEvent}}</td>
                <td>{{result.doorName}}</td>
                <td>{{result.doorReservation}}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="show-more">
        <button type="button" class="btn btn-green large-btn show-more-btn icon-only" [ngClass]="{disabled: currentCount <= 20}" (click)="showEvent('prev')" style="margin-right: 15px"><fa-icon type="solid" icon="chevron-left"></fa-icon></button>
        <label style="display: inline-block">Showing {{currentCount}}/{{totalCount}}</label>
        <button type="button" class="btn btn-green large-btn show-more-btn icon-only"[ngClass]="{disabled: currentCount >= totalCount}" (click)="showEvent('next')" style="margin-left: 15px"><fa-icon type="solid" icon="chevron-right"></fa-icon></button>
      </div>
      <div class="right-btns">
        <button type="button" class="btn btn-green large-btn show-more-btn" (click)="exportCsv()"><fa-icon type="solid" icon="file-arrow-down"></fa-icon>Export as CSV</button>
      </div>
    }
  </div>
  @if (isSearching) {
    <div class="center-item">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  }
</div>