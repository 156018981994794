<app-header activePage="locks"></app-header>
<div class="main-container">

  <div class="page-header-container">
    <div class="page-header-inner">
      <div class="page-title-with-btn-container">
        <div class="page-twb-title">
          <h1 class="view-title">Locks</h1>
        </div>
      </div>

      <form [formGroup]="lockSearch">
        <div class="row">
          <div class="col-md-3">
            <label>Search for</label>
            <input class="form-control custom-input searchQuery" formControlName="searchQuery">
          </div>
          @if (hotels.length > 1) {
            <div class="col-md-2">
              <label>Hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
          }
          <div class="col-md-7">
            <div class="row">
              <div class="col-sm-6">
                <label>Start date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="startDate"
                      [secondDate]="endDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="startDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'startDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <label>End date & time</label>
                <div class="row no-gutters">
                  <div class="col-7">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="endDate"
                      [secondDate]="startDate"
                      alignment="adaptive"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                  <div class="col-5">
                    <dropdown-menu
                      menuType="timepicker"
                      [initialDate]="endDate"
                      alignment="adaptive"
                      [fullWidth]="true"
                      (selectTime)="setDateFromDropdown($event, 'endDate')"
                    ></dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <label>Filters</label>
            <dropdown-menu
              menuType="filter"
              [filterList]="filterList"
              [fullWidth]="true"
              (updatedList)="handleUpdatedFilters($event)"
            ></dropdown-menu>
          </div>
          <div class="col-md-5"></div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-green full-width input-height" style="margin-top: 30px" [disabled]="isSearching" (click)="onSearch('new')"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
          </div>
        </div>
      </form>

    </div>
  </div>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="right-btns">
          <button type="submit" class="btn btn-green input-height" routerLink="/locks/lock" [queryParams]="{hotelId: selectedHotel !== undefined ? selectedHotel.value : ''}"><fa-icon type="solid" icon="layer-plus"></fa-icon>Create new code</button>
          <button type="submit" class="btn btn-green input-height door-icon" routerLink="/locks/code-usage" [queryParams]="{hotelId: selectedHotel !== undefined ? selectedHotel.value : ''}"><fa-icon type="solid" icon="door-open"></fa-icon>Door events</button>
        </div>
      </div>
      <div class="container-fluid">
        @if (searchResult.length > 0) {
          <div class="custom-table-wrapper">
            <table class="custom-table">
              <thead>
                <tr>
                  <th style="width:400px">Door</th>
                  <th>Name(s)</th>
                  <th>Hotel</th>
                  <th>Reservation(s)</th>
                  <th>Door code</th>
                  <th>Start date</th>
                  <th>End date</th>
                </tr>
              </thead>
              <tbody>
                @for (result of searchResult; track result) {
                  <tr routerLink="/locks/lock" [queryParams]="{hotelId: getHotelId(result.hotel), userToken: result.token}">
                    <td>{{result.doorList}}</td>
                    <td>{{result.name}}</td>
                    <td>{{result.hotel}}</td>
                    <td>{{result.reservationCode}}</td>
                    <td>{{result.doorCode}}</td>
                    <td>{{result.startDate}}</td>
                    <td>{{result.endDate}}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="show-more">
            <label>Showing {{currentCount}}/{{totalCount}}</label>
            @if (remainingCount > 0) {
              <button class="btn btn-green large-btn show-more-btn" [disabled]="isSearching"
                (click)="onSearch('more')"><fa-icon type="solid" icon="ellipsis"></fa-icon>Show {{remainingCount}}
              more</button>
            }
            <button type="button" class="btn btn-green large-btn show-more-btn" (click)="backToTopPage()" style="margin-left: 15px"><fa-icon type="solid" icon="chevron-up"></fa-icon>Back to top page</button>
          </div>
        }
      </div>
    </div>
  </div>
  @if (isSearching) {
    <div class="center-item">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  }
</div>