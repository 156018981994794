import { Pipe, PipeTransform } from '@angular/core';
import { UTILS } from '../helpers/utils';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(
    public utils: UTILS,
  ) {}

  transform(fullPrice: any, type:any): any {
    if(fullPrice || fullPrice == 0) {
      if(fullPrice == 0) {
        return "0.00 ";
      } else {
        let priceSplit = fullPrice.split(" ");
        const symbol = this.utils.getCurrency(priceSplit[0]);
        if(priceSplit.length != 2) {
          return "invalid format";
        } else {
          let price =  Number(priceSplit[1]).toFixed(2);
          switch(type) {
            case 'price':
              return price;
            case 'currency':
              return symbol;
            case 'fullPrice':
              return `${price} ${symbol}`;
            case 'fullPriceLeft':
              let formatPrice = new Intl.NumberFormat('fi-fi', { style: 'currency', currency: 'EUR' }).format(parseFloat(price));
              let splitPrice = formatPrice.slice(0,-2);
              return `${symbol} ${splitPrice}`;
            case 'fullPriceRefund':
              break;
            default:
              return "invalid format";
          }
        }
      }
    }
  }

}

