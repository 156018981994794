import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnChanges, SimpleChanges, OnDestroy, Renderer2, AfterContentInit, AfterViewInit } from '@angular/core';
import { FaIconType } from '../fa-icon/fa-icon.component';
import { format } from 'date-fns/format';
import _, { cloneDeep } from 'lodash';
import { addDays, getHours, getMinutes, isAfter, isBefore, isEqual, isValid, parse, set } from 'date-fns';
import { BsDatepickerViewMode, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

export type MenuType = {
  label: string,
  value: string,
  disabled: boolean
}
export type DialCodeMenuType = {
  code: string,
  label: string,
  value: string,
  dialCode: string
}
export type ActionMenuType = {
  icon: string,
  iconType: FaIconType,
  title: string,
  desc: string,
  value: string,
  show: boolean
}
export type FilterMenuType = {
  label: string,
  value: string,
  parentValue?: string,
  checked: boolean
}
export type LoadingAlertType = {
  title: string,
  desc?: string
}
export type ResultAlertType = {
  type: string,
  title: string,
  desc: string,
  hideFooter?: boolean
}
enum MenuKind {
  MenuType = "MenuType",
  DialCodeMenuType = "DialCodeMenuType",
  ActionMenuType = "ActionMenuType"
}
type SupportedMenuType = "general" | "dialCode" | "action" | "timepicker" | "datepicker" | "filter" | "custom";

type MainMenuParamType = {
  [key:string]: boolean
}

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.sass']
})

export class DropdownMenuComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @ViewChild('customDropdown', { static: false }) customDropdown!: ElementRef;
  @ViewChild('menuButton', { static: false }) menuButton!: ElementRef;
  @ViewChild('parentDropdown', { static: false }) parentDropdown!: ElementRef;

  // General menu
  @Input() menuList:MenuType[] = [];
  @Input() selectedItem?:MenuType;
  @Input() emptyMenuText:string = "Select item";
  @Input() preventPropagation:boolean = false;
  @Output() selectItem:EventEmitter<MenuType> = new EventEmitter<MenuType>();
  @Output() eventMenuOpened:EventEmitter<boolean> = new EventEmitter<boolean>();

  // Menu dial code
  @Input() menuDialCodeList:DialCodeMenuType[] = [];
  @Input() selectedDialCodeItem?:DialCodeMenuType;
  @Output() selectDialCodeItem:EventEmitter<DialCodeMenuType> = new EventEmitter<DialCodeMenuType>();

  // Filter menu
  @Input() filterList:FilterMenuType[] = [];
  @Input() filterRule?:string;
  @Input() hasChildValue:boolean = false;
  @Input() allOption:boolean = false;
  @Input() compactInfo:boolean = false;
  @Output() updatedList:EventEmitter<FilterMenuType[]> = new EventEmitter<FilterMenuType[]>();

  // Menu action
  @Input() buttonTitle:string = "";
  @Input() buttonIcon:string = "plus";
  @Input() buttonIconType:FaIconType = "solid";
  @Input() actionTitle:string = "";
  @Input() menuActionList:ActionMenuType[] = [];
  @Input() selectedActionItem?:ActionMenuType;
  @Output() selectActionItem:EventEmitter<string> = new EventEmitter<string>();

  // Menu timepicker/datepicker
  @Input() initialDate?:Date;
  @Input() secondDate?:Date;
  @Input() allowEmptyDate?:boolean;
  @Input() minDate?:Date;
  @Input() maxDate?:Date;
  @Input() dateBtnStyle:string = "";
  @Input() minMode:BsDatepickerViewMode = "day";
  @Output() selectTime:EventEmitter<Date> = new EventEmitter<Date>();
  @Output() selectDate:EventEmitter<Date | undefined> = new EventEmitter<Date | undefined>();

  // Menu custom
  @Input() menuButtonValue:string = "";

  // All menus
  @Input() menuType:SupportedMenuType = "general";
  @Input() menuId:string = "";
  @Input() modalOpened:boolean = false;
  @Input() menuLoading:boolean = false;
  @Input() disableMenu:boolean = false;
  @Input() fullWidth:boolean = false;
  @Input() searchable:boolean = false;
  @Input() buttonFontSize:string = "18px";
  @Input() minWidth:number = 0;
  @Input() alignment:string = "adaptive";
  @Input() highlightError:boolean = false;
  @Input() inlineMenu:boolean = false;
  @Input() adaptiveType:string = "window";

  // Menu
  menuListFiltered:MenuType[] = [];
  menuDialCodeListFiltered:DialCodeMenuType[] = [];

  searchKeyword:string = ""
  menuOpened:boolean = false;
  menuLoaded:boolean = false;
  menuFinished:boolean = false;

  firstLoad:boolean = false;
  menuAppended:boolean = false;
  menuOriginTop:number = 0;
  menuOriginLeft:number = 0;
  offsetTop:number = 0;
  offsetLeft:number = 0;
  btnHeight:number = 40;
  menuWidth:number = 0;
  menuOrigin:string = "center";
  menuScaleX:string = "0.8";
  menuScaleY:string = "0";
  mainMenuClasses?:MainMenuParamType;
  loadedTimeOut:any;
  finishedLoadTimeOut:any;
  dateInputDebounce:any;

  // Filter
  filterCompactInfo:string = "";

  // Date picker
  currentDate?:Date = undefined;
  dateMinDate?:Date = undefined;
  dateMaxDate?:Date = undefined;
  lastCorrectDate?:Date = undefined;
  datepickerCustom: DatepickerDateCustomClasses[] = [];
  displayCurrentDate:string = "";

  // Timepicker
  displayCurrentTime:string = format(new Date(), "HH:mm");

  constructor(
    private renderer: Renderer2
  ) {}
  private listenScroll: (() => void) | null = null;
  private listenOutsideClick: (() => void) | null = null;

  ngOnInit(): void {
    this.mainMenuClasses = {
      'full-width': this.fullWidth,
      'highlight-error': this.highlightError,
      'inline-menu': this.inlineMenu,
      'mui-menu': this.checkCompatibleMenuUI("mui-menu"),
      'menu-action': this.checkCompatibleMenuUI("menu-action"),
      'menu-timepicker': this.checkCompatibleMenuUI("menu-timepicker"),
      'menu-datepicker': this.checkCompatibleMenuUI("menu-datepicker"),
    }
  }
  ngAfterContentInit(): void {
    this.resetMenuFilter();
    setTimeout(() => {
      if(!this.menuAppended) {
        this.appendToBody();
        this.updateMenuPos();
        window.addEventListener('resize', () => this.updateMenuPos());
      }
      const button = this.menuButton.nativeElement.getBoundingClientRect();
      const needFixedWidth = this.needFixedWidth();
      this.menuWidth = needFixedWidth ? this.getMenuFixedWidth() : button.width;
      if(["datepicker", "timepicker"].includes(this.menuType)) {
        if(this.initialDate) {
          const tmpInitialDate = _.cloneDeep(this.initialDate);
          this.currentDate = tmpInitialDate;
          this.lastCorrectDate = tmpInitialDate;
          if(this.menuType === "timepicker") {
            this.displayCurrentTime = format(this.currentDate, "HH:mm")
          }
          if(this.menuType === "datepicker") {
            this.displayCurrentDate = format(this.currentDate, this.getCalendarModeFormat())
          }
        } else {
          this.displayCurrentTime = "";
          this.lastCorrectDate = undefined;
          this.currentDate = undefined;
        }
      }
      if(this.menuType === "filter" && this.compactInfo) {
        this.compactInfoDisplay();
      }
    }, 0);
  }
  ngOnDestroy() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.menuType === "timepicker") {
      if(changes.initialDate && changes.initialDate.currentValue && !isEqual(changes.initialDate.previousValue, changes.initialDate.currentValue)) {
        this.currentDate = changes.initialDate.currentValue;
        this.displayCurrentTime = format(changes.initialDate.currentValue, "HH:mm")
      }
    }
    if(this.menuType === "datepicker") {
      if(changes.initialDate && changes.initialDate.currentValue && !isEqual(changes.initialDate.previousValue, changes.initialDate.currentValue)) {
        this.currentDate = changes.initialDate.currentValue;
        this.lastCorrectDate = changes.initialDate.currentValue;
        this.displayCurrentDate = format(changes.initialDate.currentValue, this.getCalendarModeFormat())
      }
      if(changes.initialDate && !changes.initialDate.currentValue && changes.initialDate.previousValue !== changes.initialDate.currentValue) {
        this.currentDate = undefined;
        this.lastCorrectDate = undefined;
        this.displayCurrentDate = "";
        this.updateDatepickerDisplay();
      }
    }
    if(changes.modalOpened && changes.modalOpened.currentValue) {
      setTimeout(() => {
        this.updateMenuPos();
      }, 600);
    }
    if(changes.highlightError && this.mainMenuClasses) {
      this.mainMenuClasses['highlight-error'] = changes.highlightError.currentValue
    }
  }

  checkPropagation(e:any) {
    if(this.preventPropagation) {
      e.stopPropagation();
    }
  }

  getCalendarModeFormat() {
    switch (this.minMode) {
      case "day":
        return "dd.MM.yyyy"
      case "month":
        return "MM.yyyy"
      case "year":
        return "yyyy"
      default:
        return "dd.MM.yyyy"
    }
  }

  checkCompatibleMenuUI(type:string) {
    switch (type) {
      case "mui-menu":
        return ['general', 'dialCode', 'filter', 'custom'].includes(this.menuType);
      case "menu-action":
        return ['action'].includes(this.menuType)
      case "menu-timepicker":
        return ['timepicker'].includes(this.menuType)
      case "menu-datepicker":
        return ['datepicker'].includes(this.menuType)
      default:
        return false
    }
  }
  needFixedWidth() {
    if(["action", "datepicker", "timepicker", "filter"].includes(this.menuType)) {
      return true;
    } else {
      return false;
    }
  }
  getMenuFixedWidth():number {
    switch (this.menuType) {
      case "filter":
        return 180;
      case "timepicker":
        return 200;
      case "datepicker":
        return 335;
      case "action":
        return 300;
      default:
        return 0;
    }
  }

  handleClickOutside(event: MouseEvent) {
    if(this.parentDropdown && !this.parentDropdown.nativeElement.contains(event.target) && !this.customDropdown.nativeElement.contains(event.target) && !this.menuButton.nativeElement.contains(event.target)) {
      this.triggerMenu(false)
    }
  }
  toggleMenu() {
    this.triggerMenu(!this.menuOpened);
  }
  triggerMenu(state:boolean) {
    if(state) {
      if(!this.listenOutsideClick) {
        this.listenOutsideClick = this.renderer.listen("document", "click", this.handleClickOutside.bind(this));
      }
      if(!this.listenScroll) {
        if (this.adaptiveType === "modal") {
          const tmpElement = document.querySelector('.custom-modal-container.show .custom-modal-body');
          if (tmpElement) {
            this.listenScroll = this.renderer.listen(tmpElement, 'scroll', () => this.updateMenuPos());
          }
        } else {
          this.listenScroll = this.renderer.listen("document", 'scroll', () => {
            this.updateMenuPos();
          });
        }
      }
      clearTimeout(this.finishedLoadTimeOut);
      clearTimeout(this.loadedTimeOut);
      if(this.firstLoad) {
        this.openSearch();
        this.updateMenuPos();
        this.menuOpened = true;
        this.menuLoaded = true;
        this.finishedLoadTimeOut = setTimeout(() => {
          this.menuFinished = true;
          this.eventMenuOpened.emit(true);
        }, 100);
      } else {
        this.openSearch();
        setTimeout(() => {
          this.updateMenuPos();
          this.menuOpened = true;
          this.menuLoaded = true;
          this.finishedLoadTimeOut = setTimeout(() => {
            this.menuFinished = true;
            this.eventMenuOpened.emit(true);
          }, 100);
        }, 0);
      }
    } else {
      clearTimeout(this.finishedLoadTimeOut);
      this.menuFinished = false;
      this.menuOpened = false;
      if(this.listenOutsideClick) {
        this.listenOutsideClick();
        this.listenOutsideClick = null;
      }
      if(this.listenScroll) {
        this.listenScroll();
        this.listenScroll = null;
      }
      this.eventMenuOpened.emit(false);
      clearTimeout(this.loadedTimeOut);
      this.loadedTimeOut = setTimeout(() => {
        this.searchKeyword = "";
        this.resetMenuFilter();
        this.menuLoaded = false;
      }, 300);
    }
  }

  resetMenuFilter() {
    switch (this.menuType) {
      case "general":
        this.menuListFiltered = this.menuList;
        break;
      case "dialCode":
        this.menuDialCodeListFiltered = this.menuDialCodeList;
        break;
      case "datepicker":
        if(this.minDate) {
          this.dateMinDate = this.minDate;
        } else {
          this.dateMinDate = undefined;
        }
        if(this.maxDate) {
          this.dateMaxDate = this.maxDate;
        } else {
          this.dateMaxDate = undefined;
        }
        this.updateDatepickerDisplay();
        break;
      default:
        break;
    }
  }
  checkMenuType(item:MenuType | DialCodeMenuType | ActionMenuType) {
    if("disabled" in item) {
      return MenuKind.MenuType;
    }
    if("dialCode" in item) {
      return MenuKind.DialCodeMenuType;
    }
    if("show" in item) {
      return MenuKind.ActionMenuType;
    }
  }

  selectAllFilters() {
    let tmpNewFilters:FilterMenuType[] = _.cloneDeep(this.filterList);
    tmpNewFilters.forEach(filter => {
      filter.checked = true;
    })
    this.filterList = tmpNewFilters;
  }
  clearAllFilters() {
    let tmpNewFilters:FilterMenuType[] = _.cloneDeep(this.filterList);
    tmpNewFilters.forEach(filter => {
      filter.checked = false;
    })
    this.filterList = tmpNewFilters;
  }

  timepickerChange() {
    if(this.currentDate) {
      this.displayCurrentTime = format(this.currentDate, "HH:mm");
      this.selectTime.emit(this.currentDate);
    }
  }

  checkFilterParentUnchecked(parentValue?:string) {
    if(parentValue) {
      const findParent = this.filterList.find(filter => filter.value === parentValue);
      if(findParent) {
        return !findParent.checked;
      }
      return false;
    }
    return false;
  }
  compactInfoDisplay() {
    if(this.compactInfo) {
      let allChecked = true;
      let numberChecked = 0;
      this.filterList.forEach(filter => {
        if(filter.checked) {
          numberChecked++;
        } else {
          allChecked = false;
        }
      })
      if(allChecked) {
        this.filterCompactInfo = "All";
      } else {
        this.filterCompactInfo = `${numberChecked} selected`
      }
    }
  }
  handleSelectedFilter(item:FilterMenuType) {
    let tmpNewFilters = _.cloneDeep(this.filterList);
    switch(this.filterRule) {
      case "customers":
        if(item.value === "SSN" && item.checked) {
          tmpNewFilters.forEach(filter => {
            if(filter.value !== "SSN" && filter.value !== "BLOCKED") {
              filter.checked = false;
            }
          })
        }
        if(item.value !== "SSN" && item.value !== "BLOCKED" && item.checked) {
          const findSSNFilter = tmpNewFilters.findIndex(filter => filter.value === "SSN");
          if(findSSNFilter > -1) {
            tmpNewFilters[findSSNFilter].checked = false;
          }
        }
        break;
      default:
        break;
    }
    if(this.hasChildValue) {
      tmpNewFilters.forEach(filter => {
        if(filter.parentValue && this.checkFilterParentUnchecked(filter.parentValue)) {
          filter.checked = false;
        }
      })
    }
    this.compactInfoDisplay();
    this.filterList = tmpNewFilters;
    this.updatedList.emit(tmpNewFilters);
  }
  removeFilter(index:number, e:Event) {
    e.stopPropagation();
    let tmpNewFilters:FilterMenuType[] = _.cloneDeep(this.filterList);
    tmpNewFilters[index].checked = false;
    this.filterList = tmpNewFilters;
    this.updatedList.emit(tmpNewFilters);
  }

  // Datepicker
  validateInputDate = (date:string) => {
    if(this.allowEmptyDate && !date) {
      return true;
    } else {
      const dateFormatRegex = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[0-2])\.(\d{4})$/;
      const checkFormat = dateFormatRegex.test(date);
      if(checkFormat) {
        const parsedDate = parse(date, 'd.M.yyyy', new Date());
        const isValidFormat = isValid(parsedDate);
        return isValidFormat;
      } else {
        return false;
      }
    }
  }
  dateInputChange = (event:Event) => {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    const dateValue = inputElement.value;
    let error = !this.validateInputDate(dateValue);
    if(this.mainMenuClasses) {
      this.mainMenuClasses['highlight-error'] = error;
    }
  }
  dateInputKeyDown = (event:KeyboardEvent): void => {
    if (event.key === "Enter") {
      (event.target as HTMLInputElement).blur();
      this.triggerMenu(false);
    }
  };
  dateInputBlurDelay:any;
  dateInputBlur = (event:Event) => {
    const inputElement = event.target as HTMLInputElement;
    const dateValue = inputElement.value;
    const validate = this.validateInputDate(dateValue);
    if(validate) {
      let parsedDate = dateValue ? parse(dateValue, 'd.M.yyyy', new Date()) : undefined;
      if(parsedDate && this.currentDate && !isEqual(parsedDate, this.currentDate)) {
        if(this.minDate && isBefore(parsedDate, this.minDate)) {
          parsedDate = cloneDeep(this.minDate);
        }
        if(this.maxDate && isAfter(parsedDate, this.maxDate)) {
          parsedDate = cloneDeep(this.minDate);
        }
        inputElement.value = parsedDate ? format(parsedDate, this.getCalendarModeFormat()) : "";
        this.dateInputBlurDelay = setTimeout(() => {
          this.datepickerChange(parsedDate, true);
        }, 200);
      } else if(!parsedDate && this.allowEmptyDate) {
        inputElement.value = "";
        this.dateInputBlurDelay = setTimeout(() => {
          this.datepickerChange(parsedDate, true);
        }, 200);
      }
    } else {
      if(this.currentDate) {
        inputElement.value = this.lastCorrectDate ? format(this.lastCorrectDate, this.getCalendarModeFormat()) : "";
      } else {
        inputElement.value = "";
      }
    }
    if(this.mainMenuClasses) {
      this.mainMenuClasses['highlight-error'] = false;
    }
  }
  restoreTime = (date:Date) => {
    if(this.initialDate) {
      let tmpCurrentDate = cloneDeep(date);
      const prevHour = getHours(this.initialDate);
      const prevMinute = getMinutes(this.initialDate);
      return set(tmpCurrentDate, {hours: prevHour, minutes: prevMinute, seconds: 0});
    }
  }
  datepickerChange(newDate?:Date, fromInput:boolean = false) {
    if(this.menuFinished || fromInput) {
      if(newDate || (!newDate && this.allowEmptyDate)) {
        if(!fromInput) {
          clearTimeout(this.dateInputBlurDelay)
        }
        let tmpCurrentDate: Date | undefined = newDate;
        if(newDate && !this.allowEmptyDate && this.initialDate && !isEqual(newDate, this.initialDate)) {
          tmpCurrentDate = this.restoreTime(newDate);
        }
        this.currentDate = tmpCurrentDate;
        this.lastCorrectDate = tmpCurrentDate;
        this.displayCurrentDate = tmpCurrentDate ? format(tmpCurrentDate, this.getCalendarModeFormat()) : "";
        this.selectDate.emit(tmpCurrentDate);
      }
    }
    this.updateDatepickerDisplay();
  }
  updateDatepickerDisplay() {
    if(this.currentDate && this.secondDate) {
      let startDate:Date = _.cloneDeep(this.currentDate);
      let endDate:Date = _.cloneDeep(this.secondDate);
      if(isBefore(this.secondDate, this.currentDate)) {
        startDate = _.cloneDeep(this.secondDate);
        endDate = _.cloneDeep(this.currentDate);
      }
      let custom = [
        {date: startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = addDays(startDate, 1);
      while(isBefore(tmpTime, endDate)) {
        custom.push({
          date: tmpTime, classes: ['dateRange']
        });
        tmpTime = addDays(tmpTime, 1);
      }
      custom.push({date: endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom;
    } else if(this.currentDate) {
      this.datepickerCustom = [
        {date: this.currentDate, classes: ['selectedDate', 'start-alone']},
      ]
    } else if(this.secondDate) {
      this.datepickerCustom = [
        {date: this.secondDate, classes: ['selectedDate', 'start-alone']},
      ]
    } else {
      this.datepickerCustom = [];
    }
  }
  clearDate() {
    this.displayCurrentDate = "";
    this.currentDate = undefined;
    this.selectDate.emit(undefined);
  }
  // ------------------------

  selectMenuItem(item:MenuType | DialCodeMenuType | ActionMenuType) {
    const menuKind = this.checkMenuType(item);
    switch (menuKind) {
      case MenuKind.MenuType:
        this.selectItem.emit(item as MenuType)
        break;
      case MenuKind.DialCodeMenuType:
        this.selectDialCodeItem.emit(item as DialCodeMenuType)
        break;
      case MenuKind.ActionMenuType:
        const tmpActionItem = item as ActionMenuType;
        this.selectActionItem.emit(item.value)
        break; 
      default:
        console.error("Invalid menu type");
        break;
    }
    this.triggerMenu(false)
  }

  searchListChange() {
    if(this.searchKeyword === "") {
      this.resetMenuFilter();
    } else {
      switch (this.menuType) {
        case "general":
          const tmpMenuFilter = this.menuList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()))
          this.menuListFiltered = tmpMenuFilter
          break;
        case "dialCode":
          const tmpDialCodeMenuFilter = this.menuDialCodeList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()) || item.dialCode.toString().includes(this.searchKeyword))
          this.menuDialCodeListFiltered = tmpDialCodeMenuFilter
          break;
        default:
          break;
      }
    }
  }

  openSearch() {
    this.searchKeyword = "";
    this.resetMenuFilter();
    setTimeout(() => {
      if(this.selectedItem || this.selectedDialCodeItem) {
        const dropdownContainer = document.getElementById(`select-menu-${this.menuId}`);
        let selectedItem:HTMLElement | null = null;
        if(this.selectedDialCodeItem) {
          selectedItem = document.getElementById(this.menuId + this.selectedDialCodeItem.value)
        }
        if(this.selectedItem) {
          selectedItem = document.getElementById(this.menuId + this.selectedItem.value)
        }
        if(dropdownContainer && selectedItem) {
          dropdownContainer.scrollTop = selectedItem.offsetTop - (this.searchable ? 130 : 100);
        }
      }
    }, 0);
  }

  appendToBody() {
    const container = document.querySelector('.custom-dropdown-body');
    if(container) {
      this.renderer.appendChild(container, this.customDropdown.nativeElement);
      this.menuAppended = true;
    }
  }
  updateMenuPos() {
    const menu = this.customDropdown.nativeElement.getBoundingClientRect();
    const button = this.menuButton.nativeElement.getBoundingClientRect();
    let targetEl:any;
    switch (this.adaptiveType) {
      case "window":
        targetEl = document.querySelector('body')?.getBoundingClientRect();
        break;
      case "modal":
        targetEl = document.querySelector('.custom-modal-container.show .custom-modal-body')?.getBoundingClientRect();
        break;
      default:
        break;
    }
    if(targetEl) {
      const scrollPos = document.documentElement.scrollTop;
      const targetRight = targetEl.right - 15;
      const targetLeft = targetEl.left + 15;

      const needFixedWidth = this.needFixedWidth();
      const tmpMenuWidth = needFixedWidth ? this.getMenuFixedWidth() : button.width;
      const tmpActualMenuWidth = this.minWidth > tmpMenuWidth ? this.minWidth : tmpMenuWidth;

      let baseLeft = button.left - ((tmpActualMenuWidth/2) - (button.width/2));
      let baseTop = button.top + button.height + scrollPos;
      let baseRight = baseLeft + tmpActualMenuWidth;
      let leftAligned = button.left;
      let rightAligned = button.left - (tmpActualMenuWidth - button.width);
      // Update left pos
      let tmpMenuOrigin = "center";
      if(this.alignment === "adaptive") {
        let finalLeft = 0;

        if(baseRight > targetRight) {
          finalLeft = rightAligned;
          tmpMenuOrigin = "right";
        } else if(baseLeft < targetLeft) {
          finalLeft = leftAligned;
          tmpMenuOrigin = "left";
        } else {
          finalLeft = baseLeft;
          tmpMenuOrigin = "center";
        }
        this.menuOriginLeft = finalLeft;
      }
      switch(this.alignment) {
        case "center":
          this.menuOriginLeft = baseLeft;
          tmpMenuOrigin = "center";
          break;
        case "left":
          this.menuOriginLeft = leftAligned;
          tmpMenuOrigin = "left";
          break;
        case "right":
          this.menuOriginLeft = rightAligned;
          tmpMenuOrigin = "right";
          break;
        default:
          break;
      }
      if(tmpMenuOrigin === "center" && tmpActualMenuWidth !== button.width && !this.needFixedWidth()) {
        if(Math.abs(menu.x - button.x) < 5 && tmpActualMenuWidth > button.width) {
          tmpMenuOrigin = "left"
        }
        if(Math.abs((menu.x + tmpActualMenuWidth) - (button.x + button.width)) < 5 && tmpActualMenuWidth > button.width) {
          tmpMenuOrigin = "right"
        }
      }
      this.menuOrigin = tmpMenuOrigin;
      this.menuWidth = tmpActualMenuWidth;
      this.btnHeight = button.height;
      // Update top pos
      let tmpOffsetTop = 0;
      let maxOffsetTop = menu.height + button.height;
      if(button.bottom + menu.height > window.innerHeight - 15) {
        tmpOffsetTop = (button.bottom + menu.height) - (window.innerHeight - 15);
        if(tmpOffsetTop > maxOffsetTop) {
          tmpOffsetTop = maxOffsetTop;
        }
      }
      this.offsetTop = tmpOffsetTop;
      this.menuOriginTop = baseTop;
      if(button.width + 5 < tmpActualMenuWidth) {
        const tmpScaleX = (button.width / tmpActualMenuWidth);
        this.menuScaleX = (tmpScaleX > 0.8 ? 0.8 : tmpScaleX).toFixed(2);
      } else {
        this.menuScaleX = "0.8";
      }
      if(button.height <= menu.height && tmpOffsetTop > 15) {
        const tmpScaleY = (button.height / menu.height);
        this.menuScaleY = (tmpScaleY > 1 ? 1 : tmpScaleY).toFixed(2);
      } else {
        this.menuScaleY = "0";
      }
    }
    this.firstLoad = true;
  }


}
