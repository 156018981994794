@if(content) {
  @for(data of content; track $index) {
    @if(data.type === "valueObject") {
      <div><b>{{data.key}}</b></div>
      <div [style]="{paddingLeft: '20px'}">
        <activity-item-paragraph [content]="data.value"></activity-item-paragraph>
      </div>
    } @else {
      <div><b>{{data.key}}</b>: <span [class]="data.type">{{data.value}}</span></div>
    }
  }
}
