import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANT } from 'src/app/helpers/constants';
import { TenantService } from 'src/app/services/tenant.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { UserService } from 'src/app/services/user.service';
import { UTILS } from 'src/app/helpers/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';
import { GlobalUserSession } from 'src/app/helpers/globalAuth';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass','../../../styles.sass']
})
export class DashboardComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  tenants: any = [];
  environments: any = [];
  users: any = [];
  siteHostCurrent = window.location.origin;
  isAdminRole = false;
  isClientAdminRole = true;
  firstName:string = "";
  loadingContainer: boolean = false;

  loadingActiveUsers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingTenants: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private toast: CustomToastService,
    private tenantService: TenantService,
    private userService: UserService,
    private utils: UTILS
  ) { }

  ngOnInit(): void {
    this.initDashboard();
  }

  initDashboard() {
    this.loadingContainer = true;

    if(GlobalUserSession) {
      const tenantIds:string = GlobalUserSession.allowedTenantIds.join();
      this.firstName = GlobalUserSession.name;
      let thread = [this.loadingTenants.asObservable(), this.loadingActiveUsers.asObservable()];
      this.isAdminRole = GlobalUserSession.role === "NELSON_MANAGER";
      this.isClientAdminRole = GlobalUserSession.role === "CLIENT_ADMIN";
      this.tenantService.loadTenants({ tenantids: this.isAdminRole ? '' : tenantIds }).subscribe({
        next: data => {
          if(GlobalUserSession) {
            this.tenants = data.tenants;
            this.environments = this.utils.getEnvironments(this.tenants, GlobalUserSession.token);
            this.environments.forEach((environment: any) => environment.issues = this.getEnviromnentIssue(environment));
            this.loadingTenants.next(true);
            if(GlobalUserSession && !["NELSON_MANAGER", "CLIENT_ADMIN"].includes(GlobalUserSession.role) && this.environments.length === 1) {
              this.chooseEnvironment(this.environments[0])
            }
          }
        },
        error: err => {
          this.toast.error("Error fetching clients.", 'Error!');
          this.loadingTenants.next(true);
        }
      });
      this.userService.loadUsers({ includeenvironments: true, tenantids: this.isAdminRole ? '' : tenantIds, activeonly: true }).subscribe({
        next: (data: any) => {
          this.users = data.users;
          this.loadingActiveUsers.next(true);
        },
        error: (errResp: any) => {
          this.toast.error("Error fetching active users.", 'Error!');
          this.loadingActiveUsers.next(true);
        }
      });
      combineLatest(thread).subscribe((data) => {
        if (data.indexOf(false) < 0) {
          this.loadingContainer = false;
          this.updateActiveUsers();
        }
      });
    }
  }

  chooseEnvironment(environment: any): void {
    // Allow User Management redirect to MUI 
    if(GlobalUserSession) {
      if (this.utils.isLocalEnvironment()) {
        // Displaying local MUI in tenant
        window.location.href = `${environment.managementsitehost}/#/reservations?token=${GlobalUserSession.token}&refreshtoken=${GlobalUserSession.refreshToken}`;
        this.updateTenantName({name: "LOCALHOST"});
        return;
      }
      const tenant = this.utils.getTenantForEnvironment(this.tenants, environment);
      this.updateTenantName(tenant);
      window.location.href = `${environment.managementsitehost}/#/reservations?token=${GlobalUserSession.token}&refreshtoken=${GlobalUserSession.refreshToken}`;
    }
  }

  updateTenantName(tenant: any) {
    CONSTANT.HOTEL_CHAIN_NAME = tenant.name;
  }

  updateActiveUsers() {
    if (this.environments) {
      this.environments.forEach((environment: any) => {
        environment.activeUsers = this.users.filter((user: any) => user.environmentids?.indexOf(environment.id) > -1 && user.roles != 'NELSON_MANAGER')?.length;
      });
    }
  }

  getEnviromnentIssue(environment: any) {
    return 0; //TODO: Implement issues functionality
  }

  determineTime() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 5 && hours < 12) {
        return "Good morning";
    } else if (hours >= 12 && hours < 18) {
        return "Good afternoon";
    } else if (hours >= 18 && hours < 21) {
        return "Good evening";
    } else {
        return "Good night";
    }
  }

  routeToEditCurrentUser() {
    this.router.navigate(['/users'], {queryParams: {editCurrentUser: true}});
  }
}
