<app-header activePage="customers"></app-header>
<div class="page-header-container">
  <div class="page-header-inner">

    <div class="page-title-with-btn-container">
      <div class="page-twb-title">
        <div class="view-title">Customers</div>
      </div>
      <div class="page-twb-button">
        <button type="button" class="btn btn-green btn-big" (click)="addNewCustomer()"><fa-icon type="solid" icon="plus"></fa-icon>Block new customer</button>
      </div>
    </div>

    <form name="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- Search button -->
        <div class="col-12 col-sm-6">
          <div class="btn-group dropdown">
            <label for="textSearch">Search for</label>
            <input name="query"
              class="form-control custom-input {{!hasChooseOptionFilters ? 'has-not-choose-option-filter': ''}}"
              [(ngModel)]="form.query" (ngModelChange)="textChange()" autofocus>
          </div>
        </div>

        <!-- Optional filters for customers -->
        <div class="col-12 col-sm-6">
          <label>Optional filters</label>
          <dropdown-menu
            menuType="filter"
            [filterList]="optionFilters"
            [fullWidth]="true"
            filterRule="customers"
            (updatedList)="handleUpdatedFilters($event)"
          ></dropdown-menu>
        </div>
      </div>

      <!-- Some buttons for customers -->
      <div class="bottom-filter">
        <div class="bottom-filter-left">
          <button type="button" class="btn btn-green" (click)="dashboardOnCustomers()">
            <fa-icon type="solid" icon="chart-column"></fa-icon>Dashboard
          </button>
        </div>
        <div class="bottom-filter-right">
          <button type="button" class="btn btn-none-outline-green left-margin" (click)="clearSearchCustomers()"><fa-icon type="solid" icon="trash-can"></fa-icon>Clear Search</button>
          <button type="submit" class="btn btn-green btn-big" [disabled]="(!selectedCustomer && !loading) || loading"><fa-icon type="solid" icon="magnifying-glass"></fa-icon>Search</button>
        </div>
      </div>
    </form>

  </div>
</div>


<!-- Customers table -->
@if (customers.length>0) {
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="container-title">Customer Status</div>
        <ng-container>
          <div class="status-checkbox-group">
            <label>Status</label>
            @for (status of customerStateAll; track status; let idx = $index) {
              <div class="filter-radio">
                <div class="radio-box" [class.active]="currentFilterStatus===status" (click)="selectStatus(status)">
                  @if (currentFilterStatus===status) {
                    <span><fa-icon type="solid" icon="circle-check"></fa-icon></span>
                    }{{status}}
                  </div>
                </div>
              }
            </div>
          </ng-container>
          <div class="custom-table-wrapper">
            <table class="table table-bordered table-striped sticky-header">
              <thead>
                <tr>
                  <th class="table-customers">Email</th>
                  <th class="table-customers">First name</th>
                  <th class="table-customers">Last name</th>
                  <th class="table-customers">SSN</th>
                  <th class="table-customers">Mobile</th>
                  <th class="table-customers">State</th>
                </tr>
              </thead>
              <tbody>
                @for (customer of customers; track customer) {
                  <tr class="customer-row">
                    @if (currentFilterStatus && customer.state===currentFilterStatus) {
                      <td><a [routerLink]="['/customer-card/',customer.email,customer.mobile ? customer.mobile.substring(1,customer.mobile) : '']">{{customer.email}}</a></td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.firstName ? customer.firstName : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.lastName ? customer.lastName : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.ssn ? customer.ssn : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.mobile ? customer.mobile : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.state ? customer.state : '-' }}</td>
                    }
                    @if (!currentFilterStatus) {
                      <td><a [routerLink]="['/customer-card/',customer.email,customer.mobile ? customer.mobile.substring(1,customer.mobile) : '']">{{customer.email}}</a></td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.firstName ? customer.firstName : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.lastName ? customer.lastName : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.ssn ? customer.ssn : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.mobile ? customer.mobile : '-' }}</td>
                      <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.state ? customer.state : '-' }}</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <!-- Show more customers after searching customers -->
          <div class="row row-bottom show-result">
            @if (customers.length) {
              <div class="col-12 text-left">
                <label class="customer-total-count">Showing {{ customers.length + '/' + totalCountCustomer }}</label>
              </div>
            }
            @if (customers.length >= limit) {
              <div class="col-12 text-center">
                @if (hasShowMore) {
                  <button type="button" class="btn btn-green btn-big" (click)="loadMore()"><fa-icon type="solid" icon="ellipsis"></fa-icon>Show {{ limit }} more</button>
                }
                @if (isBackToCustomersPage) {
                  <button type="button" class="btn btn-green btn-big"
                    (click)="backToTopPage()"><fa-icon type="solid" icon="chevron-up"></fa-icon>Back to top page</button>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
    <!-- Loading icon -->
    @if (loading) {
      <div class="center-item">
        <ng-lottie
          [options]="options"
          width="200px"
          height="200px"
        ></ng-lottie>
      </div>
    }