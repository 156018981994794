import { Component, Input } from '@angular/core';
import { CompareLineItemsParamType } from '../activity-log-item.component';

@Component({
  selector: 'activity-log-lineitem',
  templateUrl: './activity-log-lineitem.component.html',
  styleUrl: './activity-log-lineitem.component.sass'
})
export class ActivityLogLineitemComponent {
  @Input() content:CompareLineItemsParamType | undefined = undefined;

}
