import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { AllotmentsService } from 'src/app/services/allotments.service';
import { HotelService } from 'src/app/services/hotel.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { addDays, getHours, getMinutes, isAfter, isBefore, set, subDays } from 'date-fns';
import _ from 'lodash';

@Component({
  selector: 'app-allotments',
  templateUrl: './allotments.component.html',
  styleUrls: ['./allotments.component.sass']
})
export class AllotmentsComponent implements OnInit {
  datepickerConfig: any = CONSTANT.datepickerConfig;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  isFormValid:boolean = false;
  isLoading: boolean = false;
  isHotelAllotment: boolean = false;
  
  bsStartDateValue: Date | undefined = set(new Date(), {hours: 0, minutes: 0, seconds: 0});
  bsEndDateValue: Date | undefined = addDays(set(new Date(), {hours: 0, minutes: 0, seconds: 0}), 3);
  hotels: any = [];

  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  allotments: any = <any>[];
  allotmentForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
  });

  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  constructor(private router: Router,
    private hotelService: HotelService,
    private toast: CustomToastService,
    private allotmentsService: AllotmentsService,
    private storeService: StoreService,
    public utils: UTILS) { 

    }

  ngOnInit(): void {
    this.hotelService.getHotels().subscribe((data:any) => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectAllHotel();
    });
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }
  dateChange(value: Date | undefined, type:string) {
    switch(type) {
      case 'startDate':
        {
          let tmpStartDate = value;
          let tmpEndDate = _.cloneDeep(this.bsEndDateValue);
          if(tmpStartDate && tmpEndDate && isAfter(tmpStartDate, tmpEndDate)) {
            const prevHour = getHours(tmpEndDate);
            const prevMinute = getMinutes(tmpEndDate);
            tmpEndDate = set(addDays(new Date(tmpStartDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.bsStartDateValue = tmpStartDate;
          this.bsEndDateValue = tmpEndDate;
        }
        break;
      case 'endDate':
        {
          let tmpStartDate = _.cloneDeep(this.bsStartDateValue);
          let tmpEndDate = value;
          if(tmpStartDate && tmpEndDate && isBefore(tmpEndDate, tmpStartDate)) {
            const prevHour = getHours(tmpStartDate);
            const prevMinute = getMinutes(tmpStartDate);
            tmpStartDate = set(subDays(new Date(tmpEndDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.bsStartDateValue = tmpStartDate;
          this.bsEndDateValue = tmpEndDate;
        }
        break;
    }
  }
  onSearch(): void {
    if(this.selectedHotel) {
      this.isLoading = true;
      let requestForm = <any>{
        includeCancelledAllotment: true,
        hotelIds: this.selectedHotel.value !== "all" ? this.selectedHotel.value : undefined,
        name: this.allotmentForm.value.name ? this.allotmentForm.value.name : undefined,
        description: this.allotmentForm.value.description ? this.allotmentForm.value.description : undefined,
        startDate: this.bsStartDateValue ? formatDate(this.bsStartDateValue, 'yyyy-MM-dd', 'en-US') : undefined,
        endDate: this.bsEndDateValue ? formatDate(this.bsEndDateValue, 'yyyy-MM-dd', 'en-US') : undefined,
      };
      this.allotments = [];
      this.allotmentsService.searchAllotments(requestForm).subscribe(data => {
        this.isLoading = false;
        if (data.length === 0) {
          this.toast.error('Allotments not found', 'Warning!');
        } else {
          for (let allotment of data) {
            let allotmentResponse = <any>{};
            allotmentResponse = allotment;
            allotmentResponse.isShowHotelAllotment = false;
            allotmentResponse.hotelAllotment = [];
            this.allotments.push(allotmentResponse);
          }
        }
      });
    }
  }

  goDetailHotelAllotment(allotment: any): void {
    allotment.isShowHotelAllotment = !allotment.isShowHotelAllotment;
    if (allotment.hotelAllotment.length == 0) {
      this.isHotelAllotment = true;
      this.allotmentsService.allotmentInfo(allotment.id).subscribe(data => {
        allotment.hotelAllotment = data;
        for (let hA of data) {
          for (let h of this.hotels) {
            if (h.hotelId == hA.hotelId) {
              hA.label = h.label;
              hA.dropdownHotelName = h.dropdownHotelName;
            }
          }
          hA.dayOfWeeks = hA.dayOfWeeks.map((d: any) => { return d.substring(0, 3) });
          this.isHotelAllotment = false;
        };
      });
      if (!allotment.vouchers) {
        this.allotmentsService.getVoucherByAllotmentId(allotment.id).subscribe(data => {
          allotment.vouchers = data;
        });
      }
    }
  }
  clearSearch(): void {
    this.allotmentForm.patchValue({
      name: "",
      description: "",
    });
    this.allotments = [];
    this.bsStartDateValue = undefined;
    this.bsEndDateValue = undefined;
    this.isValidStartDate = true;
    this.isValidEndDate = true;
    this.selectAllHotel();
  }
  redirectAllotment(): void {
    this.router.navigateByUrl("allotment");
  }
  redirectAllotmentById(allotmentId: number): void {
    this.router.navigateByUrl("/allotment/" + allotmentId);
  }
  goToUsage(allotment: any, hotel:any): void {
    let usageParams: any = {
      hotelId: hotel.hotelId,
      startDate: moment(allotment.startDate).format('YYYY-MM-DD'),
      endDate: moment(allotment.endDate).format('YYYY-MM-DD'),
      allotmentId: allotment.id,
    };
    this.router.navigate(['/usage'], { queryParams: usageParams });
  }

  disableSearch() {
    this.isFormValid = false;
  }
}