<div class="custom-modal-container" [class]="customModalClass" [ngClass]="{'show': openModal}" [style]="{zIndex: modalZIndex, placeItems: position === 'top' ? 'start center' : 'center'}">
  <div class="custom-modal-backdrop" [ngClass]="{'show': openBackdrop}" (click)="backdropClose ? toggleModal('close') : false">
    <div class="backdrop-dark"></div>
  </div>
  <div class="custom-modal-content" [class]="modalSize + ' custom-alert-' + alertType" [ngClass]="{'close-modal': closeModal}">
    @if (modalSize !== 'alert-modal') {
      <div class="custom-modal-header">
        <div class="header-title">{{modalTitle}}</div>
        <div class="header-close">
          <button (click)="toggleModal('close')"><fa-icon type="solid" icon="xmark"></fa-icon></button>
        </div>
      </div>
    }
    @if (modalSize === 'alert-modal') {
      <div class="custom-modal-header">
        @if (alertType === 'success') {
          <div class="alert-icon">
            <fa-icon type="solid" icon="circle-check"></fa-icon>
          </div>
        }
        @if (alertType === 'warning') {
          <div class="alert-icon">
            <fa-icon type="solid" icon="circle-exclamation"></fa-icon>
          </div>
        }
        @if (alertType === 'question') {
          <div class="alert-icon">
            <fa-icon type="solid" icon="circle-question"></fa-icon>
          </div>
        }
        @if (alertType === 'error') {
          <div class="alert-icon">
            <fa-icon type="solid" icon="circle-xmark"></fa-icon>
          </div>
        }
        @if (alertType === 'loading') {
          <div class="alert-loading">
            <ng-lottie
              [options]="options"
              width="200px"
              height="200px"
            ></ng-lottie>
          </div>
        }
      </div>
    }
    <ng-content></ng-content>
  </div>
</div>