import { formatDate } from '@angular/common';
import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import _, { cloneDeep, constant, forEach } from 'lodash';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { AllotmentsService } from 'src/app/services/allotments.service';
import { HotelService } from 'src/app/services/hotel.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { LoadingAlertType, MenuType, ResultAlertType } from '../common/dropdown-menu/dropdown-menu.component';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';
import { addDays, isAfter, isBefore, set, subDays } from 'date-fns';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-allotment-detail',
  templateUrl: './allotment-detail.component.html',
  styleUrls: ['../allotment/allotment.component.sass'],
})
export class AllotmentDetailComponent implements OnInit {
  hotels: any = [];
  allotment: any = {};
  hotelActive: any = {};
  hotelActived: any = {};
  totalAvailableRooms = 0;
  hasHotelSelected = true;
  isValidName = true;
  isValidEmail = true;
  isExpiryDate = false;
  isExpiryTime = false;
  isDisabled = true;
  isShowSelectRooms = false;
  isValidateForAmountOfRooms = true;
  applyRangeAmountOfRoom = 1;
  applyRangeAmountOfRoomInit = 1;
  dates = [];
  hotelAllotmentDates: any[] = [];
  currentAllotment: any = {};
  allotmentInit: any = {};
  confirmedCancel: boolean = false;
  availableRooms: any[] = [];
  selectedRooms: any = [];
  newRoomInAllotment: any = [];
  listRoomSelected: any = [];
  selectingList = 0;
  previousItem: any;
  voucherOverlap: any = [];
  detectChanges:boolean = false;

  today: any = new Date();
  startDate:Date | undefined = set(new Date(), {hours: 0, minutes: 0, seconds: 0});
  endDate:Date | undefined = addDays(set(new Date(), {hours: 0, minutes: 0, seconds: 0}), 6);
  expiryDate: any = new Date(new Date().setHours(7, 0, 0, 0));
  minEnd: any = new Date(new Date().getTime() + 6 * 24 * 3600 * 1000);
  isFormValid: boolean = false;

  applyRangeStartDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  applyRangeEndDate: any = new Date(
    new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
  );
  minRangeEnd: any = new Date(
    new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
  );
  isRangeValid: boolean = false;

  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: ''
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  addAllotmentForm = new UntypedFormGroup({
    name: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(/^[a-z0-9 ,.'-]+$/i),
    ]),
    description: new UntypedFormControl(''),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(CONSTANT.REGEX_EMAIL),
    ]),
  });

  disabledBtnWhileCallingAPI: boolean = false;

  allotmentTypeList:MenuType[] = [];
  selectedAllotmentType?:MenuType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private hotelService: HotelService,
    private allotmentsService: AllotmentsService,
    public utils: UTILS,
    private modalService: BsModalService,
    private toast: CustomToastService,
    private storeService: StoreService,
  ) {

  }
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  get f() {
    return this.addAllotmentForm.controls;
  }

  ngOnInit(): void {
    this.initializeData();
  }
  initializeData(callback?:any) {
    let paramMap: any;
    paramMap = this.route.snapshot.paramMap;
    this.hotels = [];
    if(paramMap.params.id)
    {
      let tmpAllotmentTypeList:MenuType[] = [];
      CONSTANT.ALLOTMENT_TYPES.forEach(type => {
        tmpAllotmentTypeList.push({
          label: type.name,
          value: type.value,
          disabled: false
        })
      })
      this.allotmentTypeList = tmpAllotmentTypeList;
      this.allotmentsService
      .getAllotment(paramMap.params.id)
      .subscribe((allotment: any) => {
        this.hotelService.getHotels().subscribe((hotels: any) => {
         
          this.currentAllotment = allotment;
          this.allotmentInit = cloneDeep(allotment);
          hotels.forEach((hotelTemp: any, index: any) => {
            let hotel;
    
            this.currentAllotment.hotelAllotments.forEach(
              (hotelAllotment: any) => {
                if (hotelTemp.hotelId === hotelAllotment.hotelId) {
                  hotelAllotment.label = hotelTemp.label;
                  hotelAllotment.name = hotelTemp.name;
                  hotelAllotment.defaultCheckInTime = hotelTemp.defaultCheckInTime;
                  hotelAllotment.timezone = hotelTemp.zone;
                  hotel = hotelAllotment;
                  return;
                }
              }
            );
            if (hotel) {
              // Find timezone for this hotel
              this.hotels.push(
                this.hotelAllotments(
                  hotel,
                  allotment.allotmentType,
                  false,
                  index,
                  false,
                  hotels.length === 1
                )
              );
              // Update for FormGroup
              this.addAllotmentForm.patchValue({
                name: allotment.name,
                description: allotment.description,
                email: allotment.email,
              });
              
              const findAllotmentType = this.allotmentTypeList.find(item => item.value === allotment.allotmentType);
              if(findAllotmentType) {
                this.selectedAllotmentType = findAllotmentType
              }
            } else {
              this.hotels.push(this.hotelAllotments(hotelTemp, allotment.allotmentType, false, index, true, hotels.length === 1));
            }
          });
          const firstHotel = this.hotels.findIndex((item:any) => item.selected)
          if(firstHotel > -1) {
            this.hotels[firstHotel].active = true;
            this.hotelActived = this.hotels[firstHotel];
            this.startDate = this.hotelActived.startDate;
            this.endDate = this.hotelActived.endDate;
            this.applyRangeStartDate = this.hotelActived.startDate;
            this.applyRangeEndDate = this.hotelActived.endDate;
            this.expiryDate = this.hotelActived.expiryDate;
          }
          
          // Get availabilities for hotel with Hard Allotment
          if (
            this.currentAllotment.allotmentType === 'HARD' &&
            this.hotelActived
          ) {
            this.getRooms(this.hotelActived);
            this.getAvailabilitiesForHotel(this.hotelActived, () => {
              if(callback) {
                callback();
              }
            });
          } else {
            this.updateDates();
            if(callback) {
              callback();
            }
          }
        });
      });
    }
  }
  triggerChanges() {
    this.detectChanges = true;
  }
  selectListRoom(item: any, event: any, isAvailbleRooms: any) {
    let listItemIn = this.isInList(item, this.availableRooms)
      ? this.availableRooms
      : this.selectedRooms;
    if (listItemIn != this.selectingList) {
      this.selectingList = listItemIn;
      this.initSelectFields();
    }
    if (event.shiftKey) {
      if (!event.ctrlKey) {
        this.initSelectFields();
      }
      let currentList = isAvailbleRooms
        ? this.availableRooms
        : this.selectedRooms;
      this.markMultipleRoom(
        currentList,
        _.findIndex(currentList, item),
        _.findIndex(currentList, this.previousItem)
      );
    } else if (event.ctrlKey) {
      this.previousItem = item;
    } else {
      this.initSelectFields();
      this.previousItem = item;
    }
    item.selected = true;
  }

  initSelectFields() {
    this.availableRooms = _.sortBy(this.availableRooms, 'label');
    this.selectedRooms = _.sortBy(this.selectedRooms, 'label');
    _.each(this.availableRooms, function (item) {
      item.selected = false;
    });
    _.each(this.selectedRooms, function (item) {
      item.selected = false;
    });
  }

  markMultipleRoom(list: any, firstPosition: any, secondPosition: any) {
    let lowPosition =
      firstPosition < secondPosition ? firstPosition : secondPosition;
    let highPosition =
      firstPosition < secondPosition ? secondPosition : firstPosition;
    for (let i = lowPosition; i <= highPosition; i++) {
      list[i].selected = true;
    }
  }

  isInList(item: any, list: any) {
    return list.find((i: any) => {
      return i.label == item.label;
    });
  }

  getRooms(hotelActived: any) {
    
      let request = {
        startDate: moment(hotelActived.startDate).tz(hotelActived.timezone).format(CONSTANT.DATE_FORMAT),
        endDate: moment(hotelActived.endDate).tz(hotelActived.timezone).format(CONSTANT.DATE_FORMAT),
      };
      this.allotmentsService.getAvailableRooms(hotelActived.hotelId, request).subscribe({
        next: (rooms: any) => {
          this.availableRooms = rooms;
          this.selectedRooms = this.getSelectedRoom(hotelActived.hotelId);
        },
      });
  }

  selectRoom() {
    let i = this.availableRooms.length;
    while (i--) {
      if (this.availableRooms[i].selected) {
        this.selectedRooms.push(this.availableRooms[i]);
        this.availableRooms.splice(i, 1);
      }
    }
    // Show rooms are selected after saving an allotment
    this.showRoomsSelected();
    this.triggerChanges();
  }

  unselectRoom() {
    let i = this.selectedRooms.length;
    while (i--) {
      if (this.selectedRooms[i].selected) {
        this.availableRooms.push(this.selectedRooms[i]);
        this.selectedRooms.splice(i, 1);
      }
    }
    // Show rooms are un-selected after saving an allotment
    this.showRoomsSelected();
    this.triggerChanges();
  }

  showRoomsSelected() {
    this.selectedRooms.map((selectRoom: any) => {
      return 'Room ' + selectRoom.label;
    });

    // Sort availableRooms and selectedRooms after select/unSelect room
    this.selectedRooms.sort((selectedRoom1: any, selectedRoom2: any) => {
      return selectedRoom1.label - selectedRoom2.label;
    });
    this.availableRooms.sort((availableRooms1: any, availableRooms2: any) => {
      return availableRooms1.label - availableRooms2.label;
    });

    // Update the selected rooms for roomUsage in hotelActived and hotels
    this.selectedRoomForRoomUsages();
    // Update amount when selected room
    this.updateAmountWhenSelectedRoom();
  }

  updateAmountWhenSelectedRoom() {
    // Update amount for rooms table
    if (this.currentAllotment.allotmentType === 'HARD') {
      this.applyRangeStartDate = this.startDate;
      this.applyRangeEndDate = this.endDate;
      let startDate = moment(this.applyRangeStartDate);
      let endDate = moment(this.applyRangeEndDate);
      let amount = this.selectedRooms.length;
      // If an amount of rooms not change
      if (amount > this.totalAvailableRooms) {
        this.showNotificationWhenSelectMoreRoom();
        return;
      } else {
        // If an amount of rooms not change
        this.applyRangeAmountOfRoom = amount;
        this.applyAmountOfRoom(startDate, endDate, amount);
      }
    }
  }

  checkCheckedDay = (dayIndex:number, selectedDay:any) => {
    let days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const findDay = selectedDay.find((day:any) => day.code === days[dayIndex] && day.selected);
    if(findDay) {
      return true;
    } else {
      return false;
    }
  }
  hotelAllotments(
    hotel: any,
    allotmentType: string,
    isActive: boolean,
    index: number,
    unchecked:boolean = false,
    isSingleProperty: boolean
  ): any {
    let timezone = hotel.zone ? hotel.zone : hotel.timezone;
    // moment.tz.setDefault(timezone);
    let hotelTemp: any = {};
    hotelTemp.hotelId = hotel.hotelId;
    hotelTemp.label = hotel.label;
    hotelTemp.name = hotel.name;
    hotelTemp.timezone = timezone;
    hotelTemp.active = isActive;
    hotelTemp.selected = !unchecked;
    hotelTemp.defaultCheckInTime = hotel.defaultCheckInTime;
    hotelTemp.allotmentType = allotmentType ? allotmentType : 'SOFT';
    hotelTemp.weekDays = _.cloneDeep(CONSTANT.WEEKDAYS_UPDATE);
    if(unchecked) {
      hotelTemp.startDate = new Date(new Date().setHours(0, 0, 0, 0));
      hotelTemp.endDate = new Date(
        new Date().setHours(0, 0, 0, 0) + 6 * 24 * 3600 * 1000
      );
      hotelTemp.expiryTime = '15:00';
      hotelTemp.expiryDate = new Date(
        new Date().setHours(hotelTemp.expiryTime.slice(0, 2), 0, 0, 0)
      );
      if ((hotelTemp.allotmentType === 'HARD' && !isSingleProperty) || unchecked) {
        hotelTemp.selected = false;
      } else {
        hotelTemp.selected = true;
      }
      hotelTemp.hotelAllotmentDates = this.updateDates();
    } else {
      hotelTemp.startDate = new Date(
        new Date(hotel.startDate).setHours(0, 0, 0, 0)
      );
      hotelTemp.endDate = new Date(new Date(hotel.endDate).setHours(0, 0, 0, 0));
  
      let hourForExpiryTime = hotel.expiryDateTime
        ? hotel.expiryDateTime.slice(11, 13)
        : '17';
      let minuteForExpiryTime = hotel.expiryDateTime
        ? hotel.expiryDateTime.slice(14, 16)
        : '00';
      let expiryDateFormat = moment(hotel.expiryDateTime).format('YYYY-MM-DD');
      hotelTemp.expiryDate = new Date(
        new Date(expiryDateFormat).setHours(
          hourForExpiryTime,
          minuteForExpiryTime
        )
      );
      hotelTemp.expiryTime = hotel.expiryDateTime
        ? hotel.expiryDateTime.slice(11, 16)
        : '17:00';
      // hotelTemp.expiryTime = formatDate(hotelTemp.expiryDate, "HH:mm", CONSTANT.EN_US);
      hotelTemp.weekDays.forEach((day: any) => {
        let dayExist = hotel.dayOfWeeks.find((d: any) => {
          return this.convertToFullDayName(day.code) === d;
        });
        if (!dayExist) {
          day.selected = false;
        }
      });
      if (hotel.hotelAllotmentDates) {
        let tmpAllotmentDate:any[] = [];
        hotel.hotelAllotmentDates.forEach((allot:any) => {
          const convertedData = this.hotelAllotmentDate(
            moment(allot.date).toDate(),
            allot.availability
          );
          tmpAllotmentDate.push(convertedData)
        })
        hotelTemp.hotelAllotmentDates = tmpAllotmentDate;
      }
    }
    return hotelTemp;
  }

  checkValidateEmail(): any {
    // Group contact email is optional input when creating HA
    if (this.addAllotmentForm.controls.email.value === '') {
      this.isValidEmail = true;
      return true;
    }

    if (this.addAllotmentForm.controls.email.status === 'INVALID') {
      this.isValidEmail = false;
      return false;
    } else {
      this.isValidEmail = true;
      return true;
    }
  }

  onSubmit(): void {}

  // selectAllotmentType(item:MenuType) {
  //   this.selectedAllotmentType = item;
  //   const allotmentType = item.value;
  //   this.hotelActived.allotmentType = allotmentType;
  //   // Check temp hotels
  //   let hotelsTemp: any[] = [];
  //   this.hotels.forEach((hotel: any, index: any) => {
  //     hotelsTemp.push(this.hotelAllotments(hotel, allotmentType, false, index, false, this.hotels.length === 1));
  //   });
  //   this.hotels = hotelsTemp;
  //   if (allotmentType === 'SOFT') {
  //     this.hotels[0].active = true;
  //   }
  //   // Show/hidden create button and hotel tab when selecting least one hotel/don't select any hotel
  //   this.hasHotelSelected = this.checkHasHotelSelected();
  //   // Update hotel active when changing allotment type
  //   this.hotelActived = this.hotels[0];
  //   this.expiryDate = this.hotelActived.expiryDate;
  //   // Get availabilities for hotel with Hard Allotment when choosing allotment's type
  //   if (this.hotelActived.allotmentType === 'HARD') {
  //     if (JSON.stringify(this.hotelActived) !== '[]') {
  //       this.getAvailabilitiesForHotel(this.hotelActived);
  //     }
  //   }
  // }
  checkHasHotelSelected(): any {
    let hotelsSelected = this.hotels.find((hotel: any) => {
      return hotel.selected == true;
    });
    return typeof hotelsSelected === 'undefined' ? false : true;
  }
  updateDayOfWeek(day:string, e:any) {
    let hotelActived = cloneDeep(this.hotelActived);
    // Update weekDays
    hotelActived.weekDays.forEach((d: any) => {
      if (d.code.toUpperCase() === day.toUpperCase()) {
        d.selected = e.target.checked;
      }
    });
    // Update rooms when choose days
    hotelActived.hotelAllotmentDates.forEach((d: any) => {
      if (d.day.toUpperCase() === day.toUpperCase()) {
        d.checked = e.target.checked
      }
    });
    this.updateHotelActivedForHotels(hotelActived);
    this.triggerChanges();
  }
  updateHotelActivedForHotels(hotelActived: any) {
    // Update for the actived hotel to hotels
    this.hotelActived = hotelActived;
    let foundIndex = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndex] = this.hotelActived;
  }
  onHotelCheck(hotel: any): void {
    // Update hotel is selected
    hotel.selected = !hotel.selected;
    if (hotel.selected) {
      let allInactive = true;
      for (let i = 0; i < this.hotels.length; i++) {
        if (this.hotels[i].active) {
          allInactive = false;
        }
      }
      if (allInactive) {
        hotel.active = true;
      }
      if (hotel.active) {
        // Update availabilite rooms for hotel selected and actived
        this.getAvailabilitiesForHotel(hotel);
      }
    }
    // Un-check for hotel
    if (!hotel.selected && hotel.active) {
      hotel.active = false;
      for (let j = 0; j < this.hotels.length; j++) {
        if (this.hotels[j].selected && !this.hotels[j].active) {
          this.activeHotelTab(this.hotels[j])
          break;
        }
      }
    }
    this.hasHotelSelected = this.checkHasHotelSelected();
    this.triggerChanges();
  }
  getAvailabilitiesForHotel(hotel: any, callback?:any) {
    let request = {
      startDate: formatDate(hotel.startDate, 'YYYY-MM-dd', CONSTANT.EN_US),
      endDate: formatDate(hotel.endDate, 'YYYY-MM-dd', CONSTANT.EN_US),
    };
    this.allotmentsService.getAvailabilities(hotel.label, request).subscribe({
      next: (response: any) => {
        this.totalAvailableRooms =
          _.sum(_.map(response.availabilities, 'availableRooms')) +
          this.selectedRooms.length;
        this.applyRangeAmountOfRoom = this.hotelActived.hotelAllotmentDates[0].availability;
        this.applyRangeAmountOfRoomInit = this.applyRangeAmountOfRoom;
        if(callback) {
          callback()
        }
      },
      error: (err) => {
        console.log('err: ', err);
      }
    });
  }
  updateDates(): any {
    if (this.hotels.length === 0) {
      return;
    }
    let date = moment(this.startDate, 'DD.MM.YYYY');
    let dayRange = [];
    let endDate;
    let hotelSelected = this.hotels.find((hotel: any) => {
      return hotel.active == true;
    });
    if(hotelSelected) {
      if (this.selectedAllotmentType && this.selectedAllotmentType.value === 'HARD' && this.endDate) {
        let endDateFormat = formatDate(
          subDays(this.endDate, 1),
          'dd.MM.YYY',
          'en-us'
        );
  
        endDate = moment(endDateFormat, 'DD.MM.YYYY');
        
      } else {
        endDate = moment(this.endDate, 'DD.MM.YYYY');
      }
      const selectedDay:any = hotelSelected.weekDays;
      while (date.isSameOrBefore(endDate, 'day')) {
        const softCheckDay = this.checkCheckedDay(date.toDate().getDay(), selectedDay);
        let existingDate: any;
        if (hotelSelected && hotelSelected.hotelAllotmentDates) {
          existingDate = hotelSelected.hotelAllotmentDates.find((d: any) => {
            return moment(d.date).isSame(date, 'day');
          });
        }
        if (existingDate && this.hotelActived.allotmentType === 'SOFT') {
          if(softCheckDay) {
            let tmpDateRange = this.hotelAllotmentDate(date.toDate(), existingDate.availability);
            tmpDateRange.checked = softCheckDay;
            dayRange.push(tmpDateRange);
          }
        } else {
          if (this.hotelActived.allotmentType === 'HARD') {
            if (hotelSelected) {
              dayRange.push(
                this.hotelAllotmentDate(
                  date.toDate(),
                  JSON.stringify(hotelSelected.hotelAllotmentDates) !== '[]'
                    ? hotelSelected.hotelAllotmentDates[0].availability
                    : 1
                )
              );
              this.getAvailabilitiesForHotel(this.hotelActived);
            } else {
              dayRange.push(this.hotelAllotmentDate(date.toDate(), 1));
            }
          } else {
            let tmpDateRange = this.hotelAllotmentDate(date.toDate(), 1);
            tmpDateRange.checked = softCheckDay;
            dayRange.push(tmpDateRange);
          }
        }
        date.add(1, 'd');
      }
      this.hotelActived.hotelAllotmentDates = dayRange.slice();
      return dayRange.slice();
    }
    return [];
  }
  hotelAllotmentDate(date: any, availability: any): any {
    let hotelAllotmentDate: any = {};
    hotelAllotmentDate.date = new Date(date.getTime());
    hotelAllotmentDate.availability = availability;
    hotelAllotmentDate.year = hotelAllotmentDate.date.getFullYear();
    hotelAllotmentDate.time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + (date.getFullYear());
    hotelAllotmentDate.day = toWeekDayName(date.getDay());
    hotelAllotmentDate.checked = true;

    function toWeekDayName(date: any) {
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return days[date];
    }
    return hotelAllotmentDate;
  }
  selectRooms(): void {
    this.isShowSelectRooms = !this.isShowSelectRooms;
    if (this.isShowSelectRooms) {
      this.getSelectedRoom(this.hotelActived.hotelId);
    }
  }
  goToVoucher(): void {
    let allomentParams: any = {
      allotmentId: this.currentAllotment.id,
      isRedirectFromHA: true,
      giftcard: null,
    };
    this.router.navigate(['/voucher'], { queryParams: allomentParams });
  }
  goToUsage(): void {
    let usageParams: any = {
      hotelId: this.hotelActived.hotelId,
      startDate: moment(this.hotelActived.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.hotelActived.endDate).format('YYYY-MM-DD'),
      allotmentId: this.currentAllotment.id,
    };
    this.router.navigate(['/usage'], { queryParams: usageParams });
  }
  activeHotelTab(hotelActive: any): void {
    this.isExpiryDate = false;
    const findHotelIndex = this.hotels.findIndex((item:any) => item.label === hotelActive.label);
    if(findHotelIndex > -1) {
      const ht = this.hotels[findHotelIndex];
      ht.active = true;
      this.hotelActived = ht;
      this.hotels.forEach((item: any) => {
        if(item.label !== ht.label) {
          item.active = false;
        }
      });
      if(this.selectedAllotmentType) {
        if(this.selectedAllotmentType.value === "SOFT") {
          this.applyRangeStartDate = ht.startDate;
          this.applyRangeEndDate = ht.endDate;
          this.transferDateForDatepicker();
          setTimeout(() => {
            this.updateDates();
          }, 100);
        }
        if(this.selectedAllotmentType.value === "HARD") {
          this.applyRangeStartDate = ht.startDate;
          this.applyRangeEndDate = ht.endDate;
          this.expiryDate = ht.expiryDate;
          this.getRooms(ht);
          this.transferDateForDatepicker();
          this.applyRangeAmountOfRoom = ht.hotelAllotmentDates[0].availability;
        }
      }
    }
  }
  transferDateForDatepicker(): any {
    // Update start date end date for datepicker
    this.hotelActived = this.hotels.filter((hotel: any) => {
      return hotel.active;
    })[0];
    this.startDate = this.hotelActived.startDate;
    this.endDate = this.hotelActived.endDate;
    
  }
  transferDateForHotelActived(): any {
    this.hotelActived.startDate = this.startDate;
    this.hotelActived.endDate = this.endDate;
    this.updateDates();
  }
  dateChange(value: Date | undefined, type: string) {
    if (this.hotels.length === 0) {
      return;
    }
    switch (type) {
      case 'startDate':
        {
          let tmpStartDate = value;
          let tmpEndDate = _.cloneDeep(this.endDate);
          if(tmpStartDate && tmpEndDate && isAfter(tmpStartDate, tmpEndDate)) {
            if(this.hotelActived.allotmentType === 'HARD') {
              tmpEndDate = addDays(new Date(tmpStartDate), 1);
            } else {
              tmpEndDate = cloneDeep(tmpStartDate);
            }
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
          this.applyRangeStartDate = tmpStartDate;
          this.applyRangeEndDate = tmpEndDate;
          this.transferDateForHotelActived();
          if (this.hotelActived.allotmentType === "HARD") {
            this.getAvailabilitiesForHotel(this.hotelActived);
          }
          this.triggerChanges();
        }
        break;
      case 'endDate':
        {
          let tmpStartDate = _.cloneDeep(this.startDate);
          let tmpEndDate = value;
          if(tmpStartDate && tmpEndDate && isBefore(tmpEndDate, tmpStartDate)) {
            if(this.hotelActived.allotmentType === 'HARD') {
              tmpStartDate = subDays(new Date(tmpEndDate), 1);
            } else {
              tmpEndDate = cloneDeep(tmpStartDate);
            }
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
          this.applyRangeStartDate = tmpStartDate;
          this.applyRangeEndDate = tmpEndDate;
          this.transferDateForHotelActived();
          if (this.hotelActived.allotmentType === "HARD") {
            this.getAvailabilitiesForHotel(this.hotelActived);
          }
          this.triggerChanges();
        }
        break;
      case 'rangeStartDate':
        {
          let tmpStartDate = value;
          let tmpEndDate = _.cloneDeep(this.endDate);
          if(tmpStartDate && tmpEndDate && isAfter(tmpStartDate, tmpEndDate)) {
            tmpEndDate = addDays(new Date(tmpStartDate), 1);
          }
          this.applyRangeStartDate = tmpStartDate;
          this.applyRangeEndDate = tmpEndDate;
        }
        break;
      case 'rangeEndDate':
        {
          let tmpStartDate = _.cloneDeep(this.startDate);
          let tmpEndDate = value;
          if(tmpStartDate && tmpEndDate && isBefore(tmpEndDate, tmpStartDate)) {
            tmpStartDate = subDays(new Date(tmpEndDate), 1);
          }
          this.applyRangeStartDate = tmpStartDate;
          this.applyRangeEndDate = tmpEndDate;
        }
        break;
    }
  }
  setDateFromDropdown(value:Date) {
    this.expiryDate = value;
  }
  updateExpiryDate(value: Date | undefined) {
    if(value) {
      // Update for the actived hotel to hotels
      this.hotelActived.expiryDate = value;
      this.hotelActived.expiryTime = formatDate(
        value,
        'HH:mm',
        CONSTANT.EN_US
      );
      let foundIndex = this.hotels.findIndex(
        (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
      );
      this.hotels[foundIndex] = this.hotelActived;
      this.expiryDate = value;
    }
  }
  disableSearch() {
    this.isFormValid = false;
    this.isRangeValid = false;
  }
  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  applyToAllHotel(): void {
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    this.hotels.forEach((hotel: any) => {
      hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
        if (
          moment(hotelAllotmentDate.date).isSameOrAfter(startDate, 'day') &&
          moment(hotelAllotmentDate.date).isSameOrBefore(endDate, 'day')
        ) {
          hotelAllotmentDate.availability = amount;
        }
      });
    });
    this.triggerChanges();
  }

  getInfoFromAllHotel(): any {
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    this.hotels.forEach((hotel: any) => {
      hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
        if (
          moment(startDate).isSameOrAfter(hotel.startDate, 'day') &&
          moment(endDate).isSameOrBefore(hotel.endDate, 'day')
        ) {
          hotelAllotmentDate.availability = amount;
        }
      });
    });
  }

  applyAmountOfRoomForMultipleDay(isHardAllotment: any): void {
    if (isHardAllotment) {
      this.applyRangeStartDate = this.startDate;
      this.applyRangeEndDate = this.endDate;
    }
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    if (
      this.currentAllotment.allotmentType === 'SOFT' ||
      (this.currentAllotment.allotmentType === 'HARD' && !isHardAllotment)
    ) {
      this.applyAmountOfRoom(startDate, endDate, amount);
    }
    if (this.currentAllotment.allotmentType === 'HARD' && isHardAllotment) {
      // If an amount of rooms not change
      if (
        this.applyRangeAmountOfRoomInit < amount ||
        this.applyRangeAmountOfRoomInit > amount
      ) {
        this.showNotificationWhenSelectMoreRoom();
        return;
      } else {
        // If an amount of rooms not change
        this.applyAmountOfRoom(startDate, endDate, amount);
      }
    }
    this.triggerChanges();
  }
  showNotificationWhenSelectMoreRoom(): any {
    this.openAmountOfRoomAlert();
  }
  applyAmountOfRoom(startDate: any, endDate: any, amount: any): any {
    // Keep the amount when choosing another hotel
    this.hotels.forEach((hotel: any) => {
      if (hotel.active) {
        hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
          if (
            moment(hotelAllotmentDate.date).isSameOrAfter(startDate, 'day') &&
            moment(hotelAllotmentDate.date).isSameOrBefore(endDate, 'day')
          ) {
            hotelAllotmentDate.availability = amount;
          }
        });
        this.hotelActived = hotel;
      }
    });
  }
  autoActiveHotelTab(currentHotel: any): any {
    // When choosing multi hotels then show error message should be active hotel tab automacally
    this.hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        hotel.active = false;
      }
      if (hotel.hotelId === currentHotel.hotelId) {
        hotel.active = true;
      }
    });
  }
  checkValidStartDate(): Boolean {
    let hasError: boolean = false;
    this.hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let today = moment(new Date()).format('YYYY-MM-DD');
        if (moment(hotel.startDate).isBefore(moment(today))) {
          this.toast.error(
            'Precondition failed due to the start date allotment started before ' +
              moment(new Date()).format('DD.MM.YYYY') +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        }
      }
      return hasError;
    });
    return hasError;
  }
  checkValidExpiryDateTime(): any {
    let hasError = false;
    let numberOfTimesWithoutError = 0;
    let hotels = cloneDeep(this.hotels);
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let startDate = moment(hotel.startDate).format('YYYY-MM-DD');
        let expiryDate = moment(hotel.expiryDate).format('YYYY-MM-DD');
        let today = moment(new Date()).format('YYYY-MM-DD');

        if (startDate === expiryDate) {
          if (
            Number(hotel.expiryTime.slice(0, 2)) >=
            Number(hotel.defaultCheckInTime.slice(0, 2))
          ) {
            this.isExpiryTime = true;
            this.toast.error(
              'Precondition failed due to the expiry time allotment started after ' +
                hotel.defaultCheckInTime.slice(0, 5) +
                ' in ' +
                hotel.label,
              'Error!'
            );
            hasError = true;
            this.autoActiveHotelTab(hotel);
            return hasError;
          }
        } else if (moment(hotel.expiryDate).isAfter(moment(hotel.startDate))) {
          this.isExpiryDate = true;
          this.toast.error(
            'Precondition failed due to the expiry date allotment started after ' +
              moment(hotel.startDate).format('DD.MM.YYYY') +
              ' ' +
              hotel.defaultCheckInTime.slice(0, 5) +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        } else if (moment(hotel.expiryDate).isBefore(moment(new Date()))) {
          if (today === expiryDate) return hasError;

          this.isExpiryDate = true;
          this.toast.error(
            'Precondition failed due to the expiry date allotment started before ' +
              moment(new Date()).format('DD.MM.YYYY') +
              ' in ' +
              hotel.label,
            'Error!'
          );
          hasError = true;
          this.autoActiveHotelTab(hotel);
          return hasError;
        } else {
          numberOfTimesWithoutError = numberOfTimesWithoutError + 1;
        }
      }
      return hasError;
    });
    let length = hotels.filter((hotel: any) => {
      return hotel.selected == true;
    }).length;
    if (numberOfTimesWithoutError === length) {
      hasError = false;
    }
    return hasError;
  }
  validateAmountOfRooms(amountOfRooms: any): any {
    if (this.hotelActived.allotmentType === 'HARD') {
      this.applyRangeAmountOfRoom = amountOfRooms;
      this.isValidateForAmountOfRooms = true;
    }
  }
  customValidateAmountOfRooms(type:string){
    if (this.hotelActived.allotmentType === 'HARD') {
      if (type==='increment'){
        this.applyRangeAmountOfRoom += 1;
        this.isValidateForAmountOfRooms = true;
        return
      }
      if(this.applyRangeAmountOfRoom===1) return;
      this.applyRangeAmountOfRoom -= 1;
      this.isValidateForAmountOfRooms = true;
    }
  }
  validateForAmountOfRooms(amountOfRooms: any): any {
    if (this.hotelActived.allotmentType === 'HARD') {
      this.applyRangeAmountOfRoom = amountOfRooms;
      if (
        amountOfRooms <= this.totalAvailableRooms &&
        typeof amountOfRooms !== 'undefined'
      ) {
        this.isValidateForAmountOfRooms = true;
        return true;
      } else {
        this.toast.error(
          'Maximum total availability rooms for multiple days: ' +
            this.totalAvailableRooms,
          'Warning'
        );
        this.isValidateForAmountOfRooms = false;
        return false;
      }
    }
  }
  updateAvailabilityForWeekdays(e: any, date: any) { 
    this.hotelActived.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
      if (hotelAllotmentDate.time === date.time) {
        hotelAllotmentDate.availability = Number(e.target.value);
      }
    });
    let foundIndexHotel = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndexHotel] = this.hotelActived;
    this.triggerChanges();
  }
  customChangeAvailabilityForWeekdays(type:string, date:any){
    this.hotelActived.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
      if (hotelAllotmentDate.time === date.time && type==='increment') {
        hotelAllotmentDate.availability += 1;
      }
      else if(hotelAllotmentDate.time === date.time && type==='decrement'){
        if(hotelAllotmentDate.availability===1) return;
        hotelAllotmentDate.availability -= 1;
      }
    });
    let foundIndexHotel = this.hotels.findIndex(
      (hotel: any) => hotel.hotelId == this.hotelActived.hotelId
    );
    this.hotels[foundIndexHotel] = this.hotelActived;
  }
  customChangeRangeAmountOfRoom(type:string){
    if(this.applyRangeAmountOfRoom<1) return this.applyRangeAmountOfRoom = 1;
    if(type==='increment'){
      this.applyRangeAmountOfRoom += 1;
      return;
    }
    if(this.applyRangeAmountOfRoom===1) return;
    this.applyRangeAmountOfRoom -= 1;
  }
  goToTopBrowser(): any {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  isInputValid(): any {
    let hasError = false;
    let hotels: any = [];

    if (this.addAllotmentForm.controls.name.value.length <= 0) {
      this.toast.error("Allotment name can't be empty.", 'Missing input');
      this.isValidName = false;
      hasError = true;
      this.goToTopBrowser();
      return;
    } else {
      this.isValidName = true;
    }
    if (this.hotelActived.allotmentType === 'HARD') {
      if (!this.checkValidateEmail()) {
        this.toast.error(
          'The contact person email field is in the wrong format.',
          'Error!'
        );
        hasError = true;
        this.goToTopBrowser();
        return;
      }
      // Check valid start date can not before today
      if (this.checkValidStartDate()) {
        hasError = true;
        return;
      }

      // Check valid for expiry date and time: allotment must be started before expiry time
      if (this.checkValidExpiryDateTime()) {
        hasError = true;
        return;
      }
      // Check validate for amount of rooms
      hasError = !this.validateForAmountOfRooms(this.applyRangeAmountOfRoom);
    }

    // Convert weekDays object to pure strings ["Mon", "Tue" ...]
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let days: any[] = [];
        hotel.weekDays.each((day: any) => {
          if (day.selected) {
            days.push(day.code);
          }
        });
        hotel.weekDays = days;
      }
    });
    hotels.forEach((hotel: any) => {
      if (hotel.weekDays.length <= 0) {
        this.toast.error(
          'No weekdays is not allow. (' + hotel.label + ')',
          'Invalid input'
        );
        hasError = true;
      }
    });
    if (hasError) {
      this.goToTopBrowser();
    }
    return !hasError;
  }
  openResetChangesModal() {
    this.openCustomModal("resetChangesAlert");
  }
  openCancelAllotmentModal() {
    this.openCustomModal("cancelAllotmentAlert");
  }
  openVoucherOverlapAlert() {
    this.openCustomModal("voucherOverlapAlert");
  }
  openAmountOfRoomAlert() {
    this.openCustomModal("roomAmountChangeAlert");
  }
  cancelAllotment(): any {
    this.closeCustomModal("cancelAllotmentAlert");
    this.openLoadingAlert("Cancelling allotment...");
    this.allotmentsService.cancelAllotment(this.currentAllotment.id).subscribe(
      (response: any) => {
        this.closeLoadingAlert();
        this.openResultAlert("success", "Success", "Allotment has been cancelled");
        this.currentAllotment.cancelled = true;
      },
      (errorResp: any) => {
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred while cancelling allotment, please try again later");
      }
    );
  }
  resetChanges() {
    this.closeCustomModal("resetChangesAlert");
    this.openLoadingAlert("Resetting changes...");
    this.initializeData(() => {
      this.closeLoadingAlert();
    })
  }
  confirmEditAllotment() {
    this.closeCustomModal("voucherOverlapAlert");
    this.disabledBtnWhileCallingAPI = true;
    this.sendEditRequest(this.allotment);
  }
  cancelUpdateAmount() {
    // Check amount and total availble rooms when selected room
    let amount = this.selectedRooms.length;
    if (amount > this.totalAvailableRooms) {
      window.location.reload();
    } else {
      this.closeCustomModal("roomAmountChangeAlert")
    }
  }
  confirmSetAmountOfRoom() {
    let startDate = moment(this.applyRangeStartDate);
    let endDate = moment(this.applyRangeEndDate);
    let amount = this.applyRangeAmountOfRoom;
    // Check validate for amount of rooms
    if (this.validateForAmountOfRooms(this.applyRangeAmountOfRoom)) {
      this.applyAmountOfRoom(startDate, endDate, amount);
    }
    this.closeCustomModal("roomAmountChangeAlert")
  }
  editAllotment(): any {
    if (!this.isInputValid()) {
      return;
    }
    this.disabledBtnWhileCallingAPI = true;
    let allotment: any = {};
    let hotels = cloneDeep(this.hotels);
    allotment.allotmentType = this.hotelActived.allotmentType;
    allotment.id = this.currentAllotment.id;
    allotment.cancelled = this.currentAllotment.cancelled;
    allotment.created = this.currentAllotment.created;
    allotment.name = this.addAllotmentForm.controls.name.value;
    allotment.description = this.addAllotmentForm.controls.description.value;
    allotment.email = this.addAllotmentForm.controls.email.value;
    allotment.hotelAllotments = [];

    // Convert weekDays object to pure strings ["Mon", "Tue" ...]
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        this.hotelActive = hotel;
        let days: any[] = [];
        hotel.weekDays.forEach((day: any) => {
          if (day.selected) {
            days.push(day.code);
          }
        });
        hotel.weekDays = days;
      }
    });

    // Optimize hotelAllotments and convert day to full day name
    hotels.forEach((hotel: any) => {
      if (hotel.selected) {
        let tempHotel: any = {
          allotmentId: hotel.allotmentId,
          hotelId: hotel.hotelId,
          dayOfWeeks: this.convertArrayToFullDayName(hotel.weekDays),
          startDate: formatDate(hotel.startDate, 'YYYY-MM-dd', CONSTANT.EN_US),
          endDate: formatDate(hotel.endDate, 'YYYY-MM-dd', CONSTANT.EN_US),
          expiryDateTime:
            this.currentAllotment.allotmentType === 'HARD'
              ? moment(hotel.expiryDate).format('YYYY-MM-DD') +
                'T' +
                hotel.expiryTime +
                ':00.000Z'
              : new Date(),
          hotelAllotmentDates: [],
        };
        let allotmentTemp = this.allotmentInit.hotelAllotments.filter(
          (hotelAllotment: any) => {
            return hotelAllotment.hotelId == hotel.hotelId;
          }
        );
        if (this.currentAllotment.allotmentType == 'HARD') {
          tempHotel.roomUsages = allotmentTemp[0].roomUsages;
          tempHotel.defaultAmountOfRooms = 0;
          if (tempHotel.hotelId == this.hotelActived.hotelId) {
            let roomlist = this.selectedRooms.map((room: any) => {
              return room.label;
            });
            tempHotel.reservedRoomLabels = roomlist;
          }
        }
        hotel.hotelAllotmentDates.forEach((hotelAllotmentDate: any) => {
          let dateIsValid = hotel.weekDays.find((weekDay: any) => {
            return (
              weekDay.toUpperCase() === hotelAllotmentDate.day.toUpperCase()
            );
          });
          if (dateIsValid || this.currentAllotment.allotmentType === 'HARD') {
            tempHotel.hotelAllotmentDates.push({
              date: moment(hotelAllotmentDate.date).format('YYYY-MM-DD'),
              availability: hotelAllotmentDate.availability,
            });
          }
        });
        allotment.hotelAllotments.push(tempHotel);
      }
    });
    // Confirm edit allotment after check overlap with voucher
    this.allotment = allotment;
    this.openLoadingAlert("Checking voucher overlap...")
    this.allotmentsService.checkOverlapWithVoucher(allotment).subscribe(
      (response: any) => {
        this.closeLoadingAlert();
        this.isExpiryDate = false;
        let noOverlappedVoucher: any = [];
        for (let key in response) {
          noOverlappedVoucher.push(key);
        }
        this.voucherOverlap = noOverlappedVoucher.join(", ");
        if (noOverlappedVoucher && noOverlappedVoucher.length <= 0) {
          this.sendEditRequest(allotment);
        } else {
          // Show the overlap with voucer modal after press SAVE button
          this.openVoucherOverlapAlert();
          this.disabledBtnWhileCallingAPI = false;
        }
      },
      (errorResp: any) => {
        this.closeLoadingAlert();
        let errorMsg = "An error occurred while checking voucher overlap.";
        if(errorResp.status !== 404) {
          if(errorResp.error.description) {
            errorMsg = errorResp.error.description;
          } else if (errorResp.error.message) {
            errorMsg = errorResp.error.message;
          }
        }
        this.openResultAlert("error", "Error", errorMsg);
        this.disabledBtnWhileCallingAPI = false;
        this.disabledBtnWhileCallingAPI = false;
      }
    );
  }
  sendEditRequest(allotment: any): any {
    this.openLoadingAlert("Saving changes...")
    this.allotmentsService.save(allotment).subscribe(
      (response: any) => {
        this.closeLoadingAlert();
        this.toast.success('Allotment has been updated', 'Success');
        setTimeout(() => {
          window.location.reload();
          this.goToTopBrowser();
          this.disabledBtnWhileCallingAPI = false;
        }, 500);
      },
      (errorResp: any) => {
        this.closeLoadingAlert();
        let errorMsg = "An error occurred while updating allotment, please try again later.";
        if (errorResp.status === 406 || errorResp.status === 412) {
          errorMsg = errorResp.error.description;
        }
        this.openResultAlert("error", "Error", errorMsg);
        this.disabledBtnWhileCallingAPI = false;
      }
    );
  }
  getSelectedRoom(hotelId: number): any {
    let selectedRoom: any = [];
    let listRoom: any = [];
    this.currentAllotment.hotelAllotments.map((hotelAllotment: any) => {
      if (hotelAllotment.hotelId == hotelId) {
        // Find roomRequestId in roomUsage
        let notRoomRequestIdInRoomUsages = [];
        let roomRequestIdInRoomUsages: any = [];
        let roomUsagesTemp = [];
        hotelAllotment.roomUsages.filter((roomUsage: any) => {
          if (roomUsage.roomRequestId) {
            roomRequestIdInRoomUsages.push(roomUsage);
          } else {
            notRoomRequestIdInRoomUsages.push(roomUsage);
          }
        });
        // With the case, a roomUsage is a reservation's HA then still show it. And vice versa
        roomRequestIdInRoomUsages.forEach((roomRequestIdInRoomUsage: any) => {
          let roomRequestIdTemp: any;
          hotelAllotment.roomUsages.forEach((roomUsage: any) => {
            if (roomRequestIdInRoomUsage.roomLabel === roomUsage.roomLabel) {
              if (roomUsage.roomRequestId) {
                if (roomRequestIdTemp) {
                  // If selected rooms has multi room request id in label
                  roomUsage.isHide = true;
                } else {
                  // Show the first room request id in the label
                  roomUsage.isHide = false;
                  roomRequestIdTemp = roomUsage.roomLabel;
                }
              } else {
                roomUsage.isHide = true;
              }
              roomUsagesTemp.push(roomUsage);
            }
          });
        });
        hotelAllotment.roomUsages.map((roomUsage: any) => {
          const findExistingRoom = selectedRoom.findIndex((room:any) => room.roomId === roomUsage.roomId);
          if(findExistingRoom === -1) {
            roomUsage.capacity = roomUsage.roomProductDetails.maxExtraBeds + roomUsage.roomProductDetails.maxGuestCount;
            roomUsage.label = roomUsage.roomLabel;
            selectedRoom.push(roomUsage);
          }
        });
        // Update list room by reservedRoomLabels attribute
        hotelAllotment.reservedRoomLabels.map((reservedRoomLabel: any) => {
          listRoom.push('Room ' + reservedRoomLabel);
        });
      }
    });
    this.updateNewRoomInAllotment(listRoom);
    return selectedRoom;
  }
  updateNewRoomInAllotment(rooms: any) {
    this.listRoomSelected = rooms.sort();
    if (!this.newRoomInAllotment) {
      this.newRoomInAllotment.push({
        hotelId: this.hotelActived.hotelId,
        listRoomSelected: this.listRoomSelected,
      });
    } else {
      let index = this.newRoomInAllotment.findIndex((item: any) => {
        return item.hotelId == this.hotelActived.hotelId;
      });
      if (index != -1) {
        this.newRoomInAllotment[index].listRoomSelected = this.listRoomSelected;
      } else {
        this.newRoomInAllotment.push({
          hotelId: this.hotelActived.hotelId,
          listRoomSelected: this.listRoomSelected,
        });
      }
    }
  }
  selectedRoomForRoomUsages(): any {
    this.currentAllotment.hotelAllotments.forEach((hotel: any) => {
      if (hotel.hotelId == this.hotelActived.hotelId) {
        hotel.roomUsages = this.selectedRooms;
      }
    });
  }
  convertArrayToFullDayName(days: any): any {
    return days.map((day: any) => {
      return this.convertToFullDayName(day);
    });
  }
  convertToFullDayName(day: any): any {
    let dayOfWeek: any = {
      MON: 'MONDAY',
      TUE: 'TUESDAY',
      WED: 'WEDNESDAY',
      THU: 'THURSDAY',
      FRI: 'FRIDAY',
      SAT: 'SATURDAY',
      SUN: 'SUNDAY',
    };
    return dayOfWeek[day.toUpperCase()];
  }
  onKeydown(e: any) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8 || e.keyCode == 190 || e.keyCode == 110)) {
        return false;
    }
  }
}
