import { Injectable } from '@angular/core';
import { HotelWebConfig } from "../@types/app";
import { jwtDecode } from "jwt-decode";
import { environment } from "src/environments/environment";
import {default as countryData} from 'src/assets/resources/country.json';
import { MenuType, DialCodeMenuType } from "../components/common/dropdown-menu/dropdown-menu.component";
import { TokenDecodedType } from '../@types/auth';

export type CountryDataType = {
  code: string,
  name: string,
  iso3: string,
  dial_code: string
}

@Injectable({
  providedIn: 'root'
})

export class UTILS {
  public static clearDropdown() {
    const container = document.querySelector('.custom-dropdown-body');
    if(container) {
      container.innerHTML = "";
    }
    return null
  }
  public static addDays(date: any, days: any) {
    let copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  public static subtractDays(date: any, days: any) {
    let copy = new Date(Number(date));
    copy.setDate(date.getDate() - days);
    return copy;
  }
  public static numberWithCommas(total: any): any {
    if (total > 100) {
      let parts = total.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})(?!\d))/g, ",");
      return parts.join(".");
    }
    return total;
  }

  public preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  //Convert date
  convertDate(date: any, type: string) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    switch (type) {
      case 'YYYY-MM-dd':
        return `${year}-${month}-${day}`;
      case 'dd.MM.YYYY':
        return `${day}.${month}.${year}`;
      case 'MM.YYYY':
        return `${month}.${year}`;
      case 'dd.MM.YYYY hh:mm:ss':
        let hour = ('0' + date.getHours()).slice(-2);
        let minute = ('0' + date.getMinutes()).slice(-2);
        let second = ('0' + date.getSeconds()).slice(-2);
        return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    }
    return;
  }

  //Convert string date
  public convertStringDate(date: any) {
    let inputDate = date.split('-');
    let newDay = ('0' + parseInt(inputDate[2])).slice(-2);
    let newMonth = ('0' + parseInt(inputDate[1])).slice(-2);
    let newYear = parseInt(inputDate[0]);
    return `${newDay}.${newMonth}.${newYear}`;
  }

  //Convert ISO date
  public convertISODate(date: any) {
    let createDate = new Date(date);
    return this.convertDate(createDate, 'dd.MM.YYYY');
  }

  public validateDate(year: any, month: any, day: any) {
    let originalDate = `${year}-${month}-${day}`;
    let newDate = new Date(originalDate);
    let convertedDate = this.convertDate(newDate, "YYYY-MM-dd");
    return originalDate === convertedDate;
  }

  public newDate(date: any) {
    return new Date(date);
  }

  public convertISOFullDate(date: any) {
    let createDate = new Date(date);
    return this.convertDate(createDate, 'dd.MM.YYYY hh:mm:ss');
  }

  public isValidSSN(ssn: string) {
    if (ssn === '' || typeof (ssn) === 'undefined' || ssn === null) {
      return true;
    }

    let letters: any = [
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'A', 'B', 'C', 'D', 'E', 'F', 'H', 'J', 'K', 'L',
      'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
      'Y'
    ];
    let centurySymbol: any = {
      '+': '18',
      '-': '19',
      'A': '20'
    };
    if (ssn.length != 11) return false;
    let day = ssn.substring(0, 2);
    let month = ssn.substring(2, 4);
    let year = ssn.substring(4, 6);
    let centuryMark = ssn.substring(6, 7);
    let individualNumber = ssn.substring(7, 10);
    let controlChar = ssn.substring(10);

    let centuryNumber = centurySymbol[centuryMark];
    if (Number.isNaN(parseInt(individualNumber))) return false;
    if (!centuryNumber) return false;
    if (!this.validateDate(centuryNumber + year, month, day)) return false;
    let modulus = parseInt(day + month + year + individualNumber) % 31;
    if (letters[modulus] !== controlChar) return false;
    return true;
  }

  public isLocalEnvironment() {
    return !environment.production;
  }

  public encodeLastUrl() {
    return encodeURIComponent(window.location.href);
  }

  // User management
  public isRoleUserManagement() {
    const jwtToken = window.localStorage.getItem("jwt");
    if (jwtToken) {
      const decodedJwt: any = jwtDecode(jwtToken);
      if (decodedJwt) {
        const role = decodedJwt.roles;
        return role === 'NELSON_MANAGER' || role === 'CLIENT_ADMIN';
      }
      return ""
    }
    return ""
  }
  public getEnvironments(tenants: any, token:string) {
    let environments: any = [];
    try {
      const user = jwtDecode<TokenDecodedType>(token);
      //Add local environment for development
      if (this.isLocalEnvironment()) {
        environments.push({
          activeUsers: 0,
          id: "12345-678910",
          issues: 0,
          managementsitehost: "http://localhost:3000",
          name: "Local MUI",
          sitehost: `http://${environment.buiLocalUrl}/en/booking`,
          type: "Development",
          hotels: []
        })
      }
      tenants.forEach((tenant: any) => {
        if (tenant.environments) {
          if (user.roles == "NELSON_MANAGER") {
            environments.push(...tenant.environments);
          }
          else {
            environments.push(...(tenant.environments.filter((e: any) => user.environmentids.indexOf(e.id) > -1)));
          }
        }
      });
      return environments;
    } catch (error) {
      return [];
    }
  }
  public getTenantsForEnvironments(tenants: any, environmentIds: any) {
    var tenantIds: any = [];
    environmentIds.forEach((id: string) => {
      var tenantId = tenants.filter((tenant: any) => tenant.environments.filter((env: any) => env.id == id).length > 0)[0]?.id;
      if (tenantIds.indexOf(tenantId) < 0) {
        tenantIds.push(tenantId);
      }
    });
    return tenantIds.join(',');
  }
  public getTenantForEnvironment(tenants: any, environment: any) {
    return tenants.filter((tenant: any) => tenant.environments.filter((e: any) => e.id == environment.id).length > 0)[0];
  }
  public checkObjectIsNotEmpty(object: Object) {
    return Object.keys(object).length;
  }
  public hotelDisplay(hotelConfig: HotelWebConfig, hotelList: any, hotelId: any) {
    if (hotelId === "All") {
      return "All";
    } else {
      const findHotel = hotelList.find((hotel: any) => hotel.hotelId === Number(hotelId))
      if (findHotel) {
        return hotelConfig.MUIfeature.useHotelCode ? findHotel.label : findHotel.name;
      } else {
        return "";
      }
    }
  }
  public hotelDisplayByLabel(hotelConfig: HotelWebConfig, hotelList: any, hotelLabel: any) {
    if (hotelLabel === "All") {
      return "All";
    } else {
      const findHotel = hotelList.find((hotel: any) => hotel.label === hotelLabel)
      if (findHotel) {
        return hotelConfig.MUIfeature.useHotelCode ? findHotel.label : findHotel.name;
      } else {
        return "";
      }
    }
  }
  public hotelDisplayByHotel(hotelConfig: HotelWebConfig, hotel: any) {
    if(hotel) {
      return hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name;
    }
    return ""
  }
  public getCurrency(currency: string) {
    if (currency) {
      const extractPrice = new Intl.NumberFormat("en-US", { style: 'currency', currency: currency });
      return extractPrice.format(1).split("1")[0]
    } else {
      return ""
    }
  }
  public showRoomType(roomType: any) {
    switch (roomType) {
      case "SINGLE":
        return "Single";
      case "STANDARD":
        return "Standard";
      case "SINGLE_TV_LESS":
        return "Single, no TV";
      case "STANDARD_TV_LESS":
        return "Standard, no TV";
      case "CELL_ROOM":
        return "Cell room";
      case "CELL_ROOM_SINGLE":
        return "Single Cell room";
      case "STANDARD_SHARED_BATHROOM":
        return "Standard with shared bathroom";
      case "DOUBLE":
        return "Double";
      case "WINDOWLESS":
        return "Windowless";
      case "SUITE":
        return "Suite";
      case "SUPERIOR":
        return "Superior";
      case "SAMEDATE_ECI_FLEX":
        return "Same date ECI Flex";
      default:
        return roomType;
    }
  }
  public showRoomTitle(roomName: any) {
    switch (roomName) {
      case "Double TV-Less":
        return "Double, no TV";
      case "Triple TV-Less":
      case "Tripple TV-Less":
        return "Triple, no TV";
      case "Family TV-Less":
        return "Family, no TV";
      default:
        return roomName;
    }
  }
  public showReservationStatus(status: string) {
    switch (status) {
      case "CONFIRMED":
        return "Confirmed";
      case "PENDING_CONFIRMATION":
        return "Confirmation pending";
      case "CANCELLED":
        return "Cancelled";
      case "BLOCKED":
        return "Blocked";
      case "INITIAL":
        return "Error";
      default:
        return status;
    }
  }
  public roundDown(num:number, fixed:number) {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    const rounded = num.toString().match(re);
    if(rounded) {
      return rounded[0];
    } else {
      return num
    }
  }
  public getCountryListForMenu() {
    const countries:CountryDataType[] = countryData
    return countries.map((country) => {
      let newCountry:MenuType = { value: country.iso3, label: country.name, disabled:false };
      return newCountry;
    });
  }
  public getDialCodeListForMenu() {
    const countries:CountryDataType[] = countryData
    return countries.map((country) => {
      let newCountry:DialCodeMenuType = { value: country.iso3, label: country.name, dialCode: country.dial_code, code: country.code };
      return newCountry;
    });
  }
  public createAvatarFromName(name:string) {
    if (!name) return "";
    const words = name.trim().split(/\s+/);
    const firstLetter = words[0].charAt(0).toUpperCase();
    const secondLetter = words[1]?.charAt(0).toUpperCase() || "";
    return firstLetter + secondLetter;
  }
  public getProductName(name: string) {
    switch (name) {
      case "Single TV-Less":
        return "Single, no TV";
      case "Double TV-Less":
        return "Double, no TV";
      case "Triple TV-Less":
      case "Tripple TV-Less":
        return "Triple, no TV";
      case "Family TV-Less":
        return "Family, no TV";
      case "SameDayECIFlex_15":
        return "Arrive at 15:00";
      case "SameDayECIFlex_14":
        return "Arrive at 14:00";
      case "SameDayECIFlex_13":
        return "Arrive at 13:00";
      case "SameDayECIFlex_12":
        return "Arrive at 12:00";
      case "SameDayECIFlex_11":
        return "Arrive at 11:00";
      case "SameDayECIFlex_10":
        return "Arrive at 10:00";
      case "SameDayECIFlex_9":
        return "Arrive at 09:00";
      case "SameDayECIFlex_8":
        return "Arrive at 08:00";
      case "SameDayECIFlex_7":
        return "Arrive at 07:00";
      case "SameDayECIFlex_6":
        return "Arrive at 06:00";
      default:
        return name;
    }
  }

}