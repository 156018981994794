import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotelType } from 'src/app/@types/app';
import { ReservationsService } from 'src/app/services/reservations.service';

@Component({
  selector: 'app-internal-tools-mui',
  templateUrl: './internal-tools-mui.component.html',
  styleUrls: ['./internal-tools-mui.component.sass']
})
export class InternalToolsMuiComponent implements OnInit {

  constructor(
    private router: Router,
    private reservationsService: ReservationsService,
  ) { }

  hotels: HotelType[] = [];

  ngOnInit(): void {
    this.reservationsService.getHotels().subscribe(data => {
      this.hotels = data;
    });
  }
  routeToEditCurrentUser() {
    this.router.navigate(['/users'], {queryParams: {editCurrentUser: true}});
  }

}
