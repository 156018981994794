import { Component, Input, OnInit } from '@angular/core';
import { ActivityItemType } from '../activity-log-item.component';

@Component({
  selector: 'activity-item-paragraph',
  templateUrl: './activity-item-paragraph.component.html',
  styleUrl: './activity-item-paragraph.component.sass'
})
export class ActivityItemParagraphComponent {
  @Input() content:any = null;
  @Input() contentArray:boolean = false;
}
