<div
  [ngClass]="mainMenuClasses"
  #parentDropdown
>
  @if (checkCompatibleMenuUI('mui-menu')) {
    <button
      #menuButton
      type="button"
      [ngClass]="{'disabled': disableMenu, 'loading': menuLoading}"
      class="btn dropdown-toggle"
      [style]="{fontSize: buttonFontSize}"
    >
      <!-- General menu UI -->
      @if (menuList.length) {
        <div class="button-container" (click)="toggleMenu()">
          <div class="button-title">{{selectedItem ? selectedItem.label : emptyMenuText}}</div>
          <div class="button-arrow"><fa-icon type="solid" icon="chevron-down"></fa-icon></div>
        </div>
      }
      <!-- Dial code UI -->
      @if (selectedDialCodeItem) {
        <div class="dial-code-item" (click)="toggleMenu()">
          <div class="item-dial">+{{selectedDialCodeItem.dialCode}}</div>
          <div class="item-label"><span class="mobile-hide">{{ selectedDialCodeItem.label }}</span></div>
          <div class="button-arrow">
            <fa-icon type="solid" icon="chevron-down"></fa-icon>
          </div>
        </div>
      }
      <!-- Filter UI -->
      @if(filterList.length) {
        <div class="button-container" (click)="triggerMenu(true)">
          @if(compactInfo) {
            <div class="button-title">{{filterCompactInfo}}</div>
          } @else {
            <div class="button-title filter">
              @for(filter of filterList; track filter.value; let i = $index) {
                @if(filter.checked) {
                  <div class="filter-content">
                    <div class="filter-item">
                      <div class="filter-label">{{filter.label}}</div>
                      <div class="filter-remove" (click)="removeFilter(i, $event)">
                        <fa-icon type="solid" icon="xmark"></fa-icon>
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          }
          <div class="button-arrow"><fa-icon type="solid" icon="chevron-down"></fa-icon></div>
        </div>
      }
      <!-- General menu UI -->
      @if (menuType === "custom") {
        <div class="button-container" (click)="toggleMenu()">
          <div class="button-title">{{menuButtonValue}}</div>
          <div class="button-arrow"><fa-icon type="solid" icon="chevron-down"></fa-icon></div>
        </div>
      }
    </button>
  }
  @if (checkCompatibleMenuUI('menu-action')) {
    <button
      #menuButton
      class="btn btn-green toggle-btn"
      [ngClass]="{'icon-only': !buttonTitle}"
      [disabled]="disableMenu"
      (click)="toggleMenu()"
    >
      <fa-icon [type]="buttonIconType" [icon]="buttonIcon"></fa-icon>{{buttonTitle}}
    </button>
  }
  @if (checkCompatibleMenuUI('menu-timepicker')) {
    <button
      #menuButton
      type="button"
      [ngClass]="{'disabled': disableMenu}"
      class="btn dropdown-toggle"
      [style]="{fontSize: buttonFontSize}"
    >
      <div class="button-container datepicker">
        <div class="button-icon"><fa-icon type="regular" icon="clock"></fa-icon></div>
        <div class="button-title" (click)="toggleMenu()">{{displayCurrentTime}}</div>
      </div>
    </button>
  }
  @if (checkCompatibleMenuUI('menu-datepicker')) {
    <button
      #menuButton
      type="button"
      [ngClass]="{'disabled': disableMenu, 'active': menuOpened}"
      class="btn dropdown-toggle"
      [class]="dateBtnStyle"
      [style]="{fontSize: buttonFontSize}"
    >
      <div class="button-container datepicker">
        <div class="button-icon" (click)="triggerMenu(true)"><fa-icon type="regular" icon="calendar-days"></fa-icon></div>
        <div class="button-input" (click)="triggerMenu(true)">
          <input
            [value]="displayCurrentDate"
            (input)="dateInputChange($event)"
            (blur)="dateInputBlur($event)"
            (keydown)="dateInputKeyDown($event)"
          />
        </div>
        <!-- <div class="button-title" (click)="toggleMenu()">{{displayCurrentDate}}</div> -->
        @if(allowEmptyDate) {
          <div class="button-clear">
            <button type="button" (click)="clearDate()">
              <fa-icon type="solid" icon="xmark" />
            </button>
          </div>
        }
      </div>
    </button>
  }
</div>
<div
  class="custom-dropdown-menu"
  role="menu"
  [class]="alignment + '-align'"
  [ngClass]="{
    'custom-show': menuOpened,
    'action-dropdown-menu': checkCompatibleMenuUI('menu-action'),
    'timepicker-dropdown-menu': checkCompatibleMenuUI('menu-timepicker'),
    'datepicker-dropdown-menu': checkCompatibleMenuUI('menu-datepicker')
  }"
  [style]="{
    minWidth: minWidth + 'px',
    width: menuWidth + 'px',
    top: menuOriginTop + 'px',
    left: menuOriginLeft + 'px',
    marginTop: offsetTop > 0 ? 'calc(-' + offsetTop +'px - env(safe-area-inset-bottom))' : offsetTop + 'px'
  }"
  #customDropdown
  (click)="checkPropagation($event)"
>
  <div
    class="menu-content"
    [style]="{
      transformOrigin: menuOrigin + ' ' + (offsetTop > 0 ? (offsetTop - (btnHeight ? btnHeight/2 : 0)) : 0) + 'px',
      transform: 'scaleX('+menuScaleX+') scaleY('+menuScaleY+')'
    }"
  >
    <div class="content-main">
      @if (checkCompatibleMenuUI('menu-action')) {
        <div class="custom-action-header">
          {{actionTitle}}
        </div>
        <!-- @if (requireLoading && menuLoading) {
          <div class="menu-item-loading">
            <div class="loading-spinner"></div>
          </div>
        } -->
        <div class="custom-action-body">
          @for (item of menuActionList; track item.value) {
            @if (item.show) {
              <div class="body-item" (click)="selectMenuItem(item)">
                <div class="item-container">
                  <div class="item-icon">
                    <fa-icon [type]="item.iconType" [icon]="item.icon" width="35"></fa-icon>
                  </div>
                  <div class="item-title">
                    {{item.title}}
                    <div class="item-desc">{{item.desc}}</div>
                  </div>
                </div>
              </div>
            }
          }
        </div>
      }

      @if (checkCompatibleMenuUI('mui-menu')) {
        @if (searchable) {
          <div class="menu-search">
            <input type="text" name="custom-menu-search" class="custom-input form-control" placeholder="Search item" [(ngModel)]="searchKeyword" (ngModelChange)="searchListChange()">
          </div>
        }
        <!-- @if (requireLoading && menuLoading) {
          <div class="menu-item-loading">
            <div class="loading-spinner"></div>
          </div>
        } -->
        <div class="menu-item-scroll" [attr.id]="'select-menu-' + menuId">
          @if (menuList.length) {
            @for (item of menuListFiltered; track item) {
              <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedItem && selectedItem.value === item.value, 'disabled': item.disabled}" [attr.id]="menuId + item.value">
                {{ item.label }}
              </div>
            }
          }
          @if (menuDialCodeList.length) {
            @for (item of menuDialCodeListFiltered; track item) {
              <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedDialCodeItem && selectedDialCodeItem.value === item.value}" [attr.id]="menuId + item.value">
                <div class="dial-code-item">
                  <div class="item-dial">+{{item.dialCode}}</div>
                  <div class="item-label">{{ item.label }}</div>
                </div>
              </div>
            }
          }
          @if(filterList.length) {
            @if(allOption) {
              <div class="menu-item" [attr.id]="menuId + 'clearAll'" (click)="selectAllFilters()">
                All
              </div>
            } @else {
              <div class="menu-item" [attr.id]="menuId + 'clearAll'" (click)="clearAllFilters()">
                Clear all
              </div>
            }
            @for (item of filterList; track item.value) {
              <div class="menu-item filter-item" [attr.id]="menuId + item.value">
                <div class="normal-checkbox" [ngClass]="{disabled: checkFilterParentUnchecked(item.parentValue), child: hasChildValue && item.parentValue}">
                  <input type="checkbox" [(ngModel)]="item.checked" (ngModelChange)="handleSelectedFilter(item)">
                  <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
                  <div class="checkbox-label">{{item.label}}</div>
                </div>
              </div>
            }
          }
          @if(menuType === "custom") {
            <ng-content></ng-content>
          }
        </div>
      }

      @if (checkCompatibleMenuUI('menu-timepicker')) {
        <timepicker [(ngModel)]="currentDate" [showMeridian]="false" [hourStep]="1" [minuteStep]="1" (ngModelChange)="timepickerChange()"></timepicker>
      }
      @if (checkCompatibleMenuUI('menu-datepicker')) {
        <div style="height: 305px;">
          @if(menuLoaded) {
            <bs-datepicker-inline
              [bsValue]="currentDate"
              [bsConfig]="{
                customTodayClass:'datepicker-today',
                showWeekNumbers: false,
                containerClass: 'theme-nelson',
                minMode: minMode
              }"
              [minDate]="dateMinDate"
              [maxDate]="dateMaxDate"
              [dateCustomClasses]="datepickerCustom"
              (bsValueChange)="datepickerChange($event)"
            ></bs-datepicker-inline>
          }
        </div>
      }
    </div>
  </div>
</div>