import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.sass'
})
export class BackButtonComponent {
  @Input() targetUrl?:string;
  @Input() buttonTitle:string = "Back";

  constructor(private location: Location, private router: Router) {}

  goBack() {
    if(this.targetUrl) {
      this.router.navigate([this.targetUrl])
    } else {
      this.location.back();
    }
  }
}
