import { environment } from "src/environments/environment";
import { ActivityGroupType } from "../components/reservations/activity-log-2/activity-log-2.component";

export type RuleValueConvertType = {
  ruleCodeId: string,
  ruleName: string,
  ruleBaseValueName: string
}

export class CONSTANT {
  public static DATE_FORMAT = 'YYYY-MM-DD';
  public static ONE_DAY_MS = 86400000;
  public static EN_US = "en-us";
  public static datepickerConfig = {
    containerClass: 'theme-red',
    isAnimated: true,
    dateInputFormat: 'DD.MM.YYYY'
  }
  public static LIMITOFSEARCH = 30;
  public static LIMIT_SEARCH_RESERVATION = 10;
  public static SALO_URL = "https://test-salo.bookn.io";
  public static PATH_NAME_BOOKING = "/en/booking/#";
  public static LOCALHOST_URL = "http://" + environment.buiLocalUrl + CONSTANT.PATH_NAME_BOOKING;

  public static PRICING_RULE_NAME:RuleValueConvertType[] = [
    {
      ruleCodeId: "SINGLE",
      ruleName: "Single",
      ruleBaseValueName: "Standard"
    },
    {
      ruleCodeId: "MEMBER",
      ruleName: "Member",
      ruleBaseValueName: "STG&SGL"
    },
    {
      ruleCodeId: "BOOKINGCOM",
      ruleName: "Booking.com",
      ruleBaseValueName: "selectedRoom"
    },
    {
      ruleCodeId: "EXPEDIA",
      ruleName: "Expedia",
      ruleBaseValueName: "selectedRoom"
    }
  ]
  
  //GROUP-BOOKING
  public static GROUP_BOOKING = "/group-booking";
  public static GROUP_BOOKING_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.GROUP_BOOKING;
  public static GROUP_BOOKING_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.GROUP_BOOKING;

  //MANAGE-BOOKING
  public static MANAGE_BOOKING = "/manage-booking";
  public static MANAGE_BOOKING_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.MANAGE_BOOKING;
  public static MANAGE_BOOKING_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.MANAGE_BOOKING;

  //CONFIRMATION PAGE
  public static CONFIRMATION = "/confirmation";
  public static CONFIRMATION_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.CONFIRMATION;
  public static CONFIRMATION_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.CONFIRMATION;

  //CUSTOM-SELECT-ROOM
  public static CUSTOM_SELECT_ROOM = "/customSelectRoom";
  public static CUSTOM_SELECT_ROOM_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.CUSTOM_SELECT_ROOM;
  public static CUSTOM_SELECT_ROOM_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.CUSTOM_SELECT_ROOM;


  public static ROLE: any = "";
  public static SITEHOST = '';
  public static URL_ORIGIN = '';
  public static NORMAL_CHECK_IN = 16;
  public static NORMAL_CHECK_OUT = 12;
  public static REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static WEEKDAYS = [
    { code: "MON", name: 'Monday', selected: true },
    { code: "TUE", name: 'Tueday', selected: true },
    { code: "WED", name: 'Wednesday', selected: true },
    { code: "THU", name: 'Thurday', selected: true },
    { code: "FRI", name: 'Friday', selected: true },
    { code: "SAT", name: 'Saturday', selected: true },
    { code: "SUN", name: 'Sunday', selected: true }
  ];
  public static CAPACITIES = [2,3,4];
  public static NEW_SESSION = "newSession=true";
  public static WEEKDAYS_UPDATE = [{
    code: "MON",
    selected: true
    },
    {
      code: "TUE",
      selected: true
    },
    {
      code: "WED",
      selected: true
    },
    {
      code: "THU",
      selected: true
    },
    {
      code: "FRI",
      selected: true
    },
    {
      code: "SAT",
      selected: true
    },
    {
      code: "SUN",
      selected: true
    }
  ];
  public static WEEKDAYS_TEMP = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  public static ALLOTMENT_TYPES = [
    {name: "Soft", value: 'SOFT'},
    {name: "Hard", value: 'HARD'}
  ];
  public static REPORT_TYPES = [
    {
      label: 'Breakfast Report',
      urlParam: 'breakfast',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'Cleaning Report',
      urlParam: 'cleaning-list',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE', 'CLEANER']
    },
    {
      label: 'Daily Availability Report',
      urlParam: 'daily-availability',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE', 'REVENUE_MANAGER']
    },
    {
      label: 'Guests Report',
      urlParam: 'guests',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'Guests Mobile Numbers Report',
      urlParam: 'guests-mobile',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'On The Books Report',
      urlParam: 'on-the-books',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'On The Books by Reservation Report',
      urlParam: 'on-the-books-by-reservation',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Revenue by Customer Segment',
      urlParam: 'revenue-by-customer',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Revenue Report',
      urlParam: 'new-revenue',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales by Channel Report',
      urlParam: 'sales',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
	{
      label: 'Sales Report by Payment type',
      urlParam: 'payments',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Summary Report',
      urlParam: 'financial-summary',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Forecast - Daily',
      urlParam: 'sales-forecast-daily',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Forecast per Segment - Daily',
      urlParam: 'sales-forecast-per-segment-daily',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Unpaid Expedia Reservations Report',
      urlParam: 'unpaid-expedia-reservations',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN']
    },
    {
      label: 'Unpaid Reservations Report',
      urlParam: 'unpaid-reservations',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN']
    }
  ];
  public static CLEANING_REPORT_FOR_CLEARNER_ACCOUNT = [
    {
      label: 'Cleaning Report',
      urlParam: 'cleaning-list',
    }
  ];
  public static TIMEZONE_HELSINKI = "Europe/Helsinki";
  public static TIME_SECIF = [
    { name: "SameDayECIFlex_15", friendlyName: "Arrive at 15:00" },
    { name: "SameDayECIFlex_14", friendlyName: "Arrive at 14:00" },
    { name: "SameDayECIFlex_13", friendlyName: "Arrive at 13:00" },
    { name: "SameDayECIFlex_12", friendlyName: "Arrive at 12:00" },
    { name: "SameDayECIFlex_11", friendlyName: "Arrive at 11:00" },
    { name: "SameDayECIFlex_10", friendlyName: "Arrive at 10:00" },
    { name: "SameDayECIFlex_9", friendlyName: "Arrive at 09:00" },
    { name: "SameDayECIFlex_8", friendlyName: "Arrive at 08:00" },
    { name: "SameDayECIFlex_7", friendlyName: "Arrive at 07:00" },
    { name: "SameDayECIFlex_6", friendlyName: "Arrive at 06:00" },
  ];
  public static OPTIONAL_FILTER_VOUCHER = [
    {
      label: 'Discount Voucher',
      value: 'DISCOUNT_VOUCHER',
      voucherType: 'main',
      checked: true,
      enabled: true
    },

    {
      label: 'Booking period',
      value: 'BOOKING_PERIOD',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Reservation period',
      value: 'RESERVATION_PERIOD',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Voucher name',
      value: 'VOUCHER_NAME',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Voucher description',
      value: 'VOUCHER_DESCRIPTION',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Monetary Voucher',
      value: 'MONETARY_VOUCHER',
      voucherType: 'main',
      checked: true,
      enabled: true
    },
    {
      label: 'Monetary voucher code',
      value: 'VOUCHER_CODE',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Customer',
      value: 'CUSTOMER_NAME',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Email',
      value: 'EMAIL',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
  ];
  public static VOUCHER_FILTER_LIST = [
    {
      label: 'Discount Voucher',
      value: 'DISCOUNT_VOUCHER',
      parentValue: undefined,
      checked: true
    },

    {
      label: 'Booking period',
      value: 'BOOKING_PERIOD',
      parentValue: 'DISCOUNT_VOUCHER',
      checked: true
    },
    {
      label: 'Reservation period',
      value: 'RESERVATION_PERIOD',
      parentValue: 'DISCOUNT_VOUCHER',
      checked: true
    },
    {
      label: 'Voucher name',
      value: 'VOUCHER_NAME',
      parentValue: 'DISCOUNT_VOUCHER',
      checked: true
    },
    {
      label: 'Voucher description',
      value: 'VOUCHER_DESCRIPTION',
      parentValue: 'DISCOUNT_VOUCHER',
      checked: true
    },
    {
      label: 'Monetary Voucher',
      value: 'MONETARY_VOUCHER',
      parentValue: undefined,
      checked: true
    },
    {
      label: 'Monetary voucher code',
      value: 'VOUCHER_CODE',
      parentValue: 'MONETARY_VOUCHER',
      checked: true
    },
    {
      label: 'Customer',
      value: 'CUSTOMER_NAME',
      parentValue: 'MONETARY_VOUCHER',
      checked: true
    },
    {
      label: 'Email',
      value: 'EMAIL',
      parentValue: 'MONETARY_VOUCHER',
      checked: true
    },
  ];
  public static CHANNEL_BOOKING = [
    {'value': 'NELSON', 'label': 'Web'},
    {'value': 'BOOKINGCOM', 'label': 'Booking.com'},
    {'value': 'EXPEDIA', 'label': 'Expedia'},
    {'value': 'LEGACY', 'label': 'Legacy'},
    {'value': 'MOBILEAPP', 'label': 'MobileApp'},
  ];
  public static OTA_CHANNEL = ["BOOKINGCOM", "EXPEDIA"];
  public static FILTER_RESERVATIONS = [
    {
      label: 'Full name',
      value: 'FULL_NAME',
      checked: true,
    },
    {
      label: 'First name',
      value: 'FIRST_NAME',
      checked: true,
    },
    {
      label: 'Last name',
      value: 'LAST_NAME',
      checked: true,
    },
    {
      label: 'Company name',
      value: 'ORGANIZATION',
      checked: true,
    },
    {
      label: 'Email',
      value: 'EMAIL',
      checked: true,
    },
    {
      label: 'Mobile number',
      value: 'MOBILE_NUMBER',
      checked: true,
    },
    {
      label: 'Reservation number',
      value: 'RESERVATION_NUMBER',
      checked: true,
    },
    {
      label: 'External channel reservation number',
      value: 'EXTERNAL_CHANNEL_RESERVATION_ID',
      checked: true,
    },
    {
      label: 'SSN',
      value: 'SSN',
      checked: false,
    },
    {
      label: 'Room number',
      value: 'ROOM_NUMBER',
      checked: false,
    },
    {
      label: 'Discount voucher',
      value: 'DISCOUNT_VOUCHER',
      checked: false,
    },
    {
      label: 'Monetary voucher',
      value: 'MONETARY_VOUCHER',
      checked: false,
    }
  ];
  public static FILTER_INVOICE_MANUAL = [
    {
      label: 'Full name',
      value: 'FULL_NAME',
      checked: true,
    },
    {
      label: 'First name',
      value: 'FIRST_NAME',
      checked: true,
    },
    {
      label: 'Last name',
      value: 'LAST_NAME',
      checked: true,
    },
    {
      label: 'Organization',
      value: 'ORGANIZATION',
      checked: true,
    },
    {
      label: 'Email',
      value: 'EMAIL',
      checked: true,
    },
    {
      label: 'Discount voucher',
      value: 'DISCOUNT_VOUCHER',
      checked: false,
    }
  ];
  
public static PAGE_REQUIRED_ROLES = [
  {
    pageName: 'reservations',
    friendlyName: 'Reservations',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'usage',
    friendlyName: 'Usage',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'maintenance',
    friendlyName: 'Maintenance',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
    ],
  },
  {
    pageName: 'reports',
    friendlyName: 'Reports',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'customers',
    friendlyName: 'Customers',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
    ],
  },
  {
    pageName: 'pricing',
    friendlyName: 'Pricing',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER'],
  },
  {
    pageName: 'allotments',
    friendlyName: 'Allotments',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'voucher',
    friendlyName: 'Vouchers',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'breakfast',
    friendlyName: 'Breakfast',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
  },
  {
    pageName: 'hotels',
    friendlyName: 'Settings',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
  },
  {
    pageName: 'locks',
    friendlyName: 'Locks',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
  },
  {
    pageName: 'internal-tools-mui',
    friendlyName: 'Internal tools',
    requiredRoles: ['NELSON_MANAGER'],
  }
];
  public static SEARCH_OBJECT = [
    {'value': 'CUSTOMER', 'label': 'Customer', 'checked': true},
    {'value': 'GUEST', 'label': 'Guest', 'checked': false},
  ];
  public static HOTEL_CHAIN_NAME = "OmenaHotels";
  public static EXTERNAL_CHANNEL_BOOKING = [
    {'value': 'BOOKINGCOM', 'label': 'Booking.com'},
    {'value': 'EXPEDIA', 'label': 'Expedia'}
  ];
  public static LOCALE_LANGUAGES = [
    { name: "Finnish", locale: "fi", disabled: false},
    { name: "English", locale: "en", disabled: false},
    { name: "Swedish", locale: "sv", disabled: false}
  ];
  public static SUPPORTED_LANGUAGES = [
    { label: "English", value: "en"},
    { label: "Finnish", value: "fi"},
    { label: "Swedish", value: "sv"},
    { label: "Russian", value: "ru"}
  ];
  public static DELIVERY_METHODS = [
    { name: "Electronic invoice", value: "finvoice"},
    { name: "Email", value: "email"},
  ];
  public static TERMS_OF_PAYMENT = [
    { name: "Immediately", value: 0},
    { name: "7 days net", value: 7},
    { name: "14 days net", value: 14},
    { name: "21 days net", value: 21},
    { name: "30 days net", value: 30}
   ];

  public static FILTER_LOCK = [
    {
      label: 'Door code',
      value: 'DOOR_CODE',
      checked: true,
    },
    {
      label: 'Name',
      value: 'DESCRIPTION',
      checked: false,
    },
    {
      label: 'Reservation',
      value: 'RESERVATION_CODE',
      checked: false,
    }
  ];

  public static FENNOA_INVOICE_RESERVATION_TYPE = [
    { name: "-", value: "NONE", productFennoaName: "None"},
    { name: "Balance", value: "FULL", productFennoaName: "Full"},
    { name: "Advance (VAT 10%)", value: "ADVANCE", productFennoaName: "Advance Payment (VAT 10%)"},
    { name: "Custom", value: "CUSTOM", productFennoaName: "Custom"},
    { name: "Credit invoice", value: "CREDIT_INVOICE", productFennoaName: "Credit invoice"},
  ];

  // AUTH-SERVICE
  public static AUTH_API = '/api/user/login';
  public static CONFIRM_USER_API = '/api/user/confirmuser';
  public static NEW_PASSWORD_REQUIRED_CHALLENGE = 'NEW_PASSWORD_REQUIRED';

  // USER-SERVICE
  public static LIST_USERS_API = '/api/user/listusers';
  public static USER_API = '/api/user/user';
  public static USER_ROLES_API = '/api/user/roles';
  public static RESET_PASSWORD_API = '/api/user/resetpassword';
  public static CHANGE_PASSWORD_API = '/api/user/changeuserpassword';
  public static FORGOT_PASSWORD_API = '/api/user/forgotpassword';
  public static CONFIRM_FORGOT_PASSWORD_API = '/api/user/confirmforgotpassword';

  // Activity log
  public static activityActions: {[key:string]: string} = {
    ADD_COMMENT: 'Added comment',
    UPDATE_RESERVATION: 'Updated reservation',
    MANUAL_REFUNDED: 'Manually refunded',
    CANCEL_RESERVATION: 'Cancelled reservation',
    CHANGE_CUSTOMER_NOTIFICATION_CONFIG: 'Changed customer notification config',
    SHOW_DOOR_CODE: 'Showed door code',
    RESET_DOOR_CODE: 'Reset door code',
    UPDATE_CONTACT_INFO: 'Updated customer information',
    UPDATE_CONTACT_INFO_NEW: 'Updated customer information',
    MANUAL_PAYMENT: 'Added manual payment',
    REFUND_FOR_UNSATISFIED_SERVICE: 'Refunded for unsatisfied service',
    CHANGE_ROOM: 'Changed room',
    CREATE_RESERVATION: 'Created reservation',
    CONFIRM_RESERVATION: 'Confirmed reservation',
    INVOICE_MANUAL: 'Created manual invoice',
    CREDIT_INVOICE: 'Created credit invoice',
    UPDATE_GUEST_INFO: 'Updated guest information',
    UPDATE_GUEST_INFO_NEW: 'Updated guest information',
    INVITE_GUEST: 'Invited guest',
    REMOVE_GUEST: 'Removed guest',
    COMPLETE_OCI: 'Completed online check-in',
    ACCESS_OCI_PAGE: 'Accessed online check-in page',
    UPDATE_LANGUAGE: 'Updated reservation language',
    UPDATE_RESERVATION_LABEL: 'Updated special requests',
    INVOICE_INTEGRATED: 'Created integrated invoice',
    SEND_WELCOME_SMS: 'Sent welcome message via SMS',
    SEND_CONFIRMATION_SMS: 'Sent confirmation message via SMS',
    SEND_WELCOME_EMAIL: 'Sent welcome email',
    SEND_CONFIRMATION_EMAIL: 'Sent confirmation email',
    SEND_THANKYOU_EMAIL: 'Sent thank you email',
    SEND_THANKYOU_SMS: 'Sent thank you message via SMS',
    SEND_REMINDER_EMAIL: 'Sent reminder email',
    SEND_REMINDER_SMS: 'Sent reminder message via SMS',
    SEND_CANCELATION_EMAIL: 'Sent cancellation email',
    SEND_GUEST_INVITATION_EMAIL: 'Sent guest invitation email',
    SEND_GUEST_UNINVITE_EMAIL: 'Sent guest uninvited email',
    SEND_BLOCKED_OTA_CUSTOMER_EMAIL: 'Sent blocked OTA customer email',
    SEND_RESERVATION_CHANGE_SMS: 'Sent reservation change message via SMS',
    USER_OPEN_LINK_ACCESS: 'User opened link access',
    USER_OPEN_EMAIL: 'User opened email',
    UPDATE_RESERVATION_SEGMENT: "Updated reservation segment"
  };
  public static activityGroup:ActivityGroupType[] = [
    {
      type: "PARAGRAPH",
      actionList: [
        'ADD_COMMENT',
        'MANUAL_REFUNDED',
        'CANCEL_RESERVATION',
        'CHANGE_CUSTOMER_NOTIFICATION_CONFIG',
        'SHOW_DOOR_CODE',
        'RESET_DOOR_CODE',
        'UPDATE_CONTACT_INFO',
        'MANUAL_PAYMENT',
        'REFUND_FOR_UNSATISFIED_SERVICE',
        'CONFIRM_RESERVATION',
        'INVOICE_MANUAL',
        'CREDIT_INVOICE',
        'UPDATE_GUEST_INFO',
        'INVITE_GUEST',
        'REMOVE_GUEST',
        'COMPLETE_OCI',
        'ACCESS_OCI_PAGE',
        'UPDATE_RESERVATION_LABEL',
        'INVOICE_INTEGRATED',
        'SEND_WELCOME_SMS',
        'SEND_CONFIRMATION_SMS',
        'SEND_WELCOME_EMAIL',
        'SEND_CONFIRMATION_EMAIL',
        'SEND_THANKYOU_EMAIL',
        'SEND_THANKYOU_SMS',
        'SEND_REMINDER_EMAIL',
        'SEND_REMINDER_SMS',
        'SEND_CANCELATION_EMAIL',
        'SEND_GUEST_INVITATION_EMAIL',
        'SEND_GUEST_UNINVITE_EMAIL',
        'SEND_BLOCKED_OTA_CUSTOMER_EMAIL',
        'SEND_RESERVATION_CHANGE_SMS',
        'USER_OPEN_LINK_ACCESS',
        'USER_OPEN_EMAIL'
      ]
    },
    {
      type: "COMPARE",
      actionList: [
        'CHANGE_ROOM',
        'UPDATE_LANGUAGE',
        'UPDATE_CONTACT_INFO_NEW',
        'UPDATE_GUEST_INFO_NEW',
        'UPDATE_RESERVATION_SEGMENT'
      ]
    },
    {
      type: "RESERVATION",
      actionList: [
        'CREATE_RESERVATION',
        'UPDATE_RESERVATION'
      ]
    }
  ]
  public static activityGroupNeedPrevActivity:string[] = [
    "RESERVATION"
  ]
}