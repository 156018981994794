<div [hidden]="!hotelConfig.feature.giftCard">
  <div class="view-subtitle" style="margin-top: 40px">Monetary Voucher</div>
  <div class="row no-gutters">
    <div class="col-12 col-sm-8 col-md-9" id="monetary-table">
      <div class="voucher-table">
        <table class="custom-table table-input monetary-table">
          <thead>
            <tr>
              <th rowspan="2" class="name">Card #</th>
              <th rowspan="2" class="date-col">Valid<br>From</th>
              <th rowspan="2" class="date-col">Valid<br>To</th>
              <th colspan="2" class="date">Customer</th>
              <th rowspan="2" class="amount">Amount</th>
              <th rowspan="2">Used</th>
              <th rowspan="2">Remai-<br>ning</th>
              <th rowspan="2" class="edit">Edit</th>
            </tr>
            <tr>
              <th class="date-col">First<br>name</th>
              <th class="date-col">Last<br>name</th>
            </tr>
          </thead>
          <tbody>
            @for (monetary of monetaryResult; track monetary) {
              <tr class="new-row" [attr.data-monetaryId]="monetary.voucherID" (click)="viewMonetary(monetary)">
                <td>
                  <div class="name" tooltip="{{monetary.voucherKey}}" placement="right" (click)="copyVoucher(monetary.voucherKey)">{{monetary.voucherKey}}</div>
                </td>
                <td>{{utils.convertStringDate(monetary.validFrom)}}</td>
                @if ((editDetail && selectedMonetary != monetary.voucherID) || !editDetail) {
                  <td class="date-input">
                    {{utils.convertStringDate(monetary.validUntil)}}
                  </td>
                  <td class="customer date-input">
                    {{monetary.customerFirstName}}
                  </td>
                  <td class="customer date-input">
                    {{monetary.customerLastName}}
                  </td>
                }
                @if (editDetail && selectedMonetary == monetary.voucherID) {
                  <td class="date-input">
                    <dropdown-menu
                      menuType="datepicker"
                      [initialDate]="validUntil"
                      alignment="adaptive"
                      dateBtnStyle="table-compact"
                      [allowEmptyDate]="false"
                      (selectDate)="dateChange($event, 'validUntil')"
                    ></dropdown-menu>
                  </td>
                  <td class="customer date-input">
                    <input type="text" name="customerFirstName" value="{{monetary.customerFirstName}}"
                      (input)="validateInput('customerFirstName', $event)">
                  </td>
                  <td class="customer date-input">
                    <input type="text" name="customerLastName" value="{{monetary.customerLastName}}"
                      (input)="validateInput('customerLastName', $event)">
                  </td>
                }
                <td>{{monetary.amount}}</td>
                <td>{{monetary.used}}</td>
                <td>{{monetary.amount - monetary.used}}</td>
                <td class="edit">
                  @if (editDetail && selectedMonetary == monetary.voucherID) {
                    <button class="btn btn-red icon-only" (click)="deselectMonetary()"><fa-icon type="solid" icon="xmark"></fa-icon></button>
                  }
                  @if ((editDetail && selectedMonetary != monetary.voucherID) || !editDetail) {
                    <button class="btn btn-orange icon-only" (click)="editMonetary(monetary)"><fa-icon type="solid" icon="pen-to-square"></fa-icon></button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      @if (isLoading) {
        <div class="center-item">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
      }

      @if (monetaryCount > 0) {
        <div class="show-more" style="margin-bottom: 20px">
          <label>Showing {{monetaryResult.length}}/{{monetaryCount}}</label>
          @if (monetaryCount > 10 && monetaryResult.length != monetaryCount) {
            <button class="btn btn-green large-btn show-more-btn" (click)="callShowMore()"><fa-icon type="solid" icon="ellipsis"></fa-icon>Show {{monetaryCount - monetaryResult.length >= 10 ? 10 : monetaryCount - monetaryResult.length}} more</button>
          }
        </div>
      }

    </div>
    <div class="col-12 col-sm-4 col-md-3">
      <div id="monetary-details" [ngClass]="{'init-monetary-height': monetaryResult.length > 0}">
        <div class="top-sticky">
          <app-monetary-details (updateMonetary)="updateMonetary()" [loading]="disabledBtnWhileCallingAPI" [sendVoucher]="sendVoucher"></app-monetary-details>
        </div>
      </div>
    </div>
  </div>
</div>