import { Component, Input } from '@angular/core';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';
import { format } from 'date-fns';
import { ProductByIdType } from '../reservation-detail/reservation-detail.component';
import { CONSTANT } from 'src/app/helpers/constants';
import { ACTIVITYDUMMY } from 'src/app/helpers/dummyActivity';
import { TZDate } from '@date-fns/tz';

export type ActivityGroupType = {
  type: string,
  actionList: string[]
}
export type ReservationHotelType = {
  address: string;
  city: string;
  dropdownHotelName: string;
  hotelChainId: number;
  hotelChainName: string;
  hotelId: number;
  label: string;
  name: string;
  postalCode: string;
  timezone: string;
  zone: string;
};

export type ActivityLogType = {
  action: string,
  author: {
    email: string,
    name: string
  },
  data: any,
  id: number,
  reservationCode: string,
  timestamp: string,
  closestActivityIndex?: number 
}
export type ActivityDateGroupType = {
  date:string,
  activityList:ActivityLogType[]
}

@Component({
  selector: 'activity-log-2',
  templateUrl: './activity-log-2.component.html',
  styleUrl: './activity-log-2.component.sass'
})
export class ActivityLog2Component {
  @Input() activityLogResponse:ActivityLogType[] = [];
  @Input() hotel?:ReservationHotelType;
  @Input() products?:ProductByIdType;
  activityDateGroup:ActivityDateGroupType[] = [];
  activityDateGroupFiltered:ActivityDateGroupType[] = [];
  activityLogFull: ActivityLogType[] = [];
  sortList: MenuType[] = [
    {
      value: "newest",
      label: "Newest first",
      disabled: false
    },
    {
      value: "oldest",
      label: "Oldest first",
      disabled: false
    }
  ];
  selectedSort: MenuType = this.sortList[0];
  selectSort(item:MenuType) {
    this.selectedSort = item;
    this.sortActivityLog(item.value);
  }
  findActivityGroup(activity: ActivityLogType):string | null {
    let selectedGroup:string | null = null;
    if(activity) {
      CONSTANT.activityGroup.forEach(group => {
        const findAction = group.actionList.find(action => action === activity.action);
        if(findAction) {
          selectedGroup = group.type;
        }
      })
    }
    return selectedGroup;
  }
  sortActivityLog(type:string) {
    if(this.activityLogResponse.length && this.hotel) {
      let tmpActivityLog:ActivityLogType[] = JSON.parse(JSON.stringify(this.activityLogResponse));
      // let tmpActivityLog:ActivityLogType[] = ACTIVITYDUMMY.activity;
      switch (type) {
        case "newest":
          tmpActivityLog.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          break;
        
        case "oldest":
          tmpActivityLog.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
          break;
        default:
          break;
      }
      tmpActivityLog.forEach((activity, logIndex) => {
        let prevActivityIndex = undefined;
        let selectedGroup:string | null = this.findActivityGroup(activity);
        if(selectedGroup && CONSTANT.activityGroupNeedPrevActivity.includes(selectedGroup)) {
          tmpActivityLog.every((tmpActivity, tmpLogIndex) => {
            let findGroup:string | null = this.findActivityGroup(tmpActivity);
            if(findGroup && selectedGroup === findGroup) {
              switch (type) {
                case "newest":
                  if(tmpLogIndex > logIndex) {
                    prevActivityIndex = tmpLogIndex;
                    return false;
                  } else {
                    return true;
                  }
                
                case "oldest":
                  prevActivityIndex = tmpLogIndex;
                  if(logIndex === tmpLogIndex) {
                    return false;
                  } else {
                    return true;
                  }
                default:
                  break;
              }
            } else {
              return true;
            }
          })
        }
        activity.closestActivityIndex = prevActivityIndex;
      })
      
      let uniqueDays:string[] = [];
      tmpActivityLog.forEach(activity => {
        if(this.hotel) {
          const tmpDay = format(new TZDate(new Date(activity.timestamp), this.hotel.zone), "yyyy/MM/dd");
          if(!uniqueDays.includes(tmpDay)) {
            uniqueDays.push(tmpDay);
          }
        }
      })

      let tmpActivityDateGroup:ActivityDateGroupType[] = [];
      uniqueDays.forEach(day => {
        let tmpActivity:ActivityDateGroupType = {
          date: format(new Date(day), "EEEE, dd.MM.yyyy"),
          activityList: []
        }
        const activityDayFilter = tmpActivityLog.filter(activity => this.hotel && format(new TZDate(new Date(activity.timestamp), this.hotel.zone), "yyyy/MM/dd") === day);
        tmpActivity.activityList = tmpActivity.activityList.concat(activityDayFilter);
        tmpActivityDateGroup.push(tmpActivity)
      })
      this.activityLogFull = tmpActivityLog;
      this.activityDateGroup = tmpActivityDateGroup;
      this.activityDateGroupFiltered = tmpActivityDateGroup;
    }
  }
  setupActivityLog() {
    this.selectedSort = this.sortList[0];
    this.sortActivityLog("newest")
  }
}
