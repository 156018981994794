import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { DiscountTableComponent } from './discount/discount-table/discount-table.component';
import { MonetaryTableComponent } from './monetary/monetary-table/monetary-table.component';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { FilterMenuType, MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { addDays, getHours, getMinutes, isAfter, isBefore, set, subDays } from 'date-fns';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.sass']
})
export class VoucherComponent implements OnInit {

  @ViewChild(MonetaryTableComponent)
  private monetaryTable!: MonetaryTableComponent;
  @ViewChild(DiscountTableComponent)
  private discountTable!: DiscountTableComponent;

  title = 'Vouchers';
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  startDate:any;
  endDate:any;
  dateMode:string = "OVERLAP";
  disabledBtnWhileCallingAPI: boolean = false;
  prevSearchAll:boolean = false;
  prevObject: any = {
    'discountOffset': 0,
    'monetaryOffset': 0,
    'prevQuery': null,
    'prevHotel': null,
    'prevStartDate': null,
    'prevEndDate': null,
    'prevFilter': null,
    'prevDateMode': null,
  };

  //Result
  discountResult:any[] = [];
  discountCount:number = 0;
  monetaryResult:any[] = [];
  monetaryCount:number = 0;

  filterList:FilterMenuType[] = CONSTANT.VOUCHER_FILTER_LIST.filter(option => ((option.parentValue === "MONETARY_VOUCHER" || option.value === "MONETARY_VOUCHER") && this.hotelConfig.feature.giftCard) || (option.value !== "MONETARY_VOUCHER"));

  datepickerCustom: DatepickerDateCustomClasses[];

  constructor(
    private voucherService: VoucherService,
    private route: ActivatedRoute,
    private router: Router,
    public utils: UTILS,
    private storeService: StoreService,
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.voucherService.getHotels().subscribe(data => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectAllHotel();
      
      this.checkGiftcardParams();
    });
  }
  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }

  handleUpdatedFilters(newFilters:FilterMenuType[]) {
    this.filterList = newFilters;
  }

  voucherSearch = new UntypedFormGroup({
    searchQuery: new UntypedFormControl('', Validators.required),
  });

  updateDatepicker() {
    if(this.startDate && this.endDate) {
      let custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.startDate.getTime() + 24*3600*1000;
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }

  changeDateMode(mode:string) {
    this.dateMode = mode;
  }
  clearSearch() {
    this.filterList = CONSTANT.VOUCHER_FILTER_LIST.filter(option => ((option.parentValue === "MONETARY_VOUCHER" || option.value === "MONETARY_VOUCHER") && this.hotelConfig.feature.giftCard) || (option.value !== "MONETARY_VOUCHER"));
    this.discountTable.deselectVoucher();
    this.monetaryTable.deselectMonetary();
    this.discountResult = [];
    this.discountCount = 0;
    this.monetaryResult = [];
    this.monetaryCount = 0;
    this.startDate = null;
    this.endDate = null;
    this.dateMode = 'OVERLAP';
    this.voucherSearch.patchValue({
      searchQuery: ''
    })
    this.selectAllHotel();
  }
  //Datepicker
  dateChange(value: Date | undefined, type:string) {
    switch(type) {
      case 'startDate':
        {
          let tmpStartDate = value;
          let tmpEndDate = _.cloneDeep(this.endDate);
          if(tmpStartDate && tmpEndDate && isAfter(tmpStartDate, tmpEndDate)) {
            const prevHour = getHours(tmpEndDate);
            const prevMinute = getMinutes(tmpEndDate);
            tmpEndDate = set(addDays(new Date(tmpStartDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
        }
        break;
      case 'endDate':
        {
          let tmpStartDate = _.cloneDeep(this.startDate);
          let tmpEndDate = value;
          if(tmpStartDate && tmpEndDate && isBefore(tmpEndDate, tmpStartDate)) {
            const prevHour = getHours(tmpStartDate);
            const prevMinute = getMinutes(tmpStartDate);
            tmpStartDate = set(subDays(new Date(tmpEndDate), 1), {hours: prevHour, minutes: prevMinute});
          }
          this.startDate = tmpStartDate;
          this.endDate = tmpEndDate;
        }
        break;
    }
  }

  //Search
  setTableLoading(type:boolean) {
    this.discountTable.isLoading = type;
    this.monetaryTable.isLoading = type;
  }
  onSearch():void {
    this.disabledBtnWhileCallingAPI = true;
    this.discountTable.deselectVoucher();
    this.monetaryTable.deselectMonetary();
    this.discountResult = [];
    this.discountCount = 0;
    this.monetaryResult = [];
    this.monetaryCount = 0;
    
    this.setTableLoading(true);
    let query = this.voucherSearch.controls.searchQuery.value;
    let dateMode = this.dateMode;

    let hotelId;
    if(this.selectedHotel && this.selectedHotel.value != "all") {
      hotelId = Number(this.selectedHotel.value);
    } else {
      hotelId = null;
    }

    let selectedFilters:string[] = [];
    this.filterList.forEach(filter => {
      if(filter.checked) {
        selectedFilters.push(filter.value);
      }
    })
    let filter;
    if(selectedFilters.length == 0) {
      filter = null;
    } else {
      filter = selectedFilters;
    }

    let startDate;
    if(this.startDate) {
      startDate = this.utils.convertDate(this.startDate, 'YYYY-MM-dd');
    } else {
      startDate = null;
    }

    let endDate;
    if(this.endDate) {
      endDate = this.utils.convertDate(this.endDate, 'YYYY-MM-dd');
    } else {
      endDate = null;
    }

    this.prevObject.discountOffset = 0;
    this.prevObject.monetaryOffset = 0;
    this.prevObject.prevQuery = query;
    this.prevObject.prevHotel = hotelId;
    this.prevObject.prevStartDate = startDate;
    this.prevObject.prevEndDate = endDate;
    this.prevObject.prevFilter = filter;
    this.prevObject.prevDateMode = dateMode;

    this.getSearchResult(dateMode, startDate, endDate, hotelId, 0, query, filter, 'search');
  }

  checkGiftcardParams() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkGiftcardParams = ['isRedirectFromHA', 'giftcard'].every((key:any) => paramKeys.includes(key));
      if(checkGiftcardParams && params.get('isRedirectFromHA') == 'false') {
        let giftcard = params.get('giftcard');
        this.getSearchResult("OVERLAP", null, null, null, 0, giftcard, ["VOUCHER_CODE", "CUSTOMER_NAME", "EMAIL"], 'search', () => {
          this.monetaryTable.viewMonetary(this.monetaryResult[0]);
          this.monetaryTable.editMonetary(this.monetaryResult[0]);
        });
      }
    })
  }

  showMore(type:string) {
    let prev = this.prevObject;
    switch(type) {
      case 'discount':
        this.discountTable.isLoading = true;
        prev.discountOffset = prev.discountOffset + 10
        this.getSearchResult(prev.prevDateMode, prev.prevStartDate, prev.prevEndDate, prev.prevHotel, prev.discountOffset, prev.prevQuery, prev.prevFilter,'discount', () => {
          setTimeout(() => {
            let tableHeight = document.getElementById(type+'-table')!.offsetHeight;
            document.getElementById(type+'-details')!.style.height = tableHeight + 'px';
          }, 100);
        });
        break;
      case 'monetary':
        this.monetaryTable.isLoading = true;
        prev.monetaryOffset = prev.monetaryOffset + 10
        this.getSearchResult(prev.prevDateMode, prev.prevStartDate, prev.prevEndDate, prev.prevHotel, prev.monetaryOffset, prev.prevQuery, prev.prevFilter,'monetary', () => {
          setTimeout(() => {
            let tableHeight = document.getElementById(type+'-table')!.offsetHeight;
            document.getElementById(type+'-details')!.style.height = tableHeight + 'px';
          }, 100);
        });
        break;
    }
  }

  getSearchResult(dateMode:string, startDate:any, endDate:any, hotelId:any, offset:number, query:any, filter:string[] | null, type:string, callback:any = null): void {
    
    switch(type) {
      case 'discount':
        let discountRequest = {
          'dateMode': dateMode,
          'startDate': startDate,
          'endDate': endDate,
          'hotelId': hotelId,
          'limit': 10,
          'needToGetTotal': true,
          'offset': offset,
          'query': query,
          'optionalFilters': filter
        }
        if(this.prevSearchAll) {
          this.voucherService.getDiscountAll(discountRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.discountResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        } else {
          this.voucherService.getDiscount(discountRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.discountResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        }
        break;
      case 'monetary':
        let monetaryRequest = {
          'dateMode': dateMode,
          'startDate': startDate,
          'endDate': endDate,
          'hotelId': hotelId,
          'limit': 10,
          'needToGetTotal': true,
          'offset': offset,
          'query': query,
          'optionalFilters': filter
        }
        if(this.prevSearchAll) {
          this.voucherService.getMonetaryAll(monetaryRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.monetaryResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        } else {
          this.voucherService.getMonetary(monetaryRequest).subscribe(data => {
            data.vouchers.forEach((item:any) => {
              this.monetaryResult.push(item);
            });
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
            if(callback) {
              callback();
            }
          })
        }
        break;
      case 'search':
        let filterAll:boolean = true;
        this.filterList.every(filter => {
          if(!filter.checked) {
            filterAll = false;
            return false;
          }
          return true;
        })
        if(!query && !startDate && !endDate && !hotelId && filterAll) {
          let searchRequest = {
            'dateMode': dateMode,
            'startDate': startDate,
            'endDate': endDate,
            'hotelId': hotelId,
            'limit': 10,
            'needToGetTotal': true,
            'offset': offset,
            'query': query,
            'optionalFilters': filter
          }
          combineLatest([this.voucherService.getDiscountAll(searchRequest), this.voucherService.getMonetaryAll(searchRequest)]).subscribe(data => {
            this.discountResult = data[0].vouchers;
            this.discountCount = data[0].totalCount;
            this.monetaryResult = data[1].vouchers;
            this.monetaryCount = data[1].totalCount;
            this.setTableLoading(false);
            this.disabledBtnWhileCallingAPI = false;
          })
          this.prevSearchAll = true;
        } else {
          let filterDiscountArray:string[] = [];
          let filterMonetaryArray:string[] = [];
          let hasDiscountMain = false;
          let hasMonetaryMain = false;
          this.filterList.forEach(filter => {
            if(filter.value === "DISCOUNT_VOUCHER" && filter.checked) {
              hasDiscountMain = true;
              filterDiscountArray.push(filter.value);
            }
            if(filter.value === "MONETARY_VOUCHER" && filter.checked) {
              hasMonetaryMain = true;
              filterMonetaryArray.push(filter.value);
            }
          })
          this.filterList.forEach(filter => {
            if(filter.parentValue === "DISCOUNT_VOUCHER" && hasDiscountMain && filter.checked) {
              filterDiscountArray.push(filter.value);
            }
            if(filter.parentValue === "MONETARY_VOUCHER" && hasMonetaryMain && filter.checked) {
              filterMonetaryArray.push(filter.value);
            }
          })
          if(filterDiscountArray.length > 0) {
            let discountRequest = {
              'dateMode': dateMode,
              'startDate': startDate,
              'endDate': endDate,
              'hotelId': hotelId,
              'limit': 10,
              'needToGetTotal': true,
              'offset': offset,
              'query': query,
              'optionalFilters': filterDiscountArray
            }
            this.voucherService.getDiscount(discountRequest).subscribe(data => {
              this.discountResult = data.vouchers;
              this.discountCount = data.totalCount;
              this.setTableLoading(false);
              this.disabledBtnWhileCallingAPI = false;
            })
          }
          if(filterMonetaryArray.length > 0) {
            let monetaryRequest = {
              'dateMode': dateMode,
              'startDate': startDate,
              'endDate': endDate,
              'hotelId': hotelId,
              'limit': 10,
              'needToGetTotal': true,
              'offset': offset,
              'query': query,
              'optionalFilters': filterMonetaryArray
            }
            this.voucherService.getMonetary(monetaryRequest).subscribe(data => {
              this.monetaryResult = data.vouchers;
              this.monetaryCount = data.totalCount;
              this.setTableLoading(false);
              this.disabledBtnWhileCallingAPI = false;
              if(callback) {
                callback();
              }
            })
          }
          this.prevSearchAll = false;
        }
        break;
    }
  }

  //Check table
  getRestriction(data:any, type:string) {
    switch(type) {
      case 'bookStart':
      case 'bookEnd':
        let getData = _.find(data,{'type':'InvoiceDataRestriction'});
        if(getData) {
          if(type == 'bookStart') {
            return this.utils.convertStringDate(getData.dateFrom);
          } else {
            return this.utils.convertStringDate(getData.dateUntil);
          }
        } else {
          return null;
        }
      default:
        return null;
    }
  }

}