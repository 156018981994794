<!--View detail-->
@if (viewDetail && sendVoucher) {
  <table class="custom-table detail-table discount-table">
    <colgroup>
    <col style="width: 48%;">
    <col>
    </colgroup>
    <thead>
      <tr rowspan="2">
        <th colspan="2">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Type</b></td>
        <td>
          {{getVoucherType(sendVoucher.fixedPrice)}}
        </td>
      </tr>
      @if (sendVoucher.fixedPrice.length > 0) {
        <tr>
          <td colspan="2" style="text-align: center;">
            <div class="row no-gutters">
              <div class="col-3"><b>1st</b></div>
              <div class="col-3"><b>2nd</b></div>
              <div class="col-3"><b>3rd</b></div>
              <div class="col-3"><b>4th</b></div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="number-input" style="text-align: center;">
            <div class="row no-gap">
              <div class="col-3">
                {{sendVoucher.fixedPrice[0]}}
              </div>
              <div class="col-3">
                {{sendVoucher.fixedPrice[1]}}
              </div>
              <div class="col-3">
                {{sendVoucher.fixedPrice[2]}}
              </div>
              <div class="col-3">
                {{sendVoucher.fixedPrice[3]}}
              </div>
            </div>
          </td>
        </tr>
      }
      @if (sendVoucher.percentage) {
        <td class="percentage"><b>Percentage(%)</b></td>
        <td class="number-input">
          {{sendVoucher.percentage}}%
        </td>
      }
      @if (hotelConfig.feature.breakfast) {
        <tr>
          <td><b>Incl. Breakfast</b></td>
          <td>
            {{sendVoucher.includedBreakfast ? 'Yes' : 'No'}}
          </td>
        </tr>
      }
      <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
        <td><b>Include ECI</b></td>
        <td>
          {{sendVoucher.includedECI ? 'Yes' : 'No'}}
        </td>
      </tr>
      <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
        <td><b>Include LCO</b></td>
        <td>
          {{sendVoucher.includedLCO ? 'Yes' : 'No'}}
        </td>
      </tr>
      <tr>
        <td><b>Refundable</b></td>
        <td>
          {{sendVoucher.refundable ? 'Yes' : 'No'}}
        </td>
      </tr>
      @if (hotelList.length > 1) {
        <tr>
          <td><b>Hotel</b></td>
          <td>
            {{getRestriction(sendVoucher.restrictions,'hotel')}}
          </td>
        </tr>
      }
      <tr>
        <td><b>Day</b></td>
        <td>
          {{getRestriction(sendVoucher.restrictions,'weekday')}}
        </td>
      </tr>
      <tr>
        <td><b>Min Nights</b></td>
        <td>
          {{getRestriction(sendVoucher.restrictions,'minNights')}}
        </td>
      </tr>
      <tr>
        <td><b>Max Nights</b></td>
        <td>
          {{getRestriction(sendVoucher.restrictions,'maxNights')}}
        </td>
      </tr>
      <tr>
        <td><b>Category</b></td>
        <td>
          {{getCategory(sendVoucher.categoryId)}}
        </td>
      </tr>
      <tr>
        <td><b>Group Segment</b></td>
        <td>
          @if (!editDetail) {
            {{getSegment(sendVoucher.segmentId)}}
          }
          @if (editDetail) {
            <dropdown-menu
              [menuList]="segmentList"
              [selectedItem]="selectedSegment"
              alignment="right"
              [searchable]="true"
              [minWidth]="200"
              buttonFontSize="14px"
              [fullWidth]="true"
              (selectItem)="selectSegment($event)"
            ></dropdown-menu>
          }
        </td>
      </tr>
      <tr>
        <td><b>Description</b></td>
        <td class="textarea-input">
          @if (!editDetail) {
            <span style="word-wrap: break-word;">{{sendVoucher.comment}}</span>
          }
          @if (editDetail) {
            <textarea rows="4" (input)="validateDetailInput('description',$event)">{{sendVoucher.comment}}</textarea>
          }
        </td>
      </tr>
      @if (hasAllotRole) {
        <tr>
          <td><b>Allotment</b></td>
          <td>
            @if (!editDetail) {
              {{getAllotment(sendVoucher.allotmentId)}}
            }
            @if (editDetail) {
              <dropdown-menu
                [menuList]="allotmentList"
                [selectedItem]="selectedAllotment"
                alignment="right"
                [minWidth]="200"
                [buttonFontSize]="'14px'"
                [fullWidth]="true"
                [searchable]="true"
                (selectItem)="selectAllotment($event)"
              ></dropdown-menu>
            }
          </td>
        </tr>
      }
      @if (!editDetail) {
        <tr>
          <td><b>Created by</b></td>
          <td style="word-wrap:break-word">
            {{sendVoucher.createdBy}}
          </td>
        </tr>
      }
      @if (editDetail) {
        <tr>
          <td colspan="2" style="text-align: center;">
            <button class="btn btn-green large-btn" [disabled]="loading" (click)="updateVoucher()"><fa-icon type="solid" icon="floppy-disk"></fa-icon>Update</button>
          </td>
        </tr>
      }
    </tbody>
  </table>
}

<!--New detail-->
@if (newDetailShow) {
  <table class="custom-table detail-table discount-table">
    <colgroup>
    <col style="width: 48%;">
    <col>
    </colgroup>
    <thead>
      <tr rowspan="2">
        <th colspan="2">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Type</b></td>
        <td>
          <ng-container>
            <div class="normal-checkbox radio-style">
              <input type="radio" name="type-selection" [checked]="detailType == 'FIXED_PRICE'"
                (click)="toggleDetailType('FIXED_PRICE')">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Fixed price</div>
            </div>
          </ng-container>
          <div class="normal-checkbox radio-style" style="margin-top: 5px">
            <input type="radio" name="type-selection" [checked]="detailType == 'PERCENTAGE'"
              (click)="toggleDetailType('PERCENTAGE')">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Percentage</div>
          </div>
        </td>
      </tr>
      @if (detailType == 'FIXED_PRICE') {
        <tr>
          <td colspan="2" style="text-align: center;">
            <div class="row no-gutters">
              <div class="col-3"><b>1st</b></div>
              <div class="col-3"><b>2nd</b></div>
              <div class="col-3"><b>3rd</b></div>
              <div class="col-3"><b>4th</b></div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="number-input" style="text-align: center;">
            <div class="row no-gap">
              <div class="col-3">
                <div class="custom-input-number">
                  <input type="number" min="0" maxlength="7" name="firstPrice" [(ngModel)]="orderFixedPrice.firstPrice"
                    (input)="validateDetailInput('firstPrice',$event)">
                </div>
              </div>
              <div class="col-3">
                <div class="custom-input-number">
                  <input type="number" min="0" maxlength="7" name="secondPrice" [(ngModel)]="orderFixedPrice.secondPrice"
                    (input)="validateDetailInput('secondPrice',$event)">
                </div>
              </div>
              <div class="col-3">
                <div class="custom-input-number">
                  <input type="number" min="0" maxlength="7" name="thirdPrice" [(ngModel)]="orderFixedPrice.thirdPrice"
                    (input)="validateDetailInput('thirdPrice',$event)">
                </div>
              </div>
              <div class="col-3">
                <div class="custom-input-number">
                  <input type="number" min="0" maxlength="7" name="fourthPrice" [(ngModel)]="orderFixedPrice.fourthPrice"
                    (input)="validateDetailInput('fourthPrice',$event)">
                </div>
              </div>
            </div>
          </td>
        </tr>
      }
      @if (detailType == 'PERCENTAGE') {
        <td><b>Percentage(%)</b></td>
        <td class="number-input">
          <div class="custom-input-number">
            <input type="number" min="0" max="100" maxlength="3" name="percentage" [(ngModel)]="orderFixedPrice.percentage"
              (input)="validateDetailInput('percentage',$event)">
          </div>
        </td>
      }
      @if (hotelConfig.feature.breakfast) {
        <tr>
          <td><b>Incl. Breakfast</b></td>
          <td>
            <div class="normal-checkbox" [ngClass]="{'disabled': (!hotelConfig.MUIfeature.allowBreakfast100Percent && detailType === 'PERCENTAGE') || (detailType === 'PERCENTAGE' && orderFixedPrice.percentage != 100 && !newDetail100Show)}">
              <input type="checkbox" [checked]="detailGeneral['breakfast']"
                (click)="changeDetailCheckbox('breakfast', $event)">
              <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
            </div>
          </td>
        </tr>
      }
      <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
        <td><b>Include ECI</b></td>
        <td>
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="detailGeneral['eci']" (click)="changeDetailCheckbox('eci', $event)">
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
          </div>
        </td>
      </tr>
      <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
        <td><b>Include LCO</b></td>
        <td>
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="detailGeneral['lco']" (click)="changeDetailCheckbox('lco', $event)">
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
          </div>
        </td>
      </tr>
      <tr>
        <td><b>Refundable</b></td>
        <td>
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="detailGeneral['refund']" (click)="changeDetailCheckbox('refund', $event)">
            <div class="checkbox-box"><fa-icon type="solid" icon="check"></fa-icon></div>
          </div>
        </td>
      </tr>
      @if (hotelList.length > 1) {
        <tr>
          <td><b>Hotel</b></td>
          <td>
            <dropdown-menu
              menuType="filter"
              [filterList]="hotelFilterList"
              [compactInfo]="true"
              [fullWidth]="true"
              [allOption]="true"
              (updatedList)="handleUpdatedFiltersHotel($event)"
            ></dropdown-menu>
          </td>
        </tr>
      }
      <tr>
        <td><b>Day</b></td>
        <td>
          <dropdown-menu
            menuType="filter"
            [filterList]="weekdaysList"
            [compactInfo]="true"
            [fullWidth]="true"
            [allOption]="true"
            (updatedList)="handleUpdatedFilters($event)"
          ></dropdown-menu>
        </td>
      </tr>
      <tr>
        <td><b>Min Nights</b></td>
        <td class="number-input">
          <div class="custom-input-number">
            <input type="number" name="minNights" (blur)="nightInput('minNights',$event)" [(ngModel)]="nightsInput.minNights">
          </div>
        </td>
      </tr>
      <tr>
        <td><b>Max Nights</b></td>
        <td class="number-input">
          <div class="custom-input-number">
            <input type="number" name="maxNights" (blur)="nightInput('maxNights',$event)" [(ngModel)]="nightsInput.maxNights">
          </div>
        </td>
      </tr>
      <tr>
        <td><b>Category</b></td>
        <td>
          <dropdown-menu
            [menuList]="categoryList"
            [selectedItem]="selectedCategory"
            [highlightError]="categoryError"
            [minWidth]="150"
            [buttonFontSize]="'14px'"
            [fullWidth]="true"
            (selectItem)="selectCategory($event)"
          ></dropdown-menu>
        </td>
      </tr>
      <tr>
        <td><b>Group Segment</b></td>
        <td>
          <dropdown-menu
            [menuList]="segmentList"
            [selectedItem]="selectedSegment"
            [searchable]="true"
            [minWidth]="200"
            buttonFontSize="14px"
            [fullWidth]="true"
            (selectItem)="selectSegment($event)"
          ></dropdown-menu>
        </td>
      </tr>
      <tr>
        <td><b>Description</b></td>
        <td class="textarea-input">
          <textarea rows="4" (input)="validateDetailInput('description',$event)"></textarea>
        </td>
      </tr>
      @if (hasAllotRole) {
        <tr>
          <td><b>Allotment</b></td>
          <td>
            <dropdown-menu
              [menuList]="allotmentList"
              [selectedItem]="selectedAllotment"
              [minWidth]="200"
              [buttonFontSize]="'14px'"
              [fullWidth]="true"
              [searchable]="true"
              (selectItem)="selectAllotment($event)"
            ></dropdown-menu>
          </td>
        </tr>
      }
      <tr>
        <td colspan="2" style="text-align: center;">
          <button class="btn btn-green large-btn" [disabled]="loading" (click)="addVoucher()"><fa-icon type="solid" icon="plus"></fa-icon>Create</button>
        </td>
      </tr>
    </tbody>
  </table>
}
